import { useMemo, useRef } from "react";

import { MdDelete, MdFileUpload, MdOpenInNew } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";

import PRButton from "~components/Generic/PRButton";
import PRContainer from "~components/Generic/PRContainer";
import PRTable from "~components/Generic/PRTable";
import { apiUrlProject, tableFilterStorageKey } from "~constants";
import DialogHelper from "~helpers/DialogHelper";
import UploadModal from "~pages/ChatBot/DialogComponents/UploadModal";
import { deleteProjectFile, uploadProjectFile } from "~store/settings/projectSettings/actions";
import { selectCurrentProject } from "~store/user/selectors";

function Assets() {
  const tableRef = useRef(null);
  const dispatch = useDispatch();

  const currentProject = useSelector(selectCurrentProject);

  const handleClickUpload = async () => {
    const result = await UploadModal?.show({
      title: "Upload Media",
      noRename: true,
      enableTypeSelect: true,
    });
    if (result?.length) {
      await dispatch(uploadProjectFile(currentProject.id, result[0].file, result[0].type));
      tableRef.current.refresh();
      UploadModal?.hide();
    }
  };

  const columns = useMemo(() => {
    const handleClickDelete = (id) => async () => {
      if (await DialogHelper.showQuestionDelete()) {
        await dispatch(deleteProjectFile(currentProject.id, id));
        tableRef.current.refresh();
      }
    };

    // const handleClickEdit = (row) => async () => {
    //   const result = await RenameFileModal?.show({ title: "Edit Media", name: row.name });
    //   if (result) {
    //     await dispatch(updateMedia(currentProject.id, currentBot.id, { id: row.id, name: result }));
    //     tableRef.current.refresh();
    //     RenameFileModal?.hide();
    //   }
    // };

    // const handleClickViewImage = (row) => async () => {
    //   ImagePreviewModal.show({ image: row.media_url, title: row.name });
    // };

    return [
      {
        label: "Name",
        key: "name",
        render: (row) => {
          const url = new URL(row.file);
          return url.pathname.split("/").pop();
        },
      },
      {
        label: "Type",
        key: "file_type",
      },
      {
        label: "Actions",
        key: "actions",
        actions: true,
        fixed: "right",
        render: (row) => {
          const url = new URL(row.file);
          const newUrl = `${window.location.origin}${url.pathname}`;
          // const extension = row.media_url.split(".").pop();
          // const isPreviewAvailable = ["jpg", "jpeg", "png", "gif", "bmp", "svg"].includes(extension.toLowerCase());
          return (
            <div className="d-flex justify-content-center">
              <PRButton
                outline
                className="ms-1"
                color="secondary"
                icon={MdOpenInNew}
                link={newUrl}
                linkProps={{ newTab: true, noPrefix: true }}
                size="sm"
                tooltipText="Open in new tab"
              />
              {/* <PRButton
                size="sm"
                outline
                className="ms-1"
                color="primary"
                onClick={handleClickEdit(row)}
                icon={MdEdit}
                tooltipText="Edit"
              /> */}
              <PRButton
                outline
                className="ms-1"
                color="danger"
                icon={MdDelete}
                size="sm"
                tooltipText="Delete"
                onClick={handleClickDelete(row.uuid)}
              />
            </div>
          );
        },
      },
    ];
  }, [dispatch, currentProject.id]);

  const actions = [
    {
      label: "Upload Media",
      icon: MdFileUpload,
      color: "success",
      onClick: handleClickUpload,
    },
  ];

  const parentName = [
    {
      label: "Assets",
      url: "/settings/assets/",
    },
  ];
  return (
    <PRContainer
      actions={actions}
      description={"Here you can manage your assets for your project."}
      name="Settings"
      parentName={parentName}
    >
      <PRTable
        ref={tableRef}
        columns={columns}
        storageKey={tableFilterStorageKey.assetsList}
        url={apiUrlProject.getFiles.format(currentProject.id)}
      />
    </PRContainer>
  );
}

export default Assets;
