import { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import styled from "@emotion/styled";
import { FormControlLabel, Grid, Switch } from "@mui/material";

import useLoading from "~common/hooks/useLoading";
import PRChart from "~components/Generic/PRChart";
import chartTheme from "~components/Generic/PRChart/theme.json";
import PalContainer from "~components/mui/PalContainer";
import PalPage from "~components/mui/PalPage";
import ExcelButton from "~components/Statistics/ExcelButton";
import FilterCard from "~components/Statistics/FilterCard";
import FileHelper from "~helpers/FileHelper";
import { getCallCenterQueueWaitStatistics } from "~store/statistics/actions";
import { selectStatistics, selectStatisticsFilter } from "~store/statistics/selectors";

const dataNames = {
  min30: "30 sec. queue wait time",
  min60: "1 min. queue wait time",
  min300: "5 min. queue wait time",
  min300Plus: "5+ min. queue wait time",
  missed: "Customer Missed",
  agentJoined: "Agent Joined",
  ticketOpened: "Ticket Opened",
};
const CallCenterQueueWaitStatistics = ({ hideBlanks }) => {
  const { projectId } = useParams();
  const [loading, q] = useLoading();
  const dispatch = useDispatch();
  const filter = useSelector(selectStatisticsFilter);
  const statistics = useSelector(selectStatistics);
  useEffect(() => {
    q(dispatch(getCallCenterQueueWaitStatistics(projectId, filter.beginDate, filter.endDate)));
  }, [dispatch, projectId, q, filter.beginDate, filter.endDate]);

  const chartStyle = {
    height: "50vh",
  };

  const links = [
    {
      source: dataNames.min30,
      target: dataNames.missed,
      value: statistics?.CALLCENTER_QUEUE_WAIT_30_MISSED,
    },
    {
      source: dataNames.min30,
      target: dataNames.agentJoined,
      value: statistics?.CALLCENTER_QUEUE_WAIT_30_CATCHED,
    },
    {
      source: dataNames.min30,
      target: dataNames.ticketOpened,
      value: statistics?.CALLCENTER_QUEUE_WAIT_30_TICKET,
    },
    {
      source: dataNames.min60,
      target: dataNames.missed,
      value: statistics?.CALLCENTER_QUEUE_WAIT_60_MISSED,
    },
    {
      source: dataNames.min60,
      target: dataNames.agentJoined,
      value: statistics?.CALLCENTER_QUEUE_WAIT_60_CATCHED,
    },
    {
      source: dataNames.min60,
      target: dataNames.ticketOpened,
      value: statistics?.CALLCENTER_QUEUE_WAIT_60_TICKET,
    },
    {
      source: dataNames.min300,
      target: dataNames.missed,
      value: statistics?.CALLCENTER_QUEUE_WAIT_300_MISSED,
    },
    {
      source: dataNames.min300,
      target: dataNames.agentJoined,
      value: statistics?.CALLCENTER_QUEUE_WAIT_300_CATCHED,
    },
    {
      source: dataNames.min300,
      target: dataNames.ticketOpened,
      value: statistics?.CALLCENTER_QUEUE_WAIT_300_TICKET,
    },
    {
      source: dataNames.min300Plus,
      target: dataNames.missed,
      value: statistics?.CALLCENTER_QUEUE_WAIT_300_PLUS_MISSED,
    },
    {
      source: dataNames.min300Plus,
      target: dataNames.agentJoined,
      value: statistics?.CALLCENTER_QUEUE_WAIT_300_PLUS_CATCHED,
    },
    {
      source: dataNames.min300Plus,
      target: dataNames.ticketOpened,
      value: statistics?.CALLCENTER_QUEUE_WAIT_300_PLUS_TICKET,
    },
  ];
  const filteredLinks = hideBlanks ? links.filter((link) => link.value > 0) : links;
  const data = [
    {
      name: dataNames.min30,
      itemStyle: {
        color: chartTheme.theme.color[5],
      },
    },
    {
      name: dataNames.min60,
      itemStyle: {
        color: chartTheme.theme.color[9],
      },
    },
    {
      name: dataNames.min300,
      itemStyle: {
        color: chartTheme.theme.color[2],
      },
    },
    {
      name: dataNames.min300Plus,
      itemStyle: {
        color: chartTheme.theme.color[6],
      },
    },
    {
      name: dataNames.missed,
      itemStyle: {
        color: chartTheme.theme.color[3],
      },
    },
    {
      name: dataNames.agentJoined,
      itemStyle: {
        color: chartTheme.theme.color[0],
      },
    },
    {
      name: dataNames.ticketOpened,
      itemStyle: {
        color: chartTheme.theme.color[2],
      },
    },
  ];
  const filteredData = hideBlanks
    ? data.filter((d) => {
        return filteredLinks.some((link) => link.source === d.name || link.target === d.name);
      })
    : data;

  const options = {
    tooltip: {
      trigger: "item",
      formatter: function (params) {
        if (params.dataType === "node") {
          return `${params.name} (${params.value})`;
        } else {
          return `${params.data.source} (${params.data.value}) → ${params.data.target}`;
        }
      },
    },
    label: {
      show: true,
      position: "inside",
      formatter: "{b} ({c})",
    },
    series: {
      type: "sankey",
      layout: "none",
      emphasis: {
        focus: "adjacency",
      },
      draggable: false,
      data: filteredData,
      links: filteredLinks,
    },
  };
  return <PRChart loading={loading} option={options} style={chartStyle} title="Session Count Sankey" />;
};

const StyledGrid = styled(Grid)`
  align-items: center;
  display: flex;
  justify-content: center;
  align-self: center;
`;

const CallCenterStatistics = () => {
  const [hideBlanks, setHideBlanks] = useState(true);
  const { projectId } = useParams();
  const dispatch = useDispatch();
  const filter = useSelector(selectStatisticsFilter);

  const handleHideBlanks = () => {
    setHideBlanks(!hideBlanks);
  };

  const handleExcelExport = async () => {
    const buffer = await dispatch(getCallCenterQueueWaitStatistics(projectId, filter.beginDate, filter.endDate, true));
    FileHelper.saveAs(buffer, "statistics", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet");
  };

  return (
    <PalContainer
      description={"Here you can see the statistics of the call center."}
      name="Statistics"
      parentName="Call Center Statistics"
    >
      <Grid container>
        <Grid item xs={12}>
          <FilterCard />
        </Grid>
      </Grid>
      <PalPage collapse>
        <Grid container>
          <Grid
            item
            md
            display="flex"
            justifyContent="flex-end"
            sx={{
              display: "flex",
              alignItems: "center",
              label: {
                margin: 0,
                padding: 0,
              },
              pr: 1,
            }}
            xs={10}
          >
            <FormControlLabel
              checked={hideBlanks}
              control={<Switch />}
              label="Hide blanks"
              onChange={handleHideBlanks}
            />
          </Grid>
          <StyledGrid item md={"auto"} xs={2}>
            <ExcelButton onClick={handleExcelExport} />
          </StyledGrid>
          <Grid item xs={12}>
            <CallCenterQueueWaitStatistics hideBlanks={hideBlanks} />
          </Grid>
        </Grid>
      </PalPage>
    </PalContainer>
  );
};
export default CallCenterStatistics;
