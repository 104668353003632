import { useEffect, useMemo, useRef, useState } from "react";

import { withCardon } from "cardon";
import { useFormik } from "formik";
import { MdContentPaste, MdDelete, MdHelp, MdKey } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { Col, Label, Row } from "reactstrap";
import * as Yup from "yup";

import { Switch } from "@mui/material";

import PRButton from "~components/Generic/PRButton";
import { PRDividerLabel } from "~components/Generic/PRDivider";
import PRInput from "~components/Generic/PRInput";
import PRModal from "~components/Generic/PRModal";
import PRTable from "~components/Generic/PRTable";
import PRTooltip from "~components/Generic/PRTooltip";
import { apiUrlOrganization } from "~constants";
import AlertHelper from "~helpers/AlertHelper";
import DateHelper from "~helpers/DateHelper";
import DialogHelper from "~helpers/DialogHelper";
import { createOrUpdateSecretKey, deleteSecretKey } from "~store/organization/actions";
import { selectCurrentProject } from "~store/user/selectors";

function CredentialsManagerModalContent({ get, value = "" }) {
  const [generatedKey, setGeneratedKey] = useState();
  const dispatch = useDispatch();
  const currentProject = useSelector(selectCurrentProject);
  //   const secretKeyList = useSelector(selectSecretKeyList);

  const [keepMissingMode, setKeepMissingMode] = useState(true);
  const tableRef = useRef(null);

  const formik = useFormik({
    initialValues: {
      name: "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Required").min(3, "Minimum 3 characters"),
    }),
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      const response = await dispatch(createOrUpdateSecretKey(currentProject.id, values, keepMissingMode));
      tableRef.current.refresh();
      setGeneratedKey(response);
      setSubmitting(false);
    },
  });

  useEffect(() => {
    // dispatch(getSecretKeyList(currentProject.id));
  }, [dispatch, currentProject.id]);

  const handleSwitchChange = (event) => {
    setKeepMissingMode(event.target.checked);
  };

  const columns = useMemo(() => {
    const handleClickDelete = (id) => async () => {
      if (await DialogHelper.showQuestionDelete()) {
        await dispatch(deleteSecretKey(currentProject.id, id));
        if (id === generatedKey?.id) {
          setGeneratedKey(null);
        }
        tableRef.current.refresh();
      }
    };

    return [
      {
        label: "Name",
        key: "name",
      },
      {
        label: "Expires in",
        key: "expires_in",
        render: (row) => {
          return DateHelper.getDateTimeLocal(row.expires_in).format("LLT");
        },
      },
      {
        label: "Secret Key",
        key: "masked_secret_key",
      },
      {
        label: "Update Mode",
        key: "keep_missing",
        render(row) {
          return row.keep_missing ? "Update And Create" : "Exact Replacement";
        },
      },
      {
        label: "Actions",
        key: "actions",
        actions: true,
        fixed: "right",
        render: (row) => {
          //   const url = new URL(row.data_file);
          //   const newUrl = `${window.location.origin}${url.pathname}`;

          return (
            <div className="d-flex justify-content-center">
              <PRButton
                outline
                className="ms-1"
                color="danger"
                icon={MdDelete}
                size="sm"
                tooltipText="Delete"
                onClick={handleClickDelete(row.id)}
              />
            </div>
          );
        },
      },
    ];
  }, [dispatch, currentProject.id, generatedKey]);

  const handleClickCopyKey = () => {
    navigator.clipboard.writeText(generatedKey?.secret_key);
    AlertHelper.showSuccess("Key copied to clipboard");
  };
  return (
    <PRModal size="lg" submitText={""} title="API Credentials" onClose={get(false)}>
      <p>
        You can generate a token here to upload a customer through an API request. The generated token information will
        be visible only once and cannot be viewed subsequently.
      </p>

      <p>
        To use the generated key, a Form post operation must be performed to the{" "}
        <span className="bg-secondary bg-soft rounded px-1 mx-1">
          "{window.location.origin}
          {process.env.PUBLIC_URL}/api/project/organization/load-data/"
        </span>
        address. The key value must be sent as
        <span className="bg-secondary bg-soft rounded px-1 mx-1">"secret_key"</span>, and the file should be sent with
        the key value <span className="bg-secondary bg-soft rounded px-1 mx-1">"file"</span>, as a file type.
      </p>
      <Row className="g-2">
        <Col className="d-flex  align-items-center" xs={"12"}>
          <Label className="mt-2 me-2">Name:</Label>

          <PRInput
            className="w-100"
            invalid={formik.touched.name && formik.errors.name}
            name="name"
            value={formik.values.name}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
          />
        </Col>
        <Col xs></Col>
        <Col className="d-flex align-items-center" xs={"auto"}>
          {keepMissingMode ? "Update And Create" : "Exact Replacement"}
          <PRTooltip title="If enabled, the existing members will not be deleted for this key. If disabled, the existing members will be deleted and replaced with the new members.">
            <span>
              <MdHelp className="ms-1 me-1" />
            </span>
          </PRTooltip>
          <Switch checked={keepMissingMode} inputProps={{ "aria-label": "controlled" }} onChange={handleSwitchChange} />
        </Col>
        <Col xs={"auto"}>
          <PRButton icon={MdKey} onClick={formik.handleSubmit}>
            Generate
          </PRButton>
        </Col>
      </Row>
      {generatedKey?.secret_key && (
        <div className="fs-5 text-center border border-2 border-orange p-2 my-4">
          <span className="fs-4">
            <div>
              <MdKey /> {generatedKey?.name}
            </div>

            <div className="d-flex justify-content-center align-items-center">
              <span className="  bg-secondary bg-soft rounded px-1 mx-1">{generatedKey?.secret_key}</span>
              <PRButton
                noBorder
                outline
                color="secondary"
                icon={MdContentPaste}
                tooltipText="Copy to clipboard"
                onClick={handleClickCopyKey}
              />
            </div>
          </span>
          Do not share this key with anyone. You will not be able to see this key again. If you lose this key, you can
          create a new one.
        </div>
      )}

      <PRDividerLabel pageSeparator className="my-3" color="secondary">
        API Credentials
      </PRDividerLabel>

      <PRTable
        ref={tableRef}
        inline
        noPagination
        columns={columns}
        responseDataCountKey={""}
        responseDataKey={""}
        url={apiUrlOrganization.getSecretKey.format(currentProject.id)}
      />
    </PRModal>
  );
}

const CredentialsManagerModal = withCardon(CredentialsManagerModalContent, { destroyOnHide: true });
export default CredentialsManagerModal;
