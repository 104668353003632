import { useCallback, useEffect, useRef, useState } from "react";

import { MdEdit } from "react-icons/md";
import { useSelector } from "react-redux";
import { Button, Col, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";

import { updateMessage } from "~apiRequests/bot.messages";
import PRButton from "~components/Generic/PRButton";
import PRContainer from "~components/Generic/PRContainer";
import { modalZIndex } from "~components/Generic/PRModal";
import PRTable from "~components/Generic/PRTable";
import { apiUrlChatbot, tableFilterStorageKey } from "~constants";
import AlertHelper from "~helpers/AlertHelper";
import { selectCurrentBot, selectCurrentProject } from "~store/user/selectors";

function BotMessages() {
  const [isUpdateOpen, setUpdateOpen] = useState(false);
  const [currentMessage, setCurrentMessage] = useState(null);
  const tableRef = useRef(null);

  const currentProject = useSelector(selectCurrentProject);
  const currentBot = useSelector(selectCurrentBot);

  const toggleUpdateOpen = useCallback((variable) => {
    setUpdateOpen((prevState) => !prevState);
    setCurrentMessage(variable);
  }, []);

  const handleClickEdit = useCallback(
    (variable) => () => {
      toggleUpdateOpen(variable);
    },
    [toggleUpdateOpen]
  );

  const columns = [
    {
      label: "Key",
      key: "message_type_raw",
      noWrap: true,
    },
    {
      label: "Message Type",
      key: "message_type",
      noWrap: true,
    },
    {
      label: "Message",
      key: "message",
    },
    {
      label: "Actions",
      key: "actions",
      actions: true,
      render: (row) => (
        <div className="d-flex justify-content-center">
          <PRButton outline color="primary" icon={MdEdit} size="sm" tooltipText="Edit" onClick={handleClickEdit(row)} />
        </div>
      ),
    },
  ];

  const parentName = [
    {
      label: "Bot Messages",
    },
  ];
  return (
    <PRContainer
      smalltalkSelector
      description={"Here you can manage your default bot messages."}
      name="Chatbot"
      parentName={parentName}
    >
      <UpdateMessage
        currentMessage={currentMessage}
        isOpen={isUpdateOpen}
        tableRef={tableRef}
        toggle={toggleUpdateOpen}
      />

      <PRTable
        ref={tableRef}
        columns={columns}
        storageKey={tableFilterStorageKey.botMessagesList}
        url={apiUrlChatbot.getBotMessage.format(currentProject.id, currentBot.id)}
      />
    </PRContainer>
  );
}

function UpdateMessage({ isOpen, toggle, currentMessage, tableRef }) {
  const [message, setMessage] = useState(currentMessage ? currentMessage.message : "");

  useEffect(() => {
    if (currentMessage) {
      setMessage(currentMessage?.message);
    }
  }, [currentMessage]);

  const currentProject = useSelector(selectCurrentProject);
  const currentBot = useSelector(selectCurrentBot);

  return (
    <Modal
      autoFocus={true}
      centered={true}
      className="exampleModal"
      isOpen={isOpen}
      role="dialog"
      tabIndex="-1"
      toggle={toggle}
      zIndex={modalZIndex}
    >
      <div className="modal-content">
        <ModalHeader toggle={toggle}>Update Message</ModalHeader>
        <ModalBody>
          <div className="mb-3">
            <Row>
              <Label className="col-form-label col-lg-2" htmlFor="contactmail">
                Message
              </Label>
              <Col lg="10">
                <Input
                  className="form-control"
                  name="title"
                  placeholder="Enter Key"
                  rows="3"
                  type="textarea"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                />
              </Col>
            </Row>
          </div>
        </ModalBody>
      </div>
      <ModalFooter>
        <Button
          color="primary"
          onClick={(e) => {
            if (message.length === 0) {
              AlertHelper.show("You need to fill all areas");
              return;
            }

            updateMessage(
              currentProject.id,
              currentBot.id,
              {
                message: message,
              },
              currentMessage.id
            ).then(() => {
              AlertHelper.show("Operation Successful");
              toggle();
              tableRef.current?.refresh();
            });
          }}
        >
          Update
        </Button>
      </ModalFooter>
    </Modal>
  );
}

export { BotMessages };
