import { useEffect, useMemo, useRef, useState } from "react";

import { useTranslation } from "react-i18next";
import { BiLoaderAlt } from "react-icons/bi";
import {
  MdAdd,
  MdAutoAwesome,
  MdCode,
  MdDelete,
  MdDownload,
  MdEdit,
  MdFileUpload,
  MdPieChart,
  MdPriorityHigh,
} from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";

import { deleteIntents, downloadIntents, uploadIntents } from "~apiRequests/intent";
import useProjectChange from "~common/hooks/useProjectChange";
import useQueryParams from "~common/hooks/useQueryParams";
import LowCodeEditorModal from "~common/modals/LowCodeEditorModal";
import PRButton from "~components/Generic/PRButton";
import PRContainer from "~components/Generic/PRContainer";
import PRDropZone from "~components/Generic/PRDropZone";
import PRPage from "~components/Generic/PRPage";
import PRPopover from "~components/Generic/PRPopover";
import PRTable from "~components/Generic/PRTable";
import PRTooltip from "~components/Generic/PRTooltip";
import {
  apiUrlChatbot,
  dialogComponentsIntentType,
  dialogComponentsRagFactGroupStatus,
  dialogComponentsResponseMessageTypeOptions,
  tableFilterStorageKey,
  userRole,
} from "~constants";
import AlertHelper from "~helpers/AlertHelper";
import DialogHelper from "~helpers/DialogHelper";
import FileHelper from "~helpers/FileHelper";
import HistoryHelper from "~helpers/HistoryHelper";
import Utils from "~helpers/Utils";
import { getIntentTag, getIntentTags } from "~store/dialogComponents/intentTags/actions";
import { selectCurrentBot, selectCurrentProject, selectUserInfo } from "~store/user/selectors";

const ScenarioManagerList = () => {
  const [downloadLoading, setDownloadLoading] = useState(false);
  const tableRef = useRef(null);
  const dispatch = useDispatch();

  const currentProject = useSelector(selectCurrentProject);
  const currentBot = useSelector(selectCurrentBot);
  const userInfo = useSelector(selectUserInfo);
  const { t } = useTranslation();
  const uploadFilesRef = useRef([]);
  const queryParams = useQueryParams();

  useProjectChange(() => {
    tableRef.current.resetPagination();
  }, []);

  useEffect(() => {
    (async () => {
      if (queryParams.low_code === "true") {
        await LowCodeEditorModal.show({
          noFooter: true,
        });
        const searchParams = new URLSearchParams(window.location.search);
        searchParams.delete("low_code");

        HistoryHelper.push({
          pathname: window.location.pathname,
          search: searchParams.toString(),
        });
      } else {
        LowCodeEditorModal.hide();
      }
    })();
  }, [queryParams?.low_code]);

  const isLowCodeEditable = useMemo(() => {
    return currentProject.permissions?.includes(userRole.admin) || userInfo?.is_superuser;
  }, [currentProject?.permissions, userInfo?.is_superuser]);

  function DownloadScenario() {
    setDownloadLoading(true);
    downloadIntents(currentProject.id, currentBot.id)
      .then((responseBlob) => {
        const projectName = Utils.slugify(currentProject.name);
        FileHelper.saveAs(
          responseBlob,
          `intents_${projectName}_${currentProject.id}_${currentBot.id}_${new Date().getTime()}`
        );

        // const url = window.URL.createObjectURL(new Blob([response, { type: "application/vnd.ms-excel" }]));
        // const link = document.createElement("a");
        // link.href = url;
        // link.setAttribute("download", `intents_${new Date().getTime()}.xlsx`);
        // document.body.appendChild(link);
        // link.click();
      })
      .finally(() => {
        setDownloadLoading(false);
      });
  }

  async function uploadNewScenario() {
    if (!uploadFilesRef.current?.[0]) {
      AlertHelper.showWarning("Please select a file to upload");
      return;
    }
    if (
      !(await DialogHelper.showQuestionYesNo(
        "",
        <>
          Current intents will be overwritten by the new intents. Are you sure you want to proceed for{" "}
          <b>{currentBot?.name} Bot</b> ?
        </>
      ))
    ) {
      return;
    }
    uploadIntents(currentProject.id, currentBot.id, uploadFilesRef.current[0]).then((response) => {
      AlertHelper.showSuccess();
      tableRef.current.refresh();
      DialogHelper.close("upload-intent-file-dialog");
    });
  }

  const handleClickUpload = () => {
    DialogHelper.show(
      "Upload Intent File",
      <PRDropZone
        accept={[".xlsx", ".xls"]}
        className="mt-4"
        onFileChange={(files) => (uploadFilesRef.current = files)}
      />,
      [
        {
          label: "Upload",
          variant: "contained",
          onClick: uploadNewScenario,
        },
        {
          label: "Cancel",
          variant: "outlined",
          close: true,
        },
      ],
      {
        key: "upload-intent-file-dialog",
      }
    );
  };

  const columns = useMemo(
    () => [
      {
        label: "Name",
        key: "display_text",
        style: {
          maxWidth: "40%",
        },
        render: (row) => {
          return (
            <div className="text-wrap d-flex align-items-center justify-content-between">
              <div className="d-flex align-items-center">
                {row.intent_type === dialogComponentsIntentType.RAG && (
                  <MdAutoAwesome className="text-orange-300 me-1" title="RAG" />
                )}
                <div className="text-wrap" title={row.display_text || row.text}>
                  {row.display_text || row.text}
                </div>
                {row.intent_type === dialogComponentsIntentType.RAG &&
                  row.rag_fact_group?.status === dialogComponentsRagFactGroupStatus.Processing && (
                    <BiLoaderAlt className="ms-1 spin fs-5" />
                  )}
              </div>
              {!!row.confused_intents?.length && userInfo?.is_superuser && (
                <PRPopover
                  portal
                  content={
                    <PRPage title="Confused Intents">
                      Current intent is confused with the following intents:
                      <div>
                        <PRTable
                          inline
                          noPagination
                          columns={[
                            {
                              label: "Name",
                              key: "display_text",
                              render: (row) => row.display_text || row.text,
                            },
                            {
                              label: "Count",
                              key: "count",
                            },
                            {
                              label: "Actions",
                              key: "actions",
                              actions: true,
                              fixed: "right",
                              render: (row) => {
                                return (
                                  <div className="d-flex justify-content-center">
                                    <PRButton
                                      outline
                                      color="primary"
                                      icon={MdEdit}
                                      link={`/chatbot/intent/form/${row.id}`}
                                      linkProps={{
                                        newTab: true,
                                      }}
                                      size="sm"
                                      tooltipText="Edit"
                                    />
                                  </div>
                                );
                              },
                            },
                          ]}
                          data={row.confused_intents}
                        />
                      </div>
                    </PRPage>
                  }
                  placement="bottom"
                >
                  <div className="text-danger" style={{ cursor: "pointer" }}>
                    <MdPriorityHigh />
                  </div>
                </PRPopover>
              )}
            </div>
          );
        },
      },
      {
        style: {
          whiteSpace: "nowrap",
        },
        label: "Intent Tag",
        key: "intent_tag_text",
        render: (row) => (
          <PRButton className="p-0" color="link" link={`/chatbot/intent-tags/form/${row.intent_tag}/`}>
            {row.intent_tag_text}
          </PRButton>
        ),
      },
      {
        label: "Use In Prediction",
        key: "use_in_prediction",
        render: (row) =>
          row.use_in_prediction ? (
            <span className="text-success-600">Yes</span>
          ) : (
            <span className="text-danger">No</span>
          ),
      },
      {
        label: "Responses",
        key: "first_message_type",
        render: (row) => {
          const messageItems = row.response?.message_items || [];
          const text = messageItems
            .map((item) => {
              return (
                dialogComponentsResponseMessageTypeOptions.find((option) => option.value === item.message_type)
                  ?.label || item.message_type
              );
            })
            .join("\n, ");

          const formCount = row.forms?.length || 0;
          const slotCount = row.slots?.length || 0;
          const items = [];
          if (text) {
            items.push(text);
          }
          if (formCount) {
            items.push(`Form (${formCount})`);
          }
          if (slotCount) {
            items.push(`Slot (${slotCount})`);
          }
          const cellText = items.join(", ");
          return (
            <PRTooltip title={cellText}>
              <div>
                <div className="d-flex align-items-center">{cellText}</div>
              </div>
            </PRTooltip>
          );
        },
      },
      {
        label: "Actions",
        key: "actions",
        actions: true,
        fixed: "right",
        render: (row) => {
          const handleClickDelete = (id) => async (e) => {
            if (
              !e.shiftKey &&
              !(await DialogHelper.showQuestion(t("question.delete.warning"), t("question.delete.title"), {
                label: t("question.delete"),
              }))
            )
              return;
            deleteIntents(currentProject.id, currentBot.id, id).then(() => {
              AlertHelper.show(t("question.delete.success"));
              tableRef.current.refresh();
            });
          };

          return (
            <div className="d-flex justify-content-center">
              <PRButton
                outline
                color="primary"
                icon={MdEdit}
                link={`/chatbot/intent/form/${row.id}`}
                size="sm"
                tooltipText="Edit"
              />
              <PRButton
                outline
                className="ms-1"
                color="danger"
                icon={MdDelete}
                size="sm"
                tooltipText="Delete"
                onClick={handleClickDelete(row.id)}
              />
            </div>
          );
        },
      },
    ],
    [currentBot, currentProject, t]
  );
  const actions = [
    isLowCodeEditable && {
      tooltipText: "Low Code Editor",
      icon: MdCode,
      color: "primary",
      onClick: () => {
        const searchParams = new URLSearchParams(window.location.search);
        searchParams.set("low_code", "true");

        HistoryHelper.push({
          pathname: window.location.pathname,
          search: searchParams.toString(),
        });
      },
    },
    userInfo.is_superuser && {
      tooltipText: "Distribution Map",
      icon: MdPieChart,
      color: "primary",
      link: `/chatbot/intent/distribution-map/`,
    },
    userInfo.is_superuser && {
      tooltipText: "Upload Scenario",
      icon: MdFileUpload,
      color: "primary",
      onClick: handleClickUpload,
    },
    userInfo.is_superuser && {
      icon: MdDownload,
      color: "primary",
      tooltipText: "Download Scenario",
      loading: downloadLoading,
      disabled: downloadLoading,
      onClick: DownloadScenario,
    },
    {
      label: "Create New",
      icon: MdAdd,
      color: "success",
      link: "/chatbot/intent/form",
    },
  ].filter(Boolean);

  const filters = useMemo(
    () => [
      {
        key: "intent_tag",
        //TODO: check if could be removed
        onFetch: async (searchText, callback, signal, valueProp) => {
          const response = await dispatch(
            getIntentTags(currentProject.id, currentBot.id, {
              params: {
                key__icontains: searchText,
                limit: 20,
                signal,
              },
            })
          );
          const result = response?.results || [];
          const valueIdList = valueProp;
          const missingIdList = valueIdList?.filter((id) => !result.find((item) => item.id === id));
          if (valueProp && missingIdList?.length) {
            const resultsList = await Promise.all(
              missingIdList.map((id) =>
                dispatch(getIntentTag(currentProject.id, currentBot.id, id, { loading: false }))
              )
            );
            result.unshift(...resultsList);
          }
          return result.map((item) => ({
            label: item.key,
            value: item.id,
          }));
        },
      },
      {
        key: "display_text",
        label: "Name",
        order: 1,
      },
      {
        key: "text",
        label: "Question",
        order: 2,
      },
      {
        key: "response__message_items__text",
        label: "Response Text",
        order: 3,
      },
      {
        key: "response__message_items__message_type",
        label: "Response Type",
        order: 4,
      },
      {
        key: "intent_tag__key",
        visible: false,
      },

      // {
      //   key: "slots",
      //   label: "Slots",
      // },
      // {
      //   key: "forms",
      //   label: "Forms",
      // },
    ],
    [currentBot, currentProject, dispatch]
  );

  const parentName = [
    {
      label: "Intent",
    },
  ];
  return (
    <PRContainer
      smalltalkSelector
      actions={actions}
      description="Here you can manage your intents."
      name="Chatbot"
      parentName={parentName}
    >
      <PRTable
        ref={tableRef}
        columns={columns}
        filters={filters}
        storageKey={tableFilterStorageKey.scenarioManager}
        url={apiUrlChatbot.getIntents.format(currentProject.id, currentBot.id)}
      />
    </PRContainer>
  );
};

export default ScenarioManagerList;
