import { useEffect, useMemo, useRef, useState } from "react";

import classNames from "classnames";
import { MdAdd, MdDelete, MdDownload, MdEdit, MdKey, MdUpload } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { Badge } from "reactstrap";

import PRAlert from "~components/Generic/PRAlert";
import PRButton from "~components/Generic/PRButton";
import PRContainer from "~components/Generic/PRContainer";
import PRLink from "~components/Generic/PRLink";
import PRPhoneNumber from "~components/Generic/PRPhoneNumber";
import PRTable from "~components/Generic/PRTable";
import { apiUrlOrganization, organizationMemberFields, organizationVisibilityType } from "~constants";
import DateHelper from "~helpers/DateHelper";
import DialogHelper from "~helpers/DialogHelper";
import FileHelper from "~helpers/FileHelper";
import Utils from "~helpers/Utils";
import CredentialsManagerModal from "~pages/Organization/MemberData/CredentialsManagerModal";
import UploadMemberDataModal from "~pages/Organization/MemberData/UploadMemberDataModal";
import { deleteMember, downloadMemberData, getMember, getMemberFieldFormatList } from "~store/organization/actions";
import { selectMemberFieldFormat } from "~store/organization/selectors";
import { selectPopupSettingsState } from "~store/settings/popupSettings/selectors";
import { selectCurrentProject } from "~store/user/selectors";

function UserView({ id, identifierKey }) {
  const dispatch = useDispatch();
  const currentProject = useSelector(selectCurrentProject);
  const [user, setUser] = useState(null);
  useEffect(() => {
    if (!id) return;
    (async () => {
      const data = await dispatch(
        getMember(currentProject.id, id, {
          loading: false,
          onSuccess: () => {},
        })
      );
      setUser(data);
    })();
  }, [id, dispatch, currentProject.id]);

  if (!user) return null;
  return (
    <div className="d-flex align-items-center">
      <PRLink newTab to={`/organization/customers/form/${id}`}>
        {user?.safe_information?.[identifierKey]}
      </PRLink>
    </div>
  );
}
export const CustomerListCellRenderer = ({ memberDataFields, cellKey, value }) => {
  const identifierField = memberDataFields?.find((item) => item.identifier);

  // return function CellRenderer(obj, _index, value) {
  if (value) {
    const field = memberDataFields.find((item) => item.name === cellKey);
    if (field?.field_type === organizationMemberFields.DATE) {
      return DateHelper.getDateTimeLocal(value).format("LLT");
    } else if (field?.field_type === organizationMemberFields.BOOL) {
      return value === "True" || value === true ? "Yes" : "No";
    } else if (
      [organizationMemberFields.INTEGER_ARRAY, organizationMemberFields.STRING_ARRAY].includes(field?.field_type)
    ) {
      return value.map((item) => (
        <Badge key={item} className="me-1 badge-soft-secondary">
          {item}
        </Badge>
      ));
    } else if ([organizationMemberFields.PHONE_NUMBER].includes(field?.field_type)) {
      return <PRPhoneNumber render value={value} />;
    } else if ([organizationMemberFields.USER].includes(field?.field_type)) {
      return <UserView id={value} identifierKey={identifierField?.name} />;
    }
  }
  return value;
  // };
};
export default function CustomerList() {
  const tableRef = useRef();
  const dispatch = useDispatch();
  const memberFieldFormat = useSelector(selectMemberFieldFormat);
  const memberDataFields = memberFieldFormat?.member_data_fields;
  const currentProject = useSelector(selectCurrentProject);
  const popupSettings = useSelector(selectPopupSettingsState);

  useEffect(() => {
    dispatch(getMemberFieldFormatList(currentProject.id));
  }, [dispatch, currentProject.id]);

  const serverSavedMemberDataFields = useMemo(() => {
    return memberDataFields?.filter((item) => item.id);
  }, [memberDataFields]);

  const columns = useMemo(() => {
    if (!memberDataFields) return [];

    const handleDelete = (row) => async () => {
      if (!(await DialogHelper.showQuestionDelete())) return;
      await dispatch(deleteMember(currentProject.id, row.id));
      tableRef.current.refresh();
    };

    const fields = [];
    fields.unshift({
      label: "#",
      key: "id",
      order: -1,
    });
    const nonMemberOnlyFields = memberDataFields.filter(
      (item) => item.visibility_type !== organizationVisibilityType.MEMBER_ONLY
    );
    const memberOnlyField = nonMemberOnlyFields.filter(
      (item) => item.visibility_type === organizationVisibilityType.MEMBER_ONLY
    );

    const safeInformationFields = nonMemberOnlyFields.map((item) => {
      const label = (
        <span
          className={classNames({
            "fw-semibold": item.identifier,
          })}
        >
          {item.display_name || item.name}
        </span>
      );

      return {
        label: label,
        order: item.order,
        key: `safe_information.${item.name}`,
        render: (obj, index, value) => (
          <CustomerListCellRenderer cellKey={item.name} memberDataFields={nonMemberOnlyFields} value={value} />
        ),
      };
    });
    fields.push(...safeInformationFields);

    if (memberOnlyField?.length) {
      const memberOnlyFieldRows = memberOnlyField.map((item) => {
        const label = (
          <span
            className={classNames({
              "fw-semibold": item.identifier,
            })}
          >
            {item.display_name || item.name}
          </span>
        );

        return {
          label: label,
          order: item.order,
          key: `member_only_information.${item.name}`,
          render: (obj, index, value) => (
            <CustomerListCellRenderer cellKey={item.name} memberDataFields={memberOnlyField} value={value} />
          ),
        };
      });
      fields.push(...memberOnlyFieldRows);
    }

    fields.push({
      label: "Actions",
      key: "actions",
      actions: true,
      fixed: "right",
      render: (row) => (
        <div className="d-flex justify-content-center">
          <PRButton
            outline
            color="primary"
            icon={MdEdit}
            link={`/organization/customers/form/${row.id}`}
            size="sm"
            tooltipText="Edit"
          />
          <PRButton
            outline
            className="ms-1"
            color="danger"
            icon={MdDelete}
            size="sm"
            tooltipText="Delete"
            onClick={handleDelete(row)}
          />
        </div>
      ),
    });

    return fields;
  }, [memberDataFields, dispatch]);

  const welcomeFormItems = useMemo(() => {
    return popupSettings?.welcome_form?.form_items || [];
  }, [popupSettings]);

  const memberDataFieldsWithWelcomeForm = useMemo(() => {
    const fields = [...(memberDataFields || [])];
    welcomeFormItems.forEach((item) => {
      const field = fields.find((field) => field.name === item.form_key);
      const fieldIndex = fields.findIndex((f) => f === field);

      const welcomeFormObj = {
        name: item.form_key,
        display_name: item.form_label,
        field_type:
          item.form_item_type?.toLowerCase() === "email" //formItemTypeOptions
            ? organizationMemberFields.EMAIL
            : item.form_item_type?.toLowerCase() === "phone number" //formItemTypeOptions
            ? organizationMemberFields.PHONE_NUMBER
            : organizationMemberFields.STRING,
        is_required: true,
        identifier: item.is_identifier,
        visibility_type: organizationVisibilityType.PUBLIC,

        readonly: true,
      };
      if (!field) {
        fields.unshift(welcomeFormObj);
      } else {
        const newField = { ...field, ...welcomeFormObj };
        fields[fieldIndex] = newField;
        //move to top
        fields.splice(fieldIndex, 1);
        fields.unshift(newField);
      }
    });
    return fields;
  }, [memberDataFields, welcomeFormItems]);

  const actions = useMemo(() => {
    const handleUpload = async () => {
      const result = await UploadMemberDataModal.show({ fields: memberDataFieldsWithWelcomeForm });
      if (result) {
        tableRef.current.refresh();
      }
    };
    const handleDownload = async () => {
      const blobResponse = await dispatch(downloadMemberData(currentProject.id));
      const fileName = `member_data_format_${Utils.slugify(currentProject.name)}`;

      FileHelper.saveAs(blobResponse, fileName);
    };
    const handleApiCredentials = async () => {
      await CredentialsManagerModal.show({});
    };
    return [
      {
        label: "API Credentials",
        icon: MdKey,
        color: "primary",
        onClick: handleApiCredentials,
      },
      {
        icon: MdUpload,
        color: "primary",
        tooltipText: "Upload Format",
        onClick: handleUpload,
      },
      {
        icon: MdDownload,
        color: "primary",
        tooltipText: "Download Format",
        onClick: handleDownload,
      },
      {
        label: "Create New",
        icon: MdAdd,
        color: "success",
        link: "/organization/customers/form",
        disabled: !serverSavedMemberDataFields?.length,
      },
    ];
  }, [serverSavedMemberDataFields, memberDataFieldsWithWelcomeForm, currentProject.id, dispatch, currentProject.name]);

  return (
    <PRContainer
      actions={actions}
      description={"Here you can manage your customers"}
      name="Organization"
      parentName="Customers"
    >
      {!serverSavedMemberDataFields?.length && (
        <PRAlert color="warning">
          Customer data format is not initialized yet. Click{" "}
          <PRLink to="/organization/customer-data-format/">here</PRLink> to create the format.
        </PRAlert>
      )}
      <PRTable ref={tableRef} noWrap columns={columns} url={apiUrlOrganization.getMember.format(currentProject.id)} />
    </PRContainer>
  );
}
