import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { MdClose } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";

import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import QuestionMarkOutlinedIcon from "@mui/icons-material/HelpOutline";
import { Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";

import PalButton from "~components/mui/PalButton";
import IFrameHelper from "~helpers/IFrameHelper";
import { dialogClose, dialogUpdate } from "~store/actions";
import { selectDialogList } from "~store/helpers/dialog/selectors";

import "./style.scss";

function PRDialogProvider() {
  const dispatch = useDispatch();
  const dialogList = useSelector(selectDialogList);
  const { t } = useTranslation();

  if (!dialogList?.length || IFrameHelper.isInIFrame()) return null;

  return (
    <div>
      {dialogList.map((dialog, index) => {
        const { title, content: Content, actions, key, onClose, options = {} } = dialog;
        const { disableBackdropClick, bare, icon = true, closeIcon, transparent, dialogOptions = {} } = options;

        const handleCloseAction = (key) => {
          onClose?.();
          dispatch(dialogClose({ key }));
        };

        const handleClose = (e, type) => {
          if (type === "backdropClick" && disableBackdropClick) {
            return;
          }
          handleCloseAction(key);
        };

        const Icon = actions?.length > 1 ? QuestionMarkOutlinedIcon : ErrorOutlineIcon;
        const setActions = (actions) => {
          dispatch(dialogUpdate(dialog, { actions }));
        };

        const content =
          typeof Content === "function" ? Content({ close: () => handleCloseAction(key), setActions }) : Content;

        return (
          <Dialog
            key={index}
            aria-describedby="pm-dialog-description"
            aria-labelledby="pm-dialog-title"
            classes={{
              paper: classNames({
                paper: !bare,
                "paper-wide": !IFrameHelper.isWebChatMode() && !bare,
                "paper-transparent": transparent,
              }),
            }}
            className="pr-dialog-provider"
            open={true}
            onClose={handleClose}
            {...dialogOptions}
          >
            {closeIcon && (
              <div className="corner-close">
                <MdClose className="" onClick={() => handleCloseAction(key)} />
              </div>
            )}
            {!bare && (
              <DialogTitle className="title" id="pm-dialog-title">
                {/* {icon === true ? (
                  <Icon
                    className="icon"
                    sx={(theme) => ({
                      color: "var(--bs-danger)",
                    })}
                  />
                ) : (
                  icon
                )} */}

                {title && <b> {title}</b>}
              </DialogTitle>
            )}
            {content && !bare && (
              <DialogContent className="content">
                <div className="text-muted fs-5 content-text" id="pm-dialog-description">
                  {content}
                </div>
              </DialogContent>
            )}
            {bare && content}
            {!bare && (
              <>
                {actions?.length > -1 ? (
                  <DialogActions
                    className={classNames("dialog-actions", {
                      "dialog-actions-webchat": IFrameHelper.isWebChatMode(),
                    })}
                  >
                    {actions.map(({ onClick, id, label, close, ...rest }) => {
                      const handleClickAction = async (e) => {
                        await onClick?.(id, e);
                        if (close) {
                          handleCloseAction(key);
                        }
                      };
                      return (
                        <PalButton key={label} onClick={handleClickAction} {...rest}>
                          {label}
                        </PalButton>
                      );
                    })}
                  </DialogActions>
                ) : (
                  <DialogActions
                    className={classNames("dialog-actions", {
                      "dialog-actions-webchat": IFrameHelper.isWebChatMode(),
                    })}
                  >
                    <PalButton color="primary" variant="outlined" onClick={handleClose}>
                      {t("dialog.ok")}
                    </PalButton>
                  </DialogActions>
                )}
              </>
            )}
          </Dialog>
        );
      })}
    </div>
  );
}

export default PRDialogProvider;
