import { useEffect, useMemo, useState } from "react";

import { withCardon } from "cardon";
import chroma from "chroma-js";
import { SketchPicker } from "react-color";
import { MdContentPaste, MdHelp, MdOutlineIntegrationInstructions, MdOutlineOpenInNew } from "react-icons/md";
import { useSelector } from "react-redux";

import styled from "@emotion/styled";
import { ContentCopy } from "@mui/icons-material";
import { Box, Grid, Switch, Typography } from "@mui/material";

import { getBotSettings } from "~apiRequests/bot";
import PRAccordion, { PRAccordionItem } from "~components/Generic/PRAccordion";
import PRButton from "~components/Generic/PRButton";
import PRContainer from "~components/Generic/PRContainer";
import PRInput, { PRTextArea } from "~components/Generic/PRInput";
import PRModal from "~components/Generic/PRModal";
import PRPage from "~components/Generic/PRPage";
import PRPopover from "~components/Generic/PRPopover";
import PRSelect from "~components/Generic/PRSelect";
import PRTab from "~components/Generic/PRTab";
import PRTooltip from "~components/Generic/PRTooltip";
import PalIconButton from "~components/mui/PalIconButton";
import {
  popupChatHeightDefault,
  popupIconMarginDefault,
  popupIconSizeDefault,
  popupLottieOffsetXDefault,
  popupLottieOffsetYDefault,
  popupPosition,
  popupPositionOptions,
} from "~constants";
import AlertHelper from "~helpers/AlertHelper";
import Utils from "~helpers/Utils";
import { selectPopupSettingsState } from "~store/settings/popupSettings/selectors";
import { selectCurrentBot, selectCurrentProject } from "~store/user/selectors";
import "./style.scss";

const urlQueryTemplate = `
_pproject.push([
  'url_query',
  [
    {0}
  ],
]);
`;
const scriptTemplate = `<script>
var _pproject = _pproject || [];
_pproject.push([
  '{0}',
  '{1}',
]);
{3}

(function () {
    var pp = document.createElement('script');
    pp.type = 'text/javascript';
    pp.defer = true;
    pp.src = '{2}/iframe/popup.js';
    var s = document.getElementsByTagName('script')[0];
    s.parentNode.insertBefore(pp, s);
})();
</script>`;

const htmlBotTriggerTemplate = `
<button palmate-trigger-event="click" palmate-action="postText" palmate-action-value="What can you do?">
      Help
</button>`;
const scriptModalTabOptions = [
  {
    id: "direct",
    label: "Direct Link",
  },
  {
    id: "iframe",
    label: "IFrame",
  },
];

const StickGrid = styled(Grid)`
  position: sticky;
  top: 0;
  background-color: var(--bs-white);
  padding: 0.25rem;
  z-index: 1;
`;
const ScriptModal = withCardon(
  function ScriptModalContent({ get, tokenObj }) {
    const [selectedTab, setSelectedTab] = useState("direct");
    const popupSettings = useSelector(selectPopupSettingsState);

    // const [botQueryList, setBotQueryList] = useState([]);
    const [botQueryFeature, setBotQueryFeature] = useState({
      style__primary_color: false,
      style__primary_light: false,
      style__title_color: false,
      style__contrast_color: false,
      // style__popup_header_icon_type: false,
      // style__popup_icon_type: false,
      style__popup_position: false,
      style__popup_icon_size: false,
      style__popup_margin_x: false,
      style__popup_margin_y: false,
      style__chat_height: false,
      style__lottie_width: false,
      style__lottie_height: false,
      style__lottie_offset_x: false,
      style__lottie_offset_y: false,
      style__lottie_loop_delay: false,
      style__lottie_loop_speed: false,
      // disable_header: true,
      question: false,
      disable_microphone: false,
      disable_attachment: false,
      disable_loading_backdrop: false,
      disable_footer: false,
      disable_header: false,
      close_icon_as_text: false,
    });

    const [popupQueryValues, setPopupQueryValues] = useState(() => {
      const primaryColor = "#28AAE1";
      const light = chroma(primaryColor).brighten(1.5).hex();
      const dark = chroma(primaryColor).darken(0.7).hex();
      const titleColor = Utils.determineTextColor(primaryColor);
      const contrastColor = Utils.determineTextColor(light);

      const state = {
        style__primary_color: popupSettings.popup_primary_color || primaryColor,
        style__primary_light: popupSettings.popup_style_info?.primary_light || light,
        style__primary_dark: popupSettings.popup_style_info?.primary_dark || dark,
        style__title_color: popupSettings.popup_style_info?.title_color || titleColor,
        style__contrast_color: popupSettings.popup_style_info?.contrast_color || contrastColor,
        // style__popup_header_icon_type: popupSettings.popup_style_info?.popup_header_icon_type || popupIconType.default,
        // style__popup_icon_type: popupSettings.popup_style_info?.popup_icon_type || popupIconType.default,
        style__popup_position: popupSettings.popup_style_info?.popup_position || popupPosition.bottomRight,
        style__popup_icon_size: popupSettings.popup_style_info?.popup_icon_size || popupIconSizeDefault,
        style__popup_margin_x: popupSettings.popup_style_info?.popup_margin_x || popupIconMarginDefault,
        style__popup_margin_y: popupSettings.popup_style_info?.popup_margin_y || popupIconMarginDefault,
        style__chat_height: popupSettings.popup_style_info?.chat_height || popupChatHeightDefault,
        style__lottie_width: popupSettings.popup_style_info?.lottie_width || popupIconSizeDefault,
        style__lottie_height: popupSettings.popup_style_info?.lottie_height || popupIconSizeDefault,
        style__lottie_offset_x: popupSettings.popup_style_info?.lottie_offset_x || popupLottieOffsetXDefault,
        style__lottie_offset_y: popupSettings.popup_style_info?.lottie_offset_y || popupLottieOffsetYDefault,
        style__lottie_loop_delay: popupSettings.popup_style_info?.lottie_loop_delay || 0,
        style__lottie_loop_speed: popupSettings.popup_style_info?.lottie_loop_speed || 1,
        style__popup_ballon_timeout: popupSettings.popup_style_info?.popup_ballon_timeout || 0,
        // disable_header: popupSettings.disable_header || true,
        question: "Hi, I have a question. Can you help me?",
        disable_microphone: true,
        disable_attachment: true,
        disable_loading_backdrop: true,
        disable_footer: true,
        disable_header: true,
        close_icon_as_text: false,
      };
      return state;
    });

    let origin = window.location.origin;

    if (origin === "https://app.palmate.ai") {
      origin = "https://public.palmate.ai";
    }

    const formattedScript = useMemo(() => {
      let queryParams = "";
      const enabledKeys = Object.keys(botQueryFeature).filter((key) => botQueryFeature[key]);
      if (enabledKeys?.length) {
        const queryParamContent = enabledKeys
          .map((key) => {
            return `"${key}=${popupQueryValues[key]}"`;
          })
          .join(",\n    ");

        queryParams = urlQueryTemplate.format(queryParamContent);
      }
      const template = scriptTemplate.format(
        tokenObj?.alias ? "_project_alias" : "_project_token",
        tokenObj?.alias || tokenObj?.token,
        origin + process.env.PUBLIC_URL,
        queryParams
      );
      return template;
    }, [tokenObj, origin, botQueryFeature, popupQueryValues]);

    const handleCopy = () => {
      navigator.clipboard.writeText(formattedScript);
      AlertHelper.showSuccess("Script copied to clipboard");
    };

    const productionPublicLinkUrl = useMemo(() => {
      let url = `${origin}/webchat/?token=${tokenObj?.token}`;
      if (tokenObj?.alias) {
        url = `${origin}/webchat/${tokenObj.alias}/`;
      }
      const enabledKeys = Object.keys(botQueryFeature).filter((key) => botQueryFeature[key]);
      if (enabledKeys?.length) {
        const queryParams = enabledKeys.map((key) => `${key}=${popupQueryValues[key]}`).join("&");
        if (tokenObj?.alias) {
          url = `${url}?${queryParams}`;
        } else {
          url = `${url}&${queryParams}`;
        }
      }

      return url;
    }, [tokenObj, origin, botQueryFeature, popupQueryValues]);

    const handleClickCopyPublicLink = () => {
      navigator.clipboard.writeText(productionPublicLinkUrl);
      AlertHelper.showSuccess("Public link copied to clipboard");
    };

    const handleChangeTab = (id) => {
      setSelectedTab(id);
    };

    const handleClickToggleQueryParam = (queryParam) => () => {
      setBotQueryFeature((prev) => ({
        ...prev,
        [queryParam]: !prev[queryParam],
      }));
    };

    const handleChangePopupQueryValue = (key) => (rawValue) => {
      let value = rawValue;
      if (
        [
          "style__primary_color",
          "style__primary_light",
          "style__primary_dark",
          "style__title_color",
          "style__contrast_color",
        ].includes(key)
      ) {
        value = rawValue.hex;
      } else if (
        //select types
        [
          "style__popup_position",
          // "style__popup_header_icon_type",
          // "style__popup_bot_icon_type",
          // "style__popup_icon_type",
          // "style__popup_trigger_type",
        ].includes(key)
      ) {
        value = rawValue;
      } else if (
        [
          "style__popup_margin_x",
          "style__popup_margin_y",
          "style__popup_icon_size",
          "style__chat_height",
          "style__lottie_width",
          "style__lottie_height",
          "style__lottie_offset_x",
          "style__lottie_offset_y",
          "style__lottie_loop_delay",
          "style__lottie_loop_speed",
          "style__popup_ballon_timeout",
        ].includes(key)
      ) {
        value = parseInt(rawValue.target.value);
      } else if (["question"].includes(key)) {
        value = rawValue.target.value;
      } else if (["disable_microphone", "disable_attachment", "disable_loading_backdrop"].includes(key)) {
        value = rawValue.target.checked;
      }

      setPopupQueryValues((prev) => ({
        ...prev,
        [key]: value,
      }));
    };

    const hasFooterEditPermission = popupSettings.system_chatbot_info.enable_footer_customization;
    return (
      <PRModal
        size="xl"
        submitText=""
        title={
          <div className="d-flex align-items-center">
            <MdOutlineIntegrationInstructions className="me-2" /> Generate Script & Link
          </div>
        }
        onClose={get(false)}
      >
        <PRTab className="mb-2" tab={selectedTab} tabList={scriptModalTabOptions} onChange={handleChangeTab} />
        {selectedTab === "direct" && (
          <>
            <div className="text-center mt-2 mb-3 fs-5 text-muted">
              Copy the public link below to share with your customers.
              <Box alignItems={"center"} display={"flex"} flexWrap={"nowrap"} justifyContent={"center"}>
                <Box
                  sx={{
                    my: 2,
                    padding: 1.5,
                    backgroundColor: "var(--bs-gray-200)",
                    borderRadius: "0.5rem",
                    mr: 1,
                    overflowWrap: "anywhere",
                  }}
                >
                  {productionPublicLinkUrl}
                </Box>

                <PalIconButton title="Copy public link to clipboard" onClick={handleClickCopyPublicLink}>
                  <ContentCopy />
                </PalIconButton>
              </Box>
              <div className="fs-6 mt-2">
                <span className="fw-semibold">Note:</span> Do not use this link for test cases. Use the Try Chatbot or
                Open in new tab buttons instead.
              </div>
            </div>
          </>
        )}
        {selectedTab === "iframe" && (
          <>
            <div className="mt-2 mb-3 fs-5 text-muted text-center d-flex align-items-center">
              <span>
                Insert the script below into the <span className="bg-secondary bg-soft rounded px-1">&lt;body&gt;</span>{" "}
                tag of your HTML to initiate the chatbot service on your website. For your convenience, use the button
                provided below to instantly copy the code to your clipboard.
              </span>
            </div>

            <PRTextArea
              editorMode
              readOnly
              className="pr-try-chatbot-code-block"
              editorProps={{
                defaultLanguage: "javascript",
                noToolbar: true,
                noResizable: true,
                defaultHeight: 350,
              }}
              value={formattedScript}
            />
          </>
        )}
        <PRAccordion className="mt-2">
          <PRAccordionItem title={"Advanced"}>
            <Grid container maxHeight={"30vh"} overflow={"auto"} pr={2} spacing={2}>
              <StickGrid item xs={12}>
                <Typography>Webchat Options</Typography>
              </StickGrid>
              <Grid item xs={6}>
                <Switch
                  checked={botQueryFeature.question}
                  // inputProps={{ "aria-label": "controlled" }}
                  onChange={handleClickToggleQueryParam("question")}
                />
                Initial Question on Connect
              </Grid>
              <Grid item disabled={!botQueryFeature.question} xs={6}>
                <PRInput value={popupQueryValues?.question} onChange={handleChangePopupQueryValue("question")} />
              </Grid>
              <Grid item xs={6}>
                <Switch
                  checked={botQueryFeature.disable_microphone}
                  // inputProps={{ "aria-label": "controlled" }}
                  onChange={handleClickToggleQueryParam("disable_microphone")}
                />
                Disable Microphone
              </Grid>
              <Grid item disabled={!botQueryFeature.disable_microphone} xs={6}></Grid>
              <Grid item xs={6}>
                <Switch
                  checked={botQueryFeature.disable_attachment}
                  // inputProps={{ "aria-label": "controlled" }}
                  onChange={handleClickToggleQueryParam("disable_attachment")}
                />
                Disable Attachment(for Agent chatting)
              </Grid>
              <Grid item xs={6}></Grid>
              <Grid item xs={6}>
                <Switch
                  checked={botQueryFeature.disable_loading_backdrop}
                  // inputProps={{ "aria-label": "controlled" }}
                  onChange={handleClickToggleQueryParam("disable_loading_backdrop")}
                />
                Disable Loading Backdrop
              </Grid>
              <Grid item xs={6}></Grid>
              <Grid item xs={6}>
                <Switch
                  checked={botQueryFeature.disable_header}
                  // inputProps={{ "aria-label": "controlled" }}
                  onChange={handleClickToggleQueryParam("disable_header")}
                />
                Disable header
              </Grid>
              <Grid item xs={6} />
              <Grid item>
                <Switch
                  checked={botQueryFeature.disable_footer}
                  disabled={!hasFooterEditPermission}
                  onChange={handleClickToggleQueryParam("disable_footer")}
                  // inputProps={{ "aria-label": "controlled" }}
                />
                Disable footer
                {!popupSettings?.system_chatbot_info?.enable_footer_customization && (
                  <PRTooltip title="Please contact the administrator to enable footer customization.">
                    <span>
                      <MdHelp className="ms-1 fs-6" />
                    </span>
                  </PRTooltip>
                )}
              </Grid>
              <Grid item xs={6} />
              <Grid item xs={6}>
                <Switch
                  checked={botQueryFeature.close_icon_as_text}
                  onChange={handleClickToggleQueryParam("close_icon_as_text")}
                />
                Close Icon as Text
                <PRTooltip title="Display close as text instead of icon">
                  <span>
                    <MdHelp className="ms-1 fs-6" />
                  </span>
                </PRTooltip>
              </Grid>
              <Grid item xs={6} />
              {selectedTab === "iframe" && (
                <>
                  <StickGrid item xs={12}>
                    <Typography>Webchat Click Trigger</Typography>
                  </StickGrid>
                  <Grid item xs={12}>
                    <Box mb={2}>You can use the following HTML code to trigger the chatbot on your website.</Box>
                    <PRTextArea
                      editorMode
                      readOnly
                      editorProps={{
                        defaultLanguage: "html",
                        noToolbar: true,
                        noResizable: true,
                        defaultHeight: 100,
                      }}
                      value={htmlBotTriggerTemplate}
                    />
                  </Grid>
                </>
              )}

              <StickGrid item xs={12}>
                <Typography>Webchat Parameters</Typography>
              </StickGrid>
              <Grid item xs={6}>
                <Switch
                  checked={botQueryFeature.style__primary_color}
                  // inputProps={{ "aria-label": "controlled" }}
                  onChange={handleClickToggleQueryParam("style__primary_color")}
                />
                Primary Color
              </Grid>
              <Grid item disabled={!botQueryFeature.style__primary_color} xs={6}>
                <PRPopover
                  content={
                    <>
                      <SketchPicker
                        color={popupQueryValues?.style__primary_color}
                        onChange={handleChangePopupQueryValue("style__primary_color")}
                      />
                    </>
                  }
                >
                  <div className="form-control form-control-color mw-100">
                    <div
                      className="form-control"
                      style={{
                        backgroundColor: popupQueryValues?.style__primary_color,
                        width: "100%",
                        height: "100%",
                      }}
                    />
                  </div>
                </PRPopover>
              </Grid>
              <Grid item xs={6}>
                <Switch
                  checked={botQueryFeature.style__primary_light}
                  // inputProps={{ "aria-label": "controlled" }}
                  onChange={handleClickToggleQueryParam("style__primary_light")}
                />
                Secondary Color
              </Grid>
              <Grid item disabled={!botQueryFeature.style__primary_light} xs={6}>
                <PRPopover
                  content={
                    <>
                      <SketchPicker
                        color={popupQueryValues?.style__primary_light}
                        onChange={handleChangePopupQueryValue("style__primary_light")}
                      />
                    </>
                  }
                >
                  <div className="form-control form-control-color mw-100">
                    <div
                      className="form-control"
                      style={{
                        backgroundColor: popupQueryValues?.style__primary_light,
                        width: "100%",
                        height: "100%",
                      }}
                    />
                  </div>
                </PRPopover>
              </Grid>
              <Grid item xs={6}>
                <Switch
                  checked={botQueryFeature.style__title_color}
                  // inputProps={{ "aria-label": "controlled" }}
                  onChange={handleClickToggleQueryParam("style__title_color")}
                />
                Title Color
              </Grid>
              <Grid item disabled={!botQueryFeature.style__title_color} xs={6}>
                <PRPopover
                  content={
                    <>
                      <SketchPicker
                        color={popupQueryValues?.style__title_color}
                        onChange={handleChangePopupQueryValue("style__title_color")}
                      />
                    </>
                  }
                >
                  <div className="form-control form-control-color mw-100">
                    <div
                      className="form-control"
                      style={{
                        backgroundColor: popupQueryValues?.style__title_color,
                        width: "100%",
                        height: "100%",
                      }}
                    />
                  </div>
                </PRPopover>
              </Grid>
              <Grid item xs={6}>
                <Switch
                  checked={botQueryFeature.style__contrast_color}
                  // inputProps={{ "aria-label": "controlled" }}
                  onChange={handleClickToggleQueryParam("style__contrast_color")}
                />
                Secondary Color
              </Grid>
              <Grid item disabled={!botQueryFeature.style__contrast_color} xs={6}>
                <PRPopover
                  content={
                    <>
                      <SketchPicker
                        color={popupQueryValues?.style__contrast_color}
                        onChange={handleChangePopupQueryValue("style__contrast_color")}
                      />
                    </>
                  }
                >
                  <div className="form-control form-control-color mw-100">
                    <div
                      className="form-control"
                      style={{
                        backgroundColor: popupQueryValues?.style__contrast_color,
                        width: "100%",
                        height: "100%",
                      }}
                    />
                  </div>
                </PRPopover>
              </Grid>
              <Grid item xs={6}>
                <Switch
                  checked={botQueryFeature.style__popup_position}
                  // inputProps={{ "aria-label": "controlled" }}
                  onChange={handleClickToggleQueryParam("style__popup_position")}
                />
                Popup Position
              </Grid>
              <Grid item disabled={!botQueryFeature.style__popup_position} xs={6}>
                <PRSelect
                  isPrimitiveValue
                  isClearable={false}
                  options={popupPositionOptions}
                  value={popupQueryValues?.style__popup_position}
                  onChange={handleChangePopupQueryValue("style__popup_position")}
                />
              </Grid>
              <Grid item xs={6}>
                <Switch
                  checked={botQueryFeature.style__popup_margin_x}
                  // inputProps={{ "aria-label": "controlled" }}
                  onChange={handleClickToggleQueryParam("style__popup_margin_x")}
                />
                Popup Margin X(px)
              </Grid>
              <Grid item disabled={!botQueryFeature.style__popup_margin_x} xs={6}>
                <PRInput
                  type="number"
                  value={popupQueryValues?.style__popup_margin_x}
                  onChange={handleChangePopupQueryValue("style__popup_margin_x")}
                />
              </Grid>
              <Grid item xs={6}>
                <Switch
                  checked={botQueryFeature.style__popup_margin_y}
                  // inputProps={{ "aria-label": "controlled" }}
                  onChange={handleClickToggleQueryParam("style__popup_margin_y")}
                />
                Popup Margin Y(px)
              </Grid>
              <Grid item disabled={!botQueryFeature.style__popup_margin_y} xs={6}>
                <PRInput
                  type="number"
                  value={popupQueryValues?.style__popup_margin_y}
                  onChange={handleChangePopupQueryValue("style__popup_margin_y")}
                />
              </Grid>
              <Grid item xs={6}>
                <Switch
                  checked={botQueryFeature.style__popup_icon_size}
                  // inputProps={{ "aria-label": "controlled" }}
                  onChange={handleClickToggleQueryParam("style__popup_icon_size")}
                />
                Popup Size(px)
              </Grid>
              <Grid item disabled={!botQueryFeature.style__popup_icon_size} xs={6}>
                <PRInput
                  type="number"
                  value={popupQueryValues?.style__popup_icon_size}
                  onChange={handleChangePopupQueryValue("style__popup_icon_size")}
                />
              </Grid>
              <Grid item xs={6}>
                <Switch
                  checked={botQueryFeature.style__chat_height}
                  // inputProps={{ "aria-label": "controlled" }}
                  onChange={handleClickToggleQueryParam("style__chat_height")}
                />
                Chat Height(px)
              </Grid>
              <Grid item disabled={!botQueryFeature.style__chat_height} xs={6}>
                <PRInput
                  type="number"
                  value={popupQueryValues?.style__chat_height}
                  onChange={handleChangePopupQueryValue("style__chat_height")}
                />
              </Grid>
            </Grid>
          </PRAccordionItem>
        </PRAccordion>

        {selectedTab === "iframe" && (
          <PRButton className="mt-2 w-100" icon={MdContentPaste} onClick={handleCopy}>
            Copy to clipboard
          </PRButton>
        )}
      </PRModal>
    );
  },
  { destroyOnHide: true }
);

function TryChatbot() {
  const [projectTokenObj, setProjectTokenObj] = useState(null);

  const currentProject = useSelector(selectCurrentProject);
  const currentBot = useSelector(selectCurrentBot);

  useEffect(() => {
    getBotSettings(currentProject.id, currentBot.id).then((response) => {
      const pToken = response?.project_token?.token;

      setProjectTokenObj({
        ...(response?.tokens
          ? response.tokens
          : {
              development: {
                alias: null,
                token: pToken,
              },
              production: {
                alias: null,
                token: pToken,
              },
            }),
      });
    });
  }, [currentProject.id, currentBot.id]);

  const tryChatbotToken = projectTokenObj?.development?.token;
  const tryChatbotAlias = projectTokenObj?.development?.alias;

  const actions = useMemo(() => {
    let originStr = window.location.origin;
    if (process.env.NODE_ENV === "development") {
      originStr = "http://localhost:4000";
    }
    let linkUrl = `${originStr}${process.env.PUBLIC_URL}/webchat/?token=${tryChatbotToken}`;
    if (tryChatbotAlias) {
      linkUrl = `${originStr}${process.env.PUBLIC_URL}/webchat/${tryChatbotAlias}/`;
    }
    return [
      {
        label: "Generate Script & Link",
        icon: MdOutlineIntegrationInstructions,
        disabled: !projectTokenObj?.production,
        onClick: () => {
          const tokenObjItem = projectTokenObj?.production;
          //TODO: Api returns '" "' data instead of empty string. Backend should fix this.
          if (/"\s+"/.test(tokenObjItem.alias)) {
            tokenObjItem.alias = tokenObjItem.alias.replace(/"\s+"/g, "");
          }
          ScriptModal.show({ tokenObj: tokenObjItem });
        },
      },
      {
        label: "Open Test Chatbot in New Tab",
        icon: MdOutlineOpenInNew,
        disabled: !projectTokenObj,
        link: linkUrl,
        linkProps: {
          newTab: true,
          noPrefix: true,
        },
      },
    ];
  }, [projectTokenObj, tryChatbotToken, tryChatbotAlias]);
  let originStr = window.location.origin;
  if (process.env.NODE_ENV === "development") {
    originStr = "http://localhost:4000";
  }

  let iframeUrl = `${originStr}${process.env.PUBLIC_URL}/webchat/?token=${tryChatbotToken}&mode=inline&v=${__PR_RELEASE__}`;

  if (tryChatbotAlias) {
    iframeUrl = `${originStr}${process.env.PUBLIC_URL}/webchat/${tryChatbotAlias}/?mode=inline&v=${__PR_RELEASE__}`;
  }

  return (
    <PRContainer
      actions={actions}
      description={"Here you can try your chatbot in a webchat. You can also open it in a new tab."}
      name={"Chatbot"}
      parentName={"Try Chatbot"}
    >
      <PRPage noCard className="pr-try-chatbot">
        <div className="d-flex justify-content-center mt-2">{projectTokenObj ? <iframe src={iframeUrl} /> : null}</div>
      </PRPage>
    </PRContainer>
  );
}

export default TryChatbot;
