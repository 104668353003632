import ConstantHelper from "~helpers/ConstantHelper";

export const dialogComponentsResponseMessageTypes = {
  /** @type {"Text"} */
  text: "Text",
  /** @type {"File"} */
  file: "File",
  /** @type {"Image"} */
  image: "Image",
  /** @type {"Choice"} */
  choice: "Choice",
  /** @type {"Return to Agent"} */
  returnToAgent: "Return to Agent",
  /** @type {"Direct to Ticket"} */
  directToTicket: "Direct to Ticket",
  /** @type {"HandleReservation"} */
  handleReservation: "HandleReservation",
  /** @type {"GPTSmalltalk"} */
  gptSmalltalk: "GPTSmalltalk",
  /** @type {"GPTQuestion"} */
  gptQuestion: "GPTQuestion",
  /** @type {"WikipediaAnswer"} */
  wikipediaAnswer: "WikipediaAnswer",
  /** @type {"LowCode"} */
  lowCode: "LowCode",
  /** @type {"GoIntent"} */
  goIntent: "GoIntent",
  /** @type {"Inline"} */
  inline: "Inline",
};
export const dialogComponentsResponseMessageTypeOptions = [
  { label: "Text", value: dialogComponentsResponseMessageTypes.text },
  { label: "File", value: dialogComponentsResponseMessageTypes.file },
  { label: "Image", value: dialogComponentsResponseMessageTypes.image },
  { label: "Choice", value: dialogComponentsResponseMessageTypes.choice },
  { label: "Return to Agent", value: dialogComponentsResponseMessageTypes.returnToAgent },
  { label: "Direct to Ticket", value: dialogComponentsResponseMessageTypes.directToTicket },
  { label: "Handle Reservation", value: dialogComponentsResponseMessageTypes.handleReservation },
  { label: "GPT Smalltalk", value: dialogComponentsResponseMessageTypes.gptSmalltalk },
  { label: "GPT Question", value: dialogComponentsResponseMessageTypes.gptQuestion },
  { label: "Wikipedia Answer", value: dialogComponentsResponseMessageTypes.wikipediaAnswer },
  { label: "Low Code", value: dialogComponentsResponseMessageTypes.lowCode },
  { label: "Go Intent", value: dialogComponentsResponseMessageTypes.goIntent },
];

export const dialogComponentsMessageChoiceTypes = {
  goMessage: "Go Message Item",
  goIntent: "Go Intent",
};

export const dialogComponentsVariableTypes = {
  STR: "STR",
  INT: "INT",
  FLOAT: "FLOAT",
  JSON: "JSON",
};

export const dialogComponentsVariableTypeOptions = [
  { label: "String", value: dialogComponentsVariableTypes.STR },
  { label: "Integer", value: dialogComponentsVariableTypes.INT },
  { label: "Float", value: dialogComponentsVariableTypes.FLOAT },
  { label: "JSON", value: dialogComponentsVariableTypes.JSON },
];

export const dialogComponentsAuthType = {
  /** @type {"ANONYMOUS"} */
  ANONYMOUS: "ANONYMOUS",
  /** @type {"FAMILIAR"} */
  FAMILIAR: "FAMILIAR",
  /** @type {"AUTHENTICATED"} */
  AUTHENTICATED: "AUTHENTICATED",
};

export const dialogComponentsAuthTypeOptions = [
  { label: "Anonymous", value: dialogComponentsAuthType.ANONYMOUS },
  { label: "Familiar", value: dialogComponentsAuthType.FAMILIAR },
  { label: "Authenticated", value: dialogComponentsAuthType.AUTHENTICATED },
];

export const [
  dialogComponentsEntityTypeFamily,
  dialogComponentsEntityTypeFamilyOptions,
  dialogComponentsEntityTypeFamilyMap,
] = ConstantHelper.typify({
  /** @type {"Text"} */
  Text: "Text",
  /** @type {"Email"} */
  Email: "Email",
  /** @type {"Date"} */
  Date: "Date",
  /** @type {"DateRange"} */
  DateRange: "Date Range",
  /** @type {"File"} */
  File: "File",
  /** @type {"CustomType"} */
  CustomType: "Custom Type",
  /** @type {"Select"} */
  Select: "Select",
  // /** @type {"WikipediaLookup"} */
  // WikipediaLookup: "Wikipedia Lookup",
  /** @type {"Range"} */
  Range: "Range",
  /** @type {"PhoneNumber"} */
  PhoneNumber: "Phone Number",
  /** @type {"OnlyNumbers"} */
  OnlyNumbers: "Only Number",
});

export const [
  dialogComponentsEntityTypePredictionType,
  dialogComponentsEntityTypePredictionTypeOptions,
  dialogComponentsEntityTypePredictionTypeMap,
] = ConstantHelper.typify({
  /** @type {"AI"} */
  AI: "AI",
  /** @type {"SS"} */
  SS: "String Search",
});

export const [
  dialogComponentsEntityTypeDateFormatType,
  dialogComponentsEntityTypeDateFormatTypeOptions,
  dialogComponentsEntityTypeDateFormatTypeMap,
] = ConstantHelper.typify({
  /** @type {"date"} */
  date: "Date",
  /** @type {"time"} */
  time: "Time",
  /** @type {"datetime"} */
  datetime: "Date Time",
});

export const [dialogComponentsIntentType, dialogComponentsIntentTypeOptions, dialogComponentsIntentTypeMap] =
  ConstantHelper.typify({
    /** @type {"STANDARD"} */
    STANDARD: "Standart",
    /** @type {"RAG"} */
    RAG: "Rag",
  });

export const [
  dialogComponentsRagFactGroupStatus,
  dialogComponentsRagFactGroupStatusOptions,
  dialogComponentsRagFactGroupStatusMap,
] = ConstantHelper.typify({
  /** @type {"Fresh"} */
  Fresh: "Fresh",
  /** @type {"Processed"} */
  Processed: "Processed",
  /** @type {"Processing"} */
  Processing: "Processing",
  /** @type {"Modified"} */
  Modified: "Modified",
  /** @type {"Error"} */
  Error: "Error",
});
