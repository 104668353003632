import { createRef, useEffect, useMemo, useState } from "react";

import { cloneDeep } from "lodash";
import { useTranslation } from "react-i18next";
import { MdAdd, MdEdit, MdEmail, MdRestartAlt } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { Button, Col, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";

import { postInviteUser, putProjectPermissions } from "~apiRequests/permissions";
import PRButton from "~components/Generic/PRButton";
import PRContainer from "~components/Generic/PRContainer";
import { modalZIndex } from "~components/Generic/PRModal";
import PRSelect from "~components/Generic/PRSelect";
import PRTable from "~components/Generic/PRTable";
import { apiUrlPermission, userRoleOptions } from "~constants";
import DialogHelper from "~helpers/DialogHelper";
import Utils from "~helpers/Utils";
import { sendResetPasswordForUser } from "~store/actions";
import { selectCurrentProject, selectUserInfo } from "~store/user/selectors";

function User() {
  const dispatch = useDispatch();

  const userInfo = useSelector(selectUserInfo);

  const [isInviteOpen, setInviteOpen] = useState(false);
  const [isEditOpen, setEditOpen] = useState(false);
  const [editedData, setEditedData] = useState(null);
  const currentProject = useSelector(selectCurrentProject);
  const tableRef = createRef();
  const toggleInvite = () => {
    setInviteOpen(!isInviteOpen);
  };

  const toggleEdit = () => {
    setEditOpen((prev) => !prev);
  };
  const handleRefreshTable = () => {
    tableRef.current?.refresh();
  };

  function getColorByIndex(index) {
    const classList = [
      "bg-success text-success",
      "bg-info text-info",
      "bg-danger text-danger",
      "bg-warning text-warning",
      "bg-pink text-pink",
    ];

    return classList[index % 5];
  }
  const actions = [
    {
      label: "Invitations",
      icon: MdEmail,
      color: "primary",
      link: "/settings/invited-user",
    },
    {
      label: "Invite New User",
      icon: MdAdd,
      color: "success",
      onClick: toggleInvite,
    },
  ];
  const handleClickSendResetPassword = async (data) => {
    if (
      !(await DialogHelper.showQuestionYesNo(
        null,
        `Reset link will be sent to ${data.email} where the user can reset their password. Are you sure?`
      ))
    ) {
      return;
    }
    dispatch(sendResetPasswordForUser(data.username));
  };

  const columns = useMemo(() => {
    return [
      // {
      //   label: "Img",
      //   key: "img",
      //   render: (obj, index, _value) => {
      //     return (
      //       <div className="avatar-xs">
      //         <span className={"avatar-title rounded-circle bg-soft font-size-16 " + getColorByIndex(index)}>
      //           {obj.firstname?.toLocaleUpperCase().substring(0, 1)}
      //           {obj.lastname?.toLocaleUpperCase().substring(0, 1)}
      //         </span>
      //       </div>
      //     );
      //   },
      // },
      {
        label: "First Name",
        key: "firstname",
      },
      {
        label: "Last Name",
        key: "lastname",
      },
      {
        label: "Username",
        key: "username",
      },
      {
        label: "Email",
        key: "email",
      },
      {
        label: "Roles",
        key: "roles",
        render: (obj, _index, _value) => {
          return (
            <ul className="m-0">
              {obj.roles.map((role, i) => (
                <li key={i} className=" ">
                  {role}
                </li>
              ))}
            </ul>
          );
        },
      },
      // {
      //   label: "Invitation Status",
      //   key: "invitation_status",
      //   render: (row) => {
      //     return (
      //       <span
      //         className={classNames({
      //           // "badge bg-success": row.invitation_status === "Active",
      //           // "badge bg-warning-600": row.invitation_status === "Expired",
      //           // "badge bg-danger": row.invitation_status === "Passive",
      //         })}
      //       >
      //         {row.invitation_status}
      //       </span>
      //     );
      //   },
      // },
      {
        label: "Actions",
        key: "actions",
        actions: true,
        render: (row) => {
          const handleClick = () => {
            toggleEdit();
            setEditedData(row);
          };
          const handleClickReset = () => {
            handleClickSendResetPassword(row);
          };

          return (
            <div className="gap-2 d-flex justify-content-center">
              {userInfo.is_superuser && (
                <PRButton
                  outline
                  color="danger"
                  icon={MdRestartAlt}
                  size="sm"
                  tooltipText="Reset Password"
                  onClick={handleClickReset}
                />
              )}
              <PRButton outline color="primary" icon={MdEdit} size="sm" tooltipText="Edit" onClick={handleClick} />
            </div>
          );
        },
      },
    ];
  }, []);

  const parentName = [
    {
      label: `User`,
    },
  ];
  return (
    <PRContainer
      actions={actions}
      description={"Here you can manage your users and their roles."}
      name="Settings"
      parentName={parentName}
    >
      <InviteUserModal isOpen={isInviteOpen} toggle={toggleInvite} />
      <EditUserModal
        editData={editedData}
        isOpen={isEditOpen}
        toggle={toggleEdit}
        onClickResetPassword={handleClickSendResetPassword}
        onRefreshTable={handleRefreshTable}
      />

      <div className="mt-2">
        <PRTable
          ref={tableRef}
          columns={columns}
          responseDataKey="permissions"
          url={apiUrlPermission.get.format(currentProject.id)}
        />
      </div>
    </PRContainer>
  );
}

const InviteUserModal = ({ isOpen, toggle }) => {
  const [email, setEmail] = useState("");
  const [selectedRoles, setSelectedRoles] = useState([]);
  const currentProject = useSelector(selectCurrentProject);
  const { t } = useTranslation();

  return (
    <>
      <Modal
        autoFocus={true}
        centered={true}
        className="exampleModal"
        isOpen={isOpen}
        role="dialog"
        tabIndex="-1"
        toggle={toggle}
        zIndex={modalZIndex}
      >
        <div className="modal-content">
          <ModalHeader toggle={toggle}>Invite New User</ModalHeader>
          <ModalBody>
            <Row className="d-flex align-items-center">
              <Col md={3}>
                <Label>Email</Label>
              </Col>
              <Col md={7}>
                <Input
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                />
              </Col>
            </Row>
            <Row className="mt-3 d-flex align-items-center">
              <Col md={3}>
                <Label>User Role</Label>
              </Col>
              <Col md={7}>
                <PRSelect isMulti options={userRoleOptions} value={selectedRoles} onChange={setSelectedRoles} />
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button
              color="primary"
              type="submit"
              onClick={() => {
                const errors = [];
                if (!Utils.validateEmail(email)) {
                  errors.push(t("chatbot.getInformations.invalidEmail"));
                }
                if (selectedRoles.length === 0) {
                  errors.push(t("chatbot.getInformations.invalidRole"));
                }

                if (errors.length > 0) {
                  DialogHelper.showValidate(errors);
                  return;
                }

                let payload = { email: email, roles: selectedRoles.map((r) => r.value) };
                postInviteUser(currentProject.id, payload).then(() => {
                  setEmail("");
                  toggle();
                });
              }}
            >
              Invite
            </Button>
          </ModalFooter>
        </div>
      </Modal>
    </>
  );
};

const EditUserModal = ({ isOpen, toggle, editData, onRefreshTable, onClickResetPassword }) => {
  const userInfo = useSelector(selectUserInfo);

  const [data, setData] = useState(null);
  useEffect(() => {
    setData(editData);
  }, [editData]);

  const currentProject = useSelector(selectCurrentProject);

  const roleOptions = useMemo(() => {
    return data?.roles?.map((r) => {
      return { value: r, label: r };
    });
  }, [data?.roles]);

  const handleChangeRole = (selectedOption) => {
    let clonedData = cloneDeep(data);
    clonedData.roles = selectedOption.map((r) => r.value);
    setData(clonedData);
  };

  const handleClickResetPassword = () => {
    onClickResetPassword(editData);
  };
  return (
    <Modal
      autoFocus={true}
      centered={true}
      className="exampleModal"
      isOpen={isOpen}
      role="dialog"
      tabIndex="-1"
      toggle={toggle}
      zIndex={modalZIndex}
    >
      <div className="modal-content">
        <ModalHeader toggle={toggle}>Update User</ModalHeader>
        <ModalBody>
          <Row className="d-flex align-items-center">
            <Col md={3}>First Name:</Col>
            <Col md={3}>{editData?.firstname}</Col>
          </Row>
          <Row className="mt-2 d-flex align-items-center">
            <Col md={3}>Last Name:</Col>
            <Col md={3}>{editData?.lastname}</Col>
          </Row>
          <Row className="mt-2 d-flex align-items-center">
            <Col md={3}>Email:</Col>
            <Col md={3}>{editData?.email}</Col>
          </Row>
          <Row className="mt-2 d-flex align-items-center">
            <Col md={3}>Username:</Col>
            <Col md={3}>{editData?.username}</Col>
          </Row>
          <Row className="mt-2 d-flex align-items-center">
            <Col md={3}>Roles:</Col>
            <Col md={8}>
              <PRSelect isMulti options={userRoleOptions} value={roleOptions} onChange={handleChangeRole} />
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          {userInfo.is_superuser && (
            <Button color="danger" type="submit" onClick={handleClickResetPassword}>
              Send Reset Password
            </Button>
          )}
          <Button
            color="primary"
            type="submit"
            onClick={() => {
              putProjectPermissions(currentProject.id, {
                permissions: [data],
              }).then(() => {
                onRefreshTable();
                toggle();
              });
            }}
          >
            Update
          </Button>
        </ModalFooter>
      </div>
    </Modal>
  );
};

export default User;
