import { useCallback, useEffect, useMemo, useState } from "react";

import { FaWhatsapp } from "react-icons/fa";
import { MdFullscreen, MdHelp } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { Col, FormGroup, Input, Row } from "reactstrap";

import styled from "@emotion/styled";
import { Avatar, Box, Divider, Grid, Menu, MenuItem, Skeleton, Typography } from "@mui/material";

import agentIcon from "~assets/images/agent.png";
import { ReactComponent as ThreeDotIcon } from "~assets/images/icons/livechat-three-dot.svg";
import UpdateAgentNameModal from "~common/modals/UpdateAgentNameModal";
import PRButton from "~components/Generic/PRButton";
import PRContainer from "~components/Generic/PRContainer";
import PRModal from "~components/Generic/PRModal";
import { PRCard } from "~components/Generic/PRPage";
import PRSelect from "~components/Generic/PRSelect";
import PRTab from "~components/Generic/PRTab";
import PRTooltip from "~components/Generic/PRTooltip";
import PalButton from "~components/mui/PalButton";
import PalIconButton from "~components/mui/PalIconButton";
import PalFilter from "~components/PalFilter";
import {
  LS_LIVECHAT_OPTIONS,
  audioList,
  chatbotSessionStatus,
  livechatCallcenterType,
  livechatCallcenterTypeOptions,
  userRole,
} from "~constants";
import AlertHelper from "~helpers/AlertHelper";
import AudioHelper from "~helpers/AudioHelper";
import StorageHelper from "~helpers/StorageHelper";
import store from "~store";
import { getSessionGetterSetter } from "~store/chathistory/actions";
import { selectIsGetterSetter } from "~store/chathistory/selectors";
import { getChannelList } from "~store/settings/channels/actions";
import { selectChannelList } from "~store/settings/channels/selectors";
import {
  getAgentInfo,
  getCallcenterSettings,
  getProjectSettings,
  setLivechatLoadingStatus,
  setLivechatOptions,
  setViewStatus,
  wsGetNextSession,
  wsSendSubscribeLevel,
  wsStatusUpdate,
} from "~store/socket/livechat/actions";
import { syncLivechatOptions } from "~store/socket/livechat/broadcastActions/broadcast";
import { throttleReloadOtherSessions } from "~store/socket/livechat/saga";
import {
  selectAgentInfo,
  selectCallcenterSettings,
  selectLivechatOptions,
  selectOtherSessionsInfo,
  selectSessionCountDelta,
  selectSessionId,
  selectSessions,
} from "~store/socket/livechat/selectors";
import { selectCurrentProject, selectProjects } from "~store/user/selectors";

import LiveSessionChat from "./LiveSessionChat";
import LiveSessionList from "./LiveSessionList";
import StartNewSessionModal from "./StartNewSessionModal";
import LiveSessionGetterSetter from "../ChatHistory/LiveSessionGetterSetter";

const StyledDiv = styled.div`
  //3xxl max with bootstrap
  /* @media (min-width: 1920px) { */
  /* max-width: 1920px; */
  max-width: 100%;
  /* } */
  .form-check-input {
    background-color: var(--bs-danger);
    border-color: var(--bs-danger);
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='white'/%3e%3c/svg%3e") !important;

    &:checked {
      background-color: var(--bs-success);
      border-color: var(--bs-success);
    }
  }
  .card {
    margin: 0;
  }
  .card-body {
    padding: 0px;
    position: relative;
  }
  .pr-tab {
    /* &.nav-tabs-custom { */
    /* border: none; */
    /* } */
    flex-wrap: nowrap;

    background-color: #fff;
    /* margin:0px 2px; */
    .nav-link {
      font-weight: 500;
      color: rgba(0, 0, 0, 0.5);
      padding-top: 22px;
    }
    .nav-link.active {
      background-color: #fff;
      /* border-bottom: 1px solid #fff; */
      color: rgba(0, 0, 0, 0.85);
      font-size: 13px;
      font-weight: bold;
      &::after {
        background-color: ${(props) => props.theme.palette.secondary.dark};
        height: 4px;
      }
    }
    .nav-item {
      min-width: 50px;
    }
    /* flex: 0 0 auto;
    width: 100%; */
  }
  .list-header {
    background-color: #fff;
    margin: 4px;
    padding: 15px 15px 0px;
    /* border-radius: 14px; */
    border-top-left-radius: 14px;
    border-top-right-radius: 14px;
    .list-top {
      display: flex;

      .MuiAvatar-root {
        /* margin: 21px 16px; */
        margin-right: 16px;
        margin-top: 5px;
        width: 52px;
        height: 52px;
      }
      .control {
        flex: 1 1 auto;
        overflow: hidden;
        .agent-label {
          margin-top: 6px;
          font-size: 16px;
          font-weight: 500;
          /* line-height: 34px; */
          color: #000;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .agent-status {
          .form-switch {
            height: 16px;
            margin-top: 0px;
            margin-bottom: 0px;
            min-height: auto;
            input {
              width: 32px;
              height: 16px;
              margin-top: 0px;
              margin-bottom: 0px;
            }
          }
          .checkbox-label {
            margin-left: 10px;
            font-size: 13px;
            font-weight: 300;
            line-height: 16px;
            color: rgba(0, 0, 0, 0.65);
          }
        }
      }

      .list-actions {
        display: flex;
        gap: 15px;
        svg {
          height: 18px;
          width: 18px;
        }
        .MuiButtonBase-root {
          width: 36px;
          height: 36px;
          background-color: #f7f7f7;
        }
      }
    }
  }
`;

export const TabCircle = ({ count, selected }) => {
  if (!count) return null;
  return (
    <Box
      sx={{
        width: 24,
        height: 24,
        backgroundColor: selected ? "#535353" : "rgba(83, 83, 83, 0.5)",
        color: "#fff",
        fontSize: count <= 99 ? 12 : count <= 999 ? 11 : 9,
        fontWeight: "bold",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: "50%",
        marginLeft: "4px",
      }}
    >
      {count}
    </Box>
  );
};
let initialSoundPlayed = false;
export default function LiveChat() {
  const dispatch = useDispatch();

  const [anchorEl, setAnchorEl] = useState(null);
  const [tab, setTab] = useState(1);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [onlyOneTabHasSessions, setOnlyOneTabHasSessions] = useState(false);

  const agentInfo = useSelector(selectAgentInfo);
  const livechatOptions = useSelector(selectLivechatOptions);
  const currentProject = useSelector(selectCurrentProject);
  const projects = useSelector(selectProjects);
  const sessions = useSelector(selectSessions);
  const channels = useSelector(selectChannelList);
  const otherSessionsInfo = useSelector(selectOtherSessionsInfo);
  const callcenterSettings = useSelector(selectCallcenterSettings);
  const sessionCountDelta = useSelector(selectSessionCountDelta);
  const isSessionInfo = useSelector(selectIsGetterSetter);
  const sessionId = useSelector(selectSessionId);
  useEffect(() => {
    if (currentProject?.id && sessionId) {
      dispatch(getSessionGetterSetter(currentProject?.id, sessionId));
    }
  }, [dispatch, currentProject?.id, sessionId]);

  const open = Boolean(anchorEl);
  // const [callcenterType, setCallcenterType] = useState(callcenterSettings?.callcenter_type);

  const isAgentAvailable = agentInfo?.status === "AV";

  // useEffect(() => {
  //   if (livechatOptions?.type) {
  //     setCallcenterType(callcenterSettings?.callcenter_type);
  //   }
  // }, [callcenterSettings?.callcenter_type]);
  const isAdminLiveAgent = [userRole.admin].some((role) => currentProject?.permissions?.includes(role));

  const livechatMode = useMemo(() => {
    if (isAdminLiveAgent) {
      return livechatOptions?.type || callcenterSettings?.callcenter_type || livechatCallcenterType.FIFO;
    }
    return callcenterSettings?.callcenter_type || livechatCallcenterType.FIFO;
  }, [callcenterSettings?.callcenter_type, livechatOptions?.type, isAdminLiveAgent]);

  const activeProjectFilterData = useMemo(() => {
    const filterData = livechatOptions?.filters?.[currentProject?.id] || [];
    return filterData;
  }, [livechatOptions?.filters, currentProject?.id]);

  useEffect(() => {
    if (!agentInfo?.id) {
      dispatch(getAgentInfo());
    }
    dispatch(setViewStatus("visible"));
    return () => {
      dispatch(setViewStatus("hidden"));
    };
  }, [dispatch, agentInfo?.id]);

  useEffect(() => {
    dispatch(getProjectSettings());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getChannelList(currentProject?.id));
    dispatch(getCallcenterSettings(currentProject?.id));
  }, [dispatch, currentProject?.id]);

  const isWhatsappChannelAvailable = useMemo(() => {
    return true;
  }, [channels]);

  const handleAgentAvailable = (e) => {
    const value = e.target.checked;
    dispatch(wsStatusUpdate(value));
    // dispatch(
    //   updateAgentInfo({
    //     ...agentInfo,
    //     status: value ? "AV" : "UN",
    //   })
    // );
  };

  const setLivechatOption = async (key, value) => {
    const livechatOptionsStore = selectLivechatOptions(store.getState());
    const newOptions = {
      ...livechatOptionsStore,
      [key]: value,
    };
    await StorageHelper.set(LS_LIVECHAT_OPTIONS, newOptions);
    syncLivechatOptions(newOptions);
    dispatch(setLivechatOptions(newOptions));
  };

  // const handleToggleOption = (key) => async (e) => {
  //   const value = e.target.checked;
  //   await setLivechatOption(key, value);
  // };

  const handleClose = (key, value) => async (e) => {
    // if (key === "livechat") {
    //   handleAgentAvailable({ target: { checked: !isAgentAvailable } });
    //   return;
    // }
    if (key === "alert") {
      setLivechatOption(key, !livechatOptions.alert);
      if (!livechatOptions.alert && !initialSoundPlayed) {
        //Play initial blank sounds to enable sound while tab is not focused.
        Promise.all([AudioHelper.get(audioList.noSound)]).then(async ([audio]) => {
          audio.replay();
          initialSoundPlayed = true;
        });
      }
    } else if (key === "highlight") {
      setLivechatOption(key, !livechatOptions.highlight);
    } else if (key === "continuousAlert") {
      setLivechatOption(key, !livechatOptions.continuousAlert);
    } else if (key === "agent") {
      UpdateAgentNameModal.show();
      setAnchorEl(null);
    } else if (key === "newSession") {
      StartNewSessionModal.show();
      setAnchorEl(null);
    } else {
      setAnchorEl(null);
    }
  };
  const handleChangeFilter = (filterItems, filterQuery) => {
    dispatch(setLivechatLoadingStatus(true));
    const queryObj = (filterItems || []).reduce((acc, item) => {
      if (item.type === "text") {
        acc[item.key] = item.value[0];
      } else {
        acc[item.key] = item.value;
      }
      return acc;
    }, {});

    const newFilterOptions = {
      ...livechatOptions?.filters,
      [currentProject?.id]: filterItems,
    };
    setLivechatOption("filters", newFilterOptions);

    dispatch(wsSendSubscribeLevel("all", queryObj));
  };

  const handleChangeCallcenterType = async (value) => {
    // setCallcenterType(e.value);
    await setLivechatOption("type", value);
    if (value === livechatCallcenterType.Pool) {
      throttleReloadOtherSessions();
    } else {
      handleChangeFilter([]);
      dispatch(setLivechatLoadingStatus(false));
    }

    setAnchorEl(null);
  };

  const handleOpenSettings = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const { agentChattingCount, agentWaitingCount, chatSessionsCount, isOnlyOneTabHasSessions } = useMemo(() => {
    let agentChattingCount = 0;
    let agentWaitingCount = 0;
    let chatSessionsCount = 0;
    for (const session of sessions) {
      if ([chatbotSessionStatus.AGENT_CHAT].includes(session.session_status)) {
        agentChattingCount++;
      } else if (
        [chatbotSessionStatus.AGENT_WAIT, chatbotSessionStatus.BOT_CHAT_AGENT_WAIT].includes(session.session_status)
      ) {
        agentWaitingCount++;
      } else {
        chatSessionsCount++;
      }
    }
    const isOnlyOneTabHasSessions = [agentChattingCount, agentWaitingCount, chatSessionsCount].findIndex(
      (item) => item && sessions.length == item
    );

    return {
      agentChattingCount,
      agentWaitingCount,
      // chatSessionsCount,
      chatSessionsCount: otherSessionsInfo?.count || chatSessionsCount,
      isOnlyOneTabHasSessions,
    };
  }, [sessions, otherSessionsInfo?.count]);

  useEffect(() => {
    if (onlyOneTabHasSessions !== isOnlyOneTabHasSessions) {
      setOnlyOneTabHasSessions(isOnlyOneTabHasSessions);
      if (isOnlyOneTabHasSessions > -1) {
        if (tab === 2 && !isAgentAvailable) return;
        setTab(isOnlyOneTabHasSessions + 1);
        if (tab === 3) {
          throttleReloadOtherSessions();
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOnlyOneTabHasSessions, onlyOneTabHasSessions]);

  const { visibleStatusList, nonVisibleStatusList } = useMemo(() => {
    let visibleStatusList, nonVisibleStatusList;
    if (tab === 1 || livechatMode === livechatCallcenterType.FIFO) {
      visibleStatusList = [chatbotSessionStatus.AGENT_CHAT];
    } else if (tab === 2) {
      visibleStatusList = [chatbotSessionStatus.AGENT_WAIT, chatbotSessionStatus.BOT_CHAT_AGENT_WAIT];
    } else {
      nonVisibleStatusList = [
        chatbotSessionStatus.AGENT_CHAT,
        chatbotSessionStatus.AGENT_WAIT,
        chatbotSessionStatus.BOT_CHAT_AGENT_WAIT,
      ];
    }
    return {
      visibleStatusList,
      nonVisibleStatusList,
    };
  }, [tab, livechatMode]);

  const livechatFilters = useMemo(
    () =>
      [
        projects?.length > 1 && {
          //TODO: check if could be removed
          // onFetch: async (search) => {
          //   return projects.map((item) => ({
          //     label: item.name,
          //     value: item.id,
          //   }));
          // },
          key: "project_ids",
          type: "options",
          label: "Projects",
          options: projects.map((item) => ({
            label: item.name,
            value: item.id,
          })),
          lookup_keys: ["in"],
          default_lookup_key: "in",
          multiple: true,
          // localFilter: true,
          order: 1,
        },
        {
          type: "text",
          key: "phone_number",
          label: "Phone Number",
          lookup_keys: ["icontains"],
          default_lookup_key: "icontains",
          order: 2,
        },
        {
          type: "text",
          key: "email",
          label: "Email",
          lookup_keys: ["icontains"],
          default_lookup_key: "icontains",
          order: 3,
        },
        {
          type: "text",
          key: "full_name",
          label: "Full Name",
          lookup_keys: ["icontains"],
          default_lookup_key: "icontains",
          order: 4,
        },
        {
          type: "text",
          key: "message_content",
          label: "Message Content",
          lookup_keys: ["icontains"],
          default_lookup_key: "icontains",
          order: 5,
        },
      ].filter(Boolean),
    [projects]
  );
  const handleClickChatAction = useCallback(
    (action, session) => {
      if (action === "take") {
        if (tab === 3) {
          throttleReloadOtherSessions();
        }
        setTab(1);
      }
    },
    [tab]
  );
  const actions = useMemo(() => {
    return [
      isWhatsappChannelAvailable && {
        tooltipText: "Whatsapp Template Messages",
        icon: FaWhatsapp,
        color: "primary",
        link: `/contactcenter/livechat/whatsapp-template-messages/`,
      },
      {
        tooltipText: "Fullscreen Mode",
        icon: MdFullscreen,
        color: "primary",
        onClick: () => setIsFullscreen((s) => !s),
      },
    ].filter(Boolean);
  }, [isWhatsappChannelAvailable]);

  const handleGetNewSession = () => {
    if (sessionCountDelta?.agent_chatting_count > (agentInfo?.max_active_chat_count || 1)) {
      AlertHelper.showWarning(`You can't take more than ${agentInfo?.max_active_chat_count || 1} sessions at a time.`);
      return;
    }
    dispatch(
      wsGetNextSession({
        project_id: currentProject?.id,
      })
    );
  };

  return (
    <PRContainer
      maxWidth
      actions={actions}
      description={
        "Here you can see the list of all the live chat sessions and their details. You can also take over a session from here."
      }
      name="Contact Center"
      parentName="Live Chat"
    >
      <LiveChatFullscreenModal open={isFullscreen} onClose={() => setIsFullscreen(false)}>
        <StyledDiv>
          <Grid container spacing={2}>
            <Grid item xs>
              <Grid container spacing={2}>
                <Grid item lg={6} md={6} xl={5}>
                  <PRCard>
                    <div className="list-header">
                      <div className="list-top">
                        <Avatar alt="Agent picture" src={agentIcon} />
                        <div className="control">
                          <div className="agent-label">
                            {!agentInfo?.name && (
                              <Skeleton height={20} sx={{ mb: "4px" }} variant="rectangular" width={"50%"} />
                            )}
                            {agentInfo?.name}
                          </div>
                          <div className="agent-status">
                            <FormGroup switch>
                              <Input checked={isAgentAvailable} type="switch" onChange={handleAgentAvailable} />
                              <div className="checkbox-label">{isAgentAvailable ? "Available" : "Unavailable"}</div>
                            </FormGroup>
                          </div>
                        </div>
                        <div className="list-actions">
                          {livechatMode === livechatCallcenterType.Pool && (
                            <PalFilter
                              filters={livechatFilters}
                              value={activeProjectFilterData}
                              onChange={handleChangeFilter}
                            />
                          )}

                          {/* <PalIconButton>
                      <SettingsIcon />
                    </PalIconButton> */}
                          <PalIconButton onClick={handleOpenSettings}>
                            <ThreeDotIcon />
                          </PalIconButton>
                          <Menu
                            anchorEl={anchorEl}
                            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                            open={open}
                            slotProps={{
                              paper: {
                                elevation: 0,
                                sx: {
                                  ".MuiMenuItem-root": {
                                    fontSize: "14px",
                                    fontWeight: 500,
                                    lineHeight: "28px",
                                  },
                                  "&.MuiPaper-root": {
                                    padding: "1.5px 25px",
                                    borderRadius: "8px",
                                    boxShadow: "2px 2px 12px 0 rgba(0, 0, 0, 0.08)",
                                    border: "solid 1px #f8f8f8",
                                    backgroundColor: "#fff",
                                    ".MuiButtonBase-root": {
                                      padding: "10px 6px",
                                    },
                                    ".MuiDivider-root": {
                                      margin: "4px 0px",
                                    },
                                  },
                                },
                              },
                            }}
                            transformOrigin={{ horizontal: "right", vertical: "top" }}
                            onClose={handleClose()}
                          >
                            {isWhatsappChannelAvailable && (
                              <MenuItem onClick={handleClose("newSession")}>New session</MenuItem>
                            )}
                            {isWhatsappChannelAvailable && <Divider />}
                            <MenuItem onClick={handleClose("agent")}>Edit Agent Name</MenuItem>
                            <Divider />
                            {/* <MenuItem onClick={handleClose("highlight")}>
                        <Box alignItems="center" display="flex" justifyContent="space-between" width="100%">
                          <span className="me-3">Session Highlight</span>
                          <FormGroup switch>
                            <Input readOnly checked={livechatOptions.highlight} type="switch" />
                          </FormGroup>
                        </Box>
                      </MenuItem> */}
                            <MenuItem onClick={handleClose("alert")}>
                              <Box alignItems="center" display="flex" justifyContent="space-between" width="100%">
                                <span className="me-3">Alerts</span>
                                <FormGroup switch>
                                  <Input readOnly checked={livechatOptions.alert} type="switch" />
                                </FormGroup>
                              </Box>
                            </MenuItem>
                            <MenuItem disabled={!livechatOptions.alert} onClick={handleClose("continuousAlert")}>
                              <Box alignItems="center" display="flex" justifyContent="space-between" width="100%">
                                <span className="me-3">
                                  Continuous Alerts
                                  <PRTooltip title="The alert repeats until all waiting sessions are assigned to agents.(WhatsApp sessions ignored)">
                                    <span>
                                      <MdHelp className="fs-5 ms-1" />
                                    </span>
                                  </PRTooltip>
                                </span>
                                <FormGroup switch>
                                  <Input readOnly checked={livechatOptions.continuousAlert} type="switch" />
                                </FormGroup>
                              </Box>
                            </MenuItem>

                            {isAdminLiveAgent && <Divider />}
                            {isAdminLiveAgent && (
                              <MenuItem>
                                <Box
                                  alignItems="center"
                                  display="flex"
                                  // flexDirection={"column"}
                                  justifyContent="space-between"
                                  minWidth={200}
                                  width="100%"
                                >
                                  <span className="me-4">Mode</span>
                                  <PRSelect
                                    fullWidth
                                    isPrimitiveValue
                                    menuPortal
                                    isClearable={false}
                                    options={livechatCallcenterTypeOptions}
                                    value={livechatOptions?.type ?? livechatCallcenterType.Pool}
                                    onChange={handleChangeCallcenterType}
                                  />
                                </Box>
                              </MenuItem>
                            )}
                          </Menu>
                        </div>
                      </div>

                      {livechatMode === livechatCallcenterType.FIFO && (
                        <Grid container py={1} spacing={2}>
                          <Grid item xs={6}>
                            <Typography>
                              All Sessions:{" "}
                              {(sessionCountDelta?.other_count || 0) + (sessionCountDelta?.agent_chatting_count || 0)}
                            </Typography>
                          </Grid>
                          <Grid item xs={6}>
                            <Typography>Waiting Sessions: {sessionCountDelta?.agent_waiting_count || 0}</Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <PalButton
                              fullWidth
                              disabled={!sessionCountDelta?.agent_waiting_count}
                              size="small"
                              variant="outlined"
                              onClick={handleGetNewSession}
                            >
                              Get from Queue
                            </PalButton>
                          </Grid>
                        </Grid>
                      )}
                      {/* <PalFilter onChange={(filters) => console.log("onChange filters", filters)} /> */}
                      {livechatMode === livechatCallcenterType.Pool && (
                        <PRTab
                          fullWidth
                          noUnderline
                          tab={tab}
                          tabList={[
                            {
                              id: 1,
                              label: (
                                <Row className="g-0 overflow-hidden align-items-center">
                                  <Col xs className="text-truncate">
                                    Agent Chatting
                                  </Col>
                                  <Col xs="auto">
                                    <TabCircle count={agentChattingCount} selected={tab === 1} />
                                  </Col>
                                </Row>
                              ),
                            },
                            {
                              id: 2,
                              label: (
                                <Row className="g-0 overflow-hidden align-items-center">
                                  <Col xs className="text-truncate">
                                    Agent Waiting
                                  </Col>
                                  <Col xs="auto">
                                    <TabCircle count={agentWaitingCount} selected={tab === 2} />
                                  </Col>
                                </Row>
                              ),
                            },
                            {
                              id: 3,
                              label: (
                                <Row className="g-0 overflow-hidden align-items-center">
                                  <Col xs className="text-truncate">
                                    Chat Sessions
                                  </Col>
                                  <Col xs="auto">
                                    <TabCircle count={chatSessionsCount} selected={tab === 3} />
                                  </Col>
                                </Row>
                              ),
                            },
                          ]}
                          onChange={setTab}
                        />
                      )}
                    </div>

                    <LiveSessionList
                      nonVisibleStatusList={nonVisibleStatusList}
                      showAssignedToMeOption={tab === 1 && livechatMode === livechatCallcenterType.Pool}
                      visibleStatusList={visibleStatusList}
                    />
                  </PRCard>
                </Grid>
                <Grid item lg={6} md={6} xl={7}>
                  <LiveSessionChat onClickAction={handleClickChatAction} />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={"auto"}>
              {isSessionInfo && <LiveSessionGetterSetter projectId={currentProject?.id} sessionId={sessionId} />}
            </Grid>
          </Grid>
        </StyledDiv>
      </LiveChatFullscreenModal>
    </PRContainer>
  );
}

const StyledLivechatFullscreenDiv = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  background-color: #fff;
  height: 100%;
  padding: 5px;
  z-index: 1405;

  .pr-livechat-sessionlist {
    .scrollbar-container {
      max-height: calc(100vh - 220px);
      overflow: hidden;
      ${({ historyMode }) => historyMode && "max-height: calc(100vh - 176px);"}
    }
  }
  .chat-wrapper {
    .chat {
      .chat-area {
        height: calc(100vh - 335px) !important;
        overflow-y: scroll;
      }
    }
  }
`;

const LiveChatFullscreenButton = styled(PRButton)`
  position: fixed;
  right: 5px;
  bottom: 5px;
`;

export function LiveChatFullscreenModal({ historyMode, onClose, children, open }) {
  if (!open) return children;
  return (
    <PRModal fullscreen noPadding submitText="" onClose={onClose}>
      <StyledLivechatFullscreenDiv historyMode={historyMode}>
        {children}

        <Row className="g-2">
          <Col className="justify-content-end w-100 d-flex pt-2">
            <LiveChatFullscreenButton onClick={onClose}>Close</LiveChatFullscreenButton>
          </Col>
        </Row>
      </StyledLivechatFullscreenDiv>
    </PRModal>
  );
}
