import { forwardRef, useCallback, useEffect, useState, useMemo, useRef } from "react";

import { GoogleMap, Marker, useLoadScript } from "@react-google-maps/api";
import { withCardon } from "cardon";
import { useFormik } from "formik";
import { isValidNumber } from "libphonenumber-js";
import { usePlacesWidget } from "react-google-autocomplete";
import { MdContactPhone, MdMyLocation } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { Col, Label, Row } from "reactstrap";
import * as Yup from "yup";

const libraries = ["places"];

import { Box } from "@mui/material";

import PRAlert from "~components/Generic/PRAlert";
import PRButton, { PRButtonGroup } from "~components/Generic/PRButton";
import PRDropZone from "~components/Generic/PRDropZone";
import PRFormFeedback from "~components/Generic/PRFormFeedback";
import PRInput from "~components/Generic/PRInput";
import PRModal from "~components/Generic/PRModal";
import PRSelect from "~components/Generic/PRSelect";
import PalPhoneNumber from "~components/mui/PalPhoneNumber";
import {
  chatbotGupshupTemplateStatus,
  chatbotGupshupTemplateType,
  chatbotGupshupTemplateTypeMap,
  chatbotLivechatPageSize,
} from "~constants";
import AlertHelper from "~helpers/AlertHelper";
import Utils from "~helpers/Utils";
import store from "~store";
import { getWhatsappTemplateList } from "~store/platform/actions";
import { selectWhatsappTemplateList } from "~store/platform/selectors";
import {
  setLivechatLoadingStatus,
  uploadFile,
  wsAskSessionInfo,
  wsSendTemplateMessage,
} from "~store/socket/livechat/actions";
import { selectAgentInfo, selectOnlineStatus, selectOtherSessionsInfo } from "~store/socket/livechat/selectors";
import { selectCurrentProject } from "~store/user/selectors";

import CustomerSelectModal from "./CustomerSelectModal";

const uploadOptions = [
  {
    value: "link",
    label: "Link ",
  },
  {
    value: "upload",
    label: "Upload",
  },
];

export const locateToUser = async () => {
  return new Promise((resolve) => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          resolve(position);
        },
        () => {
          console.log("Location permission denied");
          resolve();
        }
      );
    } else {
      console.log("Location not supported");
      resolve();
    }
  });
};
export const WhatsappMapSelector = forwardRef(function MapSelector(
  {
    defaultLocation,
    onReset,
    onChangeName,
    locationName,
    disableMyLocation,
    onMyLocation,
    inputRef,
    onPlaceSelected,
    markerProps,
    ...rest
  },
  ref
) {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: window.pr_config?.google,
    libraries,
    //callback
    // callbackName: "__REACT_GOOGLE_AUTOCOMPLETE_CALLBACK__",
  });

  return (
    <>
      {isLoaded && (
        <>
          <Row className="mb-2 g-2">
            <Col xs="12">Location:</Col>
            <Col xs="auto">
              <PRInput
                className="w-xl"
                name="locationName"
                placeholder="Name (Optional)"
                value={locationName}
                onChange={onChangeName}
              />
            </Col>
            <Col xs>
              <AutocompleteInput inputRef={inputRef} placeholder="Search Location" onPlaceSelected={onPlaceSelected} />
            </Col>
            <Col xs="auto">
              <PRButton onClick={onReset}>Reset Location</PRButton>
            </Col>
            <Col xs="auto">
              <PRButton disabled={disableMyLocation} icon={MdMyLocation} onClick={onMyLocation} />
            </Col>
          </Row>
          <GoogleMap {...rest} ref={ref}>
            <Marker {...markerProps} />
            {defaultLocation && !disableMyLocation && (
              <Marker
                //my location
                icon={{
                  //my location path
                  path: window.google.maps.SymbolPath.CIRCLE,
                  scale: 4,
                  strokeWeight: 8,
                  fillColor: "#4285f4",
                  strokeColor: "#4285f4",
                  fillOpacity: 1,
                  strokeOpacity: 0.3,
                }}
                position={defaultLocation}
                title="My Location"
              />
            )}
          </GoogleMap>
        </>
      )}
      {loadError && <div>Map cannot be loaded right now, try refreshing the page</div>}
    </>
  );
});

function AutocompleteInput({ inputRef, onPlaceSelected }) {
  const { ref: placeRef } = usePlacesWidget({
    apiKey: window.pr_config?.google,
    // libraries: ["places"],
    // inputAutocompleteValue: "Antalya",
    onPlaceSelected: onPlaceSelected,
    options: {
      types: ["geocode", "establishment"],
      componentRestrictions: { country: "tr" },
      // callbackName: "__REACT_GOOGLE_AUTOCOMPLETE_CALLBACK__",
    },
  });
  useEffect(() => {
    inputRef.current = placeRef.current;
  }, [inputRef, placeRef]);
  return <PRInput innerRef={placeRef} />;
}
function HighlightTemplateText({ text, params }) {
  const parts = text.split(/(\{\{\d+\}\})/g);

  let paramOrder = 0;
  return (
    <>
      {parts.map((part, index) => {
        const match = part.match(/\{\{(\d+)\}\}/);
        if (!match) return part;
        // const paramIndex = match[1] - 1;
        const paramIndex = paramOrder++;
        return (
          <span key={index} className="fw-bold text-primary">
            {params?.[paramIndex] || part}
          </span>
        );
      })}
    </>
  );
}

const defaultZoom = 15;
const defaultLocation = { lat: 37.870906, lng: 32.504787 };
function StartNewSessionModalContent({ get, id }) {
  const dispatch = useDispatch();

  const agentInfo = useSelector(selectAgentInfo);

  const googleMapsRef = useRef(null);
  const googleInputRef = useRef(null);

  const currentProject = useSelector(selectCurrentProject);
  const whatsappTemplateList = useSelector(selectWhatsappTemplateList);
  const [uploadMode, setUploadMode] = useState("upload");
  const [hasLocationPermission, setHasLocationPermission] = useState(false);

  const onlineStatus = useSelector(selectOnlineStatus);

  const isAgentAvailable = agentInfo?.status === "AV";

  const whatsappTemplateOptions = useMemo(() => {
    return whatsappTemplateList.map((template) => ({
      ...template,
      disabled: template?.response_data?.status !== chatbotGupshupTemplateStatus.APPROVED,
    }));
  }, [whatsappTemplateList]);

  const reloadOtherSessionPage = () => {
    const otherSessionInfo = selectOtherSessionsInfo(store.getState());
    const offset = (otherSessionInfo.page - 1) * chatbotLivechatPageSize;
    dispatch(setLivechatLoadingStatus(true));
    dispatch(wsAskSessionInfo(chatbotLivechatPageSize, offset));
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      phoneNumber: {
        countryCallingCode: null, // "90",
        countryCode: null, //"TR",
        nationalNumber: null, //"5",
        numberType: null,
        numberValue: null, //"+905",
      },
      template: null,
      params: [],
      file: null,
      link: "",
      defaultLocation: { ...defaultLocation },
      location: null,
      locationName: "",
      center: null,
      zoom: null,
      addressInfo: {
        formattedAddress: null,
        addressParts: null,
      },
    },
    validationSchema: Yup.object().shape({
      phoneNumber: Yup.object()
        .nullable()
        .test("phone_number", "Phone number is not valid", (val, context) => {
          if (!val) return true;
          let isValid = false;
          try {
            isValid = isValidNumber(val.numberValue, val?.countryCode);
          } catch (e) {}

          return isValid;
        })
        // .test("phone_number", "Phone number is not valid", (val) => {
        //   if (!val) return true;
        //   return ValidateHelper.phoneNumber(val.numberValue, val?.countryCode);
        // })
        .required("Phone number is required"),
      template: Yup.object().nullable().required("Template is required"),
      params: Yup.array().of(Yup.string().nullable().required("Parameter is required")),
      location: Yup.object()
        .nullable()
        .when("template", {
          is: (val) => val?.template_type === chatbotGupshupTemplateType.LOCATION,
          then: Yup.object().nullable().required("Location is required"),
        }),
      link: Yup.string().when("template", {
        is: (val) =>
          [
            chatbotGupshupTemplateType.IMAGE,
            chatbotGupshupTemplateType.DOCUMENT,
            chatbotGupshupTemplateType.VIDEO,
          ].includes(val?.template_type) && uploadMode === "link",
        then: Yup.string()
          .required("Link is required")
          .test("is-url", "Link is not valid", (val, context) => {
            if (!val) return true;
            return Utils.validateUrl(val);
          })
          .test("is-valid-type", (val, context) => {
            const tType = context.parent.template?.template_type;
            const allowedTypes =
              tType === chatbotGupshupTemplateType.IMAGE
                ? [".png", ".jpg", ".jpeg"]
                : tType === chatbotGupshupTemplateType.VIDEO
                ? [".mp4", ".mov", ".avi", ".mkv"]
                : null;
            const url = val?.toLowerCase().replace(/\/$/, "");
            const lastUrlPart = url?.slice(url?.lastIndexOf("/"));
            const hasExtension = lastUrlPart?.includes(".");
            const endChunk = hasExtension ? lastUrlPart?.slice(lastUrlPart?.lastIndexOf(".")) : null;
            const isAllowed = allowedTypes === null || endChunk === null || allowedTypes.includes(endChunk);

            if (!isAllowed) {
              return context.createError({
                message: `Only ${allowedTypes.join(", ")} files are allowed`,
                path: context.path,
              });
            }
            return true;
          }),
      }),
      file: Yup.mixed().when("template", {
        is: (val) =>
          [
            chatbotGupshupTemplateType.IMAGE,
            chatbotGupshupTemplateType.DOCUMENT,
            chatbotGupshupTemplateType.VIDEO,
          ].includes(val?.template_type) && uploadMode === "upload",
        then: Yup.mixed().required("File is required"),
      }),
    }),
    onSubmit: async (values) => {
      let url = formik.values.link;
      if (
        uploadMode === "upload" &&
        [
          chatbotGupshupTemplateType.IMAGE,
          chatbotGupshupTemplateType.DOCUMENT,
          chatbotGupshupTemplateType.VIDEO,
        ].includes(values.template.template_type)
      ) {
        const imageResponse = await dispatch(uploadFile(null, formik.values.file, currentProject.id, true));
        url = imageResponse.file_url || imageResponse.url || "";
        if (process.env.NODE_ENV === "development") {
          url = url.replace("app-dev", "app-dev2");
        }
      }

      const messageDetail = {
        message: {
          type: values.template.template_type?.toLowerCase(),
          ...(values.template.template_type === chatbotGupshupTemplateType.LOCATION && {
            location: {
              latitude: values.location.lat,
              longitude: values.location.lng,
              address: values.addressInfo.formattedAddress,
              name: values.locationName,
            },
          }),
          ...(values.template.template_type === chatbotGupshupTemplateType.IMAGE && {
            image: {
              link: url,
            },
          }),
          ...(values.template.template_type === chatbotGupshupTemplateType.DOCUMENT && {
            document: {
              link: url,
              filename: formik.values.file?.name,
            },
          }),
          ...(values.template.template_type === chatbotGupshupTemplateType.VIDEO && {
            video: {
              link: url,
            },
          }),
        },
        params: values.params,
      };

      dispatch(
        wsSendTemplateMessage({
          send_type: "whatsapp",
          phone_number: values.phoneNumber.numberValue,
          project_id: currentProject.id,
          template_id: values.template.id,
          message_details: messageDetail,
        })
      );

      AlertHelper.showSuccess("Template message sent");
      get(false)();
      setTimeout(() => {
        reloadOtherSessionPage();
      }, 1000);
    },
  });

  const handleChangePhoneNumber = (value, phoneObj) => {
    formik.setFieldValue("phoneNumber", phoneObj);
  };

  // const handleChangePhoneNumber = (data, phoneObj) => {
  //   formik.setFieldValue("phoneNumber", phoneObj);
  // };

  const handleChangeTemplate = (value) => {
    formik.setFieldValue("template", value);
  };

  const { templateLength, formattedTemplate } = useMemo(() => {
    //detect {{0}} fields as rexex;
    const regex = /{{+\d?}}/g;
    const templateFields = formik.values.template?.response_data?.data?.match(regex);
    // const uniqueTemplateFields = [...new Set(templateFields)];
    // const matchLength = uniqueTemplateFields?.length || 0;
    const matchLength = templateFields?.length || 0; // All param fields must be filled even same args
    const formattedTemplateByParams =
      templateFields?.reduce((acc, field, index) => {
        const param = formik.values.params?.[index];
        const paramValue = param || field;

        const replaceRegExp = new RegExp(Utils.escapeRegExp(field), "g");
        return acc.replace(replaceRegExp, paramValue);
      }, formik.values.template?.response_data?.data) || "";

    return {
      templateLength: matchLength,
      formattedTemplate: formattedTemplateByParams,
    };
  }, [formik.values.template, formik.values.params]);

  useEffect(() => {
    formik.setFieldValue("params", new Array(templateLength).fill(""));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [templateLength]);

  useEffect(() => {
    dispatch(getWhatsappTemplateList(currentProject.id));
  }, [dispatch, currentProject.id]);

  const autoPanMap = useCallback(() => {
    locateToUser().then((position) => {
      const focusCoords = { ...defaultLocation };
      if (position) {
        const { latitude, longitude } = position.coords;
        formik.setFieldValue("defaultLocation", { lat: latitude, lng: longitude });
        focusCoords.lat = latitude;
        focusCoords.lng = longitude;
        setHasLocationPermission(true);
      } else {
        setHasLocationPermission(false);
      }

      googleMapsRef.current?.panTo({ lat: focusCoords.lat, lng: focusCoords.lng });
      googleMapsRef.current?.setZoom(15);
    });
  }, []);

  useEffect(() => {
    if (formik.values.template?.template_type === chatbotGupshupTemplateType.LOCATION && googleMapsRef.current) {
      autoPanMap();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [templateLength, googleMapsRef, formik.values.template, autoPanMap]);

  const handleChangeParam = (index) => (e) => {
    const value = e.target.value;
    const newParams = [...formik.values.params.map((param, i) => (i === index ? value : param))];
    formik.setFieldValue("params", newParams);
  };

  const handleChangeLocation = (lat, lng) => {
    formik.setFieldValue("location", { lat: lat, lng: lng });
  };

  const handleResetLocation = () => {
    formik.setFieldValue("location", null);
    googleMapsRef.current?.panTo(formik.values.defaultLocation);
    googleMapsRef.current?.setZoom(defaultZoom);
  };
  const handleOnMyLocation = () => {
    locateToUser().then((position) => {
      if (position) {
        const { latitude, longitude } = position.coords;
        googleMapsRef.current?.panTo({ lat: latitude, lng: longitude });
        googleMapsRef.current?.setZoom(defaultZoom);
        setHasLocationPermission(true);
      } else {
        setHasLocationPermission(false);
      }
    });
  };

  const handlePlaceSelected = (place) => {
    const lat = place.geometry.location.lat();
    const lng = place.geometry.location.lng();
    handleChangeLocation(lat, lng);
    // formik.setFieldValue("center", { lat, lng });
    googleMapsRef.current.panTo({ lat, lng });
  };
  const onMapLoad = useCallback(
    (map) => {
      googleMapsRef.current = map;
      autoPanMap();
    },
    [autoPanMap]
  );

  const handleClickContacts = async () => {
    const result = await CustomerSelectModal.show();
    if (result) {
      formik.setFieldValue("phoneNumber.numberValue", result);
    }
  };

  const handleDropFile = (files) => {
    formik.setFieldValue("file", files[0]);
  };

  const handleClickMap = (e) => {
    const lat = e.latLng.lat();
    const lng = e.latLng.lng();
    handleChangeLocation(lat, lng);
    if (googleInputRef.current) {
      const geocoder = new window.google.maps.Geocoder();

      const latLng = new window.google.maps.LatLng(lat, lng);
      geocoder.geocode(
        {
          bounds: new window.google.maps.LatLngBounds(latLng),
          location: latLng,
        },
        (results, status) => {
          if (status === "OK") {
            const nonPlusCodeResult = results.find((result) => !result.plus_code);
            if (nonPlusCodeResult) {
              googleInputRef.current.value = nonPlusCodeResult?.formatted_address;
              formik.setFieldValue("addressInfo", {
                addressParts: results,
                formattedAddress: nonPlusCodeResult?.formatted_address,
              });
            } else {
              console.log("No results found");
            }
          } else {
            console.log("Geocoder failed due to: " + status);
          }
        }
      );
    }
  };

  const handleChangeLocationName = (e) => {
    formik.setFieldValue("locationName", e.target.value);
  };

  const handleClose = () => {
    get(false)();
    reloadOtherSessionPage();
  };
  return (
    <PRModal
      size="lg"
      title="Create New session"
      //   submitText="Submit"
      onClose={handleClose}
      onClick={formik.handleSubmit}
      //   loading={loading}
      submitDisabled={!isAgentAvailable || !["connected", "reconnected"].includes(onlineStatus)}
    >
      {!isAgentAvailable && (
        <PRAlert color="warning">
          You are currently unavailable to chat. Please update your status to "Available" in order to start a new
          session.
        </PRAlert>
      )}
      <Row className="g-3" disabled={!isAgentAvailable}>
        <Col xs={12}>
          <Label>Phone Number:</Label>
          <div className="d-flex align-items-start gap-2 w-100">
            <PalPhoneNumber
              fullWidth
              reactstrapMode
              invalid={formik.touched.phoneNumber && formik.errors.phoneNumber}
              name="phoneNumber"
              value={formik.values.phoneNumber?.numberValue}
              onBlur={formik.handleBlur}
              onChange={handleChangePhoneNumber}
            />
            {/* <PalPhoneNumber
              fullWidth
              borderRadius={8}
              InputProps={{
                name: "phone-number",
              }}
              invalid={formik.touched.phoneNumber && formik.errors.phoneNumber}
              smallSpacing={true}
              value={formik.values.phoneNumber?.numberValue}
              onChange={handleChangePhoneNumber}
            /> */}
            <PRButton outline icon={MdContactPhone} onClick={handleClickContacts} />
          </div>
        </Col>
        <Col xs={12}>
          <Label>Template:</Label>
          <PRSelect
            invalid={formik.touched.template && formik.errors.template}
            labelRenderer={(option) =>
              `${chatbotGupshupTemplateTypeMap[option?.template_type] || option?.template_type} - ${
                option?.response_data?.elementName
              }`
            }
            labelSelector="response_data.data"
            options={whatsappTemplateOptions}
            value={formik.values.template}
            valueSelector="id"
            onChange={handleChangeTemplate}
          />
        </Col>
        <Col xs={12}>
          <Label>Template Message:</Label>
          <Box
            sx={{
              minHeight: 50,
              maxHeight: 250,
              overflowY: "auto",
              border: "1px dashed #ced4da",
              borderRadius: "0.25rem",
              whiteSpace: "pre-wrap",
              wordWrap: "break-word",
              padding: "0.375rem 0.75rem",
            }}
          >
            <HighlightTemplateText
              params={formik.values.params}
              text={formik.values.template?.response_data?.data || ""}
            />
          </Box>
        </Col>
        <Col xs={12}>
          <Row className="align-items-center pb-2">
            <Col xs>
              <Label className="m-0">Template Parameters ({templateLength})</Label>
            </Col>
          </Row>

          <div className="d-flex flex-column gap-2">
            {templateLength > 0 ? (
              formik.values.params.map((param, index) => {
                return (
                  <div key={index} className="d-flex align-items-center w-100">
                    <PRInput
                      className="w-100"
                      invalid={formik.touched.params?.[index] && formik.errors.params?.[index]}
                      value={param}
                      onChange={handleChangeParam(index)}
                    />
                    {/* <PRButton icon={MdDelete} onClick={handleRemoveParam(index)} color="danger" /> */}
                  </div>
                );
              })
            ) : (
              <div className="text-center"> - No parameters - </div>
            )}
          </div>
        </Col>
        {formik.values.template?.template_type === chatbotGupshupTemplateType.LOCATION && (
          <Col xs={12}>
            <WhatsappMapSelector
              defaultLocation={formik.values.defaultLocation}
              disableMyLocation={!hasLocationPermission}
              inputRef={googleInputRef}
              locationName={formik.values.locationName}
              mapContainerStyle={{
                width: "100%",
                height: "250px",
              }}
              markerProps={{
                position: formik.values.location,
              }}
              options={{
                disableDefaultUI: true,
                zoomControl: true,
              }}
              onChangeName={handleChangeLocationName}
              onClick={handleClickMap}
              onLoad={onMapLoad}
              onMyLocation={handleOnMyLocation}
              onPlaceSelected={handlePlaceSelected}
              onReset={handleResetLocation}
            />
            <PRFormFeedback invalid={formik.touched.location && formik.errors.location} />
          </Col>
        )}
        {[
          chatbotGupshupTemplateType.IMAGE,
          chatbotGupshupTemplateType.DOCUMENT,
          chatbotGupshupTemplateType.VIDEO,
        ].includes(formik.values.template?.template_type) && (
          <Col xs={12}>
            <div className="d-flex align-items-center justify-content-between mb-2">
              {chatbotGupshupTemplateType.IMAGE === formik.values.template?.template_type
                ? "Image"
                : chatbotGupshupTemplateType.DOCUMENT === formik.values.template?.template_type
                ? "Document"
                : "Video"}
              :
              <PRButtonGroup options={uploadOptions} value={uploadMode} onChange={setUploadMode} />
            </div>
            {uploadMode === "upload" && (
              <PRDropZone
                accept={
                  chatbotGupshupTemplateType.IMAGE === formik.values.template?.template_type
                    ? ["image/*"]
                    : chatbotGupshupTemplateType.DOCUMENT === formik.values.template?.template_type
                    ? []
                    : chatbotGupshupTemplateType.VIDEO === formik.values.template?.template_type
                    ? ["video/*"]
                    : []
                }
                className="mt-2"
                invalid={formik.touched.file && formik.errors.file}
                maxSize={
                  (chatbotGupshupTemplateType.VIDEO === formik.values.template?.template_type ? 20 : 2) * 1024 * 1024
                }
                onFileChange={handleDropFile}
              />
            )}
            {uploadMode === "link" && (
              <PRInput
                invalid={formik.touched.link && formik.errors.link}
                name="link"
                value={formik.values.link}
                onChange={formik.handleChange}
              />
            )}
          </Col>
        )}
      </Row>
    </PRModal>
  );
}
const StartNewSessionModal = withCardon(StartNewSessionModalContent, { destroyOnHide: true });
export default StartNewSessionModal;
