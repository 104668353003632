import { useEffect, useMemo, useState } from "react";

import { PalAutoComplete, PalButton, PalDatePicker } from "@palamar/fe-library";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import styled from "@emotion/styled";
import { Grid } from "@mui/material";

import { userRole } from "~constants";
import DateHelper from "~helpers/DateHelper";
import { getPermissionUserList } from "~store/settings/permission/actions";
import { selectPermissionUserList } from "~store/settings/permission/selectors";
import { setAgentFilterIds, setStatisticsFilter } from "~store/statistics/actions";

const StyledFilterCard = styled.div`
  padding: 20px;
  margin: 10px;
  background-color: ${({ theme }) => theme.palette.grey[100]};
  border-radius: ${({ theme }) => theme.spacing(1)};
`;

const StyledFilterButton = styled(PalButton)`
  width: 100%;
  ${({ theme }) => theme.breakpoints.up("md")} {
    width: auto;
  }
`;

function FilterButton({ children, ...rest }) {
  return (
    <StyledFilterButton {...rest} size="medium">
      {children}
    </StyledFilterButton>
  );
}

const StyledPalDatePicker = styled(PalDatePicker)`
  border-top-left-radius: auto;
  ${({ theme }) => theme.breakpoints.down("md")} {
    &.MuiTextField-root {
      width: 100%;
    }
  }
`;

const FilterCard = ({ agentFilter = false }) => {
  const { projectId } = useParams();
  const dispatch = useDispatch();
  const [selected, setSelected] = useState("month");
  const [beginDate, setBeginDate] = useState(DateHelper.getDateTime().subtract(1, "month"));
  const [endDate, setEndDate] = useState(DateHelper.getDateTime());
  const [agents, setAgents] = useState([]);
  const permissionUserList = useSelector(selectPermissionUserList);

  const allAgents = useMemo(() => {
    return permissionUserList.filter((item) =>
      item.roles.some((r) => [userRole.admin, userRole.ticketAgent].includes(r))
    );
  }, [permissionUserList]);

  const agentListOptions = useMemo(() => {
    return allAgents.map((item) => ({
      label: `${item.firstname} ${item.lastname} (${item.email})`,
      value: item.id,
    }));
  }, [allAgents]);

  useEffect(() => {
    if (!agents?.length && agentListOptions?.length) {
      dispatch(setAgentFilterIds(agentListOptions));
    }
  }, [agentListOptions, agents, dispatch]);

  useEffect(() => {
    if (agentFilter) {
      if (agents.length) {
        dispatch(setAgentFilterIds(agents));
      }
      // dispatch(
      //   setAgentFilterIds(
      //     allAgents.map((item) => ({
      //       label: `${item.firstname} ${item.lastname} (${item.email})`,
      //       value: item.id,
      //     }))
      //   )
      // );
    }
  }, [agents, allAgents, agentFilter, dispatch]);

  const handleDayClick = () => {
    setSelected("day");
    dispatch(
      setStatisticsFilter({
        beginDate: DateHelper.getDateTime().subtract(1, "day").format("YYYY-MM-DD"),
        endDate: DateHelper.getDateTime().format("YYYY-MM-DD"),
      })
    );
  };
  const handleWeekClick = () => {
    setSelected("week");
    dispatch(
      setStatisticsFilter({
        beginDate: DateHelper.getDateTime().subtract(1, "week").format("YYYY-MM-DD"),
        endDate: DateHelper.getDateTime().format("YYYY-MM-DD"),
      })
    );
  };
  const handleMonthClick = () => {
    setSelected("month");
    dispatch(
      setStatisticsFilter({
        beginDate: DateHelper.getDateTime().subtract(1, "month").format("YYYY-MM-DD"),
        endDate: DateHelper.getDateTime().format("YYYY-MM-DD"),
      })
    );
  };
  const handleCustomClick = () => {
    setSelected("custom");
  };

  const handleApplyClick = () => {
    dispatch(
      setStatisticsFilter({
        beginDate: beginDate.format("YYYY-MM-DD"),
        endDate: endDate.format("YYYY-MM-DD"),
      })
    );
  };

  useEffect(() => {
    if (agentFilter) {
      dispatch(getPermissionUserList(projectId));
    }
    handleMonthClick();
  }, []);

  // const handleGetOptionDisabled = (option) => {
  //   return agents.length >= 3 && !agents.includes(option);
  // };

  const handleBeginDateChange = (date) => {
    const beginOfMonth = DateHelper.getDateTime(date);
    setBeginDate(beginOfMonth);
  };

  const handleEndDateChange = (date) => {
    const endOfMonth = DateHelper.getDateTime(date);
    setEndDate(endOfMonth);
  };

  const handleChangeAgents = (e, value) => {
    setAgents(value);
  };
  const handleGetOptionDisabled = (option) => {
    return agents.length >= 3 && !agents.includes(option);
  };

  return (
    <StyledFilterCard>
      {/* <Typography variant="h6">Filter</Typography> */}
      <Grid container spacing={2}>
        <Grid item md="auto" sm={6} xs={12}>
          <FilterButton fullWidth variant={selected === "day" ? "contained" : "outlined"} onClick={handleDayClick}>
            Yesterday
          </FilterButton>
        </Grid>
        <Grid item md="auto" sm={6} xs={12}>
          <FilterButton fullWidth variant={selected === "week" ? "contained" : "outlined"} onClick={handleWeekClick}>
            Last Week
          </FilterButton>
        </Grid>
        <Grid item md="auto" sm={6} xs={12}>
          <FilterButton fullWidth variant={selected === "month" ? "contained" : "outlined"} onClick={handleMonthClick}>
            Last Month
          </FilterButton>
        </Grid>
        <Grid item md="auto" sm={6} xs={12}>
          <FilterButton
            fullWidth
            variant={selected === "custom" ? "contained" : "outlined"}
            onClick={handleCustomClick}
          >
            Custom
          </FilterButton>
        </Grid>
      </Grid>
      {selected === "custom" && (
        <Grid container pt={2} spacing={2}>
          <Grid item sm md="auto" xs="12">
            <StyledPalDatePicker
              disabled={selected !== "custom"}
              label="Begin Date"
              openTo="month"
              value={beginDate}
              onChange={handleBeginDateChange}
            />
          </Grid>
          <Grid item sm md="auto" xs="12">
            <StyledPalDatePicker
              disabled={selected !== "custom"}
              label="End Date"
              openTo="month"
              value={endDate}
              onChange={handleEndDateChange}
            />
          </Grid>
          <Grid item md="auto" sm="auto" xs="12">
            <FilterButton sx={{ minWidth: 80, height: "100%" }} variant="text" onClick={handleApplyClick}>
              Apply
            </FilterButton>
          </Grid>
        </Grid>
      )}
      {agentFilter && (
        <PalAutoComplete
          // isPrimitiveValue
          multiple
          getOptionDisabled={handleGetOptionDisabled}
          options={agentListOptions}
          placeholder="Select Agents"
          sx={{
            width: {
              md: "100%",
              xs: "100%",
            },
            marginTop: 2,
          }}
          value={agents}
          onChange={handleChangeAgents}
        />
      )}
    </StyledFilterCard>
  );
};

export default FilterCard;
