import { useEffect, useMemo, useState } from "react";

import { useFormik } from "formik";
import { MdAdd, MdDelete, MdDownload, MdUpload } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import { Col, FormFeedback, Input, Label, Row } from "reactstrap";
import * as Yup from "yup";

import useProjectChange from "~common/hooks/useProjectChange";
import PRButton from "~components/Generic/PRButton";
import PRContainer from "~components/Generic/PRContainer";
import PRDate from "~components/Generic/PRDate";
import PRDivider from "~components/Generic/PRDivider";
import PRDragHandle from "~components/Generic/PRDragHandle";
import PRInput from "~components/Generic/PRInput";
import PRPage from "~components/Generic/PRPage";
import PRSelect from "~components/Generic/PRSelect";
import {
  dialogComponentsEntityTypeDateFormatType,
  dialogComponentsEntityTypeDateFormatTypeOptions,
  dialogComponentsEntityTypeFamily,
  dialogComponentsEntityTypeFamilyOptions,
  dialogComponentsEntityTypePredictionTypeOptions,
} from "~constants";
import DateHelper from "~helpers/DateHelper";
import DialogHelper from "~helpers/DialogHelper";
import FileHelper from "~helpers/FileHelper";
import HistoryHelper from "~helpers/HistoryHelper";
import Utils from "~helpers/Utils";
import {
  createOrUpdateEntityType,
  downloadEntityType,
  getEntityType,
  setEntityType,
  uploadEntityType,
} from "~store/dialogComponents/entityTypes/actions";
import { selectEntityType } from "~store/dialogComponents/entityTypes/selectors";
import { selectCurrentBot, selectCurrentProject } from "~store/user/selectors";

import UploadModal from "../../UploadModal";
import "./style.scss";

const goBackUrl = "/chatbot/entity-types/";

function EntityItem({ order: _, entity, onChange, onDelete, error, touched }) {
  const handleChange = (key) => (e) => {
    let value = e.target.value;
    onChange({
      ...entity,
      [key]: value,
    });
  };

  const handleAddDictionaryItem = () => {
    onChange({
      ...entity,
      dictionary: [...(entity?.dictionary || []), ""],
    });
  };

  const handleChangeDictionaryItem = (index) => (e) => {
    const value = e.target.value;
    onChange({
      ...entity,
      dictionary: entity.dictionary.map((option, i) => (i === index ? value : option)),
    });
  };

  const handleDeleteDictionaryItem = (index) => () => {
    onChange({
      ...entity,
      dictionary: entity.dictionary.filter((_, i) => i !== index),
    });
  };

  const handleDeleteEntityItem = () => {
    onDelete();
  };

  const handleEnterCreateNew = (e) => {
    if (e.key === "Enter") {
      handleAddDictionaryItem();
    }
  };

  return (
    <div className="entity-item">
      <PRDragHandle />
      <Row className="g-2 align-items-top">
        {/* <Col xs="12">
      <Row className="g-0">
        <Col md="6">
          
        </Col>
      </Row>
      </Col> */}
        <Col md>
          <Label className="" size="md">
            Display Name
          </Label>
          <PRInput
            invalid={touched?.name && error?.name}
            placeholder="Enter Name"
            value={entity.name}
            onChange={handleChange("name")}
          />
        </Col>
        <Col md>
          <Label className="" size="md">
            Value
          </Label>
          <PRInput
            invalid={touched?.company_value && error?.company_value}
            name={`company_value`}
            placeholder="Enter Company Value"
            value={entity.company_value}
            onChange={handleChange("company_value")}
          />
        </Col>
        <Col className="d-flex flex-column " md="auto">
          <Label className="opacity-0" size="md">
            &nbsp;
          </Label>
          <PRButton outline color="danger" icon={MdDelete} onClick={handleDeleteEntityItem} />
        </Col>
      </Row>

      <Row className="g-0 ">
        <PRDivider className={"my-2"} />
        <span className="text-secondary mb-1">Dictionary</span>

        <Col lg={"12"} md="12">
          <Row className="gx-2 gy-1">
            {entity.dictionary?.map((choice, index) => (
              <Row key={index} className="gx-2 gy-1">
                <Col key={index} xs>
                  <PRInput
                    autoFocus={index === entity.dictionary.length - 1}
                    invalid={touched?.dictionary && error?.dictionary?.[index]}
                    placeholder="Dictionary item"
                    size="sm"
                    value={choice}
                    onChange={handleChangeDictionaryItem(index)}
                    onKeyDown={handleEnterCreateNew}
                  />
                </Col>
                <Col xs="auto">
                  <PRButton
                    outline
                    color="danger"
                    icon={MdDelete}
                    size="sm"
                    onClick={handleDeleteDictionaryItem(index)}
                  />
                </Col>
              </Row>
            ))}

            <Col className="d-flex justify-content-end" xs="12">
              <PRButton className="me-2" color="success" icon={MdAdd} size="sm" onClick={handleAddDictionaryItem} />
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
}
const SortableEntityItem = SortableElement(EntityItem);
const SortableEntityItemList = SortableContainer(
  ({ errors, touches, items, handleChangeSurvey, handleDeleteSurvey }) => {
    return (
      <Row className="g-0">
        {items.map((item, index) => {
          return (
            <Col key={index} className="mb-2" xs="12">
              <SortableEntityItem
                key={index}
                entity={item}
                error={errors?.[index]}
                index={index}
                order={index}
                touched={touches?.[index]}
                onChange={handleChangeSurvey(item)}
                onDelete={handleDeleteSurvey(item)}
              />
            </Col>
          );
        })}
      </Row>
    );
  }
);

const dateFormat = "%d-%m-%Y";
const timeFormat = "%H:%M:%S";
const dateTimeFormat = `${dateFormat} ${timeFormat}`;

export default function EntityTypesEdit() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const currentProject = useSelector(selectCurrentProject);
  const currentBot = useSelector(selectCurrentBot);
  const entityType = useSelector(selectEntityType);
  const [acceptText, setAcceptText] = useState("");

  useProjectChange(() => {
    HistoryHelper.push(goBackUrl, { scope: "dashboard" });
  }, []);

  const handleGoBack = () => {
    HistoryHelper.goBack(goBackUrl, { scope: "dashboard" });
  };
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: entityType.id,
      name: entityType.name,
      family: entityType.family,
      entities: entityType.entities || [],
      prediction_type: entityType.prediction_type || "",
      accept: entityType.accept || [],
      max_size:
        entityType.max_size > 0 ? Math.round(Math.max(entityType.max_size / 1024 / 1024, 0)) : entityType.max_size,
      max_files: entityType.max_files,
      multiple: entityType.multiple || false,
      range_begin: entityType.range_begin,
      range_end: entityType.range_end,

      date_format_type: entityType.date_format_type || dialogComponentsEntityTypeDateFormatType.date,
      // date_format_visible: entityType.date_format_visible || dateFormat,
      final_range_begin: entityType.final_range_begin,
      final_range_end: entityType.final_range_end,
      interval: entityType.interval,
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Required").min(3, "Name must be at least 3 characters"),
      family: Yup.string().required("Required"),
      entities: Yup.array()
        .of(
          Yup.object({
            company_value: Yup.string().required("Required"),
            name: Yup.string().required("Required"),
            dictionary: Yup.array().of(Yup.string().required("Required")),
          })
        )
        .when("family", {
          is: (val) => val === dialogComponentsEntityTypeFamily.CustomType,
          then: Yup.array().min(1, "At least one entity is required"),
        }),

      // CustomType
      prediction_type: Yup.string()
        .nullable()
        .when("family", {
          is: (val) => val === dialogComponentsEntityTypeFamily.CustomType,
          then: Yup.string().required("Required"),
        }),

      // File
      accept: Yup.array()
        .nullable()
        .optional()
        .when("family", {
          is: (val) => val === dialogComponentsEntityTypeFamily.File,
          then: Yup.array().of(Yup.string().required("Required")),
        }),
      max_size: Yup.number()
        .nullable()
        .when("family", {
          is: (val) => val === dialogComponentsEntityTypeFamily.File,
          then: Yup.number().required("Required"),
        }),
      max_files: Yup.number()
        .nullable()
        .when("family", {
          is: (val) => val === dialogComponentsEntityTypeFamily.File,
          then: Yup.number().required("Required"),
        }),

      // File + Select
      multiple: Yup.bool()
        .nullable()
        .when("family", {
          is: (val) => [dialogComponentsEntityTypeFamily.File, dialogComponentsEntityTypeFamily.Select].includes(val),
          then: Yup.bool().required("Required"),
        }),

      // Date + DateRange
      // range_begin: Yup.mixed()
      //   .nullable()
      //   .when("family", {
      //     is: (val) =>
      //       [dialogComponentsEntityTypeFamily.Date, dialogComponentsEntityTypeFamily.DateRange].includes(val),
      //     then: Yup.mixed().required("Required"),
      //   }),
      // range_end: Yup.mixed()
      //   .nullable()
      //   .when("family", {
      //     is: (val) =>
      //       [dialogComponentsEntityTypeFamily.Date, dialogComponentsEntityTypeFamily.DateRange].includes(val),
      //     then: Yup.mixed()
      //       .required("Required")
      //       .test("test", "End date must be greater than start date", function (value) {
      //         const { range_begin } = this.parent;
      //         return DateHelper.getDateTime(value).isAfter(DateHelper.getDateTime(range_begin));
      //       }),
      //   }),
      date_format_type: Yup.mixed() //"date" | "time" | "datetime"
        .nullable()
        .when("family", {
          is: (val) =>
            [dialogComponentsEntityTypeFamily.Date, dialogComponentsEntityTypeFamily.DateRange].includes(val),
          then: Yup.mixed().required("Required"),
        }),
      // date_format_visible: Yup.mixed() //python date format
      //   .nullable()
      //   .when("family", {
      //     is: (val) =>
      //       [dialogComponentsEntityTypeFamily.Date, dialogComponentsEntityTypeFamily.DateRange].includes(val),
      //     then: Yup.mixed().required("Required"),
      //   }),

      // DateRange
      final_range_begin: Yup.mixed() //python date format
        .nullable()
        .when("family", {
          is: (val) => [dialogComponentsEntityTypeFamily.DateRange].includes(val),
          then: Yup.mixed().required("Required"),
        }),
      final_range_end: Yup.mixed() //python date format
        .nullable()
        .when("family", {
          is: (val) => [dialogComponentsEntityTypeFamily.DateRange].includes(val),
          then: Yup.mixed()
            .required("Required")
            .test("test", "End date must be greater than start date", function (value) {
              const { final_range_begin } = this.parent;
              return DateHelper.getDateTime(value).isAfter(DateHelper.getDateTime(final_range_begin));
            }),
        }),

      // Range
      interval: Yup.array() //[1,10]
        .nullable()
        .when("family", {
          is: (val) => [dialogComponentsEntityTypeFamily.Range].includes(val),
          then: Yup.array()
            .min(2, "Required")
            .test("test", "Max value must be greater than min value", function (value) {
              return value?.[1] > value?.[0];
            }),
        }),
    }),
    onSubmit: async (values) => {
      const payload = { ...values };
      let errors = [];

      for (const surveyItem of payload.entities) {
        if (surveyItem.name === "") {
          errors.push("Name is required");
        }
        // if (surveyItem.dictionary.length < 1) {
        //   errors.push("Dictionary is required");
        // } else
        if (surveyItem.dictionary.some((item) => item?.length < 1)) {
          errors.push("All dictionary items must be at least 1 characters");
        }
      }
      if (errors.length) {
        DialogHelper.showValidate(errors);
        return;
      }
      //convert MB to bytes
      if (payload.max_size) {
        payload.max_size = payload.max_size * 1024 * 1024;
      }
      const fixedFields = ["id", "name", "family", "entities"];
      const acceptedFieldMap = {
        [dialogComponentsEntityTypeFamily.Date]: [
          "range_begin",
          "range_end",
          "date_format_type",
          // "date_format_visible",
        ],
        [dialogComponentsEntityTypeFamily.DateRange]: [
          "range_begin",
          "range_end",
          "date_format_type",
          // "date_format_visible",
          "final_range_begin",
          "final_range_end",
        ],
        [dialogComponentsEntityTypeFamily.File]: ["accept", "max_size", "max_files", "multiple"],
        [dialogComponentsEntityTypeFamily.Select]: ["multiple"],
        [dialogComponentsEntityTypeFamily.CustomType]: ["prediction_type"],
        [dialogComponentsEntityTypeFamily.Range]: ["interval"],
      };

      const acceptedFields = (acceptedFieldMap[payload.family] || []).concat(fixedFields);
      const filteredValues = Object.keys(payload)
        .filter((key) => acceptedFields.includes(key))
        .reduce((obj, key) => {
          obj[key] = payload[key];
          return obj;
        }, {});

      await dispatch(createOrUpdateEntityType(currentProject.id, currentBot.id, filteredValues));
      handleGoBack();
    },
  });

  useEffect(() => {
    if (!id) return;
    dispatch(getEntityType(currentProject.id, currentBot.id, id));
    return () => {
      dispatch(setEntityType({}));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, id]);

  const handleChangeSelect = (name) => (value) => {
    // if (name === "date_format_type") {
    //   let format;
    //   if (value === dialogComponentsEntityTypeDateFormatType.date) {
    //     format = dateFormat;
    //   } else if (value === dialogComponentsEntityTypeDateFormatType.time) {
    //     format = timeFormat;
    //   } else if (value === dialogComponentsEntityTypeDateFormatType.datetime) {
    //     format = dateTimeFormat;
    //   }
    //   formik.setFieldValue("date_format_visible", format);
    // }
    formik.setFieldValue(name, value);
  };

  const handleChangeSurvey = (curr) => (newObj) => {
    let newSurveyItems = [...formik.values.entities];
    newSurveyItems = newSurveyItems.map((item) => {
      if (item === curr) {
        return newObj;
      }
      return item;
    });

    formik.setFieldValue("entities", newSurveyItems);
  };

  const handleDeleteSurvey = (curr) => () => {
    let newSurveyItems = [...formik.values.entities];
    newSurveyItems = newSurveyItems.filter((item) => item !== curr);
    formik.setFieldValue("entities", newSurveyItems);
  };

  const handleOnSortEnd = ({ oldIndex, newIndex }) => {
    const newItems = Utils.arrayMove([...formik.values.entities], oldIndex, newIndex);

    formik.setFieldValue(
      "entities",
      newItems.map((item, index) => ({ ...item, order: index + 1 }))
    );
  };

  const handleAddEntity = () => {
    formik.setFieldValue("entities", [
      ...formik.values.entities,
      {
        name: "",
        company_value: "",
        dictionary: [],
      },
    ]);
  };

  const handleDeleteAcceptItem = (index) => () => {
    formik.setFieldValue(
      "accept",
      formik.values.accept.filter((item, i) => i !== index)
    );
  };

  const handleAddAcceptItem = () => {
    formik.setFieldValue("accept", [...formik.values.accept, ""]);
  };

  const handleChangeDate = (name) => (date) => {
    formik.setFieldValue(name, date);
  };

  const actions = useMemo(() => {
    const handleUpload = async () => {
      const result = await UploadModal?.show({ title: "Upload Entity Type", noRename: true });
      if (result?.length) {
        await dispatch(uploadEntityType(currentProject.id, currentBot.id, id, result[0].file));

        dispatch(getEntityType(currentProject.id, currentBot.id, id));
        UploadModal?.hide();
      }
    };

    const handleDownload = async () => {
      const blobResponse = await dispatch(downloadEntityType(currentProject.id, currentBot.id, id));
      const fileName = `entity_type_${Utils.slugify(formik.values?.name)}_${id}_${Utils.slugify(currentProject.name)}_${
        currentProject.id
      }`;

      FileHelper.saveAs(blobResponse, fileName);
    };
    return [
      {
        icon: MdUpload,
        color: "primary",
        tooltipText: "Upload Entity Type",
        onClick: handleUpload,
      },
      {
        icon: MdDownload,
        color: "primary",
        tooltipText: "Download Entity Type",
        onClick: handleDownload,
      },
    ];
  }, [dispatch, currentProject.id, currentProject.name, currentBot.id, id, formik.values?.name]);

  const parentName = [
    {
      label: "Entity Types",
      url: "/chatbot/entity-types/",
    },
    {
      label: `${!id ? "Create" : "Edit"} Entity Type`,
    },
  ];
  return (
    <PRContainer
      smalltalkSelector
      actions={actions}
      className="pr-entity-types-edit"
      description={"Here you can create or edit entity type"}
      name="Chatbot"
      parentName={parentName}
    >
      <PRPage>
        <Row className="mt-2 g-2">
          <Col md="4">
            <Label className="mb-2" size="md">
              Name
            </Label>
          </Col>
          <Col md="8">
            <PRInput
              invalid={formik.touched.name && formik.errors.name}
              name="name"
              type="text"
              value={formik.values.name}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
            />
          </Col>

          <Col md="4">
            <Label size="md"> Family </Label>
          </Col>
          <Col md="8">
            <PRSelect
              isPrimitiveValue
              invalid={formik.touched.family && formik.errors.family}
              isClearable={false}
              name={`family`}
              options={dialogComponentsEntityTypeFamilyOptions}
              placeholder="Select question Type"
              value={formik.values.family}
              onChange={handleChangeSelect("family")}
            />
          </Col>
          {formik.values.family === dialogComponentsEntityTypeFamily.CustomType && (
            <>
              <Col md="4">
                <Label size="md"> Prediction Type </Label>
              </Col>
              <Col md="8">
                <PRSelect
                  isPrimitiveValue
                  invalid={formik.touched.prediction_type && formik.errors.prediction_type}
                  isClearable={false}
                  name={`prediction_type`}
                  options={dialogComponentsEntityTypePredictionTypeOptions}
                  placeholder="Select Prediction Type"
                  value={formik.values.prediction_type}
                  onChange={handleChangeSelect("prediction_type")}
                />
              </Col>
            </>
          )}

          {[dialogComponentsEntityTypeFamily.Date, dialogComponentsEntityTypeFamily.DateRange].includes(
            formik.values.family
          ) && (
            <>
              <Col md="4">
                <Label size="md">
                  Date Range
                  {dialogComponentsEntityTypeFamily.DateRange === formik.values.family ? " Start" : ""}
                </Label>
              </Col>
              <Col md="8">
                <Row className="gx-2 gy-1">
                  <Col lg="6" xs="12">
                    <PRDate
                      invalid={formik.touched.range_begin && formik.errors.range_begin}
                      name="range_begin"
                      placeholder={"Range Begin"}
                      value={formik.values.range_begin}
                      onChange={handleChangeDate("range_begin")}
                    />
                  </Col>
                  <Col lg="6" xs="12">
                    <PRDate
                      invalid={formik.touched.range_end && formik.errors.range_end}
                      name="range_end"
                      placeholder={"Range End"}
                      value={formik.values.range_end}
                      onChange={handleChangeDate("range_end")}
                    />
                  </Col>
                </Row>
              </Col>
            </>
          )}

          {[dialogComponentsEntityTypeFamily.DateRange].includes(formik.values.family) && (
            <>
              <Col md="4">
                <Label size="md"> Date Range End</Label>
              </Col>
              <Col md="8">
                <Row className="gx-2 gy-1">
                  <Col lg="6" xs="12">
                    <PRDate
                      invalid={formik.touched.final_range_begin && formik.errors.final_range_begin}
                      name="final_range_begin"
                      placeholder={"Range Begin"}
                      value={formik.values.final_range_begin}
                      onChange={handleChangeDate("final_range_begin")}
                    />
                  </Col>
                  <Col lg="6" xs="12">
                    <PRDate
                      invalid={formik.touched.final_range_end && formik.errors.final_range_end}
                      name="final_range_end"
                      placeholder={"Range End"}
                      value={formik.values.final_range_end}
                      onChange={handleChangeDate("final_range_end")}
                    />
                  </Col>
                </Row>
              </Col>
            </>
          )}
          {[dialogComponentsEntityTypeFamily.Date, dialogComponentsEntityTypeFamily.DateRange].includes(
            formik.values.family
          ) && (
            <>
              <Col md="4">
                <Label size="md">Date Type</Label>
              </Col>
              <Col md="8">
                <PRSelect
                  isPrimitiveValue
                  invalid={formik.touched.date_format_type && formik.errors.date_format_type}
                  isClearable={false}
                  name={`date_format_type`}
                  options={dialogComponentsEntityTypeDateFormatTypeOptions}
                  placeholder="Select Date Format"
                  value={formik.values.date_format_type}
                  onChange={handleChangeSelect("date_format_type")}
                />
              </Col>
              {/* 
              <Col md="4">
                <Label size="md">Date Format</Label>
              </Col>
              <Col md="8">
                <PRInput
                  invalid={formik.touched.date_format_visible && formik.errors.date_format_visible}
                  name="date_format_visible"
                  placeholder="Enter Date Format"
                  value={formik.values.date_format_visible}
                  onChange={formik.handleChange}
                />
              </Col> */}
            </>
          )}

          {[dialogComponentsEntityTypeFamily.Select, dialogComponentsEntityTypeFamily.File].includes(
            formik.values.family
          ) && (
            <>
              <Col md="4">
                <Label size="md">Multiple</Label>
              </Col>
              <Col className="d-flex align-items-center" md="8">
                <Input
                  checked={formik.values.multiple}
                  name="multiple"
                  type="checkbox"
                  onChange={formik.handleChange}
                />
              </Col>
            </>
          )}

          {[dialogComponentsEntityTypeFamily.Range].includes(formik.values.family) && (
            <>
              <Col md="4">
                <Label size="md"> Range</Label>
              </Col>
              <Col md="8">
                <Row className="gx-2 gy-1">
                  <Col lg="6" xs="12">
                    <PRInput
                      invalid={formik.touched.interval && (formik.errors.interval || formik.errors.interval?.[0])}
                      name="interval[0]"
                      placeholder="Min"
                      type="number"
                      value={formik.values.interval && formik.values.interval[0]}
                      onChange={formik.handleChange}
                    />
                  </Col>
                  <Col lg="6" xs="12">
                    <PRInput
                      invalid={formik.touched.interval && (formik.errors.interval || formik.errors.interval?.[1])}
                      name="interval[1]"
                      placeholder="Max"
                      type="number"
                      value={formik.values.interval && formik.values.interval[1]}
                      onChange={formik.handleChange}
                    />
                  </Col>
                </Row>
              </Col>
            </>
          )}
          {formik.values.family === dialogComponentsEntityTypeFamily.File && (
            <>
              <Col md="4">
                <Label size="md">Max Files</Label>
              </Col>
              <Col md="8">
                <PRInput
                  invalid={formik.touched.max_files && formik.errors.max_files}
                  max={10}
                  min={1}
                  name="max_files"
                  type="number"
                  value={formik.values.max_files}
                  onChange={formik.handleChange}
                />
              </Col>

              <Col md="4">
                <Label size="md">Max Size(MB)</Label>
              </Col>
              <Col md="8">
                <PRInput
                  invalid={formik.touched.max_size && formik.errors.max_size}
                  max={100}
                  min={0}
                  name="max_size"
                  type="number"
                  value={formik.values.max_size}
                  onChange={formik.handleChange}
                />
              </Col>
              <Col md="4">
                <Label size="md"> Accept </Label>
              </Col>
              <Col md="8">
                <Col lg={"12"} md="12">
                  <div className="entity-item">
                    <Row className="gx-2 gy-1">
                      {(formik.values.accept || [])?.map((choice, index) => (
                        <Row key={index} className="gx-2 gy-1">
                          <Col key={index} xs>
                            <PRInput
                              invalid={formik.touched.accept?.[index] && formik.errors.accept?.[index]}
                              name={`accept[${index}]`}
                              placeholder="image/*"
                              size="sm"
                              value={choice}
                              onChange={formik.handleChange}
                            />
                          </Col>
                          <Col xs="auto">
                            <PRButton
                              outline
                              color="danger"
                              icon={MdDelete}
                              size="sm"
                              onClick={handleDeleteAcceptItem(index)}
                            />
                          </Col>
                        </Row>
                      ))}
                      {formik.values.accept?.length === 0 && (
                        <div className="mt-2 text-center text-muted">Empty MIME Type</div>
                      )}
                      <Col className="d-flex justify-content-end" xs="12">
                        <PRButton
                          className="me-2"
                          color="success"
                          icon={MdAdd}
                          size="sm"
                          onClick={handleAddAcceptItem}
                        />
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Col>
            </>
          )}

          <Col md="4">
            <Label className="mb-2" size="md">
              Entities ({formik.values.entities?.length})
            </Label>
          </Col>
          <Col md="8">
            <SortableEntityItemList
              useDragHandle
              errors={formik.errors.entities}
              handleChangeSurvey={handleChangeSurvey}
              handleDeleteSurvey={handleDeleteSurvey}
              helperClass="sortable-helper"
              items={formik.values.entities || []}
              touches={formik.touched.entities}
              onSortEnd={handleOnSortEnd}
            />
            <Input className="d-none" invalid={true} />
            {typeof formik.errors.entities === "string" && (
              <FormFeedback type="invalid">{formik.errors.entities}</FormFeedback>
            )}

            <Row className="justify-content-end ">
              <Col md="auto">
                <PRButton className="ms-2" color="success" icon={MdAdd} size="sm" onClick={handleAddEntity}>
                  Add Entity
                </PRButton>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="justify-content-end mt-2">
          <Col md="auto">
            <PRButton outline link={goBackUrl}>
              Cancel
            </PRButton>
            <PRButton className="ms-2" onClick={formik.handleSubmit}>
              Save
            </PRButton>
          </Col>
        </Row>
      </PRPage>
    </PRContainer>
  );
}
