import { Fragment, useEffect, useMemo, useState } from "react";

import { useFormik } from "formik";
import { MdAdd, MdDelete, MdExpandMore } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import {
  Col,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  FormFeedback,
  Input,
  Label,
  Row,
  Table,
} from "reactstrap";
import * as Yup from "yup";

import CodeEditor from "~components/CodeEditor";
import PRButton from "~components/Generic/PRButton";
import PRDivider from "~components/Generic/PRDivider";
import PRDragHandle from "~components/Generic/PRDragHandle";
import PRInput, { PRTextArea } from "~components/Generic/PRInput";
import PRSelect from "~components/Generic/PRSelect";
import Utils from "~helpers/Utils";
import { getEntityTypeList } from "~store/dialogComponents/entityTypes/actions";
import { getTicketCategories, setScenarioFormState } from "~store/dialogComponents/scenarioManager/actions";
import { selectScenarioManagerForms } from "~store/dialogComponents/scenarioManager/selectors";
import { selectCurrentBot, selectCurrentProject } from "~store/user/selectors";

import useMonacoScenarioEditorVariables from "../../useMonacoScenarioEditorVariables";

const formItemActionTypes = {
  saveTicket: "Save Ticket",
  sendEmail: "Send Email",
  saveRecord: "Save Record",
  lowCode: "Low Code",
};

const SortableFormQuestionItem = SortableElement(FormQuestionItem);
const SortableFormItemList = SortableContainer(
  ({ items, handleChangeFormQuestionItem, handleDeleteFormQuestionItem }) => {
    return (
      <tbody>
        {items.map((formItem, index, arr) => {
          return (
            <Fragment key={index}>
              <SortableFormQuestionItem
                formItem={formItem}
                index={index}
                order={index}
                onChange={handleChangeFormQuestionItem(index)}
                onDelete={handleDeleteFormQuestionItem(index)}
              />
              <tr>
                <td className="py-0" colSpan={6}>
                  {arr.length - 1 !== index && (
                    <Col xs={12}>
                      <PRDivider color="secondary-400" />
                    </Col>
                  )}
                </td>
              </tr>
            </Fragment>
          );
        })}
      </tbody>
    );
  }
);
function FormItem({ form, onChange, onDelete, order }) {
  const sortedFormItems = useMemo(() => {
    return [...(form?.form_items || [])]?.sort((a, b) => a?.order - b?.order);
  }, [form?.form_items]);

  const handleChangeValidationText = (e) => {
    const value = e.target.value;
    onChange({ ...form, validation: value });
  };

  const handleChangeFormQuestionItem = (itemIndex) => (newItem) => {
    onChange({
      ...form,
      form_items: sortedFormItems.map((item, index) => (index === itemIndex ? newItem : item)),
    });
  };
  const handleDeleteFormQuestionItem = (itemIndex) => () => {
    onChange({
      ...form,
      form_items: sortedFormItems.filter((item, index) => index !== itemIndex),
    });
  };
  const handleClickAddNewFormQuestionItem = () => {
    const highestMessageButtonOrder = [...(sortedFormItems || [])]?.reduce((highest, item) => {
      return item?.order > highest ? item?.order : highest;
    }, 0);
    onChange({
      ...form,
      form_items: [
        ...sortedFormItems,
        { key: "", question: "", entity_type: {}, order: highestMessageButtonOrder + 1 },
      ],
    });
  };

  const handleChangeFormActionItem = (itemIndex) => (newItem) => {
    onChange({
      ...form,
      actions: form.actions.map((item, index) => (index === itemIndex ? newItem : item)),
    });
  };
  const handleDeleteFormActionItem = (itemIndex) => () => {
    onChange({
      ...form,
      actions: form.actions.filter((item, index) => index !== itemIndex),
    });
  };
  const handleClickAddNewFormActionItem = () => {
    onChange({
      ...form,
      actions: [
        ...form.actions,
        {
          action_type: formItemActionTypes.saveRecord,
          email_format: "",
          email_title: "",
          email_to_sent: "",
          ticket_body: "",
          ticket_category: null,
        },
      ],
    });
  };

  const handleOnSortEnd = ({ oldIndex, newIndex }) => {
    const newFormItems = Utils.arrayMove(sortedFormItems, oldIndex, newIndex);

    onChange({
      ...form,
      form_items: newFormItems.map((item, index) => ({ ...item, order: index + 1 })),
    });
  };
  return (
    <Col xs={12}>
      <div className="form-item-wrapper">
        <Row className="align-items-center g-2 mb-2">
          <Col xs="auto">
            <Label className="m-0" htmlFor={`form-validation-${order}`}>
              Form initialization prompt (Optional):
            </Label>
          </Col>
          <Col xs>
            <Input
              id={`form-validation-${order}`}
              name="question"
              placeholder="Enter the prompt question"
              type="text"
              value={form.validation}
              onChange={handleChangeValidationText}
            />
          </Col>
          <Col xs="auto">
            <PRButton outline color="danger" icon={MdDelete} onClick={onDelete} />
          </Col>
        </Row>

        <div className="form-item-items mb-2">
          <Row className="align-items-center">
            <Col xs>
              <Label className="m-0">Form Items ({sortedFormItems?.length || 0}):</Label>
            </Col>
            <Col xs={"auto"}>
              <div className="input-group">
                <PRButton icon={MdAdd} onClick={handleClickAddNewFormQuestionItem}>
                  Add Form Item
                </PRButton>
              </div>
            </Col>
          </Row>
          <Row>
            <Col className="overflow-auto" xs={12}>
              {sortedFormItems?.length ? (
                <Table borderless className="table-no-spacing mb-0 forms-table " size="sm">
                  <thead>
                    <tr>
                      <th className="border-top-0"></th>
                      <th className="border-top-0">Entity Type</th>
                      <th className="border-top-0">Question</th>
                      <th className="border-top-0">Name</th>
                      <th className="border-top-0">Key</th>
                      <th className="border-top-0"></th>
                    </tr>
                  </thead>
                  <SortableFormItemList
                    useDragHandle
                    handleChangeFormQuestionItem={handleChangeFormQuestionItem}
                    handleDeleteFormQuestionItem={handleDeleteFormQuestionItem}
                    helperClass="pr-scenario-manager"
                    items={sortedFormItems}
                    onSortEnd={handleOnSortEnd}
                  />
                </Table>
              ) : (
                <div className="text-center"> - No form items - </div>
              )}
            </Col>
          </Row>
        </div>

        <div className="form-item-actions">
          <Row className="align-items-center">
            <Col xs>
              <Label className="m-0" htmlFor="intent-question-sample">
                Form Actions ({form.actions?.length || 0}):
              </Label>
            </Col>
            <Col xs={"auto"}>
              <div className="input-group">
                <PRButton icon={MdAdd} onClick={handleClickAddNewFormActionItem}>
                  Add Form Action
                </PRButton>
              </div>
            </Col>
          </Row>
          <Row className="pt-2 gap-2">
            {!form?.actions?.length && (
              <Col xs={12}>
                <div className="text-center"> - No form actions - </div>
              </Col>
            )}

            {form.actions?.map((action, index, arr) => {
              return (
                <Fragment key={index}>
                  <FormActionItem
                    action={action}
                    order={index}
                    onChange={handleChangeFormActionItem(index)}
                    onDelete={handleDeleteFormActionItem(index)}
                  />
                  {arr?.length - 1 !== index && (
                    <Col xs={12}>
                      <PRDivider color="secondary-400" size="sm" />
                    </Col>
                  )}
                </Fragment>
              );
            })}
          </Row>
        </div>
      </div>
    </Col>
  );
}

function FormQuestionItem({ formItem, onChange, onDelete, order }) {
  const dispatch = useDispatch();
  const currentProject = useSelector(selectCurrentProject);
  const currentBot = useSelector(selectCurrentBot);
  const formik = useFormik({
    enableReinitialize: true,
    validateOnMount: true,
    initialValues: {
      key: formItem.key || "",
      question: formItem.question || "",
      entityType: formItem.entity_type,
      display_key: formItem.display_key || "",
    },
    validationSchema: Yup.object().shape({
      key: Yup.string()
        .required("Key is required")
        .min(2, "Key must be at least 2 characters")
        .test("valid_variable", "Key must be a valid variable", function (val) {
          const isValid = Utils.isValidPythonVariableName(val);
          if (isValid.valid) return true;
          return this.createError({ message: isValid.reason });
        }),
      display_key: Yup.string()
        .required("Name is required")
        .test("len2", "Name must be at least 2 characters", (val) => {
          return val?.length >= 2;
        }),
      question: Yup.string()
        .required("Question is required")
        .test("len23", "Question must be at least 2 characters", (val) => {
          return val?.length >= 2;
        }),
      entityType: Yup.object().shape({
        id: Yup.string().required("Entity Type is required"),
      }),
    }),
  });

  const handleChange = (key) => (e) => {
    formik.handleChange(e);
    onChange({ ...formItem, [key]: e.target.value });
  };
  const handleSearch = async (searchText, callback, signal) => {
    const response = await dispatch(
      getEntityTypeList(currentProject.id, currentBot.id, {
        params: {
          limit: 20,
          name__icontains: searchText,
        },
        signal,
      })
    );
    return response?.results || [];
  };
  const handleChangeFormQuestionEntity = (entityType) => {
    onChange({ ...formItem, entity_type: { id: entityType.id, name: entityType.name } });
    formik.setFieldValue("entityType", entityType);
  };
  return (
    <tr className="draggable-form-item">
      <td className="form-item-drag-handle">
        <PRDragHandle absolute={false} />
      </td>
      <td>
        <PRSelect
          fullWidth
          lazy
          menuPortal
          id="entityType"
          invalid={!!formik.touched.entityType && formik.errors.entityType?.id}
          isClearable={false}
          labelSelector="name"
          loadOptions={handleSearch}
          name="entityType"
          placeholder="Select a entity type"
          value={formik.values.entityType}
          valueSelector="id"
          onBlur={formik.handleBlur}
          onChange={handleChangeFormQuestionEntity}
        />
      </td>
      <td>
        <div className="w-100">
          <Input
            className="form-control"
            invalid={!!formik.touched.question && !!formik.errors.question}
            name="question"
            placeholder="Enter your question"
            type="text"
            value={formItem.question}
            onBlur={formik.handleBlur}
            onChange={handleChange("question")}
          />
          {formik.touched.question && formik.errors.question && (
            <FormFeedback type="invalid">{formik.errors.question}</FormFeedback>
          )}
        </div>
      </td>
      <td>
        <div className="w-100">
          <Input
            className="form-control"
            invalid={!!formik.touched.display_key && !!formik.errors.display_key}
            name="display_key"
            placeholder="Enter name"
            type="text"
            value={formItem.display_key}
            onBlur={formik.handleBlur}
            onChange={handleChange("display_key")}
          />
          {formik.touched.display_key && formik.errors.display_key && (
            <FormFeedback type="invalid">{formik.errors.display_key}</FormFeedback>
          )}
        </div>
      </td>
      <td>
        <div className="w-100">
          <Input
            className="form-control"
            invalid={!!formik.touched.key && !!formik.errors.key}
            name="key"
            placeholder="Enter your key"
            type="text"
            value={formItem.key}
            onBlur={formik.handleBlur}
            onChange={handleChange("key")}
          />
          {formik.touched.key && formik.errors.key && <FormFeedback type="invalid">{formik.errors.key}</FormFeedback>}
        </div>
      </td>
      <td>
        <PRButton outline color="danger" icon={MdDelete} onClick={onDelete} />
      </td>
    </tr>
  );
}

function FormActionItem({ action, onChange, onDelete, order }) {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [ticketCategories, setTicketCategories] = useState([]);
  const [codeEditor, setCodeEditor] = useState(null);
  const dispatch = useDispatch();
  const currentProject = useSelector(selectCurrentProject);
  const formik = useFormik({
    enableReinitialize: true,
    validateOnMount: true,

    initialValues: {
      ticket_body: action.ticket_body,
      ticket_category: action.ticket_category,
      email_title: action.email_title,
      email_format: action.email_format,
      email_to_sent: action.email_to_sent,
      low_code_script: action.low_code_script,
    },
    validationSchema: Yup.object().shape({
      ...(action.action_type === formItemActionTypes.sendEmail
        ? {
            email_title: Yup.string()
              .required("Key is required")
              .test("len2", "Key must be at least 2 characters", (val) => {
                return val?.length >= 2;
              }),
            email_format: Yup.string()
              .required("Question is required")
              .test("len23", "Question must be at least 2 characters", (val) => {
                return val?.length >= 2;
              }),

            email_to_sent: Yup.string()
              .required("Entity Type is required")
              .test("validEmail", "Invalid email", (val) => {
                return Utils.validateEmail(val);
              }),
          }
        : {}),
      ...(action.action_type === formItemActionTypes.saveTicket
        ? {
            ticket_body: Yup.string()
              .required("Ticket body is required")
              .test("len", "Ticket body must be at least 2 characters", (val) => {
                return val?.length >= 2;
              }),
            ticket_category: Yup.object().shape({
              id: Yup.string().required("Ticket category is required"),
            }),
          }
        : {}),
      ...(action.action_type === formItemActionTypes.lowCode
        ? {
            low_code_script: Yup.string().required("Low code is required"),
          }
        : {}),
    }),
  });

  useEffect(() => {
    if (action.action_type === formItemActionTypes.saveTicket && !ticketCategories?.length) {
      dispatch(getTicketCategories(currentProject.id)).then((res) => {
        setTicketCategories(res.results);
      });
    }
  }, [action.action_type, ticketCategories, currentProject.id, dispatch]);

  const toggleDropdown = () => setIsDropdownOpen(!isDropdownOpen);

  const handleChangeType = (type) => () => {
    onChange({ ...action, action_type: type });
  };
  const handleChangeInput = (key) => (e) => {
    const value = e.target?.value;
    formik.setFieldValue(key, value);
    onChange({ ...action, [key]: value });
  };
  const handleChangeSelect = (key) => (value) => {
    formik.setFieldValue(key, value);
    onChange({ ...action, [key]: value });
  };

  const handleChangeCodeEditor = (key) => (value) => {
    formik.setFieldValue(key, value);
    onChange({ ...action, [key]: value });
  };

  useMonacoScenarioEditorVariables(codeEditor?.editor);
  return (
    <div>
      <Col xs="12">
        <Row className="">
          <Col xs>
            <Dropdown isOpen={isDropdownOpen} toggle={toggleDropdown}>
              <DropdownToggle caret color="primary">
                <span className="text-capitalize">{action.action_type}</span>
                <MdExpandMore />
              </DropdownToggle>
              <DropdownMenu>
                {Object.values(formItemActionTypes).map((type) => (
                  <DropdownItem key={type} onClick={handleChangeType(type)}>
                    {type}
                  </DropdownItem>
                ))}
              </DropdownMenu>
            </Dropdown>
          </Col>
          <Col xs="auto">
            <PRButton outline color="danger" icon={MdDelete} onClick={onDelete} />
          </Col>
        </Row>
      </Col>
      {action.action_type === formItemActionTypes.saveTicket && (
        <Col className="mt-2" xs={12}>
          <Label>Category</Label>
          <PRSelect
            isPrimitiveValue
            className="mb-2"
            isClearable={false}
            labelSelector="name"
            options={ticketCategories}
            placeholder="Select a ticket category"
            value={action.ticket_category}
            valueSelector="id"
            onChange={handleChangeSelect("ticket_category")}
          />
          <Label>Body</Label>
          <PRTextArea
            editorMode
            editorProps={{
              onMount: setCodeEditor,
            }}
            invalid={formik.touched.ticket_body && formik.errors.ticket_body}
            name="ticket_body"
            placeholder="Enter your ticket"
            rows={3}
            value={action.ticket_body}
            onBlur={formik.handleBlur}
            onChange={handleChangeInput("ticket_body")}
          />
        </Col>
      )}
      {action.action_type === formItemActionTypes.sendEmail && (
        <Col className="mt-2" xs={12}>
          <Row className="g-2">
            <Col xs={6}>
              <Label>Title</Label>
              <PRInput
                invalid={!!formik.touched.email_title && !!formik.errors.email_title}
                name="email_title"
                placeholder="Enter your email title"
                type="text"
                value={action.email_title}
                onBlur={formik.handleBlur}
                onChange={handleChangeInput("email_title")}
              />
            </Col>
            <Col xs={6}>
              <Label>Email</Label>
              <PRInput
                invalid={!!formik.touched.email_to_sent && !!formik.errors.email_to_sent}
                name="email_to_sent"
                placeholder="Enter your email to sent"
                type="text"
                value={action.email_to_sent}
                onBlur={formik.handleBlur}
                onChange={handleChangeInput("email_to_sent")}
              />
            </Col>
            <Col xs={12}>
              <Label>Body</Label>
              <PRTextArea
                editorMode
                editorProps={{
                  onMount: setCodeEditor,
                }}
                invalid={!!formik.touched.email_format && !!formik.errors.email_format}
                name="email_format"
                placeholder="Enter your email body"
                rows={3}
                value={action.email_format}
                onBlur={formik.handleBlur}
                onChange={handleChangeInput("email_format")}
              />
              {formik.touched.email_format && formik.errors.email_format && (
                <FormFeedback type="invalid">{formik.errors.email_format}</FormFeedback>
              )}
            </Col>
          </Row>
        </Col>
      )}
      {action.action_type === formItemActionTypes.lowCode && (
        <div className="mt-2">
          <CodeEditor value={formik.values.low_code_script} onChange={handleChangeCodeEditor("low_code_script")} />
        </div>
      )}
    </div>
  );
}

export default function Forms() {
  const forms = useSelector(selectScenarioManagerForms);

  const dispatch = useDispatch();
  const handleChangeFormItem = (itemIndex) => (formItem) => {
    dispatch(setScenarioFormState("forms", [...forms.map((item, index) => (index === itemIndex ? formItem : item))]));
  };
  const handleClickAddNewForm = () => {
    dispatch(setScenarioFormState("forms", [...forms, { validation: "", form_items: [], actions: [] }]));
  };
  const handleClickDeleteFormItem = (itemIndex) => () => {
    dispatch(setScenarioFormState("forms", [...forms.filter((item, index) => index !== itemIndex)]));
  };

  return (
    <Row className="align-items-center">
      <Col xs>
        <Label className="m-0">Forms</Label>
      </Col>
      <Col className="mb-2" xs={"auto"}>
        <div className="input-group">
          <PRButton icon={MdAdd} onClick={handleClickAddNewForm}>
            Add Form
          </PRButton>
        </div>
      </Col>

      {!forms?.length && (
        <Col xs={12}>
          <p className="text-muted text-center"> - No Forms - </p>
        </Col>
      )}

      <Col xs={12}>
        <Row className="gap-2">
          {forms.map((form, index) => {
            return (
              <FormItem
                key={index}
                form={form}
                order={index}
                onChange={handleChangeFormItem(index)}
                onDelete={handleClickDeleteFormItem(index)}
              />
            );
          })}
        </Row>
      </Col>
    </Row>
  );
}
