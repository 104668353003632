import { useEffect, useState } from "react";

import { MdHistory, MdLiveHelp, MdPeople } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import styled from "@emotion/styled";
import { FormControlLabel, Grid, Switch, Tab, Tabs } from "@mui/material";

import useLoading from "~common/hooks/useLoading";
import PRChart from "~components/Generic/PRChart";
import chartTheme from "~components/Generic/PRChart/theme.json";
import PalContainer from "~components/mui/PalContainer";
import PalPage from "~components/mui/PalPage";
import AggregateCard from "~components/Statistics/AggregateCard";
import ExcelButton from "~components/Statistics/ExcelButton";
import FilterCard from "~components/Statistics/FilterCard";
import AlertHelper from "~helpers/AlertHelper";
import FileHelper from "~helpers/FileHelper";
import { getSessionCountPieChartStatistics, getSessionCountSankeyStatistics } from "~store/statistics/actions";
import { selectStatistics, selectStatisticsFilter } from "~store/statistics/selectors";

const sankeyDataMap = {
  app: "App",
  desktop: "Desktop",
  mobile: "Mobile",
  bot: "Bot Only",
  agent: "Agent Joined",
  ticket: "Ticket Opened",
  successful: "Graded Successful",
  unsuccessful: "Graded Unsuccessful",
  notGraded: "Not Graded",
};
const SessionCountSankeyStatistics = ({ hideBlanks }) => {
  const { projectId } = useParams();
  const [loading, q] = useLoading();
  const dispatch = useDispatch();
  const filter = useSelector(selectStatisticsFilter);
  const statistics = useSelector(selectStatistics);
  useEffect(() => {
    q(dispatch(getSessionCountSankeyStatistics(projectId, filter.beginDate, filter.endDate)));
  }, [dispatch, projectId, q, filter.beginDate, filter.endDate]);

  const chartStyle = {
    height: "50vh",
  };

  const links = [
    {
      source: sankeyDataMap.app,
      target: sankeyDataMap.ticket,
      value: statistics?.SESSION_COUNT_SANKEY_APP_TICKET,
    },
    {
      source: sankeyDataMap.app,
      target: sankeyDataMap.agent,
      value: statistics?.SESSION_COUNT_SANKEY_APP_AGENT,
    },
    {
      source: sankeyDataMap.app,
      target: sankeyDataMap.bot,
      value: statistics?.SESSION_COUNT_SANKEY_APP_BOT,
    },
    {
      source: sankeyDataMap.desktop,
      target: sankeyDataMap.ticket,
      value: statistics?.SESSION_COUNT_SANKEY_DESKTOP_TICKET,
    },
    {
      source: sankeyDataMap.desktop,
      target: sankeyDataMap.agent,
      value: statistics?.SESSION_COUNT_SANKEY_DESKTOP_AGENT,
    },
    {
      source: sankeyDataMap.desktop,
      target: sankeyDataMap.bot,
      value: statistics?.SESSION_COUNT_SANKEY_DESKTOP_BOT,
    },
    {
      source: sankeyDataMap.mobile,
      target: sankeyDataMap.ticket,
      value: statistics?.SESSION_COUNT_SANKEY_MOBILE_TICKET,
    },
    {
      source: sankeyDataMap.mobile,
      target: sankeyDataMap.agent,
      value: statistics?.SESSION_COUNT_SANKEY_MOBILE_AGENT,
    },
    {
      source: sankeyDataMap.mobile,
      target: sankeyDataMap.bot,
      value: statistics?.SESSION_COUNT_SANKEY_MOBILE_BOT,
    },
    {
      source: sankeyDataMap.agent,
      target: sankeyDataMap.successful,
      value:
        (statistics?.SESSION_COUNT_SANKEY_APP_AGENT_SUCCESSFUL || 0) +
        (statistics?.SESSION_COUNT_SANKEY_DESKTOP_AGENT_SUCCESSFUL || 0) +
        (statistics?.SESSION_COUNT_SANKEY_MOBILE_AGENT_SUCCESSFUL || 0),
    },
    {
      source: sankeyDataMap.agent,
      target: sankeyDataMap.unsuccessful,
      value:
        (statistics?.SESSION_COUNT_SANKEY_APP_AGENT_UNSUCCESSFUL || 0) +
        (statistics?.SESSION_COUNT_SANKEY_DESKTOP_AGENT_UNSUCCESSFUL || 0) +
        (statistics?.SESSION_COUNT_SANKEY_MOBILE_AGENT_UNSUCCESSFUL || 0),
    },
    {
      source: sankeyDataMap.agent,
      target: sankeyDataMap.notGraded,
      value:
        (statistics?.SESSION_COUNT_SANKEY_APP_AGENT_NOT_GRADED || 0) +
        (statistics?.SESSION_COUNT_SANKEY_DESKTOP_AGENT_NOT_GRADED || 0) +
        (statistics?.SESSION_COUNT_SANKEY_MOBILE_AGENT_NOT_GRADED || 0),
    },
    {
      source: sankeyDataMap.bot,
      target: sankeyDataMap.successful,
      value:
        (statistics?.SESSION_COUNT_SANKEY_APP_BOT_SUCCESSFUL || 0) +
        (statistics?.SESSION_COUNT_SANKEY_DESKTOP_BOT_SUCCESSFUL || 0) +
        (statistics?.SESSION_COUNT_SANKEY_MOBILE_BOT_SUCCESSFUL || 0),
    },
    {
      source: sankeyDataMap.bot,
      target: sankeyDataMap.unsuccessful,
      value:
        (statistics?.SESSION_COUNT_SANKEY_APP_BOT_UNSUCCESSFUL || 0) +
        (statistics?.SESSION_COUNT_SANKEY_DESKTOP_BOT_UNSUCCESSFUL || 0) +
        (statistics?.SESSION_COUNT_SANKEY_MOBILE_BOT_UNSUCCESSFUL || 0),
    },
    {
      source: sankeyDataMap.bot,
      target: sankeyDataMap.notGraded,
      value:
        (statistics?.SESSION_COUNT_SANKEY_APP_BOT_NOT_GRADED || 0) +
        (statistics?.SESSION_COUNT_SANKEY_DESKTOP_BOT_NOT_GRADED || 0) +
        (statistics?.SESSION_COUNT_SANKEY_MOBILE_BOT_NOT_GRADED || 0),
    },
  ];

  const data = [
    {
      name: sankeyDataMap.app,
    },
    {
      name: sankeyDataMap.desktop,
    },
    {
      name: sankeyDataMap.mobile,
    },
    {
      name: sankeyDataMap.bot,
      itemStyle: {
        color: chartTheme.theme.color[0],
      },
    },
    {
      name: sankeyDataMap.agent,
      itemStyle: {
        color: chartTheme.theme.color[6],
      },
    },
    {
      name: sankeyDataMap.ticket,
      itemStyle: {
        color: chartTheme.theme.color[2],
      },
    },
    {
      name: sankeyDataMap.successful,
      itemStyle: {
        color: chartTheme.theme.color[5],
      },
    },
    {
      name: sankeyDataMap.unsuccessful,
      itemStyle: {
        color: chartTheme.theme.color[3],
      },
    },
    {
      name: sankeyDataMap.notGraded,
    },
  ];

  const filteredLinks = hideBlanks ? links.filter((link) => link.value > 0) : links;
  const filteredData = hideBlanks
    ? data.filter((item) => filteredLinks.some((link) => link.source === item.name || link.target === item.name))
    : data;
  const options = {
    tooltip: {
      trigger: "item",
      formatter: function (params) {
        if (params.dataType === "node") {
          return `${params.name} (${params.value})`;
        } else {
          return `${params.data.source} (${params.data.value}) → ${params.data.target}`;
        }
      },
    },
    label: {
      show: true,
      position: "inside",
      formatter: "{b} ({c})",
    },
    series: {
      type: "sankey",
      layout: "none",
      draggable: false,
      emphasis: {
        focus: "adjacency",
      },
      data: filteredData,
      links: filteredLinks,
    },
  };
  return <PRChart loading={loading} option={options} style={chartStyle} title="Session Count Sankey" />;
};

const SessionCountPieChartStatistics = ({ hideBlanks }) => {
  const { projectId } = useParams();
  const [loading, q] = useLoading();
  const dispatch = useDispatch();
  const filter = useSelector(selectStatisticsFilter);
  const statistics = useSelector(selectStatistics);
  useEffect(() => {
    q(dispatch(getSessionCountPieChartStatistics(projectId, filter.beginDate, filter.endDate)));
  }, [dispatch, projectId, q, filter.beginDate, filter.endDate]);

  const chartStyle = {
    height: "70vh",
  };

  const filteredData = [
    { value: statistics?.SESSION_COUNT_APP, name: "App" },
    { value: statistics?.SESSION_COUNT_MOBILE, name: "Mobile" },
    { value: statistics?.SESSION_COUNT_DESKTOP, name: "Desktop" },
  ].filter((item) => (hideBlanks ? !!item.value : true));

  const filteredData2 = [
    { value: statistics?.SESSION_COUNT_APP_WHATSAPP, name: "App Whatsapp" },
    { value: statistics?.SESSION_COUNT_APP_TELEGRAM, name: "App Telegram" },
    { value: statistics?.SESSION_COUNT_APP_TEAMS, name: "App Teams" },
    { value: statistics?.SESSION_COUNT_MOBILE_ANDROID, name: "Mobile Android" },
    { value: statistics?.SESSION_COUNT_MOBILE_IOS, name: "Mobile IOS" },
    { value: statistics?.SESSION_COUNT_MOBILE_OTHER, name: "Mobile Other" },
    { value: statistics?.SESSION_COUNT_DESKTOP_WINDOWS, name: "Desktop Windows" },
    { value: statistics?.SESSION_COUNT_DESKTOP_MAC, name: "Desktop Mac" },
    { value: statistics?.SESSION_COUNT_DESKTOP_OTHER, name: "Desktop Other" },
  ].filter((item) => (hideBlanks ? !!item.value : true));

  const options = {
    tooltip: {
      trigger: "item",
      formatter: "{a} <br/>{b}: {c} ({d}%)",
    },
    legend: {
      data: [...filteredData.map((item) => item.name), ...filteredData2.map((item) => item.name)],
    },
    series: [
      {
        name: "Platforms",
        type: "pie",
        selectedMode: "single",
        radius: [0, "30%"],
        label: {
          position: "inner",
          fontSize: 14,
        },
        labelLine: {
          show: false,
        },
        data: filteredData,
      },
      {
        name: "Platforms",
        type: "pie",
        radius: ["45%", "60%"],
        labelLine: {
          length: 30,
        },
        label: {
          position: "outside",
          alignTo: "edge",
          margin: 10,
          formatter: (params) => {
            return params.value === 0 ? "" : params.name;
          },
        },
        data: filteredData2,
      },
    ],
  };
  return <PRChart loading={loading} option={options} style={chartStyle} title="Session Count Pie Chart" />;
};
const StyledTab = styled(Tab)`
  font-weight: 600;
`;

const StyledGrid = styled(Grid)`
  align-items: center;
  display: flex;
  justify-content: center;
  align-self: center;
`;

const SessionStatistics = () => {
  const [tab, setTab] = useState(0);
  const [hideBlanks, setHideBlanks] = useState(true);
  const { projectId } = useParams();
  const dispatch = useDispatch();
  const filter = useSelector(selectStatisticsFilter);

  const handleHideBlanks = () => {
    setHideBlanks(!hideBlanks);
  };

  const handleChangeTab = (event, newValue) => {
    setTab(newValue);
  };

  const handleExcelExport = async () => {
    let fn;
    switch (tab) {
      case 0:
        fn = getSessionCountSankeyStatistics;
        break;
      case 1:
        fn = getSessionCountPieChartStatistics;
        break;
      default:
        break;
    }
    if (!fn) {
      AlertHelper.show("There is no data to export.", "error");
      return;
    }

    // get the buffer from fn(), and then save it as a file
    const buffer = await dispatch(fn(projectId, filter.beginDate, filter.endDate, true));
    FileHelper.saveAs(buffer, "statistics", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet");
  };

  return (
    <PalContainer
      description={"Here you can see the statistics of the sessions."}
      name="Statistics"
      parentName="Session Statistics"
    >
      <Grid container>
        <Grid item xs={12}>
          <FilterCard />
        </Grid>
        <Grid item md={4} xs={12}>
          <AggregateCard icon={MdHistory} statisticKey="SESSION_COUNT" title="Total Sessions" />
        </Grid>
        <Grid item md={4} xs={12}>
          <AggregateCard icon={MdLiveHelp} statisticKey="SESSION_QUESTION_COUNT" title="Total Questions" />
        </Grid>
        <Grid item md={4} xs={12}>
          <AggregateCard icon={MdPeople} statisticKey="SESSION_AGENT_JOINED_COUNT" title="Total Agent Joined" />
        </Grid>
      </Grid>
      <PalPage collapse>
        <Grid container rowSpacing={2}>
          <Grid item xs={12}>
            <Grid container>
              <Grid item md xs={10}>
                <Tabs value={tab} variant="scrollable" onChange={handleChangeTab}>
                  <StyledTab label="Session Count Sankey" />
                  <StyledTab label="Session Count Pie Chart" />
                </Tabs>
              </Grid>
              <Grid
                item
                sx={{
                  pr: 1,
                  display: "flex",
                  alignItems: "center",
                  label: {
                    margin: 0,
                    padding: 0,
                  },
                }}
                xs="auto"
              >
                <FormControlLabel
                  checked={hideBlanks}
                  control={<Switch />}
                  label="Hide blanks"
                  onChange={handleHideBlanks}
                />
              </Grid>
              <StyledGrid item md={"auto"} xs={2}>
                <ExcelButton onClick={handleExcelExport} />
              </StyledGrid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            {tab === 0 && <SessionCountSankeyStatistics hideBlanks={hideBlanks} />}
            {tab === 1 && <SessionCountPieChartStatistics hideBlanks={hideBlanks} />}
          </Grid>
        </Grid>
      </PalPage>
    </PalContainer>
  );
};

export default SessionStatistics;
