import { useEffect } from "react";

import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Col, Label, Row } from "reactstrap";
import * as Yup from "yup";

import useProjectChange from "~common/hooks/useProjectChange";
import PRButton from "~components/Generic/PRButton";
import PRContainer from "~components/Generic/PRContainer";
import PRInput from "~components/Generic/PRInput";
import PRPage from "~components/Generic/PRPage";
import HistoryHelper from "~helpers/HistoryHelper";
import { createOrUpdateIntentTag, getIntentTag, setIntentTag } from "~store/dialogComponents/intentTags/actions";
import { selectIntentTag } from "~store/dialogComponents/intentTags/selectors";
import { selectCurrentBot, selectCurrentProject } from "~store/user/selectors";

const goBackUrl = "/chatbot/intent-tags/";
export default function IntentTagsEdit() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const currentProject = useSelector(selectCurrentProject);
  const currentBot = useSelector(selectCurrentBot);
  const intentTag = useSelector(selectIntentTag);

  const handleGoBack = () => {
    HistoryHelper.goBack(goBackUrl, { scope: "dashboard" });
  };
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: intentTag.id,
      key: intentTag.key,
    },
    validationSchema: Yup.object({
      key: Yup.string().required("Required").min(3, "Name must be at least 3 characters"),
    }),
    onSubmit: async (values) => {
      await dispatch(createOrUpdateIntentTag(currentProject.id, currentBot.id, values));
      handleGoBack();
    },
  });

  useEffect(() => {
    if (!id) return;
    dispatch(getIntentTag(currentProject.id, currentBot.id, id));
    return () => {
      dispatch(setIntentTag({}));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, id]);

  useProjectChange(() => {
    HistoryHelper.push(goBackUrl, { scope: "dashboard" });
  }, []);

  const parentName = [
    {
      label: "Intent Tags",
      url: goBackUrl,
    },
    {
      label: `${!id ? "Create" : "Edit"} Intent Tag`,
    },
  ];
  return (
    <PRContainer
      smalltalkSelector
      description={"Here you can create or edit an intent tag."}
      name="Chatbot"
      parentName={parentName}
    >
      <PRPage title={`Intent Tag ${id ? "Edit" : "Create"}`}>
        <Row className="mt-2 g-2">
          <Col md="4">
            <Label size="md">Name</Label>
          </Col>
          <Col md="8">
            <PRInput
              invalid={formik.touched.key && formik.errors.key}
              name="key"
              type="text"
              value={formik.values.key}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
            />
          </Col>
        </Row>
        <Row className="justify-content-end mt-2">
          <Col md="auto">
            <PRButton outline link={goBackUrl}>
              Cancel
            </PRButton>
            <PRButton className="ms-2" onClick={formik.handleSubmit}>
              Save
            </PRButton>
          </Col>
        </Row>
      </PRPage>
    </PRContainer>
  );
}
