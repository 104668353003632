import { createRef, useCallback, useMemo } from "react";

import { MdAdd, MdDownload, MdPreview } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import useProjectChange from "~common/hooks/useProjectChange";
import PRButton from "~components/Generic/PRButton";
import PRContainer from "~components/Generic/PRContainer";
import PRTable from "~components/Generic/PRTable";
import PRTooltip from "~components/Generic/PRTooltip";
import {
  popupSettingsMapper,
  apiUrlStatisticGenerator,
  statisticGeneratorReportTypeMap,
  statisticGeneratorReportType,
} from "~constants";
import DateHelper from "~helpers/DateHelper";
import DialogHelper from "~helpers/DialogHelper";
import FileHelper from "~helpers/FileHelper";
import HistoryHelper from "~helpers/HistoryHelper";
import Utils from "~helpers/Utils";
import { downloadStatisticGeneratorReport, generateAsyncStatisticGenerator } from "~store/statisticGenerator/actions";
import { selectCurrentProject } from "~store/user/selectors";

import DynamicReportChartModal from "./DynamicReportChartModal";

export default function StatisticGeneratorReportList() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const tableRef = createRef();

  const currentProject = useSelector(selectCurrentProject);

  useProjectChange(() => {
    HistoryHelper.push("/stats/statistic-generator/", { scope: "dashboard" });
  }, []);
  const columns = useMemo(() => {
    const handleDownloadExcel = (item) => async () => {
      const excelData = await dispatch(
        downloadStatisticGeneratorReport(currentProject.id, item.id, {
          responseType: "blob",
        })
      );
      const fileName = item.file_name.endsWith(".xlsx") ? item.file_name.slice(0, -5) : item.file_name;
      FileHelper.saveAs(excelData, `${Utils.slugify(fileName) + ".xlsx"}` || `report_${item.id}`);
    };

    const handleDownloadJson = (item) => async () => {
      const jsonData = await dispatch(downloadStatisticGeneratorReport(currentProject.id, item.id));
      const fileName = item.file_name.endsWith(".json") ? item.file_name.slice(0, -5) : item.file_name;
      FileHelper.saveAs(
        JSON.stringify(jsonData, null, 2),
        `${Utils.slugify(fileName) + ".json"}` || `report_${item.id}`,
        "application/json"
      );
    };

    const handleShowReportPreview = (item) => async () => {
      DynamicReportChartModal.show({ reportId: item.id });
    };
    return [
      {
        label: "File Name",
        key: "file_name",
      },
      {
        label: "Report Type",
        key: "report_type",
        render: (row) => {
          return statisticGeneratorReportTypeMap[row.report_type] || row.report_type;
        },
      },
      {
        label: "File Type",
        key: "file_type",
        render: (row) => {
          return row.file_type || "-";
        },
      },
      {
        key: "created",
        label: "Created",
        render: (item) => (
          <div>
            <PRTooltip title={DateHelper.getDateTimeLocal(item.created).format("LLT")}>
              <span>{DateHelper.getDateTimeLocal(item.created).fromNow()}</span>
            </PRTooltip>
          </div>
        ),
      },
      {
        label: "Actions",
        key: "actions",
        actions: true,
        render: (row) => (
          <div className="d-flex justify-content-center gap-1">
            {row.report_type === statisticGeneratorReportType.WEB && (
              <PRButton
                outline
                color="primary"
                icon={MdPreview}
                size="sm"
                tooltipText="Preview report"
                onClick={handleShowReportPreview(row)}
              />
            )}
            {row.report_type === statisticGeneratorReportType.JSON && (
              <PRButton
                outline
                color="primary"
                icon={MdDownload}
                size="sm"
                tooltipText="Download JSON"
                onClick={handleDownloadJson(row)}
              />
            )}
            {row.report_type === statisticGeneratorReportType.EXCEL && (
              <PRButton
                outline
                color="primary"
                icon={MdDownload}
                size="sm"
                tooltipText="Download Excel"
                onClick={handleDownloadExcel(row)}
              />
            )}
          </div>
        ),
      },
    ];
  }, [dispatch, currentProject?.id]);

  const actions = useMemo(() => {
    const handleClickGenerateReport = async () => {
      if (!(await DialogHelper.showQuestionYesNo("Generate Report", "Are you sure you want to generate the report?")))
        return;
      await dispatch(generateAsyncStatisticGenerator(currentProject.id, id));
      tableRef.current?.refresh();
    };

    return [
      {
        label: "Generate Report",
        icon: MdAdd,
        color: "success",
        onClick: handleClickGenerateReport,
      },
    ];
  }, [dispatch, id, currentProject?.id, tableRef]);

  const parentName = useMemo(
    () => [
      {
        label: "Statistics Generator",
        url: "/stats/statistic-generator/",
      },
      {
        label: "Statistic Reports",
      },
    ],
    []
  );
  const handleOnLoad = useCallback((results) => {
    return results.map((item) => {
      return {
        ...item,
        settings: {
          ...item.settings,
          ...popupSettingsMapper(item.settings),
        },
      };
    });
  }, []);
  return (
    <PRContainer
      actions={actions}
      description={"Here you can create and manage your projects. You can also create and manage your chatbots."}
      name="Statistics"
      parentName={parentName}
    >
      <div className="mt-2">
        <PRTable
          ref={tableRef}
          columns={columns}
          url={apiUrlStatisticGenerator.getReports.format(currentProject?.id, id)}
          onLoad={handleOnLoad}
        />
      </div>
    </PRContainer>
  );
}
