import { createRef, useMemo } from "react";

import { PalTooltip } from "@palamar/fe-library";
import { withCardon } from "cardon";
import { MdAdd, MdDelete, MdEdit, MdRemoveRedEye } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";

import PRButton from "~components/Generic/PRButton";
import PRContainer from "~components/Generic/PRContainer";
import PRModal from "~components/Generic/PRModal";
import PRTable from "~components/Generic/PRTable";
import { apiUrlDefaultMessage, userRole } from "~constants";
import { chatMessageTypeMap, chatMessageTypes } from "~constants/contactCenter";
import DateHelper from "~helpers/DateHelper";
import DialogHelper from "~helpers/DialogHelper";
import { deleteDefaultMessage } from "~store/knowledgeBase/actions";
import { selectCurrentProject } from "~store/user/selectors";

const MessageList = () => {
  const dispatch = useDispatch();
  const tableRef = createRef();
  const currentProject = useSelector(selectCurrentProject);

  const isAdmin = useMemo(() => currentProject?.permissions?.includes(userRole.admin), [currentProject]);

  const actions = useMemo(() => {
    return [
      {
        label: "Create New",
        icon: MdAdd,
        color: "success",
        link: "/contactcenter/chat-messages/form",
      },
    ];
  }, []);

  const columns = useMemo(() => {
    const handleClickDelete = (row) => async () => {
      if (await DialogHelper.showQuestionDelete()) {
        dispatch(deleteDefaultMessage(currentProject.id, row.id)).then(() => {
          tableRef.current.refresh();
        });
      }
    };

    const handleClickShow = (row) => async () => {
      await InfoModal.show({ item: row });
    };
    return [
      {
        label: "Title",
        key: "title",
      },
      {
        label: "Text",
        key: "data",
        render: (row) => (row.data.length > 200 ? row.data.slice(0, 200) + "..." : row.data),
      },
      {
        label: "Created By",
        key: "owner_name",
      },
      {
        label: "Updated",
        key: "updated",
        render: (row) =>
          row.updated ? (
            <PalTooltip title={DateHelper.getDateTimeLocal(row.updated).fromNow()}>
              {DateHelper.getDateTimeLocal(row.updated).format("LLL")}
            </PalTooltip>
          ) : (
            "-"
          ),
      },
      {
        label: "Message Type",
        key: "message_type",
        render: (row) => chatMessageTypeMap[row.message_type] || row?.message_type || "-",
      },
      // {
      //   label: "Create date",
      //   key: "created",
      //   render: (row) => DateHelper.getDateTimeLocal(row.created).format("LLL"),
      // },
      {
        label: "Actions",
        key: "actions",
        fixed: "right",
        actions: true,
        render: (row) => (
          <div className="d-flex justify-content-center">
            {(row?.message_type === chatMessageTypes.PRIVATE || isAdmin) && (
              <PRButton
                outline
                className="ms-1"
                color="primary"
                icon={MdEdit}
                link={`/contactcenter/chat-messages/form/${row.id}`}
                size="sm"
                tooltipText="Edit"
              />
            )}
            {row?.message_type === chatMessageTypes.PUBLIC && (
              <PRButton
                outline
                className="ms-1"
                color="primary"
                icon={MdRemoveRedEye}
                size="sm"
                tooltipText="Show Message"
                onClick={handleClickShow(row)}
              />
            )}
            <PRButton
              outline
              className="ms-1"
              color="danger"
              icon={MdDelete}
              size="sm"
              tooltipText="Delete"
              onClick={handleClickDelete(row)}
            />
          </div>
        ),
      },
    ];
  }, [currentProject.id, dispatch, tableRef, isAdmin]);
  return (
    <PRContainer
      actions={actions}
      description={"Here you can manage your Chat Messages for use in livechat"}
      name={"Contact Center"}
      parentName="Chat Messages"
    >
      <PRTable
        ref={tableRef}
        className="" //
        columns={columns}
        url={apiUrlDefaultMessage.get.format(currentProject.id)}
      />
    </PRContainer>
  );
};

const InfoModal = withCardon(
  function InfoModal({ get, item }) {
    return (
      <PRModal size="xl" submitText="" title="Chat Message Content" onClose={get(false)}>
        {item.data}
      </PRModal>
    );
  },
  { destroyOnHide: true }
);

export default MessageList;
