import { createRef, memo, useEffect, useMemo, useState } from "react";

import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { BsRobot } from "react-icons/bs";
import { FaBuilding, FaProjectDiagram } from "react-icons/fa";
import { MdDoneAll, MdNotifications, MdPerson, MdSchedule } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Col, Dropdown, DropdownMenu, DropdownToggle, FormGroup, Input, Label, Row, Spinner } from "reactstrap";
import SimpleBar from "simplebar-react";

import { Campaign } from "@mui/icons-material";
import { Alert } from "@mui/material";

import { ReactComponent as NotificationIcon } from "~assets/images/icons/notification.svg";
import useLoading from "~common/hooks/useLoading";
import PRTooltip from "~components/Generic/PRTooltip";
import DateHelper from "~helpers/DateHelper";
import HistoryHelper from "~helpers/HistoryHelper";
import { setAcknowledgedAppAnnouncementIds } from "~store/announcement/actions";
import { selectExistAndInvisibleAppAnnouncementList } from "~store/announcement/selectors";
import { getNotifications, markAsReadNotifications, setNotifications } from "~store/notification/actions";
import { selectNotifications } from "~store/notification/selectors";
import { selectCurrentBot, selectCurrentProject } from "~store/user/selectors";
import "./style.scss";

const InfinityEndComponent = memo(function InfinityEndComponent({ innerRef }) {
  return <div ref={innerRef} className="text-center mb-2 pt-4"></div>;
});

const notificationContentMap = {
  chatbot_ready: (notification) => {
    const title = "Chatbot is ready";
    const description = `${notification?.payload.chatbot_name} is ready`;
    return { title, description };
  },
};

const NotificationDropdown = () => {
  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false);

  const { results: notifications = [], count } = useSelector(selectNotifications);
  const existAndInvisibleAppAnnouncementList = useSelector(selectExistAndInvisibleAppAnnouncementList);
  const currentProject = useSelector(selectCurrentProject);
  const currentBot = useSelector(selectCurrentBot);

  const [notificationQuery, setNotificationQuery] = useState({ limit: 10, offset: 0, seen: "False" });
  const dispatch = useDispatch();
  const [unreadViewMode, setUnreadViewMode] = useState(true);
  const [loading, q] = useLoading();
  const interceptRef = createRef();
  const { t } = useTranslation();

  const pendingNotificationCount = loading
    ? 0
    : unreadViewMode
    ? count
    : notifications?.filter((notification) => !notification?.seen)?.length || 0;

  useEffect(() => {
    q(dispatch(getNotifications(notificationQuery)));
  }, [dispatch, notificationQuery, q]);

  const handleClickMarkAllAsRead = () => {
    q(
      dispatch(markAsReadNotifications(["all"])).then(() => {
        const seenNotifications = notifications.map((notification) => ({ ...notification, seen: true }));
        dispatch(
          setNotifications({
            results: seenNotifications,
            count: 0,
          })
        );

        // return dispatch(getNotifications(notificationQuery));
      })
    );
  };
  const handleClickRead = (id) => () => {
    const notification = notifications.find((notification) => notification?.id === id);
    if (notification?.category === "CHATBOT") {
      HistoryHelper.push(
        `/dashboard/project/${notification?.payload?.project_id || currentProject?.id}/bot/${
          notification?.payload?.chatbot_id || currentBot?.id
        }/chatbot/settings/`
      );
    } else if (notification?.category === "COMPANY") {
      HistoryHelper.push(`/chatbot/settings/`, { scope: "dashboard" });
    } else if (notification?.category === "PROJECT") {
      if (notification.key === "email_template_error") {
        HistoryHelper.push(`/settings/message-template/`, { scope: "dashboard" });
      } else {
        HistoryHelper.push(`/chatbot/settings/`, { scope: "dashboard" });
      }
    } else if (notification?.category === "USER") {
      HistoryHelper.push(`/settings/user/`, { scope: "dashboard" });
    }
    if (notification?.seen) return;
    q(
      dispatch(markAsReadNotifications([id])).then(() => {
        return dispatch(getNotifications(notificationQuery));
      })
    );
  };
  useEffect(() => {
    setNotificationQuery((prev) => ({ ...prev, ...(unreadViewMode ? { seen: "False" } : { seen: undefined }) }));
  }, [unreadViewMode]);

  const handleSwitchViewMode = (e) => {
    setUnreadViewMode(e.target.checked);
  };
  const viewingNotifications = useMemo(() => {
    return notifications?.filter((notification) => !!notification?.seen === !unreadViewMode) || [];
  }, [unreadViewMode, notifications]);
  useEffect(() => {
    if (loading) return;
    const intersectObserver = new IntersectionObserver(
      (entries) => {
        if (
          entries[0].intersectionRatio > 0.01 &&
          entries[0].intersectionRatio <= 0.99 &&
          count > notifications?.length
        ) {
          setNotificationQuery((prev) => ({ ...prev, offset: prev.offset + 10 }));
        }
      },
      {
        threshold: 0.01,
      }
    );
    intersectObserver.observe(interceptRef.current);
    return () => intersectObserver.disconnect();
  }, [interceptRef, loading, count, notifications]);

  useEffect(() => {
    if ("setAppBadge" in navigator) {
      navigator.setAppBadge(pendingNotificationCount);
    }
  }, [pendingNotificationCount]);

  const onClickResetAppAnnouncement = () => {
    dispatch(setAcknowledgedAppAnnouncementIds([]));
    setMenu(!menu);
  };
  return (
    <Dropdown
      className="pr-notification-dropdown dropdown d-inline-block"
      isOpen={menu}
      tag="li"
      toggle={() => setMenu(!menu)}
    >
      <DropdownToggle className="px-0 btn border-0" id="page-header-notifications-dropdown" tag="button">
        <div className="position-relative bell-dropdown">
          <NotificationIcon
            className={classNames({
              tada: !!pendingNotificationCount,
            })}
          />
          {!!pendingNotificationCount && (
            <span className="badge bg-danger rounded-pill position-absolute top-0 start-100 translate-middle fs-7">
              {pendingNotificationCount}
            </span>
          )}

          {!pendingNotificationCount && !!existAndInvisibleAppAnnouncementList?.length && (
            <span className="badge bg-primary rounded-circle position-absolute top-0 start-100 translate-middle fs-7">
              {existAndInvisibleAppAnnouncementList?.length}
            </span>
          )}
        </div>
      </DropdownToggle>

      <DropdownMenu className="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0">
        <div className="p-3">
          <Row className="align-items-center">
            {!!existAndInvisibleAppAnnouncementList?.length && (
              <Col className="text-truncate" xs="12">
                <Alert
                  className="mb-2 d-flex align-items-center"
                  icon={<Campaign />}
                  severity="info"
                  sx={{
                    "&:hover": {
                      cursor: "pointer",
                    },
                  }}
                  variant="outlined"
                  onClick={onClickResetAppAnnouncement}
                >
                  Show {existAndInvisibleAppAnnouncementList.length} hidden announcements
                </Alert>
              </Col>
            )}
            <Col xs className="d-flex align-items-center">
              <h6 className="m-0"> {t("web.notifications")} </h6>
            </Col>
            {/* <div className="col-auto">
                <a href="#" className="small">
                  Show All
                </a>
              </div> */}
            <Col xs="auto">
              <div className="col-auto">
                <FormGroup switch className="">
                  <Label className="mb-0"> Unread </Label>
                  <Input checked={unreadViewMode} type="switch" onChange={handleSwitchViewMode} />
                </FormGroup>
              </div>
            </Col>
          </Row>
        </div>

        <SimpleBar style={{ maxHeight: "400px" }}>
          {!viewingNotifications?.length && !loading && (
            <div className="text-muted no-notification mb-4">{t("You don't have any notifications")}</div>
          )}

          {!!viewingNotifications?.length &&
            viewingNotifications.map((item, index) => {
              let title = item.title;
              let description = item.description;

              const dataMapper = notificationContentMap[item.key];
              if (dataMapper) {
                const { title: newTitle, description: newDescription } = dataMapper(item);
                title = newTitle;
                description = newDescription;
              }
              return (
                <div
                  key={index}
                  className={classNames("p-2", "notification-item", {
                    "opacity-50": item.seen,
                  })}
                >
                  <Link className="text-reset" to="#" onClick={handleClickRead(item.id)}>
                    <Row className="g-2 flex-nowrap">
                      <Col style={{ minWidth: 36 }} xs="auto">
                        <span
                          className="avatar-title bg-primary rounded-circle font-size-16"
                          style={{
                            width: 36,
                            height: 36,
                          }}
                        >
                          {item.category === "CHATBOT" && <BsRobot />}
                          {item.category === "COMPANY" && <FaBuilding />}
                          {item.category === "PROJECT" && <FaProjectDiagram />}
                          {item.category === "USER" && <MdPerson />}
                          {/* {item.category === "SYSTEM" && <i className="???" />}
                        {item.category === "CHAT" && <i className="???" />}
                        {item.category === "TASK" && <i className="???" />}
                        {item.category === "FILE" && <i className="???" />}
                        {item.category === "NOTIFICATION" && <i className="???" />} */}

                          {!item.category && <MdNotifications />}
                        </span>
                      </Col>
                      <Col xs>
                        {!!title && <div className=" font-size-16 fw-medium">{title}</div>}
                        <div className=" ">{description}</div>
                        <PRTooltip placement="left" title={DateHelper.getDateTimeLocal(item.created).format("LLL")}>
                          <div className="mb-1 text-muted fs-6 d-flex align-items-center">
                            <MdSchedule className="fs-6 me-1" />

                            {DateHelper.getDateTimeLocal(item.created).fromNow()}
                          </div>
                        </PRTooltip>
                      </Col>
                    </Row>
                  </Link>
                </div>
              );
            })}
          {loading && (
            <div className="text-center">
              <Spinner className="mt-2" color="secondary" role="status" size="sm" />
            </div>
          )}
          <InfinityEndComponent innerRef={interceptRef} />
        </SimpleBar>
        <div className="p-2 border-top d-grid">
          <Link className="btn btn-sm btn-link font-size-14 text-center" to="#">
            <MdDoneAll className="me-1" />
            <span key="t-view-more" onClick={handleClickMarkAllAsRead}>
              Mark all as read
            </span>
          </Link>
        </div>
      </DropdownMenu>
    </Dropdown>
  );
};

export default NotificationDropdown;
