import { Fragment, useMemo, useState } from "react";

import { useFormik } from "formik";
import { MdAdd, MdDelete } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { Col, FormFeedback, Input, Label, Row, Table } from "reactstrap";
import * as Yup from "yup";

import CodeEditor from "~components/CodeEditor";
import PRButton from "~components/Generic/PRButton";
import PRDivider from "~components/Generic/PRDivider";
import PRSelect from "~components/Generic/PRSelect";
import { messageTemplateCurlyFormat } from "~constants";
import Utils from "~helpers/Utils";
import { getEntityTypeList } from "~store/dialogComponents/entityTypes/actions";
import { setScenarioFormState } from "~store/dialogComponents/scenarioManager/actions";
import {
  selectScenarioManagerSlotValidationText,
  selectScenarioManagerSlots,
} from "~store/dialogComponents/scenarioManager/selectors";
import { selectCurrentBot, selectCurrentProject } from "~store/user/selectors";

import useMonacoScenarioEditorVariables from "../../useMonacoScenarioEditorVariables";

function SlotItem({ slot, onChange, onDelete, order }) {
  const dispatch = useDispatch();

  const currentProject = useSelector(selectCurrentProject);
  const currentBot = useSelector(selectCurrentBot);

  const formik = useFormik({
    enableReinitialize: true,
    validateOnMount: true,

    initialValues: {
      name: slot.name || "",
      question: slot.question || "",
      entity_type: slot.entity_type,
      display_name: slot.display_name || "",
    },
    validationSchema: Yup.object().shape({
      name: Yup.string()
        .required("Name is required")
        .test("len2", "Name must be at least 2 characters", (val) => {
          return val?.length >= 2;
        })
        .test("valid_variable", "Key must be a valid variable", function (val) {
          const isValid = Utils.isValidPythonVariableName(val);
          if (isValid.valid) return true;
          return this.createError({ message: isValid.reason });
        }),
      display_name: Yup.string().required("Display Name is required"),
      question: Yup.string()
        .required("Question is required")
        .test("len23", "Question must be at least 2 characters", (val) => {
          return val?.length >= 2;
        }),
      entity_type: Yup.object().shape({
        id: Yup.string().required("Entity Type is required"),
      }),
    }),
  });

  const handleChange = (key) => (e) => {
    const value = e.target.value;
    onChange({ ...slot, [key]: value });

    formik.setFieldValue(key, e.target.value);
  };

  const handleSearch = async (searchText, callback, signal) => {
    const response = await dispatch(
      getEntityTypeList(currentProject.id, currentBot.id, {
        params: {
          limit: 20,
          name__icontains: searchText,
        },
        signal,
      })
    );
    return response?.results || [];
  };
  const handleChangeSlot = (entity_type) => {
    onChange({ ...slot, entity_type: { id: entity_type.id, name: entity_type.name } });
    // formik.setFieldValue("entity_type", slot);
  };

  return (
    <tr>
      <td>
        <PRSelect
          fullWidth
          lazy
          menuPortal
          invalid={formik.errors.entityType?.id}
          isClearable={false}
          labelSelector="name"
          loadOptions={handleSearch}
          placeholder="Select entity type"
          value={formik.values.entity_type}
          valueSelector="id"
          onChange={handleChangeSlot}
        />
      </td>
      <td>
        <div className="w-100">
          <Input
            className="form-control"
            invalid={!!formik.touched.question && !!formik.errors.question}
            name="question"
            placeholder="Enter slot question"
            type="text"
            value={formik.values.question}
            onBlur={formik.handleBlur}
            onChange={handleChange("question")}
          />
          {formik.touched.question && formik.errors.question && (
            <FormFeedback type="invalid">{formik.errors.question}</FormFeedback>
          )}
        </div>
      </td>
      <td>
        <div className="w-100">
          <Input
            className="form-control"
            invalid={!!formik.touched.display_name && !!formik.errors.display_name}
            name="displayName"
            placeholder="Enter display name"
            type="text"
            onChange={handleChange("display_name")}
            onBlur={formik.handleBlur}
            //TODO: fix value to value. Cursor moves to the end of the input field when typing.
            value={formik.values.display_name}
          />
          {formik.touched.display_name && formik.errors.display_name && (
            <FormFeedback type="invalid">{formik.errors.display_name}</FormFeedback>
          )}
        </div>
      </td>
      <td>
        <div className="w-100">
          <Input
            className="form-control"
            invalid={!!formik.touched.name && !!formik.errors.name}
            name="name"
            placeholder="Enter slot key"
            type="text"
            value={formik.values.name}
            onBlur={formik.handleBlur}
            onChange={handleChange("name")}
          />
          {formik.touched.name && formik.errors.name && (
            <FormFeedback type="invalid">{formik.errors.name}</FormFeedback>
          )}
        </div>
      </td>
      <td>
        <PRButton outline color="danger" icon={MdDelete} onClick={onDelete} />
      </td>
    </tr>
  );
}

export default function Slots() {
  const dispatch = useDispatch();

  const [codeEditor, setCodeEditor] = useState(null);

  const slots = useSelector(selectScenarioManagerSlots);
  const slotValidationText = useSelector(selectScenarioManagerSlotValidationText);

  const handleChangeValidationText = (e) => {
    dispatch(setScenarioFormState("slot_validation_text", e));
  };
  const handleChangeSlotItem = (itemIndex) => (slotItem) => {
    dispatch(setScenarioFormState("slots", [...slots.map((item, index) => (index === itemIndex ? slotItem : item))]));
  };
  const handleClickAddSample = () => {
    dispatch(setScenarioFormState("slots", [...slots, { name: "", question: "", entity_type: {} }]));
  };
  const handleClickDeleteSample = (itemIndex) => () => {
    dispatch(setScenarioFormState("slots", [...slots.filter((item, index) => index !== itemIndex)]));
  };
  const handleClickVariable = (variable) => () => {
    if (codeEditor.editor) {
      const position = codeEditor.editor.getPosition();
      codeEditor.editor.executeEdits("", [
        {
          range: new codeEditor.monaco.Range(
            position.lineNumber,
            position.column,
            position.lineNumber,
            position.column
          ),
          text: messageTemplateCurlyFormat.format(variable),
        },
      ]);
    }
  };
  const variableStatus = useMemo(() => {
    const formItems = slots || [];
    const variables = {};
    formItems.forEach((item) => {
      variables[item.name] = slotValidationText.includes(messageTemplateCurlyFormat.format(item.name));
    });
    return variables;
  }, [slots, slotValidationText]);

  useMonacoScenarioEditorVariables(codeEditor?.editor);

  return (
    <div>
      <Row className="align-items-center mt-2">
        <Col xs>
          <Label className="m-0">Slots</Label>
        </Col>
        <Col xs={"auto"}>
          <PRButton icon={MdAdd} onClick={handleClickAddSample}>
            Add Slot
          </PRButton>
        </Col>
      </Row>
      {!slots?.length && (
        <div>
          <p className="text-muted text-center"> - No Slots - </p>
        </div>
      )}
      {!!slots?.length && (
        <div className="overflow-auto">
          <Table borderless className="table-no-spacing slot-table" size="sm">
            <thead>
              <tr>
                <th>Entity Type</th>
                <th>Question</th>
                <th>Display Name</th>
                <th>Key</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {slots.map((slot, index) => {
                return (
                  <Fragment key={index}>
                    <SlotItem
                      order={index}
                      slot={slot}
                      onChange={handleChangeSlotItem(index)}
                      onDelete={handleClickDeleteSample(index)}
                    />
                    <tr>
                      <td className="py-0" colSpan={5}>
                        {slots.length - 1 !== index && (
                          <Col xs={12}>
                            <PRDivider color="secondary-400" />
                          </Col>
                        )}
                      </td>
                    </tr>
                  </Fragment>
                );
              })}
            </tbody>
          </Table>
        </div>
      )}
      <div className="d-flex justify-content-between align-items-center">
        <Label className="form-label mt-2 text-nowrap">Validation Text</Label>
        <div className="ms-2 d-flex align-items-center">
          {slots?.length > 0 && (
            <>
              <Label className="form-label mt-2 text-nowrap">Slot Variables:</Label>
              <div className=" ">
                {slots?.map((item) => {
                  if (!item.name) return null;
                  return (
                    <PRButton
                      key={item.name}
                      className="my-1 ms-1"
                      color={variableStatus[item.name] ? "success" : "warning"}
                      size="sm"
                      tooltipText={item.name}
                      onClick={handleClickVariable(item.name)}
                    >
                      {item.name}
                    </PRButton>
                  );
                })}
              </div>
            </>
          )}
        </div>
      </div>
      <CodeEditor
        noToolbar
        wordWrap
        defaultLanguage="plaintext"
        lineNumbers={false}
        minimap={false}
        value={slotValidationText}
        onChange={handleChangeValidationText}
        onMount={setCodeEditor}
      />
    </div>
  );
}
