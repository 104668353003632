import { PalIconButton, PalTooltip } from "@palamar/fe-library";
import { MdMoreVert } from "react-icons/md";

const MoreButton = ({ onClick, id }) => {
  return (
    <PalTooltip title="More">
      <PalIconButton color="primary" id="more-button" onClick={onClick}>
        <MdMoreVert />
      </PalIconButton>
    </PalTooltip>
  );
};

export default MoreButton;
