import { useEffect, useState } from "react";

import { useFormik } from "formik";
import { isValidNumber } from "libphonenumber-js";
import { MdAdd, MdDelete, MdHelp } from "react-icons/md";
import ReactPlayer from "react-player/lazy";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Col, Label, Row } from "reactstrap";
import * as Yup from "yup";

import styled from "@emotion/styled";

import useProjectChange from "~common/hooks/useProjectChange";
import PRAlert from "~components/Generic/PRAlert";
import PRButton from "~components/Generic/PRButton";
import PRContainer from "~components/Generic/PRContainer";
import PRDropZone from "~components/Generic/PRDropZone";
import PRFormFeedback from "~components/Generic/PRFormFeedback";
import PRInput, { PRTextArea } from "~components/Generic/PRInput";
import PRPage from "~components/Generic/PRPage";
import PRSelect from "~components/Generic/PRSelect";
import PRTooltip from "~components/Generic/PRTooltip";
import PalPhoneNumber from "~components/mui/PalPhoneNumber";
import {
  chatbotGupshupButtonType,
  chatbotGupshupButtonTypeOptions,
  chatbotGupshupCategory,
  chatbotGupshupCategoryOptions,
  chatbotGupshupLanguage,
  chatbotGupshupLanguageOptions,
  chatbotGupshupTemplateType,
  chatbotGupshupTemplateTypeOptions,
} from "~constants";
import HistoryHelper from "~helpers/HistoryHelper";
import Network from "~helpers/Network";
import { createOrUpdateWhatsappTemplate, getWhatsappTemplate, setWhatsappTemplate } from "~store/platform/actions";
import { selectWhatsappTemplate } from "~store/platform/selectors";
import { selectCurrentProject } from "~store/user/selectors";

const StyledPRContainer = styled(PRContainer)`
  .whatsapp-buttons {
    background-color: #fff;
    border-radius: 7px;
    margin: 10px 0;
    padding: 10px;
  }
`;

const WhatsappTemplateMessageEdit = () => {
  const { id } = useParams();
  const whatsappTemplate = useSelector(selectWhatsappTemplate);
  const currentProject = useSelector(selectCurrentProject);
  const [loadedMediaFileItem, setLoadedMediaFileItem] = useState(null);

  const dispatch = useDispatch();

  const handleClickCancel = () => {
    HistoryHelper.goBack("/contactcenter/livechat/whatsapp-template-messages/", { scope: "dashboard" });
  };

  useEffect(() => {
    if (!id) return;
    dispatch(getWhatsappTemplate(currentProject.id, id));

    return () => {
      dispatch(setWhatsappTemplate({}));
    };
  }, [dispatch, id, currentProject.id]);
  useEffect(() => {
    if (!loadedMediaFileItem && whatsappTemplate?.media_file) {
      //fetch binary file and create File object
      Network.request(whatsappTemplate?.media_file, {
        method: "GET",
        responseType: "blob",
        rawResponse: true,
      }).then((response) => {
        const file = new File(
          [response.data],
          whatsappTemplate?.response_data?.templateType === chatbotGupshupTemplateType.IMAGE
            ? "image.png"
            : whatsappTemplate?.response_data?.templateType === chatbotGupshupTemplateType.VIDEO
            ? "video.mp4"
            : whatsappTemplate?.response_data?.templateType === chatbotGupshupTemplateType.DOCUMENT
            ? "document.pdf"
            : "file"
        );

        setLoadedMediaFileItem(file);
      });

      setLoadedMediaFileItem(whatsappTemplate?.media_file);
    }
  }, [dispatch, id, currentProject.id, loadedMediaFileItem, whatsappTemplate]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      ...whatsappTemplate,

      media_file: whatsappTemplate?.media_file || null,
      data: {
        ...whatsappTemplate?.data,
        buttons: (() => {
          if (whatsappTemplate?.data?.buttons) {
            let parsed = JSON.parse(whatsappTemplate.data.buttons);
            if (typeof parsed === "string" && parsed.startsWith("[")) {
              //INFO: Temporary fix for buttons array parsing issue for old templates
              parsed = JSON.parse(parsed);
            }
            if (parsed.length > -1) {
              return parsed;
            }
          }
          return [];
        })(),
        allowTemplateCategoryChange:
          typeof whatsappTemplate?.data?.allowTemplateCategoryChange === "boolean"
            ? whatsappTemplate?.data?.allowTemplateCategoryChange
            : true,
        enableSample: true,
        category: whatsappTemplate?.data?.category || chatbotGupshupCategory.MARKETING,
        languageCode: whatsappTemplate?.data?.languageCode || chatbotGupshupLanguage.tr,
        templateType: whatsappTemplate?.data?.templateType || chatbotGupshupTemplateType.TEXT,
        addSecurityRecommendation: whatsappTemplate?.data?.addSecurityRecommendation || false,
        content: whatsappTemplate?.data?.content || "",
        example: whatsappTemplate?.data?.example || "",
        exampleHeader: whatsappTemplate?.data?.exampleHeader || "",
        footer: whatsappTemplate?.data?.footer || "",
        header: whatsappTemplate?.data?.header || "",
        vertical: whatsappTemplate?.data?.vertical || "",
        elementName: whatsappTemplate?.data?.elementName || "",
      },
    },
    validationSchema: Yup.object({
      media_file: Yup.mixed().when("templateType", {
        is: (val) =>
          [
            chatbotGupshupTemplateType.IMAGE,
            chatbotGupshupTemplateType.VIDEO,
            chatbotGupshupTemplateType.DOCUMENT,
          ].includes(val) && !whatsappTemplate?.id,

        then: Yup.mixed().required("Required"),
      }),

      data: Yup.object({
        elementName: Yup.string()
          .required("Required")
          .test("small_under_and_numbers", "Invalid name. Only small letters, numbers and _ allowed", (value) => {
            return /^[a-z0-9_]+$/.test(value);
          })
          .max(100, "Maximum 100 characters allowed"),
        category: Yup.string().required("Required"),
        vertical: Yup.string().required("Required").max(180, "Maximum 180 characters allowed"),
        languageCode: Yup.string().required("Required"),
        templateType: Yup.string().required("Required"),
        content: Yup.string().required("Required").max(1028, "Maximum 1028 characters allowed"),
        example: Yup.string().required("Required").max(1028, "Maximum 1028 characters allowed"),
        footer: Yup.string().when("category", {
          is: (val) => val !== chatbotGupshupCategory.AUTHENTICATION,
          then: Yup.string().max(60, "Maximum 60 characters allowed"),
        }),
        header: Yup.string().when("category", {
          is: (val) => val !== chatbotGupshupCategory.AUTHENTICATION,
          then: Yup.string().max(60, "Maximum 60 characters allowed"),
        }),
        exampleHeader: Yup.string().max(60, "Maximum 60 characters allowed"),

        buttons: Yup.array()
          .nullable()
          .when("category", {
            is: (val) => val !== chatbotGupshupCategory.AUTHENTICATION,
            then: Yup.array()
              .of(
                Yup.object({
                  type: Yup.string().required("Required"),
                  text: Yup.string().when("type", {
                    is: chatbotGupshupButtonType.QUICK_REPLY,
                    then: Yup.string().required("Required").max(60, "Maximum 60 characters allowed"),
                  }),
                  url: Yup.string().when("type", {
                    is: chatbotGupshupButtonType.URL,
                    then: Yup.string().required("Required").url("Invalid URL"),
                  }),
                  phone_number: Yup.string().when("type", {
                    is: chatbotGupshupButtonType.PHONE_NUMBER,
                    then: Yup.string()
                      .required("Required")
                      .test("phone_number", "Phone number is not valid", (val, context) => {
                        if (!val) return true;
                        let isValid = false;
                        try {
                          isValid = isValidNumber(val);
                        } catch (e) {}

                        return isValid;
                      }),
                  }),
                })
              )
              .test("max_2_url_buttons", "Maximum of 2 URL buttons are permitted", (value) => {
                return value.filter((item) => item.type === chatbotGupshupButtonType.URL).length <= 2;
              })
              .test("max_1_phone_number_button", "Maximum of 1 button of type Phone Number is permitted", (value) => {
                return value.filter((item) => item.type === chatbotGupshupButtonType.PHONE_NUMBER).length <= 1;
              })
              .test("max_10_quick_reply_buttons", "Maximum of 10 buttons of type Quick Reply is permitted", (value) => {
                return value.filter((item) => item.type === chatbotGupshupButtonType.QUICK_REPLY).length <= 10;
              }),
          }),
      }),
    }),
    onSubmit: async (values) => {
      // const buttonsStr = JSON.stringify(values.data.buttons);
      const payload = {
        template_type: values.data.templateType,

        ...((values.data.templateType === chatbotGupshupTemplateType.IMAGE ||
          values.data.templateType === chatbotGupshupTemplateType.VIDEO ||
          values.data.templateType === chatbotGupshupTemplateType.DOCUMENT) && {
          media_file: values.media_file,
        }),

        data: {
          ...values.data,
          // buttons: buttonsStr,
        },
        id: values.id,
      };
      await dispatch(createOrUpdateWhatsappTemplate(currentProject.id, payload));
      handleClickCancel();
    },
  });

  useProjectChange(() => {
    HistoryHelper.push("/contactcenter/chat-messages/", { scope: "dashboard" });
  }, []);

  const parentName = [
    {
      label: "Live Chat",
      url: "/contactcenter/livechat/",
    },
    {
      label: "Whatsapp Template Messages",
      url: "/contactcenter/livechat/whatsapp-template-messages/",
    },
    {
      label: `${id ? "Edit" : "Create"} Whatsapp Template Message`,
    },
  ];

  const handleChangeSelect = (key) => (value) => {
    formik.setFieldValue(key, value);
  };

  const handleClickAddButton = () => {
    formik.setFieldValue("data.buttons", [
      ...(formik.values.data?.buttons || []),
      {
        type: chatbotGupshupButtonType.QUICK_REPLY,
        text: "",
      },
    ]);
  };

  //   const exampleBody = useMemo(() => {
  //     //find {{1}} in body and replace it with example

  //     const regex = /{{\d}}/g;
  //     const body = formik.values.data?.content;
  //     const matches = body?.match(regex);
  //     if (!matches) return body;
  //     let exampleBody = body;
  //     matches.forEach((match) => {
  //       exampleBody = exampleBody.replace(match, `[Example ${match.replace(/[{}]/g, "")}]`);
  //     });
  //     return exampleBody;
  //   }, [formik.values.data?.content]);

  const handleChangeFile = (files) => {
    formik.setFieldValue("media_file", files[0]);
  };

  return (
    <StyledPRContainer
      description={"Here you can create and manage your Whatsapp Template Messages for your Whatsapp Business Account."}
      name="Contact Center"
      parentName={parentName}
    >
      {id && (
        <PRAlert color="warning">
          You can edit this template only once in 24 hours. Please ensure that you have entered the correct information.
        </PRAlert>
      )}
      <PRPage>
        <Row className="g-2">
          <Col md="6" xs="12">
            <Label>Name</Label>
            <PRInput
              disabled={!!id}
              invalid={formik.touched.data?.elementName && formik.errors.data?.elementName}
              name="data.elementName"
              placeholder="Ex: order_shipment_status"
              value={formik.values.data?.elementName}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
            />
          </Col>
          <Col md="6" xs="12">
            <div>
              <Label>Vertical</Label>
              <PRTooltip title="This field specifies the industry or sector your message template is targeting. Selecting the right vertical helps categorize your template more accurately, ensuring it aligns with the specific use case and audience relevant to your business. This can be crucial for WhatsApp's understanding and approval of your template.">
                <span>
                  <MdHelp className="fs-5 ms-1" />
                </span>
              </PRTooltip>
            </div>
            <PRInput
              disabled={!!id}
              invalid={formik.touched.data?.vertical && formik.errors.data?.vertical}
              name="data.vertical"
              placeholder="Ex: ECommerce, TechSupport"
              value={formik.values.data?.vertical}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
            />
          </Col>
          <Col md="6" xs="12">
            <Label>Category</Label>
            <PRSelect
              isPrimitiveValue
              invalid={formik.touched.data?.category && formik.errors.data?.category}
              isDisabled={!!id}
              name="data.category"
              options={chatbotGupshupCategoryOptions}
              placeholder="Select category"
              value={formik.values.data?.category}
              onBlur={formik.handleBlur}
              onChange={handleChangeSelect("data.category")}
            />
          </Col>
          <Col md="6" xs="12">
            <Label>Type</Label>
            <PRSelect
              isPrimitiveValue
              invalid={formik.touched.data?.templateType && formik.errors.data?.templateType}
              isDisabled={!!id}
              name="data.templateType"
              options={chatbotGupshupTemplateTypeOptions}
              placeholder="Select type"
              value={formik.values.data?.templateType}
              onBlur={formik.handleBlur}
              onChange={handleChangeSelect("data.templateType")}
            />
          </Col>
          <Col lg="12">
            <Label>Language</Label>
            <PRSelect
              isPrimitiveValue
              invalid={formik.touched.data?.languageCode && formik.errors.data?.languageCode}
              isDisabled={!!id}
              name="data.languageCode"
              options={chatbotGupshupLanguageOptions}
              placeholder="Select language"
              value={formik.values.data?.languageCode}
              onBlur={formik.handleBlur}
              onChange={handleChangeSelect("data.languageCode")}
            />
          </Col>
          {formik.values.data?.category !== chatbotGupshupCategory.AUTHENTICATION && (
            <>
              <Col xs="12">
                <Label>Header</Label>
                <PRInput
                  invalid={formik.touched.data?.header && formik.errors.data?.header}
                  name="data.header"
                  placeholder="Hello, {{1}}"
                  value={formik.values.data?.header}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                />
              </Col>
              <Col xs="12">
                <div>
                  <Label>Example Header</Label>
                  <PRTooltip title="Use this to provide a sample header for your template, helping in WhatsApp's approval process by showing a preview of the template's design">
                    <span>
                      <MdHelp className="fs-5 ms-1" />
                    </span>
                  </PRTooltip>
                </div>
                <PRInput
                  invalid={formik.touched.data?.exampleHeader && formik.errors.data?.exampleHeader}
                  name="data.exampleHeader"
                  placeholder="Hello, [John]"
                  value={formik.values.data?.exampleHeader}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                />
              </Col>
            </>
          )}
          <Col lg="12">
            <Label>Body</Label>
            <PRTextArea
              invalid={formik.touched.data?.content && formik.errors.data?.content}
              name="data.content"
              placeholder={`Hello, {{1}}.\n\nHow are you?`}
              rows={5}
              value={formik.values.data?.content}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
            />
          </Col>
          <Col lg="12">
            <div>
              <Label>Example Body</Label>

              <PRTooltip title="Enter a representative message to illustrate how your template will be used, aiding in WhatsApp's approval by demonstrating its practical application.">
                <span>
                  <MdHelp className="fs-5 ms-1" />
                </span>
              </PRTooltip>
            </div>
            <PRTextArea
              invalid={formik.touched.data?.example && formik.errors.data?.example}
              name="data.example"
              placeholder={`Hello, [John].\n\nHow are you?`}
              rows={5}
              value={formik.values.data?.example}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
            />
          </Col>
          {[
            chatbotGupshupTemplateType.IMAGE,
            chatbotGupshupTemplateType.VIDEO,
            chatbotGupshupTemplateType.DOCUMENT,
          ].includes(formik.values.data?.templateType) && (
            <Col xs="12">
              <Label>Example media</Label>
              {!id && (
                <PRDropZone
                  accept={
                    formik.values.data?.templateType === chatbotGupshupTemplateType.IMAGE
                      ? ["image/png", "image/jpeg"]
                      : formik.values.data?.templateType === chatbotGupshupTemplateType.VIDEO
                      ? ["video/*"]
                      : []
                  }
                  invalid={formik.touched?.media_file && formik.errors?.media_file}
                  onFileChange={handleChangeFile}
                />
              )}
              {loadedMediaFileItem && (
                <div className="mt-2">
                  {formik.values.data?.templateType === chatbotGupshupTemplateType.IMAGE && (
                    <img alt="template" height={300} src={whatsappTemplate?.media_url} width={"auto"} />
                  )}
                  {formik.values.data?.templateType === chatbotGupshupTemplateType.VIDEO && (
                    <ReactPlayer controls height={300} url={whatsappTemplate?.media_url} width={"auto"} />
                  )}
                  {formik.values.data?.templateType === chatbotGupshupTemplateType.DOCUMENT && (
                    <a href={whatsappTemplate?.media_url} rel="noreferrer" target="_blank">
                      {whatsappTemplate?.media_url}
                    </a>
                  )}
                </div>
              )}
            </Col>
          )}
          {formik.values.data?.category !== chatbotGupshupCategory.AUTHENTICATION && (
            <Col xs="12">
              <Label>Footer</Label>
              <PRInput
                invalid={formik.touched.data?.footer && formik.errors.data?.footer}
                name="data.footer"
                placeholder="Cheers, {{1}}"
                value={formik.values.data?.footer}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
              />
            </Col>
          )}
          {formik.values.data?.category !== chatbotGupshupCategory.AUTHENTICATION && (
            <Col lg="12">
              <div className="d-flex align-items-center justify-content-between">
                <Label>Buttons</Label>
                <PRButton
                  color="success-600"
                  disabled={formik.values.data?.buttons?.length >= 10}
                  icon={MdAdd}
                  size="sm"
                  onClick={handleClickAddButton}
                />
              </div>
              {formik.values.data?.buttons?.map((item, index) => {
                const handleChangePhoneNumber = async (data, phoneObj) => {
                  formik.setFieldValue(`data.buttons.${index}.phone_number`, phoneObj.numberValue);
                };
                const handleRemoveButton = () => {
                  const buttons = [...(formik.values.data?.buttons || []).filter((_, i) => i !== index)];
                  formik.setFieldValue("data.buttons", buttons);
                };
                return (
                  <Row key={index} className="g-2 whatsapp-buttons">
                    <Col className="gap-2 d-flex align-items-end justify-content-between w-100" md="12" xs="12">
                      <div className="w-100">
                        <div className="d-flex align-items-center justify-content-between">
                          <Label>Type</Label>
                          <PRButton outline color="danger" icon={MdDelete} size="sm" onClick={handleRemoveButton} />
                        </div>
                        <PRSelect
                          isPrimitiveValue
                          className="w-md-50"
                          invalid={formik.errors.data?.buttons?.[index]?.type}
                          isClearable={false}
                          name={`data.buttons.${index}.type`}
                          options={chatbotGupshupButtonTypeOptions}
                          placeholder="Select type"
                          value={item.type}
                          onBlur={formik.handleBlur}
                          onChange={handleChangeSelect(`data.buttons.${index}.type`)}
                        />
                      </div>
                    </Col>
                    {item.type === chatbotGupshupButtonType.URL && (
                      <Col md xs="12">
                        <Label>URL</Label>
                        <PRInput
                          invalid={formik.errors.data?.buttons?.[index]?.url}
                          name={`data.buttons.${index}.url`}
                          placeholder="https://example.com/foo/{{1}}"
                          value={item.url}
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                        />
                      </Col>
                    )}
                    {item.type === chatbotGupshupButtonType.PHONE_NUMBER && (
                      <Col md xs="12">
                        <Label>Phone Number</Label>
                        <div className="w-md-50">
                          <PalPhoneNumber
                            fullWidth
                            reactstrapMode
                            invalid={formik.errors.data?.buttons?.[index]?.phone_number}
                            name={`data.buttons.${index}.phone_number`}
                            size="small"
                            value={item.phone_number}
                            onBlur={formik.handleBlur}
                            onChange={handleChangePhoneNumber}
                          />
                        </div>
                      </Col>
                    )}
                    {(item.type === chatbotGupshupButtonType.QUICK_REPLY ||
                      item.type === chatbotGupshupButtonType.URL) && (
                      <Col md="12" xs="12">
                        <Label>Text</Label>
                        <PRInput
                          invalid={formik.errors.data?.buttons?.[index]?.text}
                          name={`data.buttons.${index}.text`}
                          placeholder="Enter text"
                          value={item.text}
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                        />
                      </Col>
                    )}
                  </Row>
                );
              })}
              <PRFormFeedback
                invalid={typeof formik.errors.data?.buttons === "string" && formik.errors.data?.buttons}
              />
            </Col>
          )}
          {formik.values.data?.category === chatbotGupshupCategory.AUTHENTICATION && (
            <Col lg="12">
              <Label>Add security recommendation</Label>
              <PRInput
                checked={formik.values.data?.addSecurityRecommendation}
                invalid={
                  formik.touched.data?.addSecurityRecommendation && formik.errors.data?.addSecurityRecommendation
                }
                name="data.addSecurityRecommendation"
                type="checkbox"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
              />
            </Col>
          )}
          <Col lg="12">
            <Label>Allow template category change</Label>
            <PRInput
              checked={formik.values.data?.allowTemplateCategoryChange}
              disabled={!!id}
              invalid={
                formik.touched.data?.allowTemplateCategoryChange && formik.errors.data?.allowTemplateCategoryChange
              }
              name="data.allowTemplateCategoryChange"
              type="checkbox"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
            />
          </Col>
        </Row>

        <Row className="justify-content-end mt-2">
          <Col xs="auto">
            <PRButton outline className="me-2" onClick={handleClickCancel}>
              Cancel
            </PRButton>
            <PRButton onClick={formik.handleSubmit}>{id ? "Update" : "Create"}</PRButton>
          </Col>
        </Row>
      </PRPage>
    </StyledPRContainer>
  );
};

export default WhatsappTemplateMessageEdit;
