import { Fragment, useEffect, useMemo, useState } from "react";

import {
  PalAutoComplete,
  PalButton,
  PalDatePicker,
  PalShadowWrapper,
  PalTextField,
  PalTimePicker,
  PalTooltip,
  PalTypography,
} from "@palamar/fe-library";
import { withCardon } from "cardon";
import { useFormik } from "formik";
import moment from "moment-timezone";
import { MdAdd, MdBlock, MdDelete, MdEdit } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { Col, Row } from "reactstrap";
import * as Yup from "yup";

import styled from "@emotion/styled";
import { TextField, Box, Grid, Typography, Paper, useMediaQuery } from "@mui/material";
import { PickersDay } from "@mui/x-date-pickers";

import useLoading from "~common/hooks/useLoading";
import PRButton from "~components/Generic/PRButton";
import PRContainer from "~components/Generic/PRContainer";
import PRModal from "~components/Generic/PRModal";
import PRTooltip from "~components/Generic/PRTooltip";
import { organizationVisibilityType } from "~constants";
import DateHelper from "~helpers/DateHelper";
import debounceAsync from "~helpers/debounceAsync";
import DialogHelper from "~helpers/DialogHelper";
import {
  addBlacklistResourceUser,
  addOrUpdateResourceSlot,
  deleteResourceSlot,
  getReservationMember,
  getReservationMembers,
  getResource,
  postReservationAction,
  removeBlacklistResourceUser,
} from "~store/organization/actions";
import { selectMemberFieldFormat } from "~store/organization/selectors";
import { selectCurrentProject } from "~store/user/selectors";

import { AttendanceButton } from "./AddEditReservationSlotModal";
import { CustomerListCellRenderer } from "../Customer/CustomerList";

const StyledReservationCol = styled(Col)`
  display: flex;
  flex-direction: column;
`;

const AvailableReservationIndicator = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  width: 6px;
  height: 6px;
  min-width: 6px;
  min-height: 6px;
  border-radius: 50%;
  background-color: ${(props) =>
    props.full ? props.theme.palette.error.main : props.available ? props.theme.palette.success.main : "transparent"};
`;

const PaperWithShadow = styled(Paper)`
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
`;
const BarContainer = styled.div`
  display: flex;
  width: 100%;
  height: ${({ largeMode }) => (largeMode ? "52px" : "30px")};
  border: 1px solid #ccc;
  position: relative;
  overflow: auto;
`;

const TimeBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px;
  height: 100%;
  min-width: ${({ largeMode }) => (largeMode ? "52px" : "24px")};
  background-color: ${({ available, isCurrent, isGap, theme }) =>
    isGap
      ? "#ccc"
      : isCurrent
      ? theme.palette.primary.main
      : available
      ? theme.palette.success.main
      : theme.palette.error.main};
  flex-grow: ${({ duration }) => duration};
  position: relative;
  color: white;
  font-size: 12px;
  border-right: 1px solid #ccc;
  text-align: center;
  ${(props) =>
    props.isBlocking &&
    `
  background-color: inherit;
  background-image: repeating-linear-gradient(
        45deg,
         var(--bs-orange-700),
         var(--bs-orange-700) 10px,
        var(--bs-gray-700) 10px,
        var(--bs-gray-700) 20px
    );
  `}
  ${(props) =>
    props.isBlocking &&
    props.isCurrent &&
    `
  background-color: inherit;
  background-image: repeating-linear-gradient(
        45deg,
         var(--bs-primary),
         var(--bs-primary) 10px,
        var(--bs-gray-700) 10px,
        var(--bs-gray-700) 20px
    );
  `}
`;

const TimeAvailabilityBar = ({ intervals, reservation }) => {
  const isLgDown = useMediaQuery((theme) => theme.breakpoints.down("lg"));
  const sortedIntervals = useMemo(() => {
    if (!intervals?.length) return null;
    return intervals.sort((a, b) => a?.start_date.diff(b?.start_date));
  }, [intervals]);

  const [minStart, maxEnd] = useMemo(() => {
    const minStart = sortedIntervals?.[0]?.start_date;
    const maxEnd = sortedIntervals?.[sortedIntervals.length - 1]?.end_date;
    return [minStart, maxEnd];
  }, [sortedIntervals]);

  if (!intervals?.length) return null;

  const blocks = [];

  for (let i = 0; i < sortedIntervals.length; i++) {
    const interval = sortedIntervals[i];
    const start = moment(interval.start_date);
    const end = moment(interval.end_date);
    const startMinutes = start.diff(minStart, "minutes");
    const endMinutes = end.diff(minStart, "minutes");
    const duration = endMinutes - startMinutes;

    const isCurrent =
      reservation &&
      start.isSameOrAfter(moment(reservation.start_date)) &&
      end.isSameOrBefore(moment(reservation.end_date));

    const isBlocking = interval.isBlocking;
    if (i > 0) {
      const previousEnd = moment(sortedIntervals[i - 1].end_date);
      const gapDuration = start.diff(previousEnd, "minutes");
      if (gapDuration > 0) {
        blocks.push(
          <PRTooltip key={`gap-${i}`} title={`No Slot Available`}>
            <TimeBlock key={`gap-${i}`} duration={gapDuration} isGap={true}>
              {`${previousEnd.format("HH:mm")} ${isLgDown ? "" : "-"} ${start.format("HH:mm")}`}
            </TimeBlock>
          </PRTooltip>
        );
      }
    }

    blocks.push(
      <PRTooltip
        key={`gap-${i}`}
        title={
          isCurrent
            ? `Current Slot`
            : isBlocking
            ? `Blocked Slot`
            : interval.available
            ? `Available Slot`
            : `Reserved Slot`
        }
      >
        <TimeBlock
          key={i}
          available={interval.available}
          duration={duration}
          isBlocking={isBlocking}
          isCurrent={isCurrent}
          largeMode={isLgDown}
        >
          {`${start.format("HH:mm")} ${isLgDown ? "" : "-"} ${end.format("HH:mm")}`}
        </TimeBlock>
      </PRTooltip>
    );
  }

  return (
    <div style={{ position: "relative" }}>
      <BarContainer largeMode={isLgDown}>{blocks}</BarContainer>
    </div>
  );
};

function CustomDatePicker({ fetchEvents, ...rest }) {
  const [events, setEvents] = useState([]);
  const [_isOpen, setIsOpen] = useState(false);
  const [initialFetch, setInitialFetch] = useState(false);

  const handleMonthChange = async (date) => {
    const startOfMonth = date.clone().startOf("month");
    const endOfMonth = date.clone().endOf("month").add(-1, "second");

    const response = await fetchEvents({
      start: startOfMonth,
      end: endOfMonth,
    });
    setEvents(response);
  };

  const renderDay = (props) => {
    // const propsDate = DateHelper.getDateTime(props.day);
    // propsDate.utcOffset(0, true);
    const dynamicSlots = events.filter((app) => props.day.isSame(app.start_date, "day"));

    const slots = dynamicSlots.filter((event) => !event.dynamic);
    const reservations = dynamicSlots.filter((event) => event.dynamic);

    const totalSlotMinutes = slots.reduce((acc, slot) => {
      return acc + slot.end_date.diff(slot.start_date, "minutes");
    }, 0);

    const totalReservationMinutes = reservations.reduce((acc, res) => {
      return acc + res.end_date.diff(res.start_date, "minutes");
    }, 0);

    const totalMinutes = totalSlotMinutes - totalReservationMinutes;

    const isAvailable = totalMinutes > 0;
    const hasReservationAndFull = reservations.length && totalReservationMinutes === totalSlotMinutes;

    const tooltipText = `Total Available Time: ${DateHelper.formatMoment(totalMinutes, "minutes", "auto")}`;

    const WrapperComp = isAvailable || hasReservationAndFull ? PalTooltip : Fragment;
    return (
      <WrapperComp PopperProps={{ style: { zIndex: 9000 } }} title={tooltipText}>
        <Box position="relative">
          <PickersDay {...props} />
          <AvailableReservationIndicator available={isAvailable} full={hasReservationAndFull} />
        </Box>
      </WrapperComp>
    );
  };

  const handleChangeOpenOrClose = (isOpen) => () => {
    setIsOpen(isOpen);
    if (isOpen && !initialFetch) {
      handleMonthChange(moment());
      setInitialFetch(true);
    }
  };

  return (
    <PalDatePicker
      {...rest}
      renderDay={renderDay}
      renderInput={(params) => <TextField {...params} />}
      slots={{
        day: renderDay,
        desktopPaper: PaperWithShadow,
      }}
      onClose={handleChangeOpenOrClose(false)}
      onMonthChange={handleMonthChange}
      onOpen={handleChangeOpenOrClose(true)}
    />
  );
}

function DynamicSlotAddEditContent({ editMode, date, resource, slot, fetchEvents, reservations, get }) {
  const dispatch = useDispatch();
  const currentProject = useSelector(selectCurrentProject);
  const [isModified, setIsModified] = useState(false);
  const [viewingSlots, setViewingSlots] = useState([]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: slot?.id,
      resource: resource.id,
      available_slots: 1,
      start_date: slot?.start_date,
      end_date: slot?.end_date,
      slot_date: DateHelper.getDateTimeLocal(date),
    },
    validationSchema: Yup.object().shape({
      start_date: Yup.date().required("Start date is required"),
      end_date: Yup.date().required("End date is required"),
    }),
    onSubmit: async (values) => {
      //   let startDate = values.start_date;
      //   let endDate = values.end_date;

      const baseDate = moment(formik.values.slot_date);
      const newStartDate = baseDate.clone().set({
        hour: values.start_date.hour(),
        minute: values.start_date.minute(),
      });
      const newEndDate = baseDate.clone().set({
        hour: values.end_date.hour(),
        minute: values.end_date.minute(),
      });

      const payload = {
        ...values,
        start_date: DateHelper.format(newStartDate),
        end_date: DateHelper.format(newEndDate),
      };
      let slotList = viewingSlots;
      if (slot) {
        slotList = slotList.filter((item) => item.id !== slot.id);
      }

      const overlappingSlot = slotList.find((item) => {
        return (
          (item.start_date.isBetween(newStartDate, newEndDate, null, "(]") ||
            item.end_date.isBetween(newStartDate, newEndDate, null, "(]") ||
            newStartDate.isBetween(item.start_date, item.end_date, null, "(]") ||
            newEndDate.isBetween(item.start_date, item.end_date, null, "(]")) &&
          (editMode ? slot && item.id !== slot.id : true)
        );
      });
      if (overlappingSlot) {
        DialogHelper.showOk(
          `The selected slot overlaps with another slot from ${overlappingSlot.start_date.format(
            "LLL"
          )} to ${overlappingSlot.end_date.format("LLL")}. Please choose a different timeframe.`
        );

        return;
      }

      try {
        await dispatch(addOrUpdateResourceSlot(payload, resource.id, currentProject.id));
        setIsModified(true);
        get(true)();
      } catch {}
    },
  });

  useEffect(() => {
    (async () => {
      const currentDate = formik.values.slot_date;
      let beginOfDay = DateHelper.getDateTimeLocal(currentDate).startOf("day");
      let endOfDay = DateHelper.getDateTimeLocal(currentDate).endOf("day").subtract(1, "second");
      //   if (!editMode) {
      //   beginOfDay = reservationDateRange.start;
      //   endOfDay = reservationDateRange.end;

      endOfDay.utcOffset(0, true);
      beginOfDay.utcOffset(0, true);

      //   endOfDay.utcOffset(moment().utcOffset(), true);
      //   beginOfDay.utcOffset(moment().utcOffset(), true);
      //   } else {
      //     endOfDay.utcOffset(0, true);
      //     beginOfDay.utcOffset(0, true);
      //   }

      const newEvents = await fetchEvents({
        start: beginOfDay,
        end: endOfDay,
      });
      const slots = newEvents.filter((event) => !event.dynamic);
      //   const reservations = newEvents.filter((event) => event.dynamic);
      //   const newList = splitSlots(slots, reservations);

      if (!editMode && slots?.length) {
        const lastSlotsEndDate = slots[slots.length - 1].end_date;
        formik.setFieldValue("start_date", lastSlotsEndDate.clone());
      }
      setViewingSlots(slots);
    })();
  }, [formik.values.slot_date, editMode]);

  const handleClickSubmit = async () => {
    formik.handleSubmit();
  };
  const handleChangeDateSelection = (date) => {
    formik.setFieldValue("slot_date", date);
  };
  return (
    <PRModal
      size="lg"
      submitText={editMode ? "Update" : "Create"}
      title={editMode ? "Edit Slot" : "Add Slot"}
      onClick={handleClickSubmit}
      onClose={get(isModified || false)}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <PalTypography fontWeight={"bold"} variant="body1">
            Date
          </PalTypography>
          {!editMode && (
            <CustomDatePicker
              fullWidth
              fetchEvents={fetchEvents}
              invalid={formik.touched.slot_date && formik.errors.slot_date}
              name="slot_date"
              placeholder="Select Start Time"
              value={formik.values.slot_date}
              onChange={handleChangeDateSelection}
            />
          )}
        </Grid>
        {!!viewingSlots?.length && (
          <>
            <Grid item xs={12}>
              <PalTypography fontWeight={"bold"} variant="body1">
                Existing Slots
              </PalTypography>
            </Grid>
            <Grid item xs={12}>
              <TimeAvailabilityBar intervals={viewingSlots} />
            </Grid>
          </>
        )}
        <Grid item xs={6}>
          <PalTypography fontWeight={"bold"} variant="body1">
            Start Time
          </PalTypography>
          <PalTimePicker
            fullWidth
            invalid={formik.touched.start_date && formik.errors.start_date}
            name="start_date"
            placeholder="Select Start Time"
            value={formik.values.start_date}
            onChange={(date) => formik.setFieldValue("start_date", date)}
          />
        </Grid>
        <Grid item xs={6}>
          <PalTypography fontWeight={"bold"} variant="body1">
            End Time
          </PalTypography>
          <PalTimePicker
            fullWidth
            invalid={formik.touched.end_date && formik.errors.end_date}
            name="end_date"
            placeholder="Select End Time"
            value={formik.values.end_date}
            onChange={(date) => formik.setFieldValue("end_date", date)}
          />
        </Grid>
      </Grid>
    </PRModal>
  );
}
const DynamicSlotAddEditModal = withCardon(DynamicSlotAddEditContent, {
  destroyOnHide: true,
});

function AddOrEditDynamicReservation({
  // hasCreateAction,
  // hasEditAction,
  editMode,
  get,
  resource: resourceProp,
  fetchEvents,
  slot,
  reservation: reservationProp,
  slotActionsInfo,
  reservationDateRange,
  isBlocking,
}) {
  const dispatch = useDispatch();
  const currentProject = useSelector(selectCurrentProject);
  //   const [activeDate, setActiveDate] = useState(slot.start_date.startOf("day"));
  //   const [dateState, setDateState] = useState({
  //     start: DateHelper.getDateTime(date).startOf("day").toDate(),
  //     end: DateHelper.getDateTime(date).endOf("day").toDate(),
  //   });
  const [slotsAndReservations, setSlotsAndReservations] = useState([]);
  const [selectedMember, setSelectedMember] = useState(null);
  const [loadingMember, qMember] = useLoading();
  const [customErrors, setCustomErrors] = useState({});
  const [resource, setResource] = useState(resourceProp);
  const [reservation, setReservation] = useState(reservationProp);

  const [triggerFetchEvents, setTriggerFetchEvents] = useState(1);
  const [isModified, setIsModified] = useState(false);

  const memberFieldFormat = useSelector(selectMemberFieldFormat);
  const memberDataFields = memberFieldFormat?.member_data_fields;

  const orderedPublicFields = useMemo(() => {
    const publicFields = memberDataFields.filter((item) => item.visibility_type === organizationVisibilityType.PUBLIC);

    const orderedPublicFields = [...publicFields].sort((a, b) => a.order - b.order);
    return orderedPublicFields;
  }, [memberDataFields]);

  const fetchResource = async () => {
    const newResource = await dispatch(getResource(resource?.id, currentProject.id));
    setResource(newResource);
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: reservation?.id,
      member: reservation?.reserver?.id,
      reservation_type: reservation?.secret_note?.name || null,
      note: reservation?.note,
      reservation_date: slot.start_date.startOf("day"),
      reservation_start_time:
        reservation?.start_date &&
        (() => {
          const d = DateHelper.getDateTimeLocal(reservation?.start_date);
          d.utcOffset(0, true);
          return d;
        })(),
      reservation_end_time:
        reservation?.end_date &&
        (() => {
          const d = DateHelper.getDateTimeLocal(reservation?.end_date);
          d.utcOffset(0, true);
          return d;
        })(),
    },

    validate: (values) => {
      const errors = {};
      //use customErrors to show custom error messages
      if (customErrors) {
        Object.keys(customErrors).forEach((key) => {
          if (customErrors[key]) {
            errors[key] = customErrors[key];
          }
        });
      }
      return errors;
    },

    validationSchema: Yup.object().shape({
      // member: Yup.number().nullable().required("Member is required"),
      member: Yup.number()
        .nullable()
        .when({
          is: () => !isBlocking,
          then: Yup.number().required("Member is required"),
          otherwise: Yup.number().nullable(),
        }),
      reservation_start_time: Yup.mixed()
        .required("Reservation start time is required")
        .test("minTime", `Time must be greater than ${reservationDateRange.start.format("LT")}`, (value) => {
          if (!value || isBlocking) return true;
          return value.isAfter(reservationDateRange.start) || value.isSame(reservationDateRange.start);
        }),
      reservation_end_time: Yup.mixed()
        .required("Reservation end time is required")
        .test("differentStartEndTime", "Start time and end time cannot be the same", function (value) {
          if (!value) return true;

          const start = formik.values.reservation_start_time;
          if (!start || !value) return true;
          return !start.isSame(value);
        })
        .test("maxTime", `Time must be less than ${reservationDateRange.end.format("LT")}`, (value) => {
          if (!value || isBlocking) return true;
          return value.isBefore(reservationDateRange.end) || value.isSame(reservationDateRange.end);
        }),

      reservation_type: Yup.string().when({
        is: () => !isBlocking,
        then: Yup.string().when({
          is: () => slotActionsInfo.reservation_type_required && slotActionsInfo.reservation_type_enabled,
          then: Yup.string().required("Reservation type is required").nullable(),
          otherwise: Yup.string()
            .optional()
            .test(
              "is-reservation-time-in-range",
              "Selected reservation operation time is longer than available slot time",
              (value) => {
                if (!slotActionsInfo.reservation_type_list?.length) return true;
                const selectedReservationType = slotActionsInfo.reservation_type_list?.find(
                  (item) => item.name === value
                );
                if (!selectedReservationType) return true;
                const start = formik.values.reservation_start_time;
                const end = formik.values.reservation_end_time;
                if (!start || !end) {
                  return true;
                }
                const slotStart = reservationDateRange.start;
                const slotEnd = reservationDateRange.end;
                const reservationTime = moment.duration(end.diff(start));
                const slotTime = moment.duration(slotEnd.diff(slotStart));
                return reservationTime.asMinutes() <= slotTime.asMinutes();
              }
            )
            .nullable(),
        }),
        otherwise: Yup.string().optional().nullable(),
      }),
    }),

    onSubmit: async (values) => {
      try {
        const selectedReservationTypeObj = slotActionsInfo.reservation_type_list?.find(
          (item) => item.name === values.reservation_type
        );
        let startDateWithOffset = values.reservation_start_time.clone();
        let endDateWithOffset = values.reservation_end_time.clone();

        startDateWithOffset.utcOffset(moment().utcOffset(), true);
        endDateWithOffset.utcOffset(moment().utcOffset(), true);

        if (editMode || isBlocking) {
          //get reservation_date as base and add time to it
          const baseDate = moment(formik.values.reservation_date);
          //   baseDate.utcOffset(0, true);

          const newStartDate = baseDate.clone().set({
            hour: startDateWithOffset.hour(),
            minute: startDateWithOffset.minute(),
          });
          const newEndDate = baseDate.clone().set({
            hour: endDateWithOffset.hour(),
            minute: endDateWithOffset.minute(),
          });

          startDateWithOffset = newStartDate;
          endDateWithOffset = newEndDate;
        }
        const payload = {
          button_type: editMode ? "edit" : isBlocking ? "block_slot" : "create",
          payload: {
            ...(reservation?.id && { reservation_id: reservation.id }),
            ...(!editMode && { member_id: values.member }),
            note: values.note,
            start_date: startDateWithOffset,
            end_date: endDateWithOffset,
            is_blocking: !!isBlocking,
            ...(slotActionsInfo.reservation_type_enabled && {
              secret_note: selectedReservationTypeObj,
            }),
          },
        };

        // if (isBlocking && editMode) {
        //   await dispatch(
        //     postReservationAction(currentProject.id, resource.id, {
        //       button_type: "cancel",
        //       payload: {
        //         reservation_id: reservation.id,
        //       },
        //     })
        //   );
        // }
        await dispatch(postReservationAction(currentProject.id, resource.id, payload));

        get(true)();
      } catch {}
    },
  });

  useEffect(() => {
    (async () => {
      const currentDate = formik.values.reservation_date;
      let beginOfDay = DateHelper.getDateTimeLocal(currentDate).startOf("day");
      let endOfDay = DateHelper.getDateTimeLocal(currentDate).endOf("day").subtract(1, "second");
      if (!editMode) {
        beginOfDay = reservationDateRange.start;
        endOfDay = reservationDateRange.end;

        endOfDay.utcOffset(moment().utcOffset(), true);
        beginOfDay.utcOffset(moment().utcOffset(), true);
      } else {
        endOfDay.utcOffset(0, true);
        beginOfDay.utcOffset(0, true);
      }

      const newEvents = await fetchEvents({
        start: beginOfDay,
        end: endOfDay,
      });
      const slots = newEvents.filter((event) => !event.dynamic).sort((a, b) => a.start_date - b.start_date);
      const reservations = newEvents.filter((event) => event.dynamic).sort((a, b) => a.start_date - b.start_date);
      function getFirstAvailableReservationRange(slots, reservations) {
        for (const slot of slots) {
          const slotStart = slot.start_date.clone();
          const slotEnd = slot.end_date.clone();

          let currentStart = slotStart;

          for (const reservation of reservations) {
            const resStart = reservation.start_date;
            const resEnd = reservation.end_date;

            if (resStart.isBefore(slotEnd) && resEnd.isAfter(slotStart)) {
              if (currentStart.isBefore(resStart)) {
                return { start_date: currentStart, end_date: resStart.clone() };
              }
              currentStart = resEnd.clone();
            }
          }

          if (currentStart.isBefore(slotEnd)) {
            return { start_date: currentStart, end_date: slotEnd.clone() };
          }
        }
        return null;
      }
      const availableRange = getFirstAvailableReservationRange(slots, reservations);

      let availableStartTime = availableRange ? availableRange.start_date : slots[0]?.start_date.clone();
      let availableEndTime = availableRange ? availableRange.end_date : slots[0]?.start_date.clone();

      if (!editMode && availableStartTime) {
        formik.setFieldValue("reservation_start_time", availableStartTime);
        formik.setFieldValue("reservation_end_time", availableEndTime);
      }

      const newList = splitSlots(slots, reservations);
      setSlotsAndReservations(newList);
    })();
  }, [formik.values.reservation_date, reservationDateRange, editMode, triggerFetchEvents]);

  const handleClickSubmit = async () => {
    //set all touched
    await formik.setTouched(
      Object.keys(formik.initialValues).reduce((acc, key) => {
        acc[key] = true;
        return acc;
      }, {})
    );
    formik.handleSubmit();
    // get(false)();
  };

  useEffect(() => {
    if (formik.values.member && formik.values.member !== selectedMember?.id) {
      qMember(
        dispatch(
          getReservationMember(currentProject.id, formik.values.member, {
            loading: false,
            onSuccess: () => {},
          })
        )
      ).then((member) => {
        setSelectedMember(member);
      });
    }
  }, [dispatch, currentProject.id, formik.values.member, selectedMember?.id, qMember]);

  const handleLoadOptions = debounceAsync(async (searchText, callback, signal) => {
    const memberList = await dispatch(
      getReservationMembers(
        currentProject.id,
        {
          limit: 20,
          email__icontains: searchText,
        },
        {
          signal,
          loading: false,
        }
      )
    );
    const options = memberList.results.map((item) => {
      return {
        value: item.id,
        label: item.safe_information?.email,
      };
    });
    if (formik.values.member && !options.find((item) => item.value === formik.values.member)) {
      const selectedMember = await dispatch(
        getReservationMember(currentProject.id, formik.values.member, {
          loading: false,
          onSuccess: () => {},
        })
      );
      if (selectedMember) {
        options.push({
          value: selectedMember.id,
          label: selectedMember.safe_information?.email,
        });
      }
    }
    return options;
  });

  const handleChangeMember = (e, value) => {
    formik.setFieldValue("member", value);
  };

  const startTimeMaxTime = useMemo(() => {
    if (!formik.values.reservation_type) return reservationDateRange.end;
    const reservationType = slotActionsInfo.reservation_type_list?.find(
      (item) => item.name === formik.values.reservation_type
    );
    const time = moment.duration(reservationType?.islem_suresi_dk, "minutes");
    return reservationDateRange.end.clone().subtract(time);
  }, [formik.values.reservation_type, slotActionsInfo.reservation_type_list, reservationDateRange.end]);

  const handleChangeReservationType = (e, value) => {
    const reservationType = slotActionsInfo.reservation_type_list?.find((item) => item.name === value);
    if (reservationType) {
      const time = moment.duration(reservationType?.islem_suresi_dk, "minutes");
      const potentialMaxReservationMinutes = reservationDateRange.end.diff(reservationDateRange.start, "minutes");
      if (time.asMinutes() > potentialMaxReservationMinutes) {
        DialogHelper.showOk(
          `Reservation time is longer than available slot time. You can only reserve maximum ${DateHelper.formatMoment(
            potentialMaxReservationMinutes,
            "minutes",
            "auto"
          )}`
        );
        return;
      }
      if (formik.values.reservation_start_time) {
        formik.setFieldValue("reservation_end_time", formik.values.reservation_start_time.clone().add(time));
      } else {
        formik.setFieldValue("reservation_start_time", reservationDateRange.start.clone());
        formik.setFieldValue("reservation_end_time", reservationDateRange.start.clone().add(time));
      }
    }
    formik.setFieldValue("reservation_type", value);
  };
  const handleChangeNote = (e) => {
    formik.setFieldValue("note", e.target.value);
  };

  const handleChangeReservationStartEndTime = (key) => (value) => {
    formik.setFieldValue(key, value);

    if (
      key === "reservation_start_time" &&
      slotActionsInfo.reservation_type_enabled &&
      formik.values.reservation_type
    ) {
      const reservationType = slotActionsInfo.reservation_type_list?.find(
        (item) => item.name === formik.values.reservation_type
      );
      const time = moment.duration(reservationType?.islem_suresi_dk, "minutes");

      formik.setFieldValue("reservation_end_time", value.clone().add(time));
    }
  };

  const handleChangeStartOrEndTimeError = (key) => async (err, value) => {
    let errorText = null;
    let prefixTime = key === "reservation_start_time" ? "Start" : "End";

    console.log("err", err, "key", key, "minValue", reservationDateRange.start, "val", value);

    if (err === "minTime") {
      errorText = `${prefixTime} time must be greater than ${reservationDateRange.start.format("LT")}`;
    } else if (err === "maxTime") {
      const targetDateMax = key === "reservation_start_time" ? startTimeMaxTime : reservationDateRange.end;
      errorText = `${prefixTime} time must be less than ${targetDateMax.format("LT")}`;
    }
    setCustomErrors((prev) => ({ ...prev, [key]: errorText }));

    await formik.setFieldTouched(key, true, true);
    formik.setFieldError(key, errorText);
  };

  const handleChangeDateSelection = (date) => {
    formik.setFieldValue("reservation_date", date);
  };
  const reservationTypeOptions = useMemo(() => {
    if (!slotActionsInfo.reservation_type_list) return [];
    const options = slotActionsInfo.reservation_type_list.map((item) => {
      return {
        ...item,
        value: item.name,
        label: `${item.islem_adi}, ${item.fiyat} (${item.islem_suresi})`,
      };
    });
    return options;
  }, [slotActionsInfo]);

  const targetUserInfo = Object.entries(selectedMember?.safe_information || {}); // reservation?.customer || selectedMemberObj || {});

  const visibleSlotActions = useMemo(() => {
    const hideList = ["edit", "create", "reservation_type", "block_slot"];

    if (isBlocking) {
      hideList.push("add_blacklist");
      hideList.push("remove_blacklist");
      hideList.push("change_attended_status");
    }

    const filteredActions = slotActionsInfo?.action_list?.filter((action) => !hideList.includes(action.button_type));
    return filteredActions;
  }, [slotActionsInfo?.action_list, isBlocking]);
  const handleTriggerFetchEvents = () => {
    setTriggerFetchEvents((prev) => prev + 1);
    setIsModified(true);
  };

  const handleChangeAttendanceUpdated = (e, attendance_status) => {
    // handleTriggerFetchEvents();
    // fetchResource();
    setReservation((prev) => ({ ...prev, is_attended: attendance_status }));
    setIsModified(true);
  };

  const handleTriggerFetchMember = () => {
    setSelectedMember((prev) => ({ ...prev, id: null }));
    setIsModified(true);
  };
  const isInBlacklist = resource.blacklist?.some(
    (blacklist) => blacklist.id === (selectedMember?.id || reservation?.reserver?.id)
  );
  const handleSlotActionDynamic = (actionItem) => async () => {
    if (actionItem.button_type === "add_blacklist") {
      dispatch(addBlacklistResourceUser(currentProject.id, resource?.id, reservation?.reserver?.id)).then(async () => {
        await handleTriggerFetchEvents();
        await fetchResource();
        await handleTriggerFetchMember();
        setIsModified(true);
      });
    } else if (actionItem.button_type === "remove_blacklist") {
      dispatch(removeBlacklistResourceUser(currentProject.id, resource?.id, reservation?.reserver?.id)).then(
        async () => {
          await handleTriggerFetchEvents();
          await fetchResource();
          await handleTriggerFetchMember();
          setIsModified(true);
        }
      );
    } else {
      if (actionItem.ask_yes_no) {
        if (!(await DialogHelper.showQuestionYesNo(actionItem.ask_yes_no))) return;
      }
      await dispatch(
        postReservationAction(currentProject.id, resource?.id, {
          button_type: actionItem.button_type,
          payload: {
            ...actionItem?.payload,
            reservation_id: reservation.id,
            member_id: reservation?.reserver?.id,
          },
        })
      );

      handleTriggerFetchEvents();
      setIsModified(true);
      get(true)();
    }
  };

  const startTimeDay = formik.values.reservation_start_time;
  const endTimeDay = formik.values.reservation_end_time;

  return (
    <PRModal
      size="xl"
      submitText={isBlocking ? "Block" : editMode ? "Update" : "Create"}
      title={isBlocking ? "Block Reservation" : `${editMode ? "Update" : "Create"} Reservation`}
      onClick={handleClickSubmit}
      onClose={get(isModified || false)}
      // onDelete={reservation?.id ? handleClickDeleteReservation : undefined}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <PalTypography fontWeight={"bold"} variant="body1">
            Slot Availability for {formik.values.reservation_date.format("LL")}
          </PalTypography>
          {slotsAndReservations?.length ? (
            <TimeAvailabilityBar
              intervals={slotsAndReservations}
              newEndDate={formik.values.reservation_end_time}
              newStartDate={formik.values.reservation_start_time}
              reservation={reservation}
            />
          ) : (
            <Box alignItems="center" display="flex" height="40px" justifyContent="center">
              No slots available.
            </Box>
          )}
        </Grid>
        {slotActionsInfo.reservation_type_enabled && !isBlocking && (
          <Grid item xs={12}>
            <PalTypography fontWeight={"bold"} variant="body1">
              Reservation Type
            </PalTypography>

            <PalAutoComplete
              isPrimitiveValue
              invalid={formik.touched.reservation_type && formik.errors.reservation_type}
              name="reservation_type"
              options={reservationTypeOptions}
              placeholder="Select Reservation Type"
              value={formik.values.reservation_type}
              onChange={handleChangeReservationType}
            />
          </Grid>
        )}
        {editMode && (
          <Grid item xs={editMode ? 6 : 12}>
            <PalTypography fontWeight={"bold"} variant="body1">
              Date
            </PalTypography>
            {((editMode && formik.values.reservation_date && startTimeMaxTime) || !editMode) && (
              <CustomDatePicker
                fullWidth
                fetchEvents={fetchEvents}
                invalid={formik.touched.reservation_date && formik.errors.reservation_date}
                name="reservation_date"
                placeholder="Select Start Time"
                value={formik.values.reservation_date}
                onChange={handleChangeDateSelection}
              />
            )}
          </Grid>
        )}
        <Grid item xs={editMode ? 3 : 6}>
          <PalTypography fontWeight={"bold"} variant="body1">
            Start Time
          </PalTypography>
          {(((editMode || isBlocking) && formik.values.reservation_start_time && startTimeMaxTime) || !editMode) && (
            <PalTimePicker
              // disabled={editMode}
              disableIgnoringDatePartForTimeValidation
              fullWidth
              invalid={formik.touched.reservation_start_time && formik.errors.reservation_start_time}
              {...(!isBlocking && {
                maxTime: startTimeMaxTime,
                minTime: reservationDateRange.start,
              })}
              name="reservation_start_time"
              placeholder="Select Start Time"
              value={formik.values.reservation_start_time}
              onChange={handleChangeReservationStartEndTime("reservation_start_time")}
              onError={handleChangeStartOrEndTimeError("reservation_start_time")}
            />
          )}
        </Grid>
        <Grid item xs={editMode ? 3 : 6}>
          <PalTypography fontWeight={"bold"} variant="body1">
            End Time
          </PalTypography>

          {(((editMode || isBlocking) && formik.values.reservation_end_time && startTimeMaxTime) || !editMode) && (
            <PalTimePicker
              disableIgnoringDatePartForTimeValidation
              fullWidth
              //only allow to select start time if reservation_type is selected
              disabled={!slotActionsInfo?.reservation_type_enable_custom_time && !!formik.values.reservation_type}
              invalid={formik.touched.reservation_end_time && formik.errors.reservation_end_time}
              {...(!isBlocking && {
                maxTime: reservationDateRange.end,
                minTime: reservationDateRange.start,
              })}
              name="reservation_end_time"
              placeholder="Select End Time"
              value={formik.values.reservation_end_time}
              onChange={handleChangeReservationStartEndTime("reservation_end_time")}
              onError={handleChangeStartOrEndTimeError("reservation_end_time")}
            />
          )}
        </Grid>
        {/* <Grid item xs={12}>
          <Box alignItems="center" display="flex">
            <PalSwitch
              checked={formik.values.is_blocked}
              label="Block Reservation"
              name="is_blocked"
              onChange={(e) => formik.setFieldValue("is_blocked", e.target.checked)}
            />
            <Block />
            Block the reservation for the selected time.
          </Box>
        </Grid> */}
        {!!startTimeDay && !!endTimeDay && (
          <Grid item xs={12}>
            Reservation Duration: {DateHelper.formatMoment(endTimeDay.diff(startTimeDay, "minutes"), "minutes", "auto")}
          </Grid>
        )}
        {!isBlocking && (
          <>
            <Grid item xs={12}>
              <PalTypography fontWeight={"bold"} variant="body1">
                Member
              </PalTypography>
              <PalAutoComplete
                isPrimitiveValue
                lazy
                disabled={editMode}
                invalid={formik.touched.member && formik.errors.member}
                loadOptions={handleLoadOptions}
                name="member"
                placeholder="Select Member"
                value={formik.values.member}
                onChange={handleChangeMember}
              />
              {selectedMember && isInBlacklist && (
                <Typography color="error" variant="caption">
                  This member is in the blacklist.
                </Typography>
              )}
            </Grid>
            <Grid item xs={12}>
              <PalTypography fontWeight={"bold"} variant="body1">
                Member Information
              </PalTypography>
              <PRContainer bare loading={loadingMember}>
                {targetUserInfo?.length ? (
                  <Grid container columnSpacing={1} rowSpacing={1}>
                    {orderedPublicFields.map((field) => {
                      return (
                        <Grid key={field.name} item overflow="hidden" xs={6}>
                          <Box overflow="hidden">
                            <Typography noWrap component={"div"} fontWeight={"bold"} lineHeight={1} variant="caption">
                              {field.display_name || field.name}:
                            </Typography>
                          </Box>
                          <Typography noWrap component={"div"} variant="caption">
                            <CustomerListCellRenderer
                              cellKey={field.name}
                              memberDataFields={memberDataFields}
                              value={selectedMember?.safe_information?.[field.name]}
                            />
                          </Typography>
                        </Grid>
                      );
                    })}
                  </Grid>
                ) : (
                  <div className="text-muted text-center">No information</div>
                )}
              </PRContainer>
            </Grid>
          </>
        )}
        <Grid item xs={12}>
          <PalTypography fontWeight={"bold"} variant="body1">
            Note
          </PalTypography>
          <PalTextField
            fullWidth
            isPrimitiveValue
            multiline
            invalid={formik.touched.note && formik.errors.note}
            maxRows={6}
            minRows={3}
            name="note"
            placeholder="Enter note about reservation"
            value={formik.values.note}
            onChange={handleChangeNote}
          />
        </Grid>
        {editMode && (
          <Grid item xs={12}>
            <PalTypography fontWeight={"bold"} variant="body1">
              Actions
            </PalTypography>
            <Grid container spacing={1}>
              {visibleSlotActions?.length > 0 &&
                visibleSlotActions.map((actionItem) => {
                  const { icon: _, label, ...buttonProps } = actionItem?.button_props || {};
                  const ButtonComp =
                    actionItem?.button_type === "change_attended_status" ? AttendanceButton : PalButton;
                  return (
                    <Grid key={actionItem.button_type} item sm={"auto"} xs={12}>
                      <ButtonComp
                        fullWidth
                        size="small"
                        {...buttonProps}
                        key={actionItem.button_type}
                        variant="contained"
                        // tooltipText={label}
                        onClick={handleSlotActionDynamic(actionItem)}
                        {...(actionItem.button_type === "change_attended_status" && {
                          resource,
                          reservation: reservation,
                          onUpdated: handleChangeAttendanceUpdated,
                        })}
                        {...(actionItem?.button_type === "add_blacklist" && {
                          disabled: !reservation?.reserver?.id || isInBlacklist,
                        })}
                        {...(actionItem?.button_type === "remove_blacklist" && {
                          disabled: !reservation?.reserver?.id || !isInBlacklist,
                        })}
                      >
                        {label}
                      </ButtonComp>
                    </Grid>
                  );
                })}
            </Grid>
          </Grid>
        )}
      </Grid>
    </PRModal>
  );
}

const AddOrEditDynamicReservationModal = withCardon(AddOrEditDynamicReservation, {
  destroyOnHide: true,
});

const StyledReservationItem = styled(Box)`
  background-color: var(--bs-gray-300);
  border-radius: ${(props) => props.theme.spacing(1)};
  padding: ${(props) => props.theme.spacing(1)};
  ${(props) => props.highlight && `border: 1px solid ${props.theme.palette.primary.main};`}
  ${(props) => props.blocked && `background-color: var(--bs-danger-100);`}
`;
function splitSlots(slots, reservations) {
  const result = [];

  const adjustedReservations = reservations.map((res) => {
    const resStart = DateHelper.getDateTimeLocal(res.start_date);
    const resEnd = DateHelper.getDateTimeLocal(res.end_date);

    const relatedSlot = slots.find((slot) => {
      const slotStart = DateHelper.getDateTimeLocal(slot.start_date);
      const slotEnd = DateHelper.getDateTimeLocal(slot.end_date);

      return (
        (resStart.isSameOrAfter(slotStart) || resStart.isSame(slotStart)) &&
        (resEnd.isSameOrBefore(slotEnd) || resEnd.isSame(slotEnd))
      );
    });

    return {
      ...res,
      start_date: resStart,
      end_date: resEnd,
      relatedSlot: relatedSlot ? { ...relatedSlot } : null,
    };
  });

  for (const slot of slots) {
    let slotStart = DateHelper.getDateTimeLocal(slot.start_date);
    const slotEnd = DateHelper.getDateTimeLocal(slot.end_date);

    const overlappingReservations = adjustedReservations.filter((res) => {
      return res.start_date.isBefore(slotEnd) && res.end_date.isAfter(slotStart);
    });

    overlappingReservations.sort((a, b) => a.start_date - b.start_date);

    for (const res of overlappingReservations) {
      if (slotStart.isBefore(res.start_date)) {
        result.push({
          ...slot,
          start_date: slotStart,
          end_date: res.start_date,
          isSplit: !(slotStart.isSame(slot.start_date) && res.start_date.isSame(slot.end_date)),
        });
      }

      result.push({
        ...res,
        isSplit: false,
      });

      slotStart = res.end_date;
    }

    if (slotStart.isBefore(slotEnd)) {
      result.push({
        ...slot,
        start_date: slotStart,
        end_date: slotEnd,
        isSplit: !(slotStart.isSame(slot.start_date) && slotEnd.isSame(slot.end_date)),
      });
    }
  }

  return result.sort((a, b) => {
    if (a.start_date.isBefore(b.start_date)) return -1;
    if (a.start_date.isAfter(b.start_date)) return 1;
    return 0;
  });
}

function ListDynamicReservations({
  get,
  resource: resourceProp,
  fetchEvents,
  date,
  slotActionsInfo,
  highlightReservationId,
}) {
  const [events, setEvents] = useState([]);
  const [rawSlots, setRawSlots] = useState([]);
  const [isModified, setIsModified] = useState(false);
  const [resource, setResource] = useState(resourceProp);
  const resourceId = resource?.id;
  const [showAllCurrentReservations, setShowAllCurrentReservations] = useState(false);

  const [triggerFetchEvents, setTriggerFetchEvents] = useState(1);
  //   const userInfo = useSelector(selectUserInfo);
  const memberFieldFormat = useSelector(selectMemberFieldFormat);
  const memberDataFields = memberFieldFormat?.member_data_fields;
  // const [slotTab, setSlotTab] = useState();
  // const reservations = useSelector(selectReservations);
  const hasEditAction = slotActionsInfo?.action_list?.some((action) => action.button_type === "edit");
  const hasCreateAction = slotActionsInfo?.action_list?.some((action) => action.button_type === "create");
  const hasBlockAction = slotActionsInfo?.action_list?.some((action) => action.button_type === "block_slot");

  const dispatch = useDispatch();

  //   const selectedSlotObj = useMemo(() => events?.find((event) => event.id === selectedSlotId), [events, selectedSlotId]);

  const currentProject = useSelector(selectCurrentProject);

  //   const hasProjectFullRight = [userRole.admin, userRole.organizationManager].some((role) =>
  //     currentProject?.permissions?.includes(role)
  //   );
  //const hasFullAccess = userInfo.is_superuser || hasProjectFullRight;

  const fetchResource = async () => {
    const newResource = await dispatch(getResource(resourceId, currentProject.id));
    setResource(newResource);
  };
  useEffect(() => {
    (async () => {
      const currentDate = DateHelper.getDateTimeLocal(date);
      const beginOfDay = currentDate.clone().startOf("day");
      const endOfDay = currentDate.clone().endOf("day").subtract(1, "second");

      endOfDay.utcOffset(0, true);
      beginOfDay.utcOffset(0, true);

      const newEvents = await fetchEvents({
        start: beginOfDay,
        end: endOfDay,
      });
      const slots = newEvents.filter((event) => !event.dynamic);
      const reservations = newEvents.filter((event) => event.dynamic);
      const newList = splitSlots(slots, reservations);

      setRawSlots(slots);
      setEvents(newList);
    })();
  }, [fetchEvents, triggerFetchEvents, showAllCurrentReservations, dispatch, resourceId, currentProject?.id, date]);

  const handleTriggerFetchEvents = () => {
    setTriggerFetchEvents((prev) => prev + 1);
    setIsModified(true);
  };

  const handleSlotActionDynamic = (actionItem, dynamicSlot) => async () => {
    if (actionItem.button_type === "edit") {
      const firstEventStartDate = DateHelper.getDateTimeLocal(events[0].start_date);
      const lastEventEndDate = DateHelper.getDateTimeLocal(events[events.length - 1].end_date);
      const beginOfDay = firstEventStartDate;
      const endOfDay = lastEventEndDate;

      endOfDay.utcOffset(0, true);
      beginOfDay.utcOffset(0, true);

      const result = await AddOrEditDynamicReservationModal.show({
        editMode: true,
        fetchEvents,
        resource: resource,
        slot: dynamicSlot,
        reservationDateRange: {
          start: beginOfDay,
          end: endOfDay,
        },
        reservation: dynamicSlot.reservation,
        slotActionsInfo: slotActionsInfo,
        isBlocking: dynamicSlot.isBlocking,
      });
      if (result) {
        handleTriggerFetchEvents();
        fetchResource();
      }
    } else if (actionItem.button_type === "add_blacklist") {
      dispatch(addBlacklistResourceUser(currentProject.id, resourceId, dynamicSlot?.reservation?.reserver?.id)).then(
        async () => {
          await handleTriggerFetchEvents();
          await fetchResource();
          setIsModified(true);
        }
      );
    } else if (actionItem.button_type === "remove_blacklist") {
      dispatch(removeBlacklistResourceUser(currentProject.id, resourceId, dynamicSlot?.reservation?.reserver?.id)).then(
        async () => {
          await handleTriggerFetchEvents();
          await fetchResource();
          setIsModified(true);
        }
      );
    } else {
      if (actionItem.ask_yes_no) {
        if (!(await DialogHelper.showQuestionYesNo(actionItem.ask_yes_no))) return;
      }
      await dispatch(
        postReservationAction(currentProject.id, resourceId, {
          button_type: actionItem.button_type,
          payload: {
            ...actionItem?.payload,
            reservation_id: dynamicSlot.reservation.id,
            member_id: dynamicSlot?.reservation?.reserver?.id,
          },
        })
      );

      handleTriggerFetchEvents();
      setIsModified(true);
    }
  };

  const visibleSlotActions = useMemo(() => {
    const hideList = ["create", "reservation_type", "block_slot"];

    const filteredActions = slotActionsInfo?.action_list?.filter((action) => !hideList.includes(action.button_type));
    return filteredActions;
  }, [slotActionsInfo?.action_list]);

  const handleClickSubmit = () => {
    get(false)();
  };

  const handleClickCreateSlot = async () => {
    const result = await DynamicSlotAddEditModal.show({
      fetchEvents,
      resource,
      slot: null,
      existingSlots: rawSlots,
      date: date,
    });

    if (result) {
      handleTriggerFetchEvents();
    }
  };
  return (
    <PRModal
      size="xl"
      submitText={""}
      title={`Reservations for ${DateHelper.getDateTimeLocal(date).format("LL")}`}
      onClick={handleClickSubmit}
      onClose={get(isModified || false)}
    >
      <Box display={"flex"} justifyContent="flex-end" mb={2}>
        <PRButton color="success" icon={MdAdd} size="sm" onClick={handleClickCreateSlot}>
          Create Slot
        </PRButton>
      </Box>
      <PalShadowWrapper
        style={{
          maxHeight: 800,
          overflowY: "auto",
          overflowX: "hidden",

          //   border: "1px solid var(--bs-secondary-400)",
        }}
      >
        {events.map((item, index, arr) => {
          const isReservation = item.dynamic;

          const publicFields = memberDataFields.filter(
            (item) => item.visibility_type === organizationVisibilityType.PUBLIC
          );

          const slotIdList = item.slot?.length
            ? typeof item.slot?.[0] === "object"
              ? item.slot.map((s) => s.id)
              : item.slot
            : [];

          const isInBlacklist = resource.blacklist?.some(
            (blacklist) => blacklist.id === item?.reservation?.reserver?.id
          );

          const orderedPublicFields = [...publicFields].sort((a, b) => a.order - b.order);

          const fieldKey = `slot-${item.start_date.toISOString()}-${item.end_date.toISOString()}`;

          const handleClickCancelBlockedReservation = async () => {
            if (!(await DialogHelper.showQuestionYesNo())) return;
            await dispatch(
              postReservationAction(currentProject.id, resource.id, {
                button_type: "cancel",
                payload: {
                  member_id: item?.reserver?.id,
                  reservation_id: item?.id,
                },
              })
            );
            handleTriggerFetchEvents();
          };

          if (item.isBlocking) {
            return (
              <StyledReservationItem key={fieldKey} blocked alignItems={"center"} className="mb-2">
                <Row className="g-1 align-items-center">
                  <Col xs>
                    <Typography fontWeight={"bold"} variant="subtitle2">
                      {`${item.start_date.format("LT")} - ${item.end_date.format("LT")}`} is blocked
                    </Typography>
                  </Col>
                  <Col className="d-flex gap-1" xs="auto">
                    <PRButton
                      color="primary"
                      disabled={!hasEditAction}
                      icon={MdEdit}
                      size="sm"
                      onClick={handleSlotActionDynamic({ button_type: "edit" }, item)}
                    />

                    <PRButton
                      color="danger"
                      disabled={!hasEditAction}
                      icon={MdDelete}
                      size="sm"
                      onClick={handleClickCancelBlockedReservation}
                    />
                  </Col>
                  {!!item?.reservation?.note && (
                    <StyledReservationCol className="text-truncate" xs="12">
                      <Typography fontWeight={"bold"} mb={0.25} variant="subtitle2">
                        Note
                      </Typography>
                      <Grid container spacing={0.5}>
                        <Grid item xs={12}>
                          <Box
                            sx={{
                              maxHeight: 100,
                              overflowY: "auto",
                            }}
                          >
                            <Typography variant="caption" whiteSpace="pre-wrap">
                              {item?.reservation?.note || "-"}
                            </Typography>
                          </Box>
                        </Grid>
                      </Grid>
                    </StyledReservationCol>
                  )}
                </Row>
              </StyledReservationItem>
            );
          } else if (!isReservation) {
            if (!hasCreateAction) return null;

            const handleClickAddReservation = (isBlocking) => async () => {
              //start,end fields are original slot start and end date
              const beginOfDay = DateHelper.getDateTimeLocal(item.start);
              const endOfDay = DateHelper.getDateTimeLocal(item.end);

              endOfDay.utcOffset(0, true);
              beginOfDay.utcOffset(0, true);

              const result = await AddOrEditDynamicReservationModal.show({
                fetchEvents,
                resource: resource,
                slot: item,
                reservationDateRange: {
                  start: beginOfDay,
                  end: endOfDay,
                },
                reservation: null,
                isBlocking: isBlocking,
                slotActionsInfo: slotActionsInfo,
              });
              if (result) {
                handleTriggerFetchEvents();
              }
            };

            const handleClickDeleteSlot = async () => {
              if (!(await DialogHelper.showQuestionYesNo())) return;
              await dispatch(deleteResourceSlot(item.id, resourceId, currentProject.id));
              handleTriggerFetchEvents();
            };
            return (
              <Box key={fieldKey} alignItems={"center"} className="pb-2" display={"flex"} gap={1}>
                <PRButton
                  color="success"
                  disabled={!hasEditAction}
                  icon={MdAdd}
                  size="sm"
                  onClick={handleClickAddReservation(false)}
                >
                  Create reservation in{" "}
                  <Box component="span" minWidth={80}>
                    {`${item.start_date.format("LT")} - ${item.end_date.format("LT")}`}
                  </Box>
                </PRButton>
                {hasBlockAction && (
                  <PRButton
                    color="warning"
                    icon={MdBlock}
                    size="sm"
                    tooltipText="Block Slot"
                    onClick={handleClickAddReservation(true)}
                  />
                )}
                {!item.isSplit && (
                  <PRButton
                    color="danger"
                    icon={MdDelete}
                    size="sm"
                    tooltipText="Delete Slot"
                    onClick={handleClickDeleteSlot}
                  />
                )}
              </Box>
            );
          }
          return (
            <StyledReservationItem
              key={fieldKey}
              className="mb-2"
              highlight={highlightReservationId && highlightReservationId === item.reservation?.id}
            >
              <Row className="g-1 align-items-center">
                <Col className="" xs="12">
                  <Typography fontWeight={"bold"} variant="subtitle2">
                    {`${DateHelper.getDateTimeLocal(item.start_date).format("LT")} - ${DateHelper.getDateTimeLocal(
                      item.end_date
                    ).format("LT")}`}
                    {item?.reservation?.secret_note?.islem_adi && ` (${item?.reservation?.secret_note?.islem_adi})`}
                  </Typography>
                </Col>
                <StyledReservationCol className="text-truncate" xs="12">
                  <Typography fontWeight={"bold"} mb={0.25} variant="subtitle2">
                    Customer Information{" "}
                    {isInBlacklist && (
                      <Typography color="error" variant="caption">
                        (This customer is in blacklist)
                      </Typography>
                    )}
                  </Typography>
                  <Grid container spacing={0.5}>
                    {orderedPublicFields.map((field) => {
                      return (
                        <Grid key={field.name} item md={3} overflow="hidden" sm={4} xs={6}>
                          <Box overflow="hidden">
                            <Typography noWrap component={"div"} fontWeight={"bold"} lineHeight={1} variant="caption">
                              {field.display_name || field.name}
                            </Typography>
                          </Box>
                          <Typography noWrap component={"div"} variant="caption">
                            <CustomerListCellRenderer
                              cellKey={item.name}
                              memberDataFields={memberDataFields}
                              value={item?.reservation?.reserver?.safe_information?.[field.name]}
                            />
                          </Typography>
                        </Grid>
                      );
                    })}
                  </Grid>
                </StyledReservationCol>
                {!!item?.reservation?.note && (
                  <StyledReservationCol className="text-truncate" xs="12">
                    <Typography fontWeight={"bold"} mb={0.25} variant="subtitle2">
                      Note
                    </Typography>
                    <Grid container spacing={0.5}>
                      <Grid item xs={12}>
                        <Box
                          sx={{
                            maxHeight: 100,
                            overflowY: "auto",
                          }}
                        >
                          <Typography variant="caption" whiteSpace="pre-wrap">
                            {item?.reservation?.note || "-"}
                          </Typography>
                        </Box>
                      </Grid>
                    </Grid>
                  </StyledReservationCol>
                )}
                <Col className="gap-1 d-flex" xs="12">
                  {visibleSlotActions?.length > 0 &&
                    visibleSlotActions.map((actionItem) => {
                      const { icon: _, label, ...buttonProps } = actionItem?.button_props || {};
                      const ButtonComp =
                        actionItem?.button_type === "change_attended_status" ? AttendanceButton : PalButton;
                      return (
                        <ButtonComp
                          size="small"
                          {...buttonProps}
                          key={actionItem.button_type}
                          variant="contained"
                          // tooltipText={label}
                          onClick={handleSlotActionDynamic(actionItem, item)}
                          {...(actionItem.button_type === "change_attended_status" && {
                            resource,
                            reservation: item?.reservation,
                            onUpdated: handleTriggerFetchEvents,
                          })}
                          {...(actionItem?.button_type === "add_blacklist" && {
                            disabled: !item?.reservation?.reserver?.id || isInBlacklist,
                          })}
                          {...(actionItem?.button_type === "remove_blacklist" && {
                            disabled: !item?.reservation?.reserver?.id || !isInBlacklist,
                          })}
                        >
                          {label}
                        </ButtonComp>
                      );
                    })}
                </Col>
              </Row>
            </StyledReservationItem>
          );
        })}
      </PalShadowWrapper>
    </PRModal>
  );
}

const ListDynamicReservationsModal = withCardon(ListDynamicReservations, {
  destroyOnHide: true,
});

export { ListDynamicReservationsModal, DynamicSlotAddEditModal };
export default AddOrEditDynamicReservationModal;
