import classNames from "classnames";
import { groupBy } from "lodash";
import { MdEmail, MdNotifications, MdPhoneAndroid } from "react-icons/md";
import { Col, Label, Row } from "reactstrap";

import PRTooltip from "~components/Generic/PRTooltip";
import { settingsLanguageMap, settingsTemplatePlatformType } from "~constants";
import Utils from "~helpers/Utils";

import { messageTemplateTextDescriptionMap } from "../MessageTemplateDefinition";

import "./style.scss";

const MessageDefinitionPreviewItem = ({ templateKey, templateGroups, className }) => {
  const templates = templateGroups || [];
  // const isSelected = templateGroup.isSelected;

  const groupedByLang = groupBy(templates, "language");
  const groupedByLangItems = Object.values(groupedByLang) || [];

  return (
    <div className={classNames("pr-message-definition-preview-item preview-card", className)}>
      <div className="text-muted d-flex flex-column justify-content-between" style={{ height: "100px" }}>
        <span className="py-2 px-1">{messageTemplateTextDescriptionMap[templateKey]?.description}</span>
        {!templates?.length ? (
          <div className="text-muted pt-4 text-center">
            <span className> No template defined</span>
          </div>
        ) : (
          <div className="text-center">
            <Label className="text-center fw-semibold" size="sm">
              Languages{!!groupedByLangItems.length && ` (${groupedByLangItems.length})`}
            </Label>
          </div>
        )}
      </div>

      <div className="p-1" style={{ height: "135px" }}>
        <Row className="g-2">
          {groupedByLangItems.map((items) => {
            const firstItem = items?.[0];
            const hasEmailTemplate = items.find((item) => item.platform === settingsTemplatePlatformType.EMAIL);
            const hasSmsTemplate = items.find((item) => item.platform === settingsTemplatePlatformType.SMS);
            const hasPushNotificationTemplate = items.find(
              (item) => item.platform === settingsTemplatePlatformType.PUSH_NOTIFICATION
            );

            let languageCode = firstItem?.language?.toLowerCase();
            return (
              <Col key={firstItem.language} className="preview-card-lang" xs="12">
                <Row className="gx-1 align-items-center">
                  <Col xs>
                    <Label className="d-flex align-items-center" size="sm">
                      <img
                        alt={firstItem.language}
                        loading="lazy"
                        src={Utils.getFlagUrlByIsoCode(languageCode)}
                        width={18}
                      />
                      <span className="ms-2">{settingsLanguageMap[firstItem.language]}</span>
                    </Label>
                  </Col>
                  <Col xs="auto">
                    <PRTooltip title={"Email"}>
                      <span>
                        <MdEmail
                          className={classNames("fs-5", {
                            "text-primary": hasEmailTemplate,
                          })}
                        />
                      </span>
                    </PRTooltip>
                  </Col>
                  <Col xs="auto">
                    <PRTooltip title={"SMS"}>
                      <span>
                        <MdPhoneAndroid
                          className={classNames("fs-5", {
                            "text-primary": hasSmsTemplate,
                          })}
                        />
                      </span>
                    </PRTooltip>
                  </Col>
                  <Col xs="auto">
                    <PRTooltip title={"Push Notification"}>
                      <span>
                        <MdNotifications
                          className={classNames("fs-5", {
                            "text-primary": hasPushNotificationTemplate,
                          })}
                        />
                      </span>
                    </PRTooltip>
                  </Col>
                </Row>
              </Col>
            );
          })}
        </Row>
      </div>
    </div>
  );
};

export default MessageDefinitionPreviewItem;
