import { createRef, useMemo } from "react";

import { MdAdd, MdDelete, MdEdit, MdSync } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";

import PRButton from "~components/Generic/PRButton";
import PRContainer from "~components/Generic/PRContainer";
import PRTable from "~components/Generic/PRTable";
import PalDot from "~components/mui/PalDot";
import {
  apiUrlWhatsappTemplate,
  chatbotGupshupCategoryMap,
  chatbotGupshupLanguageMap,
  chatbotGupshupTemplateStatus,
  chatbotGupshupTemplateStatusMap,
  chatbotGupshupTemplateTypeMap,
  tableFilterStorageKey,
} from "~constants";
import DialogHelper from "~helpers/DialogHelper";
import { deleteWhatsappTemplate, syncWhatsappTemplate } from "~store/platform/actions";
import { selectCurrentProject } from "~store/user/selectors";
// import { deleteMemberField, getFilterList } from "~store/organization/actions";

export default function WhatsappTemplateMessageList({ organization }) {
  const dispatch = useDispatch();
  const tableRef = createRef();

  const currentProject = useSelector(selectCurrentProject);

  const columns = useMemo(() => {
    const handleDelete = (row) => async () => {
      if (!(await DialogHelper.showQuestionDelete())) return;
      await dispatch(deleteWhatsappTemplate(currentProject.id, row.id));
      tableRef.current.refresh();
    };
    return [
      {
        label: "Name",
        key: "response_data.elementName",
      },
      {
        label: "Status",
        key: "response_data.status",
        render: (row) => (
          <div className="d-flex align-items-center">
            <PalDot
              color={
                row.response_data?.status === chatbotGupshupTemplateStatus.PENDING
                  ? "warning-600"
                  : row.response_data?.status === chatbotGupshupTemplateStatus.APPROVED
                  ? "primary"
                  : "danger"
              }
            />
            {chatbotGupshupTemplateStatusMap[row.response_data.status] || row.response_data.status}
          </div>
        ),
      },
      {
        label: "Category",
        key: "response_data.category",
        render: (row) => (
          <span>{chatbotGupshupCategoryMap[row.response_data?.category] || row.response_data?.category}</span>
        ),
      },
      {
        label: "Template Type",
        key: "response_data.templateType",
        render: (row) => (
          <span>{chatbotGupshupTemplateTypeMap[row.response_data.templateType] || row.response_data.templateType}</span>
        ),
      },
      {
        label: "Template",
        key: "response_data.data",
      },
      {
        label: "Vertical",
        key: "response_data.vertical",
      },
      {
        label: "Language",
        key: "response_data.languageCode",
        render: (row) => (
          <span>{chatbotGupshupLanguageMap[row.response_data.languageCode] || row.response_data.languageCode}</span>
        ),
      },
      {
        label: "Actions",
        key: "actions",
        actions: true,
        fixed: "right",
        render: (row) => (
          <div className="d-flex justify-content-center">
            <PRButton
              outline
              color="primary"
              icon={MdEdit}
              link={`/contactcenter/livechat/whatsapp-template-messages/form/${row.id}`}
              size="sm"
              tooltipText="Edit"
            />
            <PRButton
              outline
              className="ms-1"
              color="danger"
              icon={MdDelete}
              size="sm"
              tooltipText="Delete"
              onClick={handleDelete(row)}
            />
          </div>
        ),
      },
    ];
  }, [dispatch, tableRef, currentProject.id]);

  const actions = useMemo(() => {
    const handleClickSync = async () => {
      await dispatch(syncWhatsappTemplate(currentProject.id));
      tableRef.current.refresh();
    };
    return [
      {
        label: "Create New",
        icon: MdAdd,
        color: "success",
        link: "/contactcenter/livechat/whatsapp-template-messages/form/",
      },
      {
        icon: MdSync,
        color: "primary",
        onClick: handleClickSync,
        tooltipText: "Synchronize template message approval status",
      },
    ];
  }, [dispatch, tableRef, currentProject.id]);

  const parentName = [
    ...(organization
      ? [
          {
            label: "Action Templates",
            url: "/organization/action-templates/",
          },
          {
            label: "Action Template Create",
            url: "/organization/action-templates/form/",
          },
          {
            label: "Whatsapp Template Messages",
          },
        ]
      : [
          {
            label: "Live Chat",
            url: "/contactcenter/livechat/",
          },
          {
            label: "Whatsapp Template Messages",
          },
        ]),
  ];
  return (
    <PRContainer
      actions={actions}
      description={"Here you can manage your Whatsapp Template Messages for your Whatsapp Business Account."}
      name={organization ? "Organization" : "Contact Center"}
      parentName={parentName}
    >
      <PRTable
        ref={tableRef}
        columns={columns}
        storageKey={tableFilterStorageKey.whatsappTemplateList}
        url={apiUrlWhatsappTemplate.getList.format(currentProject.id)}
      />
    </PRContainer>
  );
}
