import { useMemo, useRef } from "react";

import { MdDelete, MdDownload, MdEdit, MdFileUpload, MdOpenInNew } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";

import PRButton from "~components/Generic/PRButton";
import PRContainer from "~components/Generic/PRContainer";
import PRTable from "~components/Generic/PRTable";
import { apiUrlChatbot, tableFilterStorageKey } from "~constants";
import DialogHelper from "~helpers/DialogHelper";
import FileHelper from "~helpers/FileHelper";
import Network from "~helpers/Network";
import { deleteDatabase, updateDatabase, uploadDatabase } from "~store/dialogComponents/scenarioManager/actions";
import { selectCurrentBot, selectCurrentProject } from "~store/user/selectors";

import RenameFileModal from "../RenameFileModal";
import UploadModal from "../UploadModal";

function Database() {
  const dispatch = useDispatch();

  const tableRef = useRef(null);

  const currentProject = useSelector(selectCurrentProject);
  const currentBot = useSelector(selectCurrentBot);

  const handleClickUpload = async () => {
    const result = await UploadModal?.show({ title: "Upload Database" });
    if (result?.length) {
      await dispatch(uploadDatabase(currentProject.id, currentBot.id, result[0].file, result[0].name));
      tableRef.current.refresh();
      UploadModal?.hide();
    }
  };

  const columns = useMemo(() => {
    const handleClickDelete = (id) => async () => {
      if (await DialogHelper.showQuestionDelete()) {
        await dispatch(deleteDatabase(currentProject.id, currentBot.id, id));
        tableRef.current.refresh();
      }
    };
    const handleClickDownload = (row) => async () => {
      Network.request(row.data_file, {
        responseType: "blob",
      }).then(function (blobResponse) {
        const urlExt = row.data_file.split(".").pop();

        FileHelper.saveAs(blobResponse, row.name + "." + urlExt);
      });
    };
    const handleClickEdit = (row) => async () => {
      const result = await RenameFileModal?.show({ title: "Edit Database", name: row.name });
      if (result) {
        await dispatch(updateDatabase(currentProject.id, currentBot.id, { id: row.id, name: result }));
        tableRef.current.refresh();
        RenameFileModal?.hide();
      }
    };

    return [
      {
        label: "Name",
        key: "name",
      },
      // {
      //   label: "Data URL",
      //   key: "data_file",
      //   render: (row) => (
      //     <div className="text-truncate">
      //       <PRTooltip title={row.data_file}>
      //         <a href={row.data_file} target="_blank" rel="noreferrer">
      //           {Utils.ellipsisUrl(row.data_file)}
      //         </a>
      //       </PRTooltip>
      //     </div>
      //   ),
      // },
      {
        label: "Actions",
        key: "actions",
        actions: true,
        fixed: "right",
        render: (row) => {
          const url = new URL(row.data_file);
          const newUrl = `${window.location.origin}${url.pathname}`;

          return (
            <div className="d-flex justify-content-center">
              <PRButton
                outline
                className="ms-1"
                color="secondary"
                icon={MdOpenInNew}
                link={newUrl}
                linkProps={{ newTab: true, noPrefix: true }}
                size="sm"
                tooltipText="Open in new tab"
              />
              <PRButton
                outline
                className="ms-1"
                color="secondary"
                icon={MdDownload}
                size="sm"
                tooltipText="Download"
                onClick={handleClickDownload(row)}
              />
              <PRButton
                outline
                className="ms-1"
                color="primary"
                icon={MdEdit}
                size="sm"
                tooltipText="Edit"
                onClick={handleClickEdit(row)}
              />
              <PRButton
                outline
                className="ms-1"
                color="danger"
                icon={MdDelete}
                size="sm"
                tooltipText="Delete"
                onClick={handleClickDelete(row.id)}
              />
            </div>
          );
        },
      },
    ];
  }, [dispatch, currentProject.id, currentBot.id]);

  const actions = [
    {
      label: "Upload Database",
      icon: MdFileUpload,
      color: "success",
      onClick: handleClickUpload,
    },
  ];
  const parentName = [
    {
      label: "Database",
    },
  ];
  return (
    <PRContainer
      smalltalkSelector
      actions={actions}
      description={"Here you can manage your database to use in your chatbot."}
      name="Chatbot"
      parentName={parentName}
    >
      <PRTable
        ref={tableRef}
        columns={columns}
        storageKey={tableFilterStorageKey.databaseList}
        url={apiUrlChatbot.getDatabase.format(currentProject.id, currentBot.id)}
      />
    </PRContainer>
  );
}

export default Database;
