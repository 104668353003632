import { createRef, useEffect, useMemo } from "react";

import { MdAdd, MdDelete, MdEdit } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";

import PRButton from "~components/Generic/PRButton";
import PRContainer from "~components/Generic/PRContainer";
import PRTable from "~components/Generic/PRTable";
import { apiUrlTicketCategory, tableFilterStorageKey } from "~constants";
import DialogHelper from "~helpers/DialogHelper";
import { deleteTicketCategory } from "~store/helpdesk/actions";
import { getPermissionUserList } from "~store/settings/permission/actions";
import { selectPermissionUserList } from "~store/settings/permission/selectors";
import { selectCurrentProject } from "~store/user/selectors";

import "./style.scss";

export default function ActionsList() {
  const dispatch = useDispatch();

  const permissionUserList = useSelector(selectPermissionUserList);
  const currentProject = useSelector(selectCurrentProject);
  const tableRef = createRef();

  useEffect(() => {
    dispatch(getPermissionUserList(currentProject.id));
  }, [dispatch, currentProject.id]);

  const columns = useMemo(() => {
    const handleDelete = (row) => async () => {
      if (!(await DialogHelper.showQuestionDelete())) return;
      await dispatch(deleteTicketCategory(currentProject.id, row.id));
      tableRef.current?.refresh();
    };
    return [
      {
        label: "Name",
        key: "name",
      },
      {
        label: "Agent List",
        key: "agent_list",
        render: (row) => (
          <span>
            <ul className="m-0 font-size-12">
              {row.agent_list?.map((item, index) => {
                if (!permissionUserList?.length) return null;
                const user = permissionUserList.find((user) => user.id === item);
                return <li key={index}>{`${user?.firstname} ${user?.lastname} (${user?.email})`}</li>;
              })}
            </ul>
          </span>
        ),
      },
      {
        label: "Actions",
        key: "actions",
        actions: true,
        render: (row) => (
          <div className="d-flex justify-content-center">
            <PRButton
              outline
              color="primary"
              icon={MdEdit}
              link={`/helpdesk/ticket-category/form/${row.id}`}
              size="sm"
              tooltipText="Edit"
            />
            <PRButton
              outline
              className="ms-1"
              color="danger"
              icon={MdDelete}
              size="sm"
              tooltipText="Delete"
              onClick={handleDelete(row)}
            />
          </div>
        ),
      },
    ];
  }, [dispatch, permissionUserList, currentProject.id, tableRef]);

  const actions = useMemo(() => {
    return [
      {
        label: "Create New",
        icon: MdAdd,
        color: "success",
        link: "/helpdesk/ticket-category/form",
      },
    ];
  }, []);

  const filters = useMemo(
    () => [
      {
        key: "agent_list__id",
        label: "Agent List",
        options: permissionUserList?.map((item) => ({
          label: `${item.firstname} ${item.lastname} (${item.email})`,
          value: item.id,
        })),
      },
    ],
    [permissionUserList]
  );

  return (
    <PRContainer
      actions={actions}
      className=""
      description={"Here you can manage your ticket categories."}
      name="Help Desk"
      parentName="Ticket Category"
    >
      <PRTable
        ref={tableRef}
        columns={columns}
        filters={filters}
        storageKey={tableFilterStorageKey.ticketCategoryList}
        url={apiUrlTicketCategory.get.format(currentProject.id)}
      />
    </PRContainer>
  );
}
