import React, { useCallback } from "react";

import { MuiTelInput } from "mui-tel-input";

import styled from "@emotion/styled";

import Utils from "~helpers/Utils";

const Styled = styled(MuiTelInput, {
  shouldForwardProp: (prop) => !["smallSpacing", "borderRadius"].includes(prop),
})`
  border-radius: ${(props) => props.borderRadius}px;
  .MuiInputBase-root {
    min-height: 52px;
    border-radius: ${(props) => props.borderRadius}px;
    background: #f8f8f8;
    padding-left: 0px;
    // padding-right: 0px;
  }
  .MuiInputBase-input {
    // padding-left: ${(props) => (props.smallSpacing ? "5px" : "5px")};
    padding-right: 0px;
    // padding-right: ${(props) => (props.smallSpacing ? "15px" : "25px")};
    padding-top: 14.5px;
    padding-bottom: 14.5px;
  }

  .MuiFormLabel-root {
    padding: 0px ${(props) => (props.smallSpacing ? "0px" : "12px")};
  }
  .MuiOutlinedInput-notchedOutline,
  .Mui-focused .MuiOutlinedInput-notchedOutline {
    border-width: 1px;
  }
  .MuiOutlinedInput-notchedOutline {
    padding: 0px ${(props) => (props.smallSpacing ? "12px" : "18px")};

    span {
      // padding-right: ${(props) => (props.smallSpacing ? "10px" : "5px")};
      ${(props) => (props.label ? `padding-right: ${(props) => (props.smallSpacing ? "10px" : "5px")};` : "")}
    }
  }
  .MuiFormHelperText-root {
    margin-left: ${(props) => (props.smallSpacing ? "15px" : "25px")};
    font: normal normal normal 14px/20px Museo Sans;
  }

  .MuiInputAdornment-positionStart {
    margin-left: 16px;
  }
`;

const reactstrapModeStyle = {
  "& .MuiInputBase-root": {
    borderRadius: "4px !important",
    borderColor: "var(--bs-gray-400) !important",
    minHeight: "36px !important",
    input: {
      paddingTop: "0.5rem !important",
      paddingBottom: "0.5rem !important",
      fontSize: "0.8125rem !important",
      color: "#495057 !important",
    },
    fieldset: {
      borderColor: "#ced4da !important",
    },
    "&.Mui-error": {
      fieldset: {
        borderColor: "var(--bs-danger) !important",
      },
    },
  },
  ".MuiFormHelperText-root": {
    fontSize: "0.7rem !important",
    color: "var(--bs-danger) !important",
    marginLeft: "0 !important",
  },
};
/**
 * @typedef {typeof MuiTelInput} PalPhoneNumberProps
 * @type {PalPhoneNumberProps}
 */
const PalPhoneNumber = ({ reactstrapMode, invalid, smallSpacing, borderRadius, ...rest }) => {
  const isInvalid = !!invalid;

  const FlagElementCallback = useCallback((isoCode, { imgProps, countryName, isSelected }) => {
    return <img alt={countryName} loading="lazy" src={Utils.getFlagUrlByIsoCode(isoCode)} width={26}></img>;
  }, []);

  return (
    <Styled
      borderRadius={borderRadius || 26}
      error={isInvalid}
      helperText={isInvalid ? invalid : ""}
      smallSpacing={smallSpacing}
      {...rest}
      defaultCountry="TR"
      excludedCountries={["TA", "AC"]}
      getFlagElement={FlagElementCallback}
      {...(reactstrapMode ? { sx: reactstrapModeStyle } : {})}

      //   InputProps={{
      //     slots: {
      //       input: PalTextField,
      //     },
      //   }}
    />
  );
};

export default PalPhoneNumber;
