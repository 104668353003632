import { useEffect } from "react";

import { useFormik } from "formik";
import { MdAdd, MdDelete } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Col, Label, Row } from "reactstrap";
import * as Yup from "yup";

import useProjectChange from "~common/hooks/useProjectChange";
import PRButton from "~components/Generic/PRButton";
import PRContainer from "~components/Generic/PRContainer";
import PRInput from "~components/Generic/PRInput";
import PRPage from "~components/Generic/PRPage";
import HistoryHelper from "~helpers/HistoryHelper";
import { createOrUpdateDictionary, getDictionary, setDictionary } from "~store/dialogComponents/dictionaries/actions";
import { selectDictionary } from "~store/dialogComponents/dictionaries/selectors";
import { selectCurrentBot, selectCurrentProject } from "~store/user/selectors";

const goBackUrl = "/chatbot/dictionaries/";
export default function DictionariesEdit() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const currentProject = useSelector(selectCurrentProject);
  const currentBot = useSelector(selectCurrentBot);
  const dictionary = useSelector(selectDictionary);

  useProjectChange(() => {
    HistoryHelper.push(goBackUrl, { scope: "dashboard" });
  }, []);

  const handleGoBack = () => {
    HistoryHelper.goBack(goBackUrl, { scope: "dashboard" });
  };
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: dictionary.id,
      key: dictionary.key,
      values: dictionary.values || [],
    },
    validationSchema: Yup.object({
      key: Yup.string().required("Required").min(3, "Name must be at least 3 characters"),
    }),
    onSubmit: async (values) => {
      await dispatch(createOrUpdateDictionary(currentProject.id, currentBot.id, values));
      handleGoBack();
    },
  });

  const formikAdder = useFormik({
    enableReinitialize: true,
    initialValues: {
      value: "",
    },
    validationSchema: Yup.object({
      value: Yup.string()
        .required("Required")
        .min(3, "Name must be at least 3 characters")
        .test("is-unique", "Value already exists", (value) => {
          if (formik.values.values.includes(value)) return false;
          return true;
        }),
    }),
    onSubmit: async ({ value }, helper) => {
      formik.setFieldValue("values", [...formik.values.values, value]);
      helper.setFieldValue("value", "");
      helper.setTouched({ value: false });
    },
  });

  const handleAddValue = () => {
    formikAdder.handleSubmit();
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleAddValue();
    }
  };

  const handleDeleteValue = (index) => () => {
    const values = [...formik.values.values.filter((_, i) => i !== index)];
    formik.setFieldValue("values", values);
  };

  useEffect(() => {
    if (!id) return;
    dispatch(getDictionary(currentProject.id, currentBot.id, id));
    return () => {
      dispatch(setDictionary({}));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, id]);

  const parentName = [
    {
      label: "Dictionaries",
      url: "/chatbot/dictionaries",
    },
    {
      label: `${!id ? "Create" : "Edit"} Dictionary`,
    },
  ];
  return (
    <PRContainer
      smalltalkSelector
      description={"Here you can create or edit a dictionary."}
      name="Chatbot"
      parentName={parentName}
    >
      <PRPage>
        <Row className="mt-2 g-2">
          <Col md="4">
            <Label className="mb-2">Name</Label>
          </Col>
          <Col md="8">
            <PRInput
              invalid={formik.touched.key && formik.errors.key}
              name="key"
              type="text"
              value={formik.values.key}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
            />
          </Col>
          <Col md="4">
            <Label className="mb-2">Value</Label>
          </Col>
          <Col md="8">
            <Row className="gx-2">
              <Col xs>
                <PRInput
                  invalid={formikAdder.touched.value && formikAdder.errors.value}
                  name="value"
                  type="text"
                  value={formikAdder.values.value}
                  onBlur={formikAdder.handleBlur}
                  onChange={formikAdder.handleChange}
                  onKeyDown={handleKeyDown}
                />
              </Col>
              <Col xs="auto">
                <PRButton icon={MdAdd} type="button" onClick={handleAddValue} />
              </Col>
            </Row>
          </Col>
          <Col md="12">
            <Label className="mb-2">Values</Label>
            {formik.values.values?.length ? (
              <ul>
                {formik.values.values?.map((item, index) => {
                  return (
                    <li key={index} className="mb-1">
                      <Row className="gx-2">
                        <Col xs>
                          <Label>{item}</Label>
                        </Col>
                        <Col xs="auto">
                          <PRButton
                            outline
                            className="ms-2"
                            color="danger"
                            icon={MdDelete}
                            onClick={handleDeleteValue(index)}
                          />
                        </Col>
                      </Row>
                    </li>
                  );
                })}
              </ul>
            ) : (
              <div className="mb-2  text-muted text-center">
                <Label> No values</Label>
              </div>
            )}
          </Col>
        </Row>
        <Row className="justify-content-end mt-2">
          <Col md="auto">
            <PRButton outline link={goBackUrl}>
              Cancel
            </PRButton>
            <PRButton className="ms-2" onClick={formik.handleSubmit}>
              Save
            </PRButton>
          </Col>
        </Row>
      </PRPage>
    </PRContainer>
  );
}
