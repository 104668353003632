import { useEffect, useState } from "react";

import { withCardon } from "cardon";
import { BiLoaderAlt } from "react-icons/bi";
import { MdOutlineWarningAmber } from "react-icons/md";
import { useSelector } from "react-redux";
import { Alert as BSAlert, Col, ListGroup, ListGroupItem, Row } from "reactstrap";

import { Box, Switch } from "@mui/material";

import { getBotSettings, trainBot, updateBotSettings } from "~apiRequests/bot";
import PRButton from "~components/Generic/PRButton";
import PRContainer from "~components/Generic/PRContainer";
import PRInput, { PRTextArea } from "~components/Generic/PRInput";
import PRLink from "~components/Generic/PRLink";
import PRModal from "~components/Generic/PRModal";
import PRPage from "~components/Generic/PRPage";
import AlertHelper from "~helpers/AlertHelper";
import DateHelper from "~helpers/DateHelper";
import DialogHelper from "~helpers/DialogHelper";
import LoadingHelper from "~helpers/LoadingHelper";
import { selectCurrentBot, selectCurrentProject, selectUserInfo } from "~store/user/selectors";

import DisplayLogsModal from "./display.logs";

function UntrainedRagIntentModalContent({ get, untrainedIntents }) {
  const [trainIntentIds, setTrainIntentIds] = useState([]);
  const handleChangeSwitch = () => {
    if (!trainIntentIds?.length) {
      setTrainIntentIds(untrainedIntents.map((intent) => intent.id));
    } else {
      setTrainIntentIds([]);
    }
  };

  return (
    <PRModal submitText="Continue" title="Untrained RAG Intents" onClick={get(trainIntentIds)} onClose={get([])}>
      <p>The following RAG intents have not been trained yet:</p>
      <Box maxHeight={500} overflow="auto">
        <ListGroup>
          {untrainedIntents.map((intent) => (
            <ListGroupItem key={intent.id}>
              <PRLink newTab target="_blank" to={`/chatbot/intent/form/${intent.id}`}>
                {intent.display_text || intent.text}
              </PRLink>
            </ListGroupItem>
          ))}
        </ListGroup>
      </Box>
      <p className="mt-3">Are you sure you want to proceed with the training?</p>
      <Box>
        <Switch checked={trainIntentIds?.length} onChange={handleChangeSwitch} />
        {trainIntentIds?.length ? "Intent training with untrained RAG intents" : "Intent training only"}
      </Box>
    </PRModal>
  );
}

const UntrainedRagIntentModal = withCardon(UntrainedRagIntentModalContent, { destroyOnHide: true });

function BotSettings() {
  const [botSettings, setBotSettings] = useState(null);
  const [pageRenderHelper, setPageRenderHelper] = useState(false);
  const userInfo = useSelector(selectUserInfo);

  const currentProject = useSelector(selectCurrentProject);
  const currentBot = useSelector(selectCurrentBot);
  useEffect(() => {
    LoadingHelper.open();
    getBotSettings(currentProject.id, currentBot.id)
      .then((response) => {
        setBotSettings(response);
      })
      .finally(() => {
        LoadingHelper.close();
      });
  }, [currentBot, pageRenderHelper, currentProject]);

  function renderAlerts() {
    function renderAlertType() {
      if (botSettings?.alerts) {
        let waitIndex = botSettings?.alerts.findIndex((alert) => alert.resolve_type === "wait_go_live");
        if (waitIndex !== -1) {
          return (
            <BSAlert color="danger">
              <Row className="gx-2 align-items-center">
                <Col xs>
                  Go live operation is started. This takes some time, so you can refresh the page after waiting.
                </Col>
              </Row>
            </BSAlert>
          );
        }

        let failIndex = botSettings?.alerts.findIndex((alert) => alert.resolve_type === "fail_train");
        if (failIndex !== -1) {
          return (
            <BSAlert color="danger">
              <Row className="gx-2 align-items-center">
                <Col xs>A system malfunction has occurred about train. We will reach you as soon as possible.</Col>
              </Row>
            </BSAlert>
          );
        }

        let waitTrainIndex = botSettings?.alerts.findIndex((alert) => alert.resolve_type === "wait_train");
        if (waitTrainIndex !== -1) {
          return (
            <BSAlert color="danger">
              <Row className="gx-2 align-items-center">
                <Col xs>
                  <BiLoaderAlt className="me-2 spin" />
                  Train operation is currently in progress, please wait.
                </Col>
                <Col xs="auto">
                  <PRButton
                    color="secondary"
                    onClick={(e) => {
                      DisplayLogsModal.show();
                    }}
                  >
                    Display Train Logs
                  </PRButton>
                </Col>
              </Row>
            </BSAlert>
          );
        } else {
          let trainIndex = botSettings?.alerts.findIndex((alert) => alert.resolve_type === "train");

          let goLiveIndex = botSettings?.alerts.findIndex((alert) => alert.resolve_type === "go_live");
          if (trainIndex !== -1) {
            return (
              <BSAlert color="danger">
                <Row className="gx-2 align-items-center">
                  <Col xs>
                    <MdOutlineWarningAmber className="me-2" />
                    The train operation needs to be restarted in order to apply the changes
                  </Col>
                  <Col xs="auto">
                    <PRButton
                      color="primary"
                      onClick={async (e) => {
                        let ragIntentIds = [];
                        if (botSettings?.rag_train_needed?.length > 0) {
                          const result = await UntrainedRagIntentModal.show({
                            untrainedIntents: botSettings?.rag_train_needed,
                          });
                          if (result?.length) {
                            ragIntentIds = result;
                          }
                        }
                        if (
                          !(await DialogHelper.showQuestionYesNo(
                            "",
                            <>
                              Are you sure you want to train for <b>{currentBot.name}</b> ? This action cannot be
                              undone.
                            </>
                          ))
                        ) {
                          return;
                        }

                        trainBot(currentProject.id, currentBot.id, "train", {
                          ...(ragIntentIds?.length && { rag_intent_ids: ragIntentIds }),
                        })
                          .then((response) => {
                            AlertHelper.show("Train operation started successfully");
                            setPageRenderHelper(!pageRenderHelper);
                          })
                          .catch((error) => {
                            AlertHelper.show(
                              "An error occurred while starting the training. Reach system admin",
                              "error"
                            );
                          })
                          .finally(() => {});
                      }}
                    >
                      Train
                    </PRButton>
                    {goLiveIndex > -1 && userInfo.is_superuser && (
                      <PRButton
                        className="ms-2"
                        color="primary"
                        onClick={async (e) => {
                          if (
                            !(await DialogHelper.showQuestionYesNo(
                              "",
                              <>
                                Are you sure you want to go live for <b>{currentBot.name}</b> ? This action cannot be
                                undone.
                              </>
                            ))
                          ) {
                            return;
                          }

                          trainBot(currentProject.id, currentBot.id, "go-live")
                            .then((response) => {
                              AlertHelper.show("Go Live operation started successfully");
                              setPageRenderHelper(!pageRenderHelper);
                            })
                            .catch((error) => {
                              AlertHelper.show(
                                "An error occurred while starting the training. Reach system admin",
                                "error"
                              );
                            });
                        }}
                      >
                        Go Live
                      </PRButton>
                    )}
                  </Col>
                </Row>
              </BSAlert>
            );
          } else {
            let goLiveIndex = botSettings?.alerts.findIndex((alert) => alert.resolve_type === "go_live");

            if (goLiveIndex !== -1) {
              return (
                <BSAlert color="danger">
                  <Row className="gx-2 align-items-center">
                    <Col xs>You must perform a Go Live action for the changes to go live.</Col>
                    <Col xs="auto">
                      <PRButton
                        color="primary"
                        onClick={async (e) => {
                          if (
                            !(await DialogHelper.showQuestionYesNo(
                              "",
                              <>
                                Are you sure you want to go live for <b>{currentBot.name}</b> ? This action cannot be
                                undone.
                              </>
                            ))
                          ) {
                            return;
                          }

                          trainBot(currentProject.id, currentBot.id, "go-live")
                            .then((response) => {
                              AlertHelper.show("Go Live operation started successfully");
                              setPageRenderHelper(!pageRenderHelper);
                            })
                            .catch((error) => {
                              AlertHelper.show(
                                "An error occurred while starting the training. Reach system admin",
                                "error"
                              );
                            });
                        }}
                      >
                        Go Live
                      </PRButton>
                    </Col>
                  </Row>
                </BSAlert>
              );
            } else {
              return (
                <BSAlert color="success">
                  <Row className="gx-2 align-items-center">
                    <Col xs> Your model is up to date.</Col>
                  </Row>
                </BSAlert>
              );
            }
          }
        }
      }
    }

    return <>{renderAlertType()}</>;
  }

  const handleChange = (key, isCheckbox) => (e) => {
    const value = isCheckbox ? e.target.checked : e.target.value;
    setBotSettings((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  return (
    <PRContainer
      smalltalkSelector
      description={"Here you can change the settings of your bot."}
      name="ChatBot"
      parentName="Bot Settings"
    >
      {botSettings?.is_fake ? (
        <BSAlert color="warning">
          <Row className="gx-2 align-items-center">
            <Col xs>
              AI engine for this bot is not enabled. You do not need to train the bot, changes are effective
              immediately.
            </Col>
          </Row>
        </BSAlert>
      ) : (
        <div className="fw-semibold">{renderAlerts()}</div>
      )}
      <PRPage title={botSettings?.name ? `Bot Settings - ${botSettings?.name}` : "Bot Settings"}>
        <Row className="d-flex align-items-center">
          <Col lg={4}>Bot Name:</Col>
          <Col lg={8}>
            <PRInput
              style={{ width: "40%" }}
              type="text"
              value={botSettings?.name || ""}
              onChange={handleChange("name")}
            />
          </Col>
        </Row>
        {userInfo.is_superuser && (
          <Row className="mt-4 d-flex align-items-start">
            <Col lg={4}>Bot Description:</Col>
            <Col lg={8}>
              <PRTextArea
                style={{ width: "40%" }}
                type="text"
                value={botSettings?.awareness_prompt || ""}
                onChange={handleChange("awareness_prompt")}
              />
            </Col>
          </Row>
        )}
        <Row className="mt-4 d-flex align-items-center">
          <Col lg={4}>Bot Enabled: </Col>
          <Col lg={8}>
            <PRInput checked={botSettings?.enabled} type="checkbox" onChange={handleChange("enabled", true)} />
          </Col>
        </Row>
        {/* <Row className="mt-4 d-flex align-items-center">
          <Col lg={4}>Feedback Enabled:</Col>
          <Col lg={8}>
            <PRInput
              type="checkbox"
              checked={botSettings?.feedback_enabled}
              onChange={handleChange("feedback_enabled", true)}
            />
          </Col>
        </Row> */}
        {/* <Row className="mt-4 d-flex align-items-center">
          <Col lg={4}>Message Validation:</Col>
          <Col lg={8}>
            <PRInput
              type="checkbox"
              checked={botSettings?.message_validation}
              onChange={handleChange("message_validation", true)}
            />
          </Col>
        </Row> */}

        <Row className="mt-4 d-flex align-items-center">
          <Col lg={4}>Remaining Train:</Col>
          <Col lg={8}>
            <label>{botSettings?.train_info?.remaining}</label>
          </Col>
        </Row>
        <Row className="mt-4 d-flex align-items-center">
          <Col lg={4}>Train Time:</Col>
          <Col lg={8}>
            {botSettings?.train_info?.train_time ? (
              <label>
                {DateHelper.getDateTimeLocal(botSettings?.train_info?.train_time).format("LLT")} (
                {DateHelper.getDateTimeLocal(botSettings?.train_info?.train_time).fromNow()})
              </label>
            ) : (
              "-"
            )}
          </Col>
        </Row>

        <Row className="mt-4 d-flex align-items-center">
          <Col lg={4}>Go Live Time:</Col>
          <Col lg={8}>
            {botSettings?.train_info?.go_live_time ? (
              <label>
                {DateHelper.getDateTimeLocal(botSettings?.train_info?.go_live_time).format("LLT")} (
                {DateHelper.getDateTimeLocal(botSettings?.train_info?.go_live_time).fromNow()})
              </label>
            ) : (
              "-"
            )}
          </Col>
        </Row>
        <Row>
          <Col className="d-flex justify-content-end" lg={12}>
            <PRButton
              color="primary"
              onClick={(e) => {
                LoadingHelper.open();
                updateBotSettings(currentProject.id, currentBot.id, botSettings)
                  .then((response) => {
                    AlertHelper.show("Updated successfully");
                  })
                  .finally(() => {
                    LoadingHelper.close();
                  });
              }}
            >
              Save Bot Settings
            </PRButton>
          </Col>
        </Row>
      </PRPage>
    </PRContainer>
  );
}

export default BotSettings;
