import { useEffect, useMemo, useRef, useState } from "react";

import { PalAutoComplete, PalButton, PalPage, PalTypography } from "@palamar/fe-library";
import { withCardon } from "cardon";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import styled from "@emotion/styled";
import { Poll } from "@mui/icons-material";
import {
  Avatar,
  Box,
  CircularProgress,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Tab,
  Tabs,
} from "@mui/material";

import useLoading from "~common/hooks/useLoading";
import PRChart from "~components/Generic/PRChart";
import chartTheme from "~components/Generic/PRChart/theme.json";
import PRContainer from "~components/Generic/PRContainer";
import PRModal from "~components/Generic/PRModal";
import PRTable from "~components/Generic/PRTable";
import { apiUrlOrganization } from "~constants";
import debounceAsync from "~helpers/debounceAsync";
import { getReservationMembers, getSurveyResult } from "~store/organization/actions";
import { selectCurrentProject } from "~store/user/selectors";

const chartStyle = {
  height: "600px",
  maxHeight: "50vh",
};

const StyledTab = styled(Tab)`
  font-weight: 600;
`;

const StyledList = styled(List)`
  max-height: 60vh;
  /* margin-left: ${({ theme }) => theme.spacing(2)}; */
  /* margin-right: ${({ theme }) => theme.spacing(2)}; */
  border: 1px solid ${({ theme }) => theme.palette.divider};
  border-radius: 5px;
  overflow-y: auto;
`;

const StyledTitle = styled(PalTypography)`
  margin-left: ${({ theme }) => theme.spacing(4)};
`;

const SurveyResults = () => {
  const dispatch = useDispatch();
  const { id: surveyId } = useParams();
  const currentProject = useSelector(selectCurrentProject);
  const [loading, q] = useLoading();
  const [tab, setTab] = useState(0);

  const [survey, setSurvey] = useState(null);
  const [answers, setAnswers] = useState([]);

  useEffect(() => {
    (async () => {
      const response = await q(dispatch(getSurveyResult(surveyId, currentProject.id)));
      setSurvey(response);
    })();
  }, [q, dispatch, surveyId, currentProject.id]);

  useEffect(() => {
    setAnswers(survey?.answers || []);
  }, [survey]);

  const handleChangeTab = (event, newValue) => {
    setTab(newValue);
  };

  const parentName = [
    {
      label: "Surveys",
      url: "/organization/surveys",
    },
    {
      label: "Survey Results",
    },
  ];

  const showMembersHandler = () => SurveyMemberModal.show({ surveyId });

  const showAnswersHandler = () => {
    const answer = answers.find((_, index) => tab === index);
    SurveyAnswerModal.show({ surveyId, answerId: answer.id, title: answer.question });
  };

  return (
    <PRContainer name="Organization" parentName={parentName}>
      <Grid container spacing={2}>
        {/* {answers.map((answer, index) => (
          <>
          <Grid key={`${index}-header`} item xs={12}>
          <StyledTitle variant="h6">{answer.question}</StyledTitle>
          </Grid>
          <Grid key={`${index}-body`} item xs={12}>
          <SurveyAnswer answer={answer} />
          </Grid>
          </>
          ))} */}
        <Grid item xs={6}>
          <PalPage>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <PalTypography variant="h6">Waiting Count:</PalTypography>
              </Grid>{" "}
              <Grid item xs>
                <PalTypography variant="h6">{survey?.waiting_count}</PalTypography>
              </Grid>
              <Grid item xs={6}>
                <PalTypography variant="h6">Started Count:</PalTypography>
              </Grid>{" "}
              <Grid item xs={6}>
                <PalTypography variant="h6">{survey?.started_count}</PalTypography>
              </Grid>
              <Grid item xs={6}>
                <PalTypography variant="h6">Finished Count:</PalTypography>
              </Grid>{" "}
              <Grid item xs={6}>
                <PalTypography variant="h6">{survey?.finished_count}</PalTypography>
              </Grid>
              <Grid item xs={6}>
                <PalTypography variant="h6">Total Count:</PalTypography>
              </Grid>{" "}
              <Grid item xs={6}>
                <PalTypography variant="h6">{survey?.total_count}</PalTypography>
              </Grid>
            </Grid>
          </PalPage>
        </Grid>
        <Grid item xs={6}>
          <Box display="flex" justifyContent="flex-end">
            <PalButton onClick={showMembersHandler}>Show Members</PalButton>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <PalPage>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Grid container noWrap>
                  <Grid item xs>
                    <Tabs value={tab} variant="scrollable" onChange={handleChangeTab}>
                      {answers.map((answer, index) => (
                        <StyledTab key={index} label={answer.question} />
                      ))}
                    </Tabs>
                  </Grid>
                  <Grid item xs={"auto"}>
                    <PalButton onClick={showAnswersHandler}>Show Answers</PalButton>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                {loading ? (
                  <CircularProgress />
                ) : (
                  answers.map((answer, index) => tab === index && <SurveyAnswer key={index} answer={answer} />)
                )}
              </Grid>
            </Grid>
          </PalPage>
        </Grid>
      </Grid>
    </PRContainer>
  );
};

const SurveyAnswer = ({ answer }) => {
  const options = useMemo(() => {
    const barWidth = 10;

    switch (answer.question_type) {
      case "CHOICE":
        return {
          // tooltip: chartTooltip,
          xAxis: {
            type: "category",
            data: answer.options,
          },
          yAxis: {
            type: "value",
          },
          series: [
            {
              data: Object.entries(answer.result.histogram || {}),
              type: "bar",
              stack: "total",
              name: "Choices",
              itemStyle: { color: chartTheme.theme.color[6] },
            },
          ].map((item) => {
            return {
              ...item,
              // maxBarWidth: `40px`,
              barWidth: `${barWidth}%`,
            };
          }),
        };
      case "RANGE":
        return {
          // tooltip: chartTooltip,
          xAxis: {
            type: "value",
            min: answer.interval[0] - 1,
            max: answer.interval[1] + 1,
          },
          yAxis: {
            type: "value",
          },
          series: [
            {
              data: Object.entries(answer.result.histogram || {}),
              type: "bar",
              stack: "total",
              name: "Range",
              itemStyle: { color: chartTheme.theme.color[6] },
            },
          ].map((item) => {
            return {
              ...item,
              barWidth: `${barWidth}%`,
            };
          }),
        };
      default:
        return {};
    }
  }, [answer]);

  return answer.question_type === "TEXT" ? (
    <TextList data={answer.result.responses} />
  ) : (
    <PalPage>
      <PRChart option={options} style={chartStyle} />
    </PalPage>
  );
};

const TextList = ({ data }) => {
  // const data = [
  //   "metin",
  //   "metin2",
  //   "metin2",
  //   "metin2",
  //   "metin2",
  //   "metin2",
  //   "metin2",
  //   "metin2",
  //   "metin2",
  //   "metin2",
  //   "metin2",
  //   "metin2",
  //   "metin2",
  // ];
  return (
    <StyledList>
      {data.map((item, index) => (
        <ListItem key={index}>
          <ListItemAvatar>
            <Avatar>
              <Poll />
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary={item} />
        </ListItem>
      ))}{" "}
    </StyledList>
  );
};

const SurveyAnswerModal = withCardon(
  function SurveyAnswerModal({ get, answerId, surveyId, title = "Survey Answer" }) {
    const currentProject = useSelector(selectCurrentProject);
    const url = useMemo(() => {
      const url = apiUrlOrganization.getSurveyResult.format(currentProject.id, surveyId) + `?question=${answerId}`;
      return url.toString();
    }, [answerId, surveyId, currentProject.id]);

    const ref = useRef();

    const columns = useMemo(
      () => [
        {
          label: "Member Email",
          value: "member_email",
        },
        {
          label: "Member Name",
          key: "member_name",
        },
        {
          label: "Answer",
          key: "answer",
        },
      ],
      []
    );

    return (
      <PRModal size="xl" submitText={""} title={title} onClose={get(false)}>
        <PRTable ref={ref} columns={columns} responseDataKey="answers_by_users" url={url} />
      </PRModal>
    );
  },
  {
    destroyOnHide: true,
  }
);

const SurveyMemberModal = withCardon(
  function SurveyAgentModal({ get, surveyId, title = "Survey Member" }) {
    const dispatch = useDispatch();
    const currentProject = useSelector(selectCurrentProject);

    const [agent, setAgent] = useState(undefined);

    const url = useMemo(() => {
      const url =
        apiUrlOrganization.getSurveyResult.format(currentProject.id, surveyId) + (agent ? `?member=${agent}` : "");
      return url;
    }, [surveyId, currentProject.id, agent]);

    const handleAgentChange = (e, id) => {
      setAgent(id);
    };

    const columns = useMemo(
      () => [
        {
          label: "Member Email",
          value: "member_email",
        },
        {
          label: "Member Name",
          key: "member_name",
        },
        {
          label: "Answer",
          key: "answer",
        },
      ],
      []
    );

    const handleLoadOptions = debounceAsync(async (searchText, callback, signal) => {
      const response = await dispatch(
        getReservationMembers(
          currentProject.id,
          {
            limit: 20,
            email__icontains: searchText,
          },
          {
            signal,
            loading: false,
          }
        )
      );

      const options = response.results.map((item) => {
        return {
          value: item.id,
          label: item.safe_information?.email,
        };
      });

      return options;
    }, 500);

    const ref = useRef();

    return (
      <PRModal size="xl" submitText="" title={title} onClose={get(false)}>
        <Box mb={2}>
          <PalAutoComplete
            initialFetch
            isPrimitiveValue
            lazy
            label="Member"
            loadOptions={handleLoadOptions}
            value={agent}
            onChange={handleAgentChange}
          />
        </Box>
        <PRTable ref={ref} columns={columns} responseDataKey="answers_by_users" url={url} />
      </PRModal>
    );
  },
  {
    destroyOnHide: true,
  }
);

export default SurveyResults;
