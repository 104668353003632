// import { apiUrlStatistics } from "~constants";
// import Network from "~helpers/Network";

import { apiUrlStatisticGenerator } from "~constants";
import Network from "~helpers/Network";

import * as at from "./actionTypes";

export const setStatisticGenerator = (payload) => ({
  type: at.SET_STATISTICS_GENERATOR,
  payload,
});

export const getStatisticGenerator = (projectId, id) => (_dispatch, _getState) => {
  const url = `${apiUrlStatisticGenerator.get.format(projectId)}${id}/`;
  return Network.request(url, {
    loading: true,
    onSuccess: setStatisticGenerator,
  });
};

export const createOrUpdateStatisticGenerator =
  (projectId, data, options = {}) =>
  (_dispatch, _getState) => {
    let url = `${apiUrlStatisticGenerator.get.format(projectId)}`;
    if (data.id) {
      url += data.id + "/";
    }

    return Network.request(url, {
      method: data.id ? "PUT" : "POST",
      data,
      loading: true,
      successMsg: true,
      ...options,
    });
  };

// export const setWeeklySessions = (payload) => ({
//   type: at.SET_WEEKLY_SESSIONS,
//   payload,
// });

// export const setWeeklyTickets = (payload) => ({
//   type: at.SET_WEEKLY_TICKETS,
//   payload,
// });

// export const setAgentStatistics = (payload) => ({
//   type: at.SET_AGENT_STATISTICS,

//   payload,
// });

// export const setAgentTicketStatistics = (payload) => ({
//   type: at.SET_AGENT_TICKET_STATISTICS,
//   payload,
// });

// export const setTicketStatistics = (payload) => ({
//   type: at.SET_TICKET_STATISTICS,
//   payload,
// });
// export const setGenericStatistics = (payload) => ({
//   type: at.SET_GENERIC_STATISTICS,
//   payload,
// });

// export const getChatbotStatistics = (projectId, botId) => (_dispatch, _getState) => {
//   const url = `${apiUrlStatistics.getChatbot.format(projectId, botId)}`;
//   return Network.request(url, {
//     onSuccess: setChatbotStatistics,
//   });
// };

// export const getWeeklyTickets = (projectId) => (_dispatch, _getState) => {
//   const url = `${apiUrlStatistics.getWeeklyTickets.format(projectId)}`;
//   return Network.request(url, {
//     loading: true,
//     onSuccess: setWeeklyTickets,
//   });
// };

// export const getAgentStatistics = (projectId, agentId) => (_dispatch, _getState) => {
//   const url = `${apiUrlStatistics.getAgent.format(projectId, agentId)}`;
//   return Network.request(url, {
//     onSuccess: setAgentStatistics,
//   });
// };

// export const getAgentTicketStatistics = (projectId, agentId) => (_dispatch, _getState) => {
//   const url = `${apiUrlStatistics.getAgentTicket.format(projectId, agentId)}`;
//   return Network.request(url, {
//     loading: true,
//     onSuccess: setAgentTicketStatistics,
//   });
// };

// export const getTicketStatistics =
//   (projectId, data = {}) =>
//   () => {
//     const url = `${apiUrlStatistics.getTicketStatistics.format(projectId)}`;

//     return Network.request(url, {
//       method: "POST",
//       loading: true,
//       onSuccess: setTicketStatistics,
//       data,
//     });
//   };

// export const getGenericStatistics =
//   (projectId, data, options = {}) =>
//   (_dispatch, _getState) => {
//     const url = `${apiUrlStatistics.getGenericStatistics.format(projectId)}`;
//     return Network.request(url, {
//       loading: false,
//       method: "POST",
//       data,
//       onSuccess: setGenericStatistics,
//       ...options,
//     });
//   };

// export const downloadStatistics =
//   (projectId, data, options = {}) =>
//   (_dispatch, _getState) => {
//     const url = `${apiUrlStatistics.getGenericStatistics.format(projectId)}?excel=True`;
//     return Network.request(url, {
//       method: "POST",
//       responseType: "blob",
//       data,
//       loading: true,
//       ...options,
//     });
//   };

export const deleteStatisticGenerator =
  (projectId, generatorId, options = {}) =>
  (_dispatch, _getState) => {
    const url = `${apiUrlStatisticGenerator.get.format(projectId)}${generatorId}/`;
    return Network.request(url, {
      method: "DELETE",
      loading: true,
      ...options,
    });
  };

export const generateAsyncStatisticGenerator =
  (projectId, generatorId, options = {}) =>
  (_dispatch, _getState) => {
    const url = `${apiUrlStatisticGenerator.generateAsync.format(projectId, generatorId)}`;
    return Network.request(url, {
      method: "POST",
      loading: true,
      successMsg: false,
      ...options,
    });
  };
export const generateStatisticGenerator =
  (projectId, generatorId, options = {}) =>
  (_dispatch, _getState) => {
    const url = `${apiUrlStatisticGenerator.generate.format(projectId, generatorId)}`;
    return Network.request(url, {
      method: "POST",
      loading: true,
      successMsg: false,
      ...options,
    });
  };

export const downloadStatisticGeneratorReport =
  (projectId, reportId, options = {}) =>
  (_dispatch, _getState) => {
    const url = `${apiUrlStatisticGenerator.downloadReport.format(projectId, reportId)}`;
    return Network.request(url, {
      method: "GET",
      loading: true,
      ...options,
    });
  };
// export const deleteStatisticGeneratorProcedure = (projectId, botId, id) => (_dispatch, _getState) => {
//   const url = apiUrlStatisticGenerator.getProcedure.format(projectId, botId, id);

//   return Network.request(url, {
//     method: "DELETE",
//     loading: true,
//     successMsg: true,
//   });
// };

// export const getStatisticGeneratorProcedureVersion =
//   (projectId, botId, procedureId, versionId, params) => (_dispatch, _getState) => {
//     const url = apiUrlStatisticGenerator.getProcedure, apiUrlChatbot.getProcedureVersion.format(projectId, botId, procedureId, versionId);

//     return Network.request(url, {
//       loading: true,
//       params,
//     });
//   };

// export const getStatisticGeneratorProcedureList = (projectId, botId, params) => (_dispatch, _getState) => {
//   const url = apiUrlChatbot.getProcedure.format(projectId, botId);

//   return Network.request(url, {
//     loading: true,
//     params: { limit: 9999, ...params },
//     onSuccess: ({ results = [] }) => setProcedureList(results),
//   });
// };

// export const createOrUpdateStatisticGeneratorProcedure =
//   (projectId, botId, data, options = {}) =>
//   (_dispatch, _getState) => {
//     let url = apiUrlChatbot.getProcedure.format(projectId, botId);
//     if (data.id) {
//       url += data.id + "/";
//     }

//     return Network.request(url, {
//       method: data.id ? "PUT" : "POST",
//       data,
//       loading: true,
//       successMsg: true,
//       ...options,
//     });
//   };
