import { useEffect, useMemo, useRef, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Alert, Col, Row } from "reactstrap";

import PRButton from "~components/Generic/PRButton";
import PRContainer from "~components/Generic/PRContainer";
import PRPage from "~components/Generic/PRPage";
import HistoryHelper from "~helpers/HistoryHelper";
import { createOrUpdateAction, getAction, getFilterList, setAction } from "~store/organization/actions";
import { selectAction } from "~store/organization/selectors";
import { selectCurrentProject } from "~store/user/selectors";

import ActionRule from "./ActionRule";

import "./style.scss";

export default function ActionsEdit() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const currentProject = useSelector(selectCurrentProject);
  const actionRuleRef = useRef();
  const [isTemplate, setIsTemplate] = useState();
  const action = useSelector(selectAction);

  useEffect(() => {
    dispatch(getFilterList(currentProject.id));
  }, [dispatch, currentProject.id]);

  useEffect(() => {
    setIsTemplate(action?.is_template);
  }, [action?.is_template]);

  useEffect(() => {
    if (id) {
      dispatch(getAction(id, currentProject.id));
    }
    return () => {
      dispatch(setAction(null));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, id, currentProject.id]);

  const handleClickCancel = () => {
    HistoryHelper.goBack("/organization/action-templates", { scope: "dashboard" });
  };
  const parentName = [
    {
      label: "Action Templates",
      url: "/organization/action-templates",
    },
    {
      label: `Action Template ${id ? "Edit" : "Create"}`,
    },
  ];

  console.log("actionRuleRef", actionRuleRef);
  const handleSave = async () => {
    const values = await actionRuleRef.current?.handleSubmit();
    if (!values) return;

    await dispatch(createOrUpdateAction(values, currentProject.id));
    HistoryHelper.goBack("/organization/action-templates", { scope: "dashboard" });
  };

  const combinedAction = useMemo(() => {
    return { ...action, is_template: isTemplate };
  }, [action, isTemplate]);

  return (
    <PRContainer className="" name="Organization" parentName={parentName}>
      <PRPage className="" title={`Action ${id ? "Edit" : "Create"}`}>
        {combinedAction?.id && !combinedAction?.is_template && (
          <Alert className="mb-3" color="info">
            You are currently in read-only mode because this content is not recognized as a template.
          </Alert>
        )}
        <div disabled={combinedAction?.id && !combinedAction?.is_template}>
          <ActionRule ref={actionRuleRef} fromActionPage action={combinedAction} />
        </div>
        <Row className="g-0 justify-content-end mt-2">
          <Col md="auto">
            <PRButton outline className="" onClick={handleClickCancel}>
              Cancel
            </PRButton>
            <PRButton
              className="ms-2"
              disabled={combinedAction?.id && !combinedAction?.is_template}
              onClick={handleSave}
            >
              Save
            </PRButton>
          </Col>
        </Row>
      </PRPage>
    </PRContainer>
  );
}
