import { createRef, useMemo } from "react";

import { useDispatch } from "react-redux";

import PRButton from "~components/Generic/PRButton";
import PRContainer from "~components/Generic/PRContainer";
import PRTable from "~components/Generic/PRTable";
import { apiUrlGuide, guideTypeMap, tableFilterStorageKey } from "~constants";
import DialogHelper from "~helpers/DialogHelper";
import { deleteGuide } from "~store/settings/guideDefinition/actions";

import "./style.scss";
import { MdAdd, MdDelete, MdEdit } from "react-icons/md";

export default function GuideDefinitionList() {
  const dispatch = useDispatch();

  const tableRef = createRef();

  const columns = useMemo(() => {
    const handleDelete = (row) => async () => {
      if (!(await DialogHelper.showQuestionDelete())) return;
      await dispatch(deleteGuide(row.id));
      tableRef.current?.refresh();
    };
    return [
      {
        label: "Title",
        key: "title",
      },
      {
        label: "Regex",
        key: "test_regex",
      },
      {
        label: "Type",
        key: "type",
        render: (row) => {
          return guideTypeMap[row.type] || row.type;
        },
      },
      {
        label: "Actions",
        key: "actions",
        actions: true,
        render: (row) => (
          <div className="d-flex justify-content-center">
            <PRButton
              outline
              color="primary"
              icon={MdEdit}
              link={`/settings/guide-definition/form/${row.id}`}
              size="sm"
              tooltipText="Edit"
            />
            <PRButton
              outline
              className="ms-1"
              color="danger"
              icon={MdDelete}
              size="sm"
              tooltipText="Delete"
              onClick={handleDelete(row)}
            />
          </div>
        ),
      },
    ];
  }, [dispatch, tableRef]);

  const actions = useMemo(() => {
    return [
      {
        label: "Create New",
        icon: MdAdd,
        color: "success",
        link: "/settings/guide-definition/form",
      },
    ];
  }, []);

  const filters = useMemo(() => [], []);

  return (
    <PRContainer
      actions={actions}
      className=""
      description="Here you can manage your guide definitions to show guide on your pages"
      name="Settings"
      parentName="Guide Definition"
    >
      <PRTable
        ref={tableRef}
        columns={columns}
        filters={filters}
        storageKey={tableFilterStorageKey.guideDefinitionList}
        url={apiUrlGuide.get}
      />
    </PRContainer>
  );
}
