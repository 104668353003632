import { useState } from "react";

import { withCardon } from "cardon";
import Cron, { HEADER } from "react-cron-generator";

import PRModal from "~components/Generic/PRModal";

import "./style.scss";

const options = {
  headers: [HEADER.MONTHLY, HEADER.WEEKLY, HEADER.MINUTES, HEADER.HOURLY, HEADER.DAILY],
};

function CrontabEditor({ get, value = "" }) {
  //TODO: initial value assignment does not work properly for "react-cron-generator"
  const [val, setVal] = useState("");

  const handleSubmit = () => {
    get(val)();
  };

  return (
    <PRModal size="lg" submitText={"Submit"} title="Crontab Editor" onClick={handleSubmit} onClose={get(false)}>
      <div className="d-flex justify-content-center pr-crontab-editor">
        <Cron options={options} showResultCron={false} showResultText={true} value={val} onChange={setVal} />
      </div>
    </PRModal>
  );
}
const CrontabEditorModal = withCardon(CrontabEditor, { destroyOnHide: true });
export default CrontabEditorModal;
