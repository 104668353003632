import { PalIconButton, PalTooltip } from "@palamar/fe-library";
import { SiMicrosoftexcel } from "react-icons/si";

const ExcelButton = ({ onClick }) => {
  return (
    <PalTooltip title="Export to Excel">
      <PalIconButton color="primary" onClick={onClick}>
        <SiMicrosoftexcel />
      </PalIconButton>
    </PalTooltip>
  );
};

export default ExcelButton;
