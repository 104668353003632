import { useMemo, useState } from "react";

import { format, parse } from "libphonenumber-js";
import _ from "lodash";
import { MdCircle, MdExpandLess, MdExpandMore, MdInfo } from "react-icons/md";
import { useSelector } from "react-redux";

import styled from "@emotion/styled";
import { Collapse, Grid, Tab, Tabs } from "@mui/material";

import { useDebouncedEffect } from "~common/hooks/useDebounceEffect";
import PalButton from "~components/mui/PalButton";
import PalLink from "~components/mui/PalLink";
import PalTooltip from "~components/mui/PalTooltip";
import DateHelper from "~helpers/DateHelper";
import { selectSelectedSession } from "~store/socket/livechat/selectors";
import { selectUserInfo } from "~store/user/selectors";

const StyledDiv = styled.div`
  /* width: 90%; */
  text-align: center;
  /* .pr-tab {
    overflow-y: hidden;
    overflow-x: auto;
    white-space: nowrap;
    .nav-item {
      min-width: 120px;
    }
  } */
`;

const StyledTooltipDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
`;

const StyledButton = styled(PalButton)`
  position: relative;
  margin-bottom: -18px;
  border-width: 1px;
  border-radius: 4px;
  border-color: #dddddd;
  background-color: #ffffffff;
  && {
    height: 36px;
  }
  width: 70%;
  min-width: 250px;
  padding: 10.5px 33.5px;
  span {
    font-size: 13px;
    color: #000;
    align-items: center;
    justify-content: space-between;
  }
  :hover {
    background-color: #f8f8f8;
  }
`;

const StyledLeftGrid = styled(Grid)`
  & > div {
    display: flex;
    /* text-align: left; */
    align-items: start;
    justify-content: space-between;
    padding: 5px;
    ${(props) => props.theme.breakpoints.down("md")} {
      align-items: center;
      text-align: center;
      justify-content: center;
      padding-top: ${(props) => props.theme.spacing(2)};
    }
    div {
      font-weight: 500px;
      font-size: 13px;
      line-height: 13px;
    }
  }
`;

const StyledSemicolonDiv = styled.div`
  ${(props) => props.theme.breakpoints.down("md")} {
    display: none;
  }
`;

const StyledRightGrid = styled(Grid)`
  & > div {
    display: flex;
    font-size: 13px;

    text-align: left;
    align-items: start;
    padding: 5px;
    ${(props) => props.theme.breakpoints.down("md")} {
      align-items: center;
      text-align: center;
      justify-content: center;
    }
    b {
      font-weight: 300;
      font-size: 13px;
      line-height: 13px;
    }
  }
`;

const StyledCollapse = styled(Collapse)`
  background-color: #f8f8f8;
  padding: 30px;
  padding-top: 35px;
  border-width: 1px;
  border-radius: 4px;
  border-color: #e3e3e3;
  border-style: solid;
  max-width: 540px;
`;

const StyledIconDiv = styled.span`
  position: absolute;
  right: 10px;
`;

const LeftGrid = ({ children, ...rest }) => {
  return (
    <StyledLeftGrid item direction="row" md="4" xs="12" {...rest}>
      <div>{children}</div>
    </StyledLeftGrid>
  );
};

const RightGrid = ({ children, ...rest }) => {
  return (
    <StyledRightGrid item md="8" xs="12" {...rest}>
      <div>{children}</div>
    </StyledRightGrid>
  );
};

export default function SessionMetadataMessage({ distributedRef, connections = [], customer }) {
  const [open, setOpen] = useState(false);
  const [tab, setTab] = useState("metadata");
  const [stateChanging, setStateChanging] = useState(false);

  const userInfo = useSelector(selectUserInfo);

  useSelector(selectSelectedSession);
  const toggle = () => {
    distributedRef.current.disableScrollOnMessage = true;
    setOpen(!open);
    setStateChanging(true);
  };
  const handleTabChange = (e, tab) => {
    distributedRef.current.disableScrollOnMessage = true;
    setTab(tab);
    setStateChanging(true);
  };

  useDebouncedEffect(
    () => {
      if (stateChanging) {
        distributedRef.current.disableScrollOnMessage = false;
        setStateChanging(false);
      }
    },
    [open, stateChanging],
    400
  );

  useDebouncedEffect(
    () => {
      if (stateChanging) {
        distributedRef.current.disableScrollOnMessage = false;
        setStateChanging(false);
      }
    },
    [tab, stateChanging],
    200
  );
  const mappedConnections = connections.map((item) => ({
    ...item,
    lastAccess: DateHelper.getDateTime(item.last_access),
  }));

  const orderedConnections = _.orderBy(mappedConnections, ["lastAccess"], ["desc"]);
  const primaryConnection = orderedConnections.find((item) => item.online) || orderedConnections[0];
  // const metadata = primaryConnection?.metadata || {};

  const tabList = useMemo(() => {
    const tabs = [];

    for (const c of mappedConnections) {
      tabs.push({
        id: c.connection_id,
        label: c.online ? (
          <span className="d-flex align-items-center">
            {`Tab - ${c.connection_id}`}
            <MdCircle className="fs-5 text-success ms-1" />
          </span>
        ) : (
          <> {`Tab - ${c.connection_id}`}</>
        ),
      });
    }
    return tabs;
  }, [mappedConnections]);

  const activeConnection = useMemo(() => {
    return mappedConnections.find((item) => item.connection_id === tab) || primaryConnection;
  }, [mappedConnections, tab, primaryConnection]);

  const hasMultipleConnections = connections.length > 1;
  const metadata = useMemo(() => {
    return activeConnection?.metadata || {};
  }, [activeConnection?.metadata]);

  const { whatsappNumber, formattedWhatsappNumber } = useMemo(() => {
    const whatsapp = customer?.phone_number || metadata?.whatsapp_phone_number;
    let parsedNumber;
    try {
      parsedNumber = parse(whatsapp);
    } catch (error) {}
    return {
      whatsappNumber: whatsapp,
      formattedWhatsappNumber: parsedNumber && format(parsedNumber, "INTERNATIONAL"),
    };
  }, [metadata, customer]);

  return (
    <StyledDiv>
      <div>
        <StyledButton curved={false} onClick={toggle}>
          <span>METADATA INFORMATION</span>
          <StyledIconDiv>{open ? <MdExpandMore size={20} /> : <MdExpandLess size={20} />}</StyledIconDiv>
        </StyledButton>
      </div>
      {/* <StyledDivider /> */}
      <StyledCollapse in={open}>
        {hasMultipleConnections && (
          <Tabs value={tab} onChange={handleTabChange}>
            {tabList.map((item) => (
              <Tab key={item.id} label={item.label} value={item.id} />
            ))}
          </Tabs>
        )}
        <Grid container>
          {userInfo.is_superuser && whatsappNumber && (
            <>
              <LeftGrid>
                <div>Phone Number</div>
                <StyledSemicolonDiv>:</StyledSemicolonDiv>
              </LeftGrid>
              <RightGrid>
                <b>
                  <PalLink newTab noPrefix to={`https://wa.me/${whatsappNumber}`}>
                    {formattedWhatsappNumber}
                  </PalLink>
                </b>
              </RightGrid>
            </>
          )}
          {metadata?.uuid && (
            <>
              <LeftGrid>
                <StyledTooltipDiv>
                  Tracking ID
                  <PalTooltip
                    title={
                      "The unique identifier of the page visitor's device. This is used to identify the same visitor across multiple sessions."
                    }
                  >
                    <div>
                      <MdInfo />
                    </div>
                  </PalTooltip>
                </StyledTooltipDiv>
                <StyledSemicolonDiv>:</StyledSemicolonDiv>
              </LeftGrid>

              <RightGrid>
                <b>{metadata?.uuid}</b>
              </RightGrid>
            </>
          )}

          <LeftGrid>
            <div> Page Title </div>
            <StyledSemicolonDiv>:</StyledSemicolonDiv>
          </LeftGrid>
          <RightGrid>
            <b>{metadata?.title}</b>
          </RightGrid>

          <LeftGrid>
            <div> Start Date </div>
            <StyledSemicolonDiv>:</StyledSemicolonDiv>
          </LeftGrid>
          <RightGrid>
            <b>{DateHelper.getDateTimeLocal(activeConnection?.started).format("LLT")}</b>
          </RightGrid>

          <LeftGrid>
            <div> Last Access </div>
            <StyledSemicolonDiv>:</StyledSemicolonDiv>{" "}
          </LeftGrid>
          <RightGrid>
            <b>{DateHelper.getDateTimeLocal(activeConnection?.last_access).format("LLT")}</b>
          </RightGrid>

          <LeftGrid>
            <div> Platform </div>
            <StyledSemicolonDiv>:</StyledSemicolonDiv>
          </LeftGrid>
          <RightGrid>
            <b>{metadata?.platform}</b>
          </RightGrid>

          <LeftGrid>
            <div> OS </div>
            <StyledSemicolonDiv>:</StyledSemicolonDiv>{" "}
          </LeftGrid>
          <RightGrid>
            <b>
              {metadata?.os} ({metadata?.core} Core)
            </b>
          </RightGrid>

          <LeftGrid>
            <div> Language </div>
            <StyledSemicolonDiv>:</StyledSemicolonDiv>
          </LeftGrid>
          <RightGrid>
            <b>{metadata?.language}</b>
          </RightGrid>

          <LeftGrid>
            <div> Screen Resolution </div>
            <StyledSemicolonDiv>:</StyledSemicolonDiv>
          </LeftGrid>
          <RightGrid>
            <b>{metadata?.screen_resolution}</b>
          </RightGrid>

          <LeftGrid>
            <div>Browser </div>
            <StyledSemicolonDiv>:</StyledSemicolonDiv>
          </LeftGrid>
          <RightGrid>
            <b>
              {metadata?.browser} ({metadata?.browser_version})
            </b>
          </RightGrid>

          <LeftGrid>
            <div>Timezone </div>
            <StyledSemicolonDiv>:</StyledSemicolonDiv>
          </LeftGrid>
          <RightGrid>
            <b>{metadata?.timezone}</b>
          </RightGrid>

          <LeftGrid>
            <div>Cookies Enabled </div>
            <StyledSemicolonDiv>:</StyledSemicolonDiv>
          </LeftGrid>
          <RightGrid>
            <b>{metadata?.cookies_enabled ? "Yes" : "No"}</b>
          </RightGrid>

          <LeftGrid>
            <div>Do Not Track </div>
            <StyledSemicolonDiv>:</StyledSemicolonDiv>
          </LeftGrid>
          <RightGrid>
            <b>{metadata?.do_not_track ? "Yes" : "No"}</b>
          </RightGrid>

          <LeftGrid>
            <div>Startup URL </div>
            <StyledSemicolonDiv>:</StyledSemicolonDiv>
          </LeftGrid>
          <RightGrid>
            <b>{metadata?.href}</b>
          </RightGrid>

          <LeftGrid>
            <div>Referrer </div>
            <StyledSemicolonDiv>:</StyledSemicolonDiv>
          </LeftGrid>
          <RightGrid>
            <b>{metadata?.referrer}</b>
          </RightGrid>

          <LeftGrid>
            <div>User Agent </div>
            <StyledSemicolonDiv>:</StyledSemicolonDiv>
          </LeftGrid>
          <RightGrid>
            <b>{metadata?.user_agent}</b>
          </RightGrid>
        </Grid>
      </StyledCollapse>
    </StyledDiv>
  );
}
