import React, { useEffect } from "react";

import { useFormik } from "formik";
import ReCAPTCHA from "react-google-recaptcha";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import * as Yup from "yup";

import styled from "@emotion/styled";
import { Grid, Alert, FormHelperText, Box } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";

import { ReactComponent as Logo } from "~assets/images/palmate-logo.svg";
import { useDebouncedEffect } from "~common/hooks/useDebounceEffect";
import useQueryParams from "~common/hooks/useQueryParams";
import Footer, { PrivacyPolicy, TermsOfUse } from "~components/Layout/Footer";
import PalButton from "~components/mui/PalButton";
import PalCheckbox from "~components/mui/PalCheckbox";
import PalLink from "~components/mui/PalLink";
import PalTextField from "~components/mui/PalTextField";
import AlertHelper from "~helpers/AlertHelper";
import DateHelper from "~helpers/DateHelper";
import DialogHelper from "~helpers/DialogHelper";
import HistoryHelper from "~helpers/HistoryHelper";
import SanitizeHelper from "~helpers/SanitizeHelper";
import TokenHelper from "~helpers/TokenHelper";
import Utils from "~helpers/Utils";
import { checkUsername, getInvitationInfo, register, registerCompany } from "~store/actions";

const StyledDiv = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #fff;
  position: absolute;
  width: 100%;
  height: calc(100% - 80px);
  padding: 30px 50px;
  @media (max-width: 600px) {
    padding: 30px 20px;
  }

  overflow-y: auto;
  svg {
    flex: 0 0 auto;
  }
  .input-box {
    margin: 100px auto auto auto;
    @media (max-width: 600px) {
      max-width: 100%;
      margin: 30px auto auto;
    }

    max-width: 555px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    .title {
      color: ${(props) => props.theme.palette.primary.dark};
      //
      font: normal normal 600 36px/34px MuseoModerno;
      height: 57px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .desc {
      font: normal normal normal 22px/28px Museo Sans;
      color: ${(props) => props.theme.palette.primary.dark};
      letter-spacing: 0;
      margin-bottom: 20px;
      // text-align: center;
    }
    .company {
      font: normal normal normal 16px/20px Museo Sans;

      margin-bottom: 10px;
    }
    .checkbox {
      max-width: calc(100vw - 100px);
      @media (max-width: 600px) {
        max-width: calc(100vw - 40px);
      }
      margin: -12px -30px -6px -30px;
      font-family: MuseoSans;
      font-size: 14px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 20px;
      text-align: left;
      color: #535353;
    }
    .forgot-password {
      color: ${(props) => props?.theme?.palette?.primary?.light};

      font: normal normal normal 16px/20px Museo Sans;
      letter-spacing: 0;
      cursor: pointer;
      display: flex;
      justify-content: center;
    }
  }

  .divider {
    height: 1px;
    background-color: #ddd;
    margin: 0px 25px;
    width: calc(100% - 50px);
  }
  .divider-label {
    display: flex;
    align-items: center;
    gap: 10px;
    font-family: Museo Sans;
    font-size: 13px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
    color: #535353;
    margin: 0px 25px;
    .divider {
      margin: 0px;
    }
  }
  .MuiLink-root {
    color: #535353;
    text-decoration: underline !important;
  }
  .register-btn {
    max-width: 365px;
    font-weight: bold;
    background-color: ${(props) => props.theme.palette.primary.light};
    border-color: ${(props) => props.theme.palette.primary.light};
  }
  .login {
    font-family: Museo Sans;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    text-align: center;
    color: #838383;
    a {
      margin-left: 5px;
      color: #50c8f4;
      text-decoration: none !important;
    }
  }
  .error-text {
    margin: 0px 25px;
    font: normal normal normal 14px/20px Museo Sans;
  }
`;
const StyledRecaptcha = styled(ReCAPTCHA)`
  display: flex;
  justify-content: center;
  align-items: center;
`;
const Register2 = () => {
  //meta title
  document.title = "Register | Palmate";
  const dispatch = useDispatch();

  const [alertInfo, setAlertInfo] = React.useState({
    msg: "",
    color: "success",
  });

  const { t } = useTranslation();

  const [isLoading, setIsLoading] = React.useState(false);
  const [invitationInfo, setInvitationInfo] = React.useState({
    project_name: "",
    email: "",
    expires_in: "",
    isValid: false,
    invalid: false,
  });
  const [usernameStatus, setUsernameStatus] = React.useState({
    loading: false,
    isValid: null,
  });
  const { invite_token } = useQueryParams();

  const formik = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      firstname: "",
      lastname: "",
      email: invitationInfo?.email || "",
      username: "",
      password: "",
      confirm_password: "",
      company_name: "",
      address: "",
      country: "",
      terms: false,
      captcha: "",
    },
    validationSchema: Yup.object({
      firstname: Yup.string().required("Please Enter Your Name"),
      lastname: Yup.string().required("Please Enter Your Name"),
      email: Yup.string()
        .required("Please Enter Your Email")
        .test("email", "Please Enter Valid Email", (value) => {
          return Utils.validateEmail(value);
        }),
      username: Yup.string()
        .required("Please Enter Your Username")
        .min(3, "Username must be at least 3 characters")
        .test("no-whitespace", "Username cannot contain whitespace", (value) => !/\s/.test(value))
        .test("valid-characters", "Username can only contain letters and '-' character", (value) => {
          return Utils.isValidUsername(value);
        })
        .test(t("register.usernameTaken"), (value) => {
          if (!invitationInfo.isValid || usernameStatus.isValid === null || value?.length < 3) return true;

          return usernameStatus.isValid === true;
        }),
      password: Yup.string()
        .required("Please Enter Your Password")
        .min(8, "Password must be at least 8 characters")
        .max(64, "Password must be at most 64 characters")
        .test(
          "passwordComplexity",
          "Password must contain at least one uppercase, one lowercase, one number, and one special character",
          (value) => Utils.validatePassword(value)
        ),
      ...(!invite_token && {
        company_name: Yup.string().required("Please Enter Your Company Name"),
        country: Yup.string().required("Please Enter Your Country"),
      }),
      confirm_password: Yup.string()
        .required("Please Enter Your Confirm Password")
        .oneOf([Yup.ref("password"), null], "Passwords must match"),
      terms: Yup.bool().oneOf([true], "Please Accept Privacy Policy and Terms of Use"),
      captcha: Yup.string().required("Please complete the captcha"),
    }),
    onSubmit: (values) => {
      setIsLoading(true);
      let payload = {
        username: values.username?.normalizeAccent()?.toLowerCase()?.trim(),
        password: values.password?.trim(),
        firstname: SanitizeHelper.safeText(values.firstname?.trim()),
        lastname: SanitizeHelper.safeText(values.lastname?.trim()),
        email: values.email?.normalizeAccent("", true)?.toLowerCase()?.trim(),
        ...(!invite_token && {
          company_name: values.company_name?.trim(),
          address: values.address?.trim(),
          country: values.country?.trim(),
        }),
        captcha: values.captcha,
      };
      const registerFunction = invite_token ? register : registerCompany;
      dispatch(registerFunction(payload, invite_token))
        .then(() => {
          setAlertInfo({
            msg: "Registration Successful. Redirecting to Login Page...",
            color: "success",
          });
          setTimeout(() => {
            window.location = "/login/";
          }, 3000);
        })
        .catch(() => {
          AlertHelper.show("An error occurred", "error");
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
  });

  useEffect(() => {
    if (!invite_token) {
      TokenHelper.getJwtIfValid().then((jwt) => {
        if (jwt) {
          HistoryHelper.push("/dashboard");
        }
      });
    } else {
      dispatch(getInvitationInfo(invite_token))
        .then((res) => {
          const isRegistered = res.is_registered;
          if (isRegistered) {
            HistoryHelper.replace(
              { pathname: "/join-project", search: Utils.qs({ invite_token: invite_token }) },
              {
                scope: "",
              }
            );
          }
          const expiresIn = DateHelper.getDateTime(res.expires_in);
          const now = DateHelper.getDateTime();
          const isValid = expiresIn > now;
          setInvitationInfo((prev) => ({ ...prev, ...res, isValid }));

          if (!isValid) {
            setAlertInfo({
              msg: "Invite token is expired.",
              color: "error",
            });
          }
        })
        .catch(() => {
          setInvitationInfo({
            invalid: true,
          });
          setAlertInfo({
            msg: "Invite token is invalid.",
            color: "error",
          });
        });
    }
  }, [dispatch, invite_token]);

  const handleOpenOk = (title, body) => () => {
    DialogHelper.showOk(
      title,
      <div className="text-start">{body}</div>,

      null,
      {
        dialogOptions: {
          maxWidth: "md",
        },
      }
    );
  };

  useDebouncedEffect(
    () => {
      if (!invite_token || !invitationInfo.isValid || formik.values.username?.length < 3) return;
      const controller = new AbortController();
      setUsernameStatus((prev) => ({ ...prev, loading: true }));
      dispatch(
        checkUsername(invite_token, formik.values.username, {
          signal: controller.signal,
        })
      )
        .then((res) => {
          setUsernameStatus((prev) => ({ ...prev, loading: false, isValid: res?.status === "success" }));
        })
        .catch((res) => {
          setUsernameStatus((prev) => ({ ...prev, loading: false, isValid: false }));
        });
      return () => {
        controller.abort();
      };
    },
    [formik.values.username, invitationInfo.isValid, invite_token],
    500
  );
  // const checkUsername = () => {
  //   const dispatch
  // };
  // const debouncedCheckUsername = React.useCallback(debounce(checkUsername, 500), []);

  const handleChangeUsername = (e) => {
    formik.handleChange(e);
    if (!invite_token || !invitationInfo.isValid) return;
    setUsernameStatus((prev) => ({ ...prev, loading: true }));
  };
  const disabled = invite_token && !invitationInfo.isValid;

  const usernameInvalid =
    (formik.values.username?.length > 3 &&
      invitationInfo.isValid &&
      usernameStatus.isValid === false &&
      t("register.usernameTaken")) ||
    (formik.touched.username && formik.errors.username);

  const handleCaptchaError = () => {
    formik.setFieldValue("captcha", "");
  };

  const handleChangeCaptcha = (value) => {
    formik.setFieldValue("captcha", value);
  };
  return (
    <StyledDiv>
      {/* <div className="wrapper"> */}

      <div>
        <PalLink noPrefix to="/">
          <Logo />
        </PalLink>
      </div>
      <div className="input-box">
        <Grid container columnSpacing={"15px"} rowSpacing={"25px"}>
          <Grid item xs={12}>
            <div className="title">Register {!invite_token && "your Company"}</div>
            <div className="desc">Get your Palmate account now.</div>
            <div className="company">{!!invitationInfo?.project_name && `${invitationInfo?.project_name}`} </div>
          </Grid>
          {alertInfo?.msg && (
            <Grid item xs={12}>
              <Alert fullWidth severity={alertInfo.color}>
                {alertInfo.msg}
              </Alert>
            </Grid>
          )}
          {!invite_token && (
            <>
              <Grid item xs={12}>
                <PalTextField
                  fullWidth
                  disabled={disabled}
                  invalid={formik.touched.company_name && formik.errors.company_name}
                  label="Company Name"
                  name="company_name"
                  value={formik.values.company_name}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                />
              </Grid>
              <Grid item xs={6}>
                <PalTextField
                  fullWidth
                  disabled={disabled}
                  invalid={formik.touched.country && formik.errors.country}
                  label="Country"
                  name="country"
                  value={formik.values.country}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                />
              </Grid>

              <Grid item xs={6}>
                <PalTextField
                  fullWidth
                  disabled={disabled}
                  invalid={formik.touched.address && formik.errors.address}
                  label="Address"
                  name="address"
                  value={formik.values.address}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                />
              </Grid>

              <Grid item xs={12}>
                <div className="divider"></div>
              </Grid>
            </>
          )}

          <Grid item xs={12}>
            <PalTextField
              fullWidth
              FormHelperTextProps={{
                sx: (theme) => ({
                  ...(!formik.errors.username &&
                    usernameStatus.isValid === true && { color: theme.palette.success.main }),
                }),
              }}
              invalid={usernameInvalid}
              label="Username"
              name="username"
              value={formik.values.username?.toLowerCase()}
              onBlur={formik.handleBlur}
              onChange={handleChangeUsername}
              {...(formik.values.username?.length > 3 &&
                invitationInfo.isValid &&
                !formik.errors.username &&
                usernameStatus.isValid === true && {
                  helperText: t("register.usernameAvailable"),
                })}
              disabled={disabled}
            />
          </Grid>

          <Grid item xs={6}>
            <PalTextField
              fullWidth
              disabled={disabled}
              invalid={formik.touched.firstname && formik.errors.firstname}
              label="First Name"
              name="firstname"
              value={formik.values.firstname}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
            />
          </Grid>

          <Grid item xs={6}>
            <PalTextField
              fullWidth
              disabled={disabled}
              invalid={formik.touched.lastname && formik.errors.lastname}
              label="Last Name"
              name="lastname"
              value={formik.values.lastname}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <PalTextField
              fullWidth
              autoComplete="email"
              disabled={disabled || invitationInfo.email}
              invalid={formik.touched.email && formik.errors.email}
              label="Email"
              name="email"
              type="email"
              value={(invitationInfo.email || formik.values.email || "")?.toLowerCase()}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <PalTextField
              fullWidth
              disabled={disabled}
              invalid={formik.touched.password && formik.errors.password}
              label="Password"
              name="password"
              type="password"
              value={formik.values.password || ""}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <PalTextField
              fullWidth
              disabled={disabled}
              invalid={formik.touched.confirm_password && formik.errors.confirm_password}
              label="Confirm Password"
              name="confirm_password"
              type="password"
              value={formik.values.confirm_password || ""}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              className="checkbox"
              control={<PalCheckbox />}
              disabled={disabled}
              label={
                <>
                  You agree to our <PalLink onClick={handleOpenOk("Terms of Use", <TermsOfUse />)}>Term of Use</PalLink>{" "}
                  and <PalLink onClick={handleOpenOk("Privacy Policy", <PrivacyPolicy />)}>Privacy Policy</PalLink> by
                  registering to Palmate
                </>
              }
              name="terms"
              value={formik.values.terms}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
            />
            <FormHelperText className="error-text" error={formik.touched.terms && formik.errors.terms}>
              {formik.touched.terms && formik.errors.terms}
            </FormHelperText>
          </Grid>
          <Grid item xs={12}>
            <>
              {window.pr_config?.recaptcha_v2 ? (
                <Box alignItems={"center"} display="flex" flexDirection={"column"} justifyContent={"center"}>
                  <StyledRecaptcha
                    sitekey={window.pr_config.recaptcha_v2}
                    onChange={handleChangeCaptcha}
                    onErrored={handleCaptchaError}
                    onExpired={handleCaptchaError}
                  />
                  <FormHelperText className="error-text" error={formik.touched.captcha && formik.errors.captcha}>
                    {formik.touched.captcha && formik.errors.captcha}
                  </FormHelperText>
                </Box>
              ) : (
                <span className="text-danger">An error occurred while loading captcha. Please refresh the page.</span>
              )}
            </>
          </Grid>
          <Grid item xs={12}>
            <PalButton
              fullWidth
              className="register-btn"
              disabled={disabled}
              loading={isLoading}
              variant="contained"
              onClick={formik.handleSubmit}
            >
              Register
            </PalButton>
          </Grid>
          <Grid item className="login" xs={12}>
            Already have a Palmate Account?
            <PalLink noPrefix to="/login">
              Log In
            </PalLink>
          </Grid>
        </Grid>
      </div>
      <Footer />
      {/* </div> */}
    </StyledDiv>
  );
};

export default Register2;
