import {
  Children,
  Fragment,
  cloneElement,
  forwardRef,
  memo,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";

import classNames from "classnames";
import { BiLoaderAlt } from "react-icons/bi";
import { Button, ButtonGroup } from "reactstrap";

import PRLink from "../PRLink";
import PRTooltip from "../PRTooltip";

import "./style.scss";

const ForwardedPRTooltip = function ForwardedPRTooltip({ children, ...rest }) {
  const cloneRef = useRef(null);
  const arrayChildren = Children.toArray(children);
  const isSingle = arrayChildren.length === 1;

  if (!isSingle) {
    return (
      <PRTooltip {...rest}>
        <span>{children}</span>
      </PRTooltip>
    );
  }

  const ForwardedRefComp = forwardRef(function ForwardedRefComp({ ...rest }, ref) {
    const handleRef = (refProp) => {
      if (!refProp) return;
      const childrenProps = arrayChildren[0]?.props;
      cloneRef.current = refProp;

      if (typeof childrenProps?.ref === "function") {
        childrenProps?.ref(ref);
      } else if (childrenProps?.ref) {
        childrenProps.ref.current = refProp;
      }
      if (typeof ref === "function") {
        ref(refProp);
      } else if (ref) {
        childrenProps.ref.current = refProp;
      }
    };

    const childrenProps = arrayChildren[0]?.props;
    return cloneElement(arrayChildren[0], { ...rest, ...childrenProps, innerRef: handleRef });
  });

  return (
    <PRTooltip {...rest}>
      <ForwardedRefComp />
    </PRTooltip>
  );
};

/**
 * @param {{ loading?: boolean; icon?: string }} props - Props.
 * @returns {JSX.Element} - JSX.
 */
const PRButton = forwardRef(function PRButton(props, ref) {
  const {
    children,
    tooltipText,
    icon: IconComp,
    loading = false,
    disabled,
    className,
    classNameIcon,
    tooltipProps,
    tooltipPlacement,
    tooltipDelay = 500,
    size = "md",
    minSize,
    noBorder = false,
    link = "",
    linkScope = "dashboard",
    linkProps = {},
    onMouseDown,
    color = "primary",
    noTransition,
    onMouseEnter,
    onMouseLeave,
    round,
    ...rest
  } = props;
  const [isHover, setIsHover] = useState(false);

  const isHoverRef = useRef(isHover);
  const buttonRef = useRef(null);

  useImperativeHandle(ref, () => buttonRef.current, [buttonRef]);

  useEffect(() => {
    const handleGlobalMouseMove = (e) => {
      //handle global mouse move event to check if mouse is inside or outside of the button

      if (buttonRef.current && buttonRef.current?.contains(e.target) !== isHoverRef.current) {
        setIsHover(!isHoverRef.current);
      }
    };

    window.addEventListener("mousemove", handleGlobalMouseMove);
    return () => {
      window.removeEventListener("mousemove", handleGlobalMouseMove);
    };
  }, []);

  useEffect(() => {
    isHoverRef.current = isHover;
  }, [isHover]);

  const handleMouseEnterLeave = useCallback(
    (state) => (e) => {
      setIsHover(state);
      if (state) {
        onMouseEnter?.(e);
      } else {
        onMouseLeave?.(e);
      }
    },
    [onMouseEnter, onMouseLeave]
  );

  const Wrapper = useMemo(() => {
    let WrapperResult;

    const WrapperComp =
      tooltipText && !disabled
        ? ({ children, ...rest }) => <ForwardedPRTooltip {...rest}>{children}</ForwardedPRTooltip>
        : ({ children }) => <Fragment>{children}</Fragment>;
    WrapperResult = WrapperComp;

    const handleClickPreventDefault = (e) => {
      if (disabled) {
        e.preventDefault();
      }
    };
    if (link) {
      WrapperResult = function LinkWrapper({ ...rest }) {
        return (
          <PRLink {...linkProps} className={className} scope={linkScope} to={link} onClick={handleClickPreventDefault}>
            <WrapperComp {...rest} />
          </PRLink>
        );
      };
    }
    return WrapperResult;
  }, [tooltipText, link, linkProps, linkScope, disabled, className]);

  const handleMouseDown = useCallback(
    (e) => {
      /**
       * This line is added to prevent the button from inside of an input from triggering the input's onBlur event.
       * formik - See: https://github.com/redux-form/redux-form/issues/860#issuecomment-625254444
       */
      e.preventDefault();
      onMouseDown?.(e);
    },
    [onMouseDown]
  );
  const isOnlyIcon = useMemo(() => {
    return !children && typeof IconComp === "function";
  }, [children, IconComp]);

  const IconPart = (
    <>
      {loading && <BiLoaderAlt className={classNames(classNameIcon, "spin")} />}
      {!loading && typeof IconComp === "function" && <IconComp className={classNames(classNameIcon, "lh-1")} />}
    </>
  );
  return (
    <Wrapper
      {...(!!tooltipText && {
        ...tooltipProps,
        delay: tooltipDelay,
        title: tooltipText,
        placement: tooltipPlacement,
      })}
    >
      <Button
        color={color}
        innerRef={buttonRef}
        size={size}
        {...rest}
        outline={!!rest.outline}
        {...(disabled ? { disabled: true } : {})}
        className={classNames("pr-button", className, {
          "button-disabled": disabled,
          square: isOnlyIcon,
          "square-sm": isOnlyIcon && size === "sm",
          "square-md": isOnlyIcon && size === "md",
          "square-lg": isOnlyIcon && size === "lg",
          "square-xs": isOnlyIcon && size === "xs",
          "no-border": noBorder,
          "no-transition": noTransition,
          "w-xs": minSize === "xs",
          "w-sm": minSize === "sm",
          "w-md": minSize === "md",
          "w-lg": minSize === "lg",
          "w-xl": minSize === "xl",
          "w-xxl": minSize === "xxl",
          "p-0": noBorder && isOnlyIcon,
          round,
          xs: size === "xs",
          hover: isHover && !disabled,
        })}
        onMouseDown={handleMouseDown}
        onMouseEnter={handleMouseEnterLeave(true)}
        onMouseLeave={handleMouseEnterLeave(false)}
      >
        {children ? (
          <span className="d-flex align-items-center justify-content-center w-100 h-100 p-0 m-0">
            {IconPart}

            <span
              className={classNames("d-flex align-items-center", {
                "ms-2": loading || IconComp,
              })}
            >
              {children}
            </span>
          </span>
        ) : (
          IconPart
        )}
      </Button>
    </Wrapper>
  );
});
export default memo(PRButton);

export const PRButtonGroup = memo(function PRButtonGroup({
  options = [],
  onChange,
  value: valueProp,
  color = "secondary",
  className,
  size,
}) {
  const isControlled = valueProp !== undefined;
  const [intervalValue, setIntervalValue] = useState(valueProp);
  const handleClick = (period) => () => {
    onChange?.(period);

    if (!isControlled) {
      setIntervalValue(period);
    }
  };

  const value = useMemo(() => {
    if (isControlled) {
      return valueProp;
    }
    return intervalValue;
  }, [intervalValue, isControlled, valueProp]);

  return (
    <ButtonGroup className={classNames("gap-1 pr-button-group", className)}>
      {options.map((option) => (
        <PRButton
          key={option?.value}
          color={color}
          disabled={option?.disabled}
          icon={option?.icon}
          outline={value !== option?.value}
          size={size}
          tooltipText={option?.tooltipText}
          onClick={handleClick(option?.value)}
        >
          {option.label}
        </PRButton>
      ))}
    </ButtonGroup>
  );
});
