import { useCallback, useState } from "react";

import { withCardon } from "cardon";
import { Label } from "reactstrap";

import PRInput from "~components/Generic/PRInput";
import PRModal from "~components/Generic/PRModal";

function CreateAsTemplateModalContent({ get }) {
  const [value, setValue] = useState("");

  const handleChange = useCallback((e) => {
    setValue(e.target.value);
  }, []);

  return (
    <PRModal submitText="Create" title="Create as Template" onClick={get(value)} onClose={get(false)}>
      <Label htmlFor="slot-validation-text">Name:</Label>
      <PRInput placeholder="Enter template name" type="text" value={value} onChange={handleChange} />
    </PRModal>
  );
}
const CreateAsTemplateModal = withCardon(CreateAsTemplateModalContent, { destroyOnHide: true });
export default CreateAsTemplateModal;
