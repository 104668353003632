import ConstantHelper from "~helpers/ConstantHelper";

export const [statisticType, statisticTypeOptions, statisticTypeMap] = ConstantHelper.typify({
  AVERAGE_CHAT_TIME_CARD: "AVERAGE_CHAT_TIME_CARD",
  TOTAL_SESSION_COUNT_CARD: "TOTAL_SESSION_COUNT_CARD",
  TOTAL_ONLY_BOT_SESSION_COUNT_CARD: "TOTAL_ONLY_BOT_SESSION_COUNT_CARD",
  TOTAL_CALLCENTER_SESSION_COUNT_CARD: "TOTAL_CALLCENTER_SESSION_COUNT_CARD",
  TOTAL_CHATBOT_MESSAGE_COUNT_CARD: "TOTAL_CHATBOT_MESSAGE_COUNT_CARD",
  CHATBOT_MESSAGE_PER_SESSION_CARD: "CHATBOT_MESSAGE_PER_SESSION_CARD",

  CHATBOT_MESSAGE_PER_SESSION: "CHATBOT_MESSAGE_PER_SESSION",
  TOTAL_CALLCENTER_SESSION_COUNT: "TOTAL_CALLCENTER_SESSION_COUNT",
  TOTAL_ONLY_BOT_SESSION_COUNT: "TOTAL_ONLY_BOT_SESSION_COUNT",
  TOTAL_SESSION_COUNT: "TOTAL_SESSION_COUNT",
  WHATSAPP_CUSTOMER_COUNT: "WHATSAPP_CUSTOMER_COUNT",
  TELEGRAM_CUSTOMER_COUNT: "TELEGRAM_CUSTOMER_COUNT",
  WEB_CUSTOMER_COUNT: "WEB_CUSTOMER_COUNT",
  TEAMS_CUSTOMER_COUNT: "TEAMS_CUSTOMER_COUNT",

  AGENT_SESSION_RATE_AVERAGE: "AGENT_SESSION_RATE_AVERAGE",
  AGENT_SESSION_RATE_MEDIAN: "AGENT_SESSION_RATE_MEDIAN",
  AGENT_SESSION_RATE_STANDART_DEVIATION: "AGENT_SESSION_RATE_STANDART_DEVIATION",
  AGENT_SESSION_RATE_MAX: "AGENT_SESSION_RATE_MAX",
  AGENT_SESSION_RATE_MIN: "AGENT_SESSION_RATE_MIN",
  AGENT_SESSION_COUNT: "AGENT_SESSION_COUNT",

  AGENT_TOTAL_CHAT_TIME: "AGENT_TOTAL_CHAT_TIME",
  AGENT_CHAT_TIME_AVERAGE: "AGENT_CHAT_TIME_AVERAGE",
  AGENT_CHAT_TIME_MEDIAN: "AGENT_CHAT_TIME_MEDIAN",
  AGENT_CHAT_TIME_STANDART_DEVIATION: "AGENT_CHAT_TIME_STANDART_DEVIATION",
  AGENT_CHAT_TIME_MAX: "AGENT_CHAT_TIME_MAX",
  AGENT_CHAT_TIME_MIN: "AGENT_CHAT_TIME_MIN",

  AGENT_RESPONSE_TIME_AVERAGE: "AGENT_RESPONSE_TIME_AVERAGE",
  AGENT_RESPONSE_TIME_MEDIAN: "AGENT_RESPONSE_TIME_MEDIAN",
  AGENT_RESPONSE_TIME_STANDART_DEVIATION: "AGENT_RESPONSE_TIME_STANDART_DEVIATION",
  AGENT_RESPONSE_TIME_MAX: "AGENT_RESPONSE_TIME_MAX",
  AGENT_RESPONSE_TIME_MIN: "AGENT_RESPONSE_TIME_MIN",
  AGENT_RESPONSE_TIME_TOTAL: "AGENT_RESPONSE_TIME_TOTAL",

  TICKET_OPEN_COUNT: "TICKET_OPEN_COUNT",
  TICKET_RESOLVE_COUNT: "TICKET_RESOLVE_COUNT",
  TICKET_ASSIGN_COUNT: "TICKET_ASSIGN_COUNT",
  TICKET_CATEGORY: "TICKET_CATEGORY",

  CALLCENTER_QUEUE_TIME_AVERAGE: "CALLCENTER_QUEUE_TIME_AVERAGE",
  CALLCENTER_QUEUE_TIME_MEDIAN: "CALLCENTER_QUEUE_TIME_MEDIAN",
  CALLCENTER_QUEUE_TIME_STANDART_DEVIATION: "CALLCENTER_QUEUE_TIME_STANDART_DEVIATION",
  CALLCENTER_QUEUE_TIME_MAX: "CALLCENTER_QUEUE_TIME_MAX",
  CALLCENTER_QUEUE_TIME_MIN: "CALLCENTER_QUEUE_TIME_MIN",

  CHATBOT_SESSION_RATE_AVERAGE: "CHATBOT_SESSION_RATE_AVERAGE",
  CHATBOT_SESSION_RATE_MEDIAN: "CHATBOT_SESSION_RATE_MEDIAN",
  CHATBOT_SESSION_RATE_STANDART_DEVIATION: "CHATBOT_SESSION_RATE_STANDART_DEVIATION",
  CHATBOT_SESSION_RATE_MAX: "CHATBOT_SESSION_RATE_MAX",
  CHATBOT_SESSION_RATE_MIN: "CHATBOT_SESSION_RATE_MIN",

  CHATBOT_QUEUE_TIME_AVERAGE: "CHATBOT_QUEUE_TIME_AVERAGE",
  CHATBOT_QUEUE_TIME_MEDIAN: "CHATBOT_QUEUE_TIME_MEDIAN",
  CHATBOT_QUEUE_TIME_STANDART_DEVIATION: "CHATBOT_QUEUE_TIME_STANDART_DEVIATION",
  CHATBOT_QUEUE_TIME_MAX: "CHATBOT_QUEUE_TIME_MAX",
  CHATBOT_QUEUE_TIME_MIN: "CHATBOT_QUEUE_TIME_MIN",

  CHATBOT_CHAT_TIME_AVERAGE: "CHATBOT_CHAT_TIME_AVERAGE",
  CHATBOT_CHAT_TIME_MEDIAN: "CHATBOT_CHAT_TIME_MEDIAN",
  CHATBOT_CHAT_TIME_STANDART_DEVIATION: "CHATBOT_CHAT_TIME_STANDART_DEVIATION",
  CHATBOT_CHAT_TIME_MAX: "CHATBOT_CHAT_TIME_MAX",
  CHATBOT_CHAT_TIME_MIN: "CHATBOT_CHAT_TIME_MIN",

  CHATBOT_RESPONSE_TIME_AVERAGE: "CHATBOT_RESPONSE_TIME_AVERAGE",
  CHATBOT_RESPONSE_TIME_MEDIAN: "CHATBOT_RESPONSE_TIME_MEDIAN",
  CHATBOT_RESPONSE_TIME_STANDART_DEVIATION: "CHATBOT_RESPONSE_TIME_STANDART_DEVIATION",
  CHATBOT_RESPONSE_TIME_MAX: "CHATBOT_RESPONSE_TIME_MAX",
  CHATBOT_RESPONSE_TIME_MIN: "CHATBOT_RESPONSE_TIME_MIN",
  CHATBOT_RESPONSE_TIME_TOTAL: "CHATBOT_RESPONSE_TIME_TOTAL",

  AGENT_TICKET_COUNT: "AGENT_TICKET_COUNT",
  AGENT_TICKET_ASSIGN_COUNT: "AGENT_TICKET_ASSIGN_COUNT",
  AGENT_TICKET_RESOLVE_COUNT: "AGENT_TICKET_RESOLVE_COUNT",
  AGENT_TICKET_RESOLVE_TIME_AVERAGE: "AGENT_TICKET_RESOLVE_TIME_AVERAGE",
  AGENT_TICKET_RESOLVE_TIME_MEDIAN: "AGENT_TICKET_RESOLVE_TIME_MEDIAN",
  AGENT_TICKET_RESOLVE_TIME_STANDART_DEVIATION: "AGENT_TICKET_RESOLVE_TIME_STANDART_DEVIATION",
  AGENT_TICKET_RESOLVE_TIME_MAX: "AGENT_TICKET_RESOLVE_TIME_MAX",
  AGENT_TICKET_RESOLVE_TIME_MIN: "AGENT_TICKET_RESOLVE_TIME_MIN",

  INTENT_SHOW_COUNT: "INTENT_SHOW_COUNT",
  INTENT_LIKE_COUNT: "INTENT_LIKE_COUNT",
  INTENT_DISLIKE_COUNT: "INTENT_DISLIKE_COUNT",
  INTENT_PREDICT_COUNT: "INTENT_PREDICT_COUNT",
  INTENT_SELECT_COUNT: "INTENT_SELECT_COUNT",
  INTENT_OTHER_TRIGGER_COUNT: "INTENT_OTHER_TRIGGER_COUNT",
  INTENT_RATING_AVERAGE: "INTENT_RATING_AVERAGE",

  INTENT_TAG_SHOW_COUNT: "INTENT_TAG_SHOW_COUNT",
  INTENT_TAG_LIKE_COUNT: "INTENT_TAG_LIKE_COUNT",
  INTENT_TAG_DISLIKE_COUNT: "INTENT_TAG_DISLIKE_COUNT",
  //-------

  AGENT_MESSAGE_COUNT: "AGENT_MESSAGE_COUNT",
  AGENT_LIKE_COUNT: "AGENT_LIKE_COUNT",
  AGENT_DISLIKE_COUNT: "AGENT_DISLIKE_COUNT",
  AGENT_AVAILABLE_TIME: "AGENT_AVAILABLE_TIME",
  TOTAL_AGENT_MESSAGE_COUNT: "TOTAL_AGENT_MESSAGE_COUNT",
  TOTAL_AGENT_LIKE_COUNT: "TOTAL_AGENT_LIKE_COUNT",
  TOTAL_AGENT_DISLIKE_COUNT: "TOTAL_AGENT_DISLIKE_COUNT",
  TOTAL_AGENT_SESSION_RATE_AVERAGE: "TOTAL_AGENT_SESSION_RATE_AVERAGE",
});

export const [statisticGeneratorReportType, statisticGeneratorReportTypeOptions, statisticGeneratorReportTypeMap] =
  ConstantHelper.typify({
    WEB: "Web",
    EXCEL: "Excel",
    JSON: "Json",
  });

export const [
  statisticGeneratorWebReportFormat,
  statisticGeneratorWebReportFormatOptions,
  statisticGeneratorWebReportFormatMap,
] = ConstantHelper.typify({
  echarts: "ECharts",
  html: "HTML",
  markdown: "Markdown",
});

export const [statisticGeneratorStatus, statisticGeneratorStatusOptions, statisticGeneratorStatusMap] =
  ConstantHelper.typify({
    DONE: "Done",
    SCHEDULED: "Scheduled",
    PROCESSING: "Processing",
    FAILED: "Failed",
  });
