import { useCallback, useEffect, useMemo, useRef, useState } from "react";

import classNames from "classnames";
import { MdAdd, MdShare } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Alert, Badge, Col, Label, Nav, NavItem, NavLink, Row } from "reactstrap";
import * as Yup from "yup";

import styled from "@emotion/styled";
import { Attachment } from "@mui/icons-material";
import { Chip, Grid } from "@mui/material";

import DynamicFormModal from "~common/modals/FormikModal";
import PRButton from "~components/Generic/PRButton";
import PRChatMessage from "~components/Generic/PRChatMessage";
import PRContainer from "~components/Generic/PRContainer";
import { PRDividerLabel } from "~components/Generic/PRDivider";
import PRInput from "~components/Generic/PRInput";
import PRLink from "~components/Generic/PRLink";
import PRPage from "~components/Generic/PRPage";
import PRSelect from "~components/Generic/PRSelect";
import PRTable from "~components/Generic/PRTable";
import PRTooltip from "~components/Generic/PRTooltip";
import PRChatMessageFilePopper from "~components/PRChatMessageFilePopper";
import TemplatePopup from "~components/Session/SessionChat/TemplatePopup";
import {
  ticketStatus,
  ticketStatusMap,
  ticketStatusOptions,
  ticketWaitingAction,
  ticketWaitingActionMap,
  userRole,
} from "~constants";
import DateHelper from "~helpers/DateHelper";
import DialogHelper from "~helpers/DialogHelper";
import {
  appendTicketComment,
  appendTicketNote,
  createOrUpdateTicket,
  createPublicTicketToken,
  getTicket,
  getTicketCategoryList,
  setTicket,
} from "~store/helpdesk/actions";
import { selectTicket, selectTicketCategoryList } from "~store/helpdesk/selectors";
import { getPermissionUserList } from "~store/settings/permission/actions";
import { selectPermissionUserList } from "~store/settings/permission/selectors";
import { selectAgentInfo } from "~store/socket/livechat/selectors";
import { selectCurrentProject, selectUserInfo } from "~store/user/selectors";

import "./style.scss";

export default function TicketDetail() {
  // const [messages, setMessages] = useState([]);
  const ticket = useSelector(selectTicket);
  const agentInfo = useSelector(selectAgentInfo);

  const categoryList = useSelector(selectTicketCategoryList);
  const permissionUserList = useSelector(selectPermissionUserList);
  const dispatch = useDispatch();
  const { uuid } = useParams();
  const currentProject = useSelector(selectCurrentProject);
  const userInfo = useSelector(selectUserInfo);
  const [note, setNote] = useState("");
  const [tab, setTab] = useState(1);
  const [chatInputMessage, setChatInputMessage] = useState("");
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [isChatbotReady, setIsChatbotReady] = useState(false);
  const chatRef = useRef();

  const isAssignedToMe = !!userInfo?.id && ticket?.assigned_user === userInfo.id;
  const handleChangeNote = (e) => {
    setNote(e.target.value);
  };

  const chatMessages = useMemo(() => {
    let previousCommentTime = null;
    const messages = [];
    const comments = [...(ticket?.comments || []).map((item) => ({ ...item }))];
    comments.forEach((comment) => {
      comment.created = DateHelper.getDateTimeLocal(comment?.created);
    });

    comments.sort((a, b) => {
      return a.created.isBefore(b.created) ? -1 : 1;
    });

    for (const comment of comments) {
      const commentTime = comment?.created;
      const isSameDay = commentTime.isSame(previousCommentTime, "day");
      if (!previousCommentTime || !isSameDay) {
        messages.push({
          type: "notification",
          position: "center",
          sender_type: "SYS",
          messageTime: commentTime,
          text: commentTime.format("LLL"),
          isSent: true,
        });
      }

      if (comment?.new_files?.length) {
        for (const file of comment?.new_files) {
          const IsImage = /\.(gif|jpe?g|tiff?|png|webp|bmp)$/i.test(file?.file_name);
          messages.push({
            type: IsImage ? "image" : "file",
            position: comment?.creator === "CUS" ? "left" : "right",
            sender_type: comment?.user?.is_bot ? "BOT" : "AGT",
            messageTime: comment?.created,
            text: file?.name,
            isSent: true,
            fileName: file?.file_name,
            ...(IsImage ? { imageUrl: file?.file_url } : { fileUrl: file?.file_url }),
          });
        }
      }
      if (comment?.comment) {
        messages.push({
          type: "text",
          position: comment?.creator === "CUS" ? "left" : "right",
          sender_type: comment?.user?.is_bot ? "BOT" : "AGT",
          messageTime: comment?.created,
          text: comment?.comment,
          isSent: true,
        });
      }
      previousCommentTime = commentTime;
    }

    if (!messages?.length) {
      messages.push({
        type: "notification",
        position: "center",
        sender_type: "SYS",
        messageTime: DateHelper.getDateTimeLocal(),
        text: "No comments",
      });
    }
    return messages;
  }, [ticket]);

  useEffect(() => {
    dispatch(getTicket(uuid, currentProject.id));
    return () => {
      dispatch(setTicket({}));
    };
  }, [dispatch, uuid, currentProject.id]);

  useEffect(() => {
    dispatch(getTicketCategoryList(currentProject.id, { params: { limit: 9999 } }));
    dispatch(getPermissionUserList(currentProject.id, { params: { limit: 9999 } }));
  }, [dispatch, currentProject.id]);

  //   const assignedPermissionData = useMemo(() => {
  //     return permissionUserList?.find((item) => item.id === ticket.assigned_user);
  //   }, [permissionUserList, ticket]);

  const permissionUserListOptions = useMemo(() => {
    const ticketValidRoles = [userRole.ticketAgent, userRole.admin];

    const permissionList = permissionUserList
      .filter((item) => item?.roles?.some((role) => ticketValidRoles.includes(role)))
      .map((item) => ({
        label: (
          <span
            className={classNames({
              "fw-medium": item.id === userInfo.id,
            })}
          >{`${item.firstname} ${item.lastname} (${item.email})`}</span>
        ),
        value: item.id,
      }));
    // if (!currentProject.permissions.includes("Admin")) {
    //   return permissionList.filter((item) => {
    //     return item.value === userInfo.id;
    //   });
    // }
    return permissionList;
  }, [permissionUserList, userInfo.id]);

  const categoryOptions = useMemo(() => {
    return categoryList.map((item) => ({
      label: item.name,
      value: item.id,
    }));
  }, [categoryList]);

  const handleMessage = async (message, e) => {
    if (ticket.status === ticketStatus.RESOLVED) {
      if (
        !(await DialogHelper.showQuestionYesNo(
          "Send Message",
          "Current ticket is resolved and will be reopened. Are you sure you want to send this message?"
        ))
      ) {
        e.preventDefault();
        return;
      }
    } else {
      if (!(await DialogHelper.showQuestionYesNo("Send Message", "Are you sure you want to send this message?"))) {
        e.preventDefault();
        return;
      }
    }

    await dispatch(createOrUpdateTicket(currentProject.id, { uuid: ticket.uuid, status: ticketStatus.PROGRESS }));

    const form = new FormData();

    if (message.text) {
      form.append("comment", message.text);
    }
    selectedFiles.forEach((file) => {
      form.append("files", file);
    });

    await dispatch(appendTicketComment(currentProject.id, ticket.uuid, form));

    setSelectedFiles([]);
    setChatInputMessage("");
    dispatch(getTicket(uuid, currentProject.id));
  };

  const actions = useMemo(() => {
    return [
      {
        icon: MdShare,
        label: "Share",
        // outline: true,
        disabled: !isAssignedToMe,
        onClick: async () => {
          let results = await DynamicFormModal.show({
            title: "Share Ticket",
            submitText: "Create",
            fields: [
              {
                label: "Email",
                type: "text",
                name: "email",
                validation: Yup.string().email().required(),
              },
              {
                label: "Full Name",
                type: "text",
                name: "full_name",
                required: true,
              },
              {
                label: "Comment",
                type: "text",
                name: "comment",
                required: false,
                multiple: true,
              },
            ],
          });
          if (results) {
            dispatch(createPublicTicketToken(currentProject.id, uuid, results));
          }
        },
      },
    ];
  }, [dispatch, uuid, currentProject.id, isAssignedToMe]);

  const handleChangeAssignedUser = async (value) => {
    if (await DialogHelper.showQuestionYesNo()) {
      await dispatch(
        createOrUpdateTicket(currentProject.id, {
          uuid: uuid,
          assigned_user: value,
        })
      );
      dispatch(getTicket(uuid, currentProject.id));
    }
  };
  const handleChangeCategory = async (value) => {
    if (await DialogHelper.showQuestionYesNo()) {
      await dispatch(
        createOrUpdateTicket(currentProject.id, {
          uuid: uuid,
          category: value,
        })
      );
      dispatch(getTicket(uuid, currentProject.id));
    }
  };
  const handleChangeStatus = async (value) => {
    if (await DialogHelper.showQuestionYesNo()) {
      await dispatch(
        createOrUpdateTicket(currentProject.id, {
          uuid: uuid,
          status: value,
        })
      );
      dispatch(getTicket(uuid, currentProject.id));
    }
  };

  const handleClickSaveNote = async () => {
    await dispatch(appendTicketNote(currentProject.id, ticket.uuid, note));
    dispatch(getTicket(uuid, currentProject.id));
  };

  //   const handleDrop = async (files) => {
  //     await dispatch(
  //       appendTicketComment(currentProjectId, ticket.share_token, {
  //         file: files[0],
  //         comment: "File attached",
  //         email: ticket.email,
  //       })
  //     );
  //     dispatch(getTicket(uuid, currentProjectId));
  //   };
  const handleSelectTab = (tabId) => () => setTab(tabId);
  const parentName = [
    {
      label: "Ticket",
      url: "/helpdesk/ticket",
    },
    {
      label: `Ticket Detail`,
    },
  ];
  const chatMessagesTextLength = useMemo(
    () => chatMessages.filter((item) => item.type === "text")?.length || 0,
    [chatMessages]
  );

  const handleClickAssignToMe = async () => {
    if (await DialogHelper.showQuestionYesNo()) {
      await dispatch(
        createOrUpdateTicket(currentProject.id, {
          uuid: uuid,
          assigned_user: userInfo.id,
        })
      );
      dispatch(getTicket(uuid, currentProject.id));
    }
  };
  const handleDrop = async (files) => {
    if (!files?.length) return;
    setSelectedFiles(files);
  };
  const handleMount = useCallback(() => {
    setIsChatbotReady(true);
  }, []);

  const handleSubmitTemplate = useCallback((e, text) => {
    if (e?.shiftKey) {
      chatRef.current?.send(text);
    } else {
      chatRef.current?.setText(text);
    }
  }, []);

  const variableMap = useMemo(() => {
    return {
      ...ticket,
      customer_info: undefined,
      ...ticket.customer_info,
      agent_name: agentInfo?.name,
    };
  }, [ticket, agentInfo?.name]);
  return (
    <PRContainer actions={actions} name="Help Desk" parentName={parentName}>
      <PRPage className="pr-ticket-info">
        {/* <PRDividerLabel>Customer Information</PRDividerLabel> */}
        {!isAssignedToMe && (
          <Alert className="my-2" color="warning">
            Note: You cannot add any comment or note to this ticket because you are not assigned to this ticket.
          </Alert>
        )}
        <Row className="g-3 mt-2 align-items-center w-100 w-lg-50">
          <Col lg={"3"} xs={"12"}>
            <Label className="mb-0">Status:</Label>
          </Col>
          <Col xl="9" xs={"12"}>
            <PRSelect
              isPrimitiveValue
              isClearable={false}
              isDisabled={ticket.status === ticketStatus.RESOLVED}
              options={ticketStatusOptions}
              value={ticket.status}
              onChange={handleChangeStatus}
            />
          </Col>
          <Col lg={"3"} xs={"12"}>
            <Label className="mb-0">Waiting Action :</Label>
          </Col>
          <Col xl="9" xs={"12"}>
            {(function () {
              if (ticket.waiting_action === ticketWaitingAction.AGENT_ACTION_WAITING) {
                return (
                  <Badge className="font-size-12" color="orange">
                    {ticketWaitingActionMap[ticket.waiting_action] || ticket.waiting_action}
                  </Badge>
                );
              } else if (ticket.waiting_action === ticketWaitingAction.CUSTOMER_ACTION_WAITING) {
                return (
                  <Badge className="font-size-12" color="primary">
                    {ticketWaitingActionMap[ticket.waiting_action] || ticket.waiting_action}
                  </Badge>
                );
              } else {
                return (
                  <Badge className="font-size-12" color="secondary">
                    {ticketWaitingActionMap[ticket.waiting_action] || ticket.waiting_action}
                  </Badge>
                );
              }
            })()}
          </Col>
          <Col lg={"3"} xs={"12"}>
            <Label className="mb-0">Email:</Label>
          </Col>
          <Col xl="9" xs={"12"}>
            {ticket.contact || "-"}
          </Col>

          <Col lg={"3"} xs={"12"}>
            <Label className="mb-0">Create Date:</Label>
          </Col>
          <Col xl="9" xs={"12"}>
            {(ticket.created && (
              <>
                {DateHelper.getDateTimeLocal(ticket.created).format("LLT")} (
                {DateHelper.getDateTimeLocal(ticket.created).fromNow()}){" "}
              </>
            )) ||
              "-"}
          </Col>
          <Col lg={"3"} xs={"12"}>
            <Label className="mb-0">Update Date:</Label>
          </Col>
          <Col xl="9" xs={"12"}>
            {(ticket.updated && (
              <>
                {DateHelper.getDateTimeLocal(ticket.updated).format("LLT")} (
                {DateHelper.getDateTimeLocal(ticket.updated).fromNow()})
              </>
            )) ||
              "-"}
          </Col>

          <Col lg={"3"} xs={"12"}>
            <Label className="mb-0">Session:</Label>
          </Col>
          <Col xl="9" xs={"12"}>
            {ticket.session ? (
              <>
                <PRLink newTab to={`/contactcenter/history/${ticket.session}/`}>
                  Click here to view session history
                </PRLink>
              </>
            ) : (
              "-"
            )}
          </Col>
        </Row>

        <PRDividerLabel pageSeparator className={"mb-2 mt-2"}>
          Assignment
        </PRDividerLabel>

        <Row className="g-3  align-items-center w-100 w-lg-50">
          <Col lg={"3"} xs={"12"}>
            <Label className="mb-0">Category:</Label>
          </Col>
          <Col lg={"9"} xs={"12"}>
            {/* {assignedPermissionData ? `${assignedPermissionData.firstname} ${assignedPermissionData.lastname}` : "-"} */}
            <PRSelect
              isPrimitiveValue
              isClearable={false}
              options={categoryOptions}
              value={ticket.category}
              onChange={handleChangeCategory}
            />
          </Col>
          <Col lg={"3"} xs={"12"}>
            <Label className="mb-0">Assigned To:</Label>
          </Col>
          <Col className="d-flex align-items-center gx-3 gap-2" lg={"9"} xs={"12"}>
            {/* {assignedPermissionData ? `${assignedPermissionData.firstname} ${assignedPermissionData.lastname}` : "-"} */}
            <PRSelect
              fullWidth
              isPrimitiveValue
              isClearable={false}
              options={permissionUserListOptions}
              value={ticket.assigned_user}
              onChange={handleChangeAssignedUser}
            />
            {!isAssignedToMe && (
              <PRButton className="text-nowrap" onClick={handleClickAssignToMe}>
                Assign to Me
              </PRButton>
            )}
          </Col>
          <Col lg={"3"} xs={"12"}>
            <Label className="mb-0">Assign Date:</Label>
          </Col>
          <Col lg="9" xs={"12"}>
            {ticket.assigned_time ? <>{DateHelper.getDateTimeLocal(ticket.assigned_time).format("LLT")}</> : "-"}
          </Col>
        </Row>

        <PRDividerLabel pageSeparator className={"mt-4 mb-2"}>
          Description
        </PRDividerLabel>

        <span className="fs-5"> {ticket.text}</span>
        <Grid container spacing={1}>
          {ticket.files?.length > 0 &&
            ticket.files.map((file, index) => (
              <Grid key={index} item>
                <Chip
                  clickable
                  component="a"
                  href={file.file_url}
                  icon={<Attachment />}
                  label={file.file_name}
                  size="small"
                  sx={{
                    textDecoration: "none !important",
                  }}
                  target="_blank"
                />
              </Grid>
            ))}
        </Grid>
        <PRDividerLabel pageSeparator className={"mt-2"}>
          Detail
        </PRDividerLabel>

        <Nav tabs className="nav-tabs-custom font-size-14" role="tablist">
          <NavItem>
            <NavLink
              className={classNames("d-flex align-items-center", { active: tab === 1 })}
              onClick={handleSelectTab(1)}
            >
              Messages
              {!!chatMessagesTextLength && (
                <Badge className="ms-1" color="primary">
                  {chatMessagesTextLength}
                </Badge>
              )}
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classNames("d-flex align-items-center", { active: tab === 2 })}
              onClick={handleSelectTab(2)}
            >
              Notes
              {!!ticket.notes?.length && (
                <Badge className="ms-1" color="primary">
                  {ticket.notes?.length}
                </Badge>
              )}
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classNames("d-flex align-items-center", { active: tab === 3 })}
              onClick={handleSelectTab(3)}
            >
              Ticket History
              {!!ticket.logs?.length && (
                <Badge className="ms-1" color="primary">
                  {ticket.logs?.length}
                </Badge>
              )}
            </NavLink>
          </NavItem>
        </Nav>

        <div className="msg-box-wrapper mt-1">
          <Row className="g-2 align-items-stretch h-100 g-2">
            {tab === 1 && (
              <Col xs={12}>
                <PRChatMessageFilePopper files={selectedFiles} onChange={setSelectedFiles} />
                {isChatbotReady && <TemplatePopup variableMap={variableMap} onSubmit={handleSubmitTemplate} />}
                <StyledPRChatMessage
                  ref={chatRef}
                  disableAvatar
                  dropDisabled
                  enableBlankMessage
                  multiline
                  noReturnToHome
                  showAttachment
                  showFilePreview
                  className="ticket-chatbox"
                  colorPrimary={"#28aae1"}
                  disableButton={!selectedFiles?.length && !chatInputMessage}
                  disableMic={!isAssignedToMe}
                  disableTextInput={!isAssignedToMe}
                  dropMaxFiles={5}
                  focusOnMount={false}
                  focusOnRender={false}
                  messages={chatMessages}
                  reverseChat={true}
                  onDrop={handleDrop}
                  onMessage={handleMessage}
                  onMessageChange={setChatInputMessage}
                  onMount={handleMount}
                />
              </Col>
            )}
            {tab === 2 && (
              <Col xs={12}>
                <div className="border p-1">
                  <div className="note-bubble">
                    {ticket.notes?.map((item, index) => (
                      <NoteBubble key={index} note={item} />
                    ))}
                    {!ticket.notes?.length && (
                      <div className="d-flex h-100">
                        <p className="text-muted m-auto text-center">
                          No notes
                          <p className="text-muted">(Notes are not visible to the customer)</p>
                        </p>
                      </div>
                    )}
                  </div>
                  <Row className="gx-1 align-items-stretch mt-2" disabled={!isAssignedToMe}>
                    <Col xs>
                      <PRInput className="border-0" rows={1} type="textarea" value={note} onChange={handleChangeNote} />
                    </Col>
                    <Col xs={"auto"}>
                      <PRButton
                        outline
                        className="h-100"
                        disabled={note === ticket.note}
                        icon={MdAdd}
                        onClick={handleClickSaveNote}
                      />
                    </Col>
                  </Row>
                </div>
              </Col>
            )}
            {tab === 3 && (
              <Col xs={12}>
                <TicketLog logs={ticket.logs} />
              </Col>
            )}
          </Row>
        </div>
      </PRPage>
    </PRContainer>
  );
}

const StyledPRChatMessage = styled(PRChatMessage)`
  min-height: 600px;
`;

function NoteBubble({ note }) {
  return (
    <div className="bg-light rounded p-2 mb-2">
      <div className="note-bubble-text">{note.note}</div>
      <div className=" text-muted mb-2">
        <PRTooltip title={() => DateHelper.getDateTimeLocal(note.created).fromNow()}>
          {DateHelper.getDateTimeLocal(note.created).format("LLT")}
        </PRTooltip>
      </div>
    </div>
  );
}

function TicketLog({ logs = [] }) {
  const permissionUserList = useSelector(selectPermissionUserList);
  const sortedLogs = useMemo(() => {
    return [...logs].sort((a, b) => {
      return DateHelper.getDateTime(b.detail?.created_date).isAfter(DateHelper.getDateTime(a.detail?.created_date))
        ? 1
        : -1;
    });
  }, [logs]);

  const columns = [
    {
      label: "Action",
      key: "detail.data",
      render: (row) => {
        let message = row?.detail?.data || "";
        if (row.detail?.type === "change_agent") {
          let currentAgentName = row.detail?.data?.current_agent?.name;
          let nextAgentName = row.detail?.data?.next_agent?.name;

          const currentAgentEmail = permissionUserList.find(
            (item) => item.id === row.detail?.data?.current_agent?.id
          )?.email;
          if (currentAgentEmail) {
            currentAgentName += ` (${currentAgentEmail})`;
          }

          const nextAgentEmail = permissionUserList.find((item) => item.id === row.detail?.data?.next_agent?.id)?.email;
          if (nextAgentEmail) {
            nextAgentName += ` (${nextAgentEmail})`;
          }

          if (currentAgentName && nextAgentName) {
            message = (
              <span>
                Change agent from <span className="fw-semibold">{currentAgentName}</span> to{" "}
                <span className="fw-semibold">{nextAgentName}</span>
              </span>
            );
          } else if (currentAgentName) {
            message = (
              <span>
                Change agent from <span className="fw-semibold">{currentAgentName}</span> to Unassigned
              </span>
            );
          } else if (nextAgentName) {
            message = (
              <span>
                Change agent from Unassigned to <span className="fw-semibold">{nextAgentName}</span>
              </span>
            );
          }
        } else if (row.detail?.type === "change_category") {
          const currentCategoryName = row.detail?.data?.current_category?.name;
          const nextCategoryName = row.detail?.data?.next_category?.name;

          if (currentCategoryName && nextCategoryName) {
            message = (
              <span>
                Change category from <span className="fw-semibold">{currentCategoryName}</span> to{" "}
                <span className="fw-semibold">{nextCategoryName}</span>
              </span>
            );
          } else if (currentCategoryName) {
            message = (
              <span>
                Change category from <span className="fw-semibold">{currentCategoryName}</span> to Unassigned
              </span>
            );
          } else if (nextCategoryName) {
            message = (
              <span>
                Change category from Unassigned to <span className="fw-semibold">{nextCategoryName}</span>
              </span>
            );
          }
        } else if (row.detail?.type === "change_status") {
          message = (
            <span>
              Ticket Status changed from
              <span className="mx-1 fw-semibold">
                {ticketStatusMap[row.detail?.previous_status] || row.detail?.previous_status}
              </span>
              to
              <span className="ms-1 fw-semibold">
                {ticketStatusMap[row.detail?.new_status] || row.detail?.new_status}
              </span>
            </span>
          );
        } else {
          message = typeof row?.detail?.data === "string" ? row?.detail?.data : row?.detail?.type;
        }
        return <div>{message}</div>;
      },
    },
    {
      label: "Type",
      key: "detail.create_note",
    },
    {
      label: "Created",
      key: "detail.created",
      render: (row) => {
        return (
          <PRTooltip title={() => DateHelper.getDateTimeLocal(row.created_date).fromNow()}>
            {DateHelper.getDateTimeLocal(row.detail?.created_date).format("LLT")}
          </PRTooltip>
        );
      },
    },
  ];
  return <PRTable inline columns={columns} data={sortedLogs} />;
}
