import { apiUrlChatLabels, apiUrlLivechat, apiUrlRag, popupSettingsMapper } from "~constants";
import Network from "~helpers/Network";
import Utils from "~helpers/Utils";
import { selectProjects } from "~store/user/selectors";

import * as at from "./actionTypes";

export const onBroadcastMessage = (payload) => ({
  type: at.ON_BROADCAST_MESSAGE,
  payload,
});

/**
 * @param {typeof import("./reducer.js").initState.onlineStatus} payload
 * @returns
 */
export const setOnlineStatus = (payload) => ({
  type: at.SET_ONLINE_STATUS,
  payload,
});

/**
 * @param {typeof import("./reducer.js").initState.viewStatus} payload
 * @returns
 */
export const setViewStatus = (payload) => ({
  type: at.SET_VIEW_STATUS,
  payload,
});

/**
 * @param {typeof import("./reducer").initState.agentInfo} payload Payload
 * @returns {import("redux").Action} Action
 */
export const setAgentInfo = (payload) => ({
  type: at.SET_AGENT_INFO,
  payload,
});

export const setOnlineAgents = (payload) => ({
  type: at.SET_ONLINE_AGENTS,
  payload,
});

export const setLlmSettings = (payload) => ({
  type: at.SET_LLM_SETTINGS,
  payload: payload,
});

export const setCallcenterSettings = (payload) => ({
  type: at.SET_CALLCENTER_SETTINGS,
  payload,
});

export const setSessionCallcenterSettings = (payload) => ({
  type: at.SET_SESSION_CALLCENTER_SETTINGS,
  payload,
});

export const onMessage = (payload) => ({
  type: at.ON_MESSAGE,
  payload,
});

/**
 * @param {typeof import("./reducer").initState.sessionStatus} payload Payload
 * @returns {import("redux").Action} Action
 */
export const setSessionStatus = (payload) => ({
  type: at.SET_SESSION_STATUS,
  payload,
});

/**
 * @param {typeof import("./reducer").initState.sessions} payload Payload
 * @returns {import("redux").Action} Action
 */
export const setSessions = (payload) => ({
  type: at.SET_SESSION_LIST,
  payload,
});

/**
 * @param {number} id Id
 * @param {typeof import("./reducer").initState.sessions[0]} message Message
 * @returns {import("redux").Action} Action
 */
export const appendMessageToSession = (id, message) => ({
  type: at.APPEND_MESSAGE_TO_SESSION,
  payload: { id, message },
});

/**
 * @param {number} id Id
 * @param {typeof import("./reducer").initState.selectedSession.session_status} status Status
 * @param payload
 * @returns {import("redux").Action} Action
 */
export const updateSessionStatus = (id, payload) => ({
  type: at.UPDATE_SESSION_STATUS,
  payload: { id, ...payload },
});
export const updateSessionLabel = (id, payload) => ({
  type: at.UPDATE_SESSION_LABEL,
  payload: { id, ...payload },
});
/**
 * @param {typeof import("./reducer").initState.selectedSession} payload Payload
 * @returns {import("redux").Action} Action
 */
export const setSelectedSession = (payload) => ({
  type: at.SET_SELECTED_SESSION,
  payload,
});

export const setPong = (payload) => ({
  type: at.SET_PONG,
  payload,
});
export const setProjectSettings = (payload) => ({
  type: at.SET_PROJECT_SETTINGS,
  payload,
});

export const setLivechatOptions = (payload) => ({
  type: at.SET_LIVECHAT_OPTIONS,
  payload,
});

export const setConnectionOptions = (payload) => ({
  type: at.SET_CONNECTION_OPTIONS,
  payload,
});

export const updateInteractionStatus = (payload) => ({
  type: at.UPDATE_INTERACTION_STATUS,
  payload,
});

export const setOtherSessionsInfo = (payload) => ({
  type: at.SET_OTHER_SESSIONS_INFO,
  payload,
});

export const setInteractionStatus = (payload) => ({
  type: at.SET_INTERACTION_STATUS,
  payload,
});

export const setLivechatLoadingStatus = (payload) => ({
  type: at.SET_LIVECHAT_LOADING_STATUS,
  payload,
});

/**
 * @param {number | number[]} sessionId
 * @param {"all" | "status_only" | "offline"} listenType
 */
export const sendListenAction = (sessionId, listenType) => ({
  type: at.WS_SEND_MESSAGE_LISTEN_SESSION,
  payload: {
    idList: Array.isArray(sessionId) ? sessionId : [sessionId],
    listenType,
  },
});

export const setSessionCountDelta = (sessionDelta, override) => ({
  type: at.SET_SESSION_COUNT_DELTA,
  payload: {
    sessionDelta,
    override,
  },
});

//--------------  Websocket Actions --------------

export const wsConnect = (reason) => ({
  type: at.WS_CONNECT,
  payload: reason,
});
export const wsDisconnect = () => ({
  type: at.WS_DISCONNECT,
});

export const wsReconnect = () => ({
  type: at.WS_RECONNECT,
});

export const wsConnectSuccess = () => ({
  type: at.WS_CONNECT_SUCCESS,
});

export const wsConnectFail = () => ({
  type: at.WS_CONNECT_FAIL,
});

export const wsLogin = (payload) => ({
  type: at.WS_LOGIN,
  payload,
});

export const wsLoginSuccess = () => ({
  type: at.WS_LOGIN_SUCCESS,
});

export const wsLoginFail = (payload) => ({
  type: at.WS_LOGIN_FAIL,
  payload,
});

export const wsSend = (payload) => ({
  type: at.WS_SEND_MESSAGE,
  payload,
});

export const wsSendLogin = (token, subscribeType = "all", filters) => ({
  type: at.WS_SEND_MESSAGE,
  payload: {
    type: "login",
    payload: {
      token: token,
      subscribe_type: subscribeType,
      query: filters,
    },
  },
});
/**
 * @param {number} sessionId
 * @param {string} data
 * @param {"TEXT" | "FILE" | "IMAGE"} type
 * @returns
 */

export const wsSendMsg = (sessionId, data, type = "TEXT", fileName = "", fileUUID = "") => {
  let payload = {
    type: "message",
    payload: {
      format: "PLAIN",
      type: type,
      ack_id: Utils.getId(),
      session_id: sessionId,
      ...(type === "TEXT"
        ? {
            text: data,
          }
        : {
            url: data,
            ...(fileUUID && { machine_data: fileUUID }),
            ...(fileName && { name: fileName }),
          }),
    },
  };
  return wsSend(payload);
};

// export const wsSendFileMsg = (uploadPayload) => {
//   let payload = {
//     type: "action",
//     payload: {
//       type: "message",
//       payload: { type: "file", data: uploadPayload },
//     },
//   };
//   return wsSend(payload);
// };

export const wsSendAssignSession = (sessionId) => {
  let payload = {
    type: "action",
    payload: {
      type: "join_session",
      ack_id: Utils.getId(),
      session_id: sessionId,
      payload: {
        id: sessionId,
      },
    },
  };
  return wsSend(payload);
};

export const wsStatusUpdate = (isAvailable) => {
  let payload = {
    type: "status_update",
    payload: {
      ack_id: Utils.getId(),
      status: isAvailable ? "AVAILABLE" : "UNAVAILABLE",
    },
  };
  return wsSend(payload);
};

export const wsSendLeaveSession = (sessionId) => {
  let payload = {
    type: "action",
    payload: {
      type: "leave_session",
      ack_id: Utils.getId(),
      session_id: sessionId,
      payload: {
        id: sessionId,
      },
    },
  };
  return wsSend(payload);
};
export const wsTransferAgent = (sessionId, agentId, note) => {
  let payload = {
    type: "action",
    payload: {
      payload: {
        agent_id: agentId,
        previous_process_note: note,
      },
      session_id: sessionId,
      type: "transfer_agent",
      ack_id: Utils.getId(),
    },
  };
  return wsSend(payload);
};

/**
 * @typedef {{
 *   send_type: "whatsapp" | null;
 *   project_id: number;
 *   template_id: number;
 *   email: string | null;
 *   message_details: {
 *     params: string[];
 *     message: {};
 *   };
 * }} BaseType
 * @param {BaseType &
 *   (
 *     | {
 *         send_type: "whatsapp" | null;
 *       }
 *     | {
 *         phone_number: string;
 *       }
 *     | {
 *         to_session_id: number;
 *       }
 *   )} data
 */
export const wsSendTemplateMessage = (data) => {
  let payload = {
    type: "action",
    payload: {
      ack_id: Utils.getId(),
      payload: {
        ...data,
      },
      type: "send_template_message",
    },
  };
  return wsSend(payload);
};

//TASK:project_based_livechat
// export const wsSetProject = (projectId) => {
//   let payload = {
//     type: "action",
//     payload: {
//       type: "set_project",
//       payload: {
//         project_id: projectId,
//       },
//     },
//   };
//   return wsSend(payload);
// };

/**
 * @param {"status_only" | "all"} level
 * @param {string} query
 */
export const wsSendSubscribeLevel = (level, query) => {
  let payload = {
    type: "subscription_type",
    payload: {
      ack_id: Utils.getId(),
      subscribe_type: level,
      query,
    },
  };
  return wsSend(payload);
};

export const wsSendCloseSession = (sessionId, force = false) => {
  let payload = {
    type: "action",
    payload: {
      type: "terminate_session",
      session_id: sessionId,
      ack_id: Utils.getId(),
      force: force,
      payload: {
        id: sessionId,
        force: force,
      },
    },
  };
  return wsSend(payload);
};

export const wsSendReturnToTicket = (sessionId) => {
  let payload = {
    type: "action",
    payload: {
      ack_id: Utils.getId(),
      type: "direct_to_ticket",
      session_id: sessionId,
      payload: {
        id: sessionId,
      },
    },
  };
  return wsSend(payload);
};

export const wsSendDirectToBot = (sessionId) => {
  let payload = {
    type: "action",
    payload: {
      ack_id: Utils.getId(),
      type: "direct_to_bot",
      session_id: sessionId,
      payload: {
        id: sessionId,
      },
    },
  };
  return wsSend(payload);
};

export const wsSendTicketCreate = (sessionId) => {
  let payload = {
    type: "action",
    payload: {
      ack_id: Utils.getId(),
      type: "direct_to_ticket",
      session_id: sessionId,
      payload: {
        id: sessionId,
      },
    },
  };
  return wsSend(payload);
};

/**
 * @param {number | number[]} sessionId
 * @param {"all" | "status_only"} listenType
 */
export const wsSendListen = (sessionId, listenType = "all") => {
  let payload = {
    type: "action",
    payload: {
      ack_id: Utils.getId(),
      type: "listen_session",
      payload: {
        sessions: Array.isArray(sessionId) ? sessionId : [sessionId],
        listen_type: listenType,
      },
    },
  };
  return wsSend(payload);
};

/** @param {number | number[]} sessionId */
export const wsSendStopListen = (sessionId) => {
  let payload = {
    type: "action",
    payload: {
      ack_id: Utils.getId(),
      type: "stop_listen_session",
      payload: {
        sessions: Array.isArray(sessionId) ? sessionId : [sessionId],
        // listen_type: listenType,
      },
    },
  };
  return wsSend(payload);
};

export const wsSendTransferToAgent = (agentId, note = "") => {
  let payload = {
    type: "action",
    payload: {
      ack_id: Utils.getId(),
      type: "transfer_agent",
      payload: {
        agent_id: agentId,
        previous_process_note: note, //mandatory field
      },
    },
  };
  return wsSend(payload);
};

export const wsAskSessionInfo = (limit, offset) => {
  let payload = {
    type: "action",
    payload: {
      ack_id: Utils.getId(),
      type: "ask_session_info",
      payload: {
        query_type: "other", //"assigned","waiting"
        limit: limit,
        offset: offset,
      },
    },
  };
  return wsSend(payload);
};

export const wsSendInteractionStatus = (sessionId, { focused, typing, location, time }) => {
  let payload = {
    type: "interaction_status",
    session_id: sessionId,
    payload: {
      focused,
      typing,
      location,
      time,
    },
  };
  return wsSend(payload);
};

export const wsSendPing = () => {
  let payload = {
    type: "ping",
  };
  return wsSend(payload);
};

export const wsGetNextSession = ({ project_id }) => {
  let payload = {
    type: "action",
    payload: {
      ack_id: Utils.getId(),
      type: "get_next_session",
      payload: {
        project_id,
      },
    },
  };
  return wsSend(payload);
};

// -------------- API --------------

//TODO move to common api
export const getProjectSettings = () => async (dispatch, getState) => {
  const otherProjects = selectProjects(getState());
  const responseList = await Promise.all([
    ...otherProjects.map((project) => Network.request(`project/${project.id}/project-settings/`, { loading: true })),
  ]);

  const firstItemAggregated = responseList.reduce((acc, item) => {
    const settingsItem = popupSettingsMapper(item?.results?.[0]);
    return [...acc, settingsItem];
  }, []);

  if (firstItemAggregated?.length) {
    dispatch(setProjectSettings(firstItemAggregated));
  }
  return firstItemAggregated;
};

export const getAgentInfo = () => (_dispatch, _getState) => {
  return Network.request("callcenter/agent-info/", {
    onSuccess: (data) => {
      return setAgentInfo(data);
    },
  });
};

/**
 * @param {typeof import("./reducer").initState.agentInfo} payload Payload
 * @param {object} [restOptions={}] RestOptions. Default is `{}`
 * @returns {import("redux").Action} Action
 */
export const updateAgentInfo =
  (payload, restOptions = {}) =>
  (_dispatch, _getState) => {
    return Network.request("callcenter/agent-info/", {
      loading: true,
      ...restOptions,
      data: payload,
      method: "POST",
      onSuccess: (data) => {
        return setAgentInfo({ ...data });
      },
    });
  };

export const getOnlineAgents =
  (projectId, filterText = "", restOptions = {}) =>
  (_dispatch, _getState) => {
    return Network.request(`project/${projectId}/callcenter/available-agents/?q=${filterText}`, {
      loading: true,
      ...restOptions,
      onSuccess: setOnlineAgents,
    });
  };

export const getLlmSettings =
  (projectId, restOptions = {}) =>
  async (_dispatch, _getState) => {
    const response = await Network.request(apiUrlRag.llmSettings.format(projectId, ""), {
      loading: true,
    });
    const results = response?.results || [];
    _dispatch(setLlmSettings(results[0] ?? {}));
    return results[0] ?? {};
  };

export const updateLlmSettings =
  (projectId, payload, restOptions = {}) =>
  (_dispatch, _getState) => {
    return Network.request(apiUrlRag.llmSettings.format(projectId, payload.id || ""), {
      loading: true,
      data: payload,
      method: payload.id ? "PATCH" : "POST",
      ...restOptions,
      // onSuccess: setLlmSettings,
    });
  };

export const deleteLlmSettings =
  (projectId, id, restOptions = {}) =>
  (_dispatch, _getState) => {
    return Network.request(apiUrlRag.llmSettings.format(projectId, id), {
      loading: true,
      method: "DELETE",
      ...restOptions,
      // onSuccess: setLlmSettings,
    });
  };

export const getSessionCallcenterSettings =
  (projectId, restOptions = {}) =>
  (_dispatch, _getState) => {
    return Network.request(apiUrlLivechat.callcenterSettings.format(projectId), {
      loading: true,
      ...restOptions,
      onSuccess: setSessionCallcenterSettings,
    });
  };

export const getCallcenterSettings =
  (projectId, restOptions = {}) =>
  (_dispatch, _getState) => {
    return Network.request(apiUrlLivechat.callcenterSettings.format(projectId), {
      loading: true,
      ...restOptions,
      onSuccess: setCallcenterSettings,
    });
  };

export const updateCallcenterSettings =
  (projectId, payload, restOptions = {}) =>
  (_dispatch, _getState) => {
    return Network.request(apiUrlLivechat.callcenterSettings.format(projectId), {
      loading: true,
      data: payload,
      method: "PUT",
      ...restOptions,
      // onSuccess: setCallcenterSettings,
    });
  };

export const uploadFile =
  (sessionId, file, projectId = null, isPublic = false) =>
  (_dispatch, _getState) => {
    const form = new FormData();
    form.append("file", file);
    if (sessionId) {
      form.append("session_id", sessionId);
    }
    if (projectId) {
      form.append("project_id", projectId);
    }
    form.append("public", isPublic ? "True" : "False");

    return Network.request(apiUrlLivechat.upload, {
      method: "POST",
      data: form,
    });
  };

export const createOrUpdateChatLabel = (project, sessionId, labelId) => (_dispatch, _getState) => {
  const url = apiUrlChatLabels.set.format(project, sessionId);

  return Network.request(url, {
    method: "POST",
    loading: true,
    successMsg: "Label set successfully",
    data: {
      label: labelId,
    },
  });
};
