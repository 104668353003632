import { MdDragIndicator } from "react-icons/md";
import { SortableHandle } from "react-sortable-hoc";

import "./style.scss";
import classNames from "classnames";

const Handle = SortableHandle(() => <MdDragIndicator className="" />);

const PRDragHandle = function PRDragHandle({ className, absolute = true, ...props }) {
  return (
    <div
      className={classNames("pr-drag-handle", className, {
        absolute: absolute,
      })}
      {...props}
    >
      <Handle />
    </div>
  );
};
export default PRDragHandle;
