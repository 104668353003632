import { useEffect, useMemo, useState } from "react";

import classNames from "classnames";
import { useDispatch, useSelector } from "react-redux";

import { Grid } from "@mui/material";

import useScrollDirection from "~common/hooks/useScrollDirection";
import PRContainer from "~components/Generic/PRContainer";
import PRPage from "~components/Generic/PRPage";
import PRTable from "~components/Generic/PRTable";
import LeaderboardFilterCard from "~components/Leaderboards/LeaderboardFilterCard";
import PalLink from "~components/mui/PalLink";
import { apiUrlIntentLeaderboard, tableFilterStorageKey } from "~constants";
import DateHelper from "~helpers/DateHelper";
import { getIntentTag, getIntentTags } from "~store/dialogComponents/intentTags/actions";
import { selectPopupSettingsState } from "~store/settings/popupSettings/selectors";
import { selectCurrentBot, selectCurrentProject } from "~store/user/selectors";

export default function IntentLeaderboard({ scope }) {
  // const bots = useSelector(selectBots);
  const isFromStatistics = scope === "statistics";
  const [filterSticked, setFilterSticked] = useState(false);
  const popupSettings = useSelector(selectPopupSettingsState);
  const currentBot = useSelector(selectCurrentBot);
  const scrollDirection = useScrollDirection(document.getElementById("main-scroll"));
  const dispatch = useDispatch();

  const [genericQueryFilter, setGenericQueryFilter] = useState({
    begin_date: DateHelper.getDateTimeLocal().subtract(1, "month").format("YYYY-MM-DD"),
    end_date: DateHelper.getDateTimeLocal().format("YYYY-MM-DD"),
    filter_day: 1,
    view_type: 1,
  });

  const currentProject = useSelector(selectCurrentProject);

  const filters = useMemo(() => {
    return [
      {
        key: "intent_tag",
        //TODO: check if could be removed
        onFetch: async (searchText, callback, signal, valueProp) => {
          const response = await dispatch(
            getIntentTags(currentProject.id, currentBot.id, {
              params: {
                key__icontains: searchText,
                limit: 20,
                signal,
              },
            })
          );
          const result = response?.results || [];
          const valueIdList = valueProp;
          const missingIdList = valueIdList?.filter((id) => !result.find((item) => item.id === id));
          if (valueProp && missingIdList?.length) {
            const resultsList = await Promise.all(
              missingIdList.map((id) =>
                dispatch(getIntentTag(currentProject.id, currentBot.id, id, { loading: false }))
              )
            );
            result.unshift(...resultsList);
          }
          return result.map((item) => ({
            label: item.key,
            value: item.id,
          }));
        },
      },
    ];
  }, [currentBot.id, currentProject.id, dispatch]);

  useEffect(() => {
    const positionHandler = () => {
      const filterDom = document.getElementById("filter-col");
      if (!filterDom) return;

      const { top } = filterDom.getBoundingClientRect();
      const limit = 110; // header + padding-top
      // const limit = 70 + 24; // header + padding-top
      if (top <= limit) {
        setFilterSticked(true);
      } else if (scrollDirection === "up") {
        setFilterSticked(false);
      }
    };

    const filterDom = document.getElementById("filter-col");
    if (!filterDom) return;

    const mainDom = document.getElementById("main-scroll");
    mainDom.addEventListener("scroll", positionHandler);

    return () => {
      mainDom.removeEventListener("scroll", positionHandler);
    };
  }, [scrollDirection]);

  const handleChangeGenericFilter = (key) => (value) => {
    let newFilter = {};

    const newState = {
      ...genericQueryFilter,
      [key]: value,
      ...newFilter,
    };
    setGenericQueryFilter(newState);
  };

  const { selectedListUrl, columns } = useMemo(() => {
    const url = genericQueryFilter?.view_type === 1 ? apiUrlIntentLeaderboard.listTag : apiUrlIntentLeaderboard.list;

    const columns =
      genericQueryFilter?.view_type === 1
        ? [
            {
              label: "Tag",
              key: "key",
              render: (row) => {
                return (
                  <PalLink newTab to={`/chatbot/intent-tags/form/${row.id}/`}>
                    {row.key}
                  </PalLink>
                );
              },
            },
            {
              label: "Show Count",
              key: "show_count",
            },
            popupSettings.show_thumps_up && {
              label: "Like Count",
              key: "like_count",
            },

            popupSettings.show_thumps_up && {
              label: "Dislike Count",
              key: "dislike_count",
            },
            // {
            //   label: "Bot",
            //   key: "bot",
            //   render: (row) => {
            //     const bot = bots.find((bot) => bot.id === row.bot);
            //     return <>{bot?.name}</>;
            //   },
            // },
          ].filter(Boolean)
        : [
            {
              label: "Intent",
              key: "text",
              render: (row) => {
                return (
                  <PalLink newTab to={`/chatbot/intent/form/${row.id}/`}>
                    {row.text}
                  </PalLink>
                );
              },
            },
            {
              label: "Display Text",
              key: "display_text",
            },
            {
              label: "View Count",
              key: "view_count",
            },
            popupSettings.show_thumps_up && {
              label: "Like Count",
              key: "like_count",
            },
            popupSettings.show_thumps_up && {
              label: "Dislike Count",
              key: "dislike_count",
            },
            // {
            //   label: "Bot",
            //   key: "bot",
            //   render: (row) => {
            //     const bot = bots.find((bot) => bot.id === row.bot);
            //     return <>{bot?.name}</>;
            //   },
            // },
          ].filter(Boolean);
    return {
      selectedListUrl: url,
      columns,
    };
  }, [genericQueryFilter?.view_type, popupSettings.show_thumps_up]);

  const handleDateChange = ({ beginDate, endDate }) => {

    setGenericQueryFilter({
      ...genericQueryFilter,
      begin_date: beginDate,
      end_date: endDate,
    });
  };

  const handleViewChange = (viewType) => {
    setGenericQueryFilter({
      ...genericQueryFilter,
      view_type: viewType,
    });
  };

  return (
    <PRContainer
      className={"pr-agent-stats"}
      description={"Here you can see the statistics of intent usage."}
      name={isFromStatistics ? "Statistics" : "Chatbot"}
      parentName={"Intent Leaderboard"}
    >
      <Grid container>
        <Grid
          item
          className={classNames("pr-stats-filter-col", {
            "pr-stats-filter-col-sticked": filterSticked,
          })}
          id="filter-col"
          xs={12}
        >
          <PRPage headerColor="primary" title={!filterSticked && "Filter"}>
            <Grid container marginTop={!filterSticked ? 0 : 3}>
              <Grid item xs={12}>
                <LeaderboardFilterCard intentFilter onDateChange={handleDateChange} onViewChange={handleViewChange} />
              </Grid>
            </Grid>
          </PRPage>
        </Grid>
        <Grid item xs={12}>
          <PRPage title={`${genericQueryFilter?.view_type === 2 ? "Intents" : "Intent Tags"} for ${currentBot?.name}`}>
            <PRTable
              inline
              columns={columns}
              filters={genericQueryFilter?.view_type === 2 ? filters : undefined}
              memoizeAsQuery={false}
              storageKey={tableFilterStorageKey.intentLeaderboardList}
              url={`${selectedListUrl.format(currentProject?.id)}?begin_date=${
                genericQueryFilter?.begin_date
              }&end_date=${genericQueryFilter?.end_date}&bot=${currentBot?.id}`}
            />
          </PRPage>
        </Grid>
      </Grid>
    </PRContainer>
  );
}
