import React, { useEffect, useState } from "react";

import debounce from "lodash/debounce";

import styled from "@emotion/styled";
import { Grid } from "@mui/material";

import { ReactComponent as OfflineImage } from "~assets/images/offline.svg";
import PalButton from "~components/mui/PalButton";
import HistoryHelper from "~helpers/HistoryHelper";

const StyledDiv = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #fff;
  position: absolute;
  width: 100%;
  height: calc(100% - 80px);
  padding: 30px 50px;
  @media (max-width: 600px) {
    padding: 30px 20px;
  }

  overflow-y: auto;
  svg {
    flex: 0 0 auto;
  }
  .input-box {
    margin: 100px auto auto auto;
    @media (max-width: 600px) {
      max-width: 100%;
      margin: 30px auto auto;
    }

    max-width: 555px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    .title {
      color: ${(props) => props.theme.palette.primary.dark};
      //
      font: normal normal 600 36px/34px MuseoModerno;
      height: 57px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .desc {
      font: normal normal normal 22px/28px Museo Sans;
      color: ${(props) => props.theme.palette.primary.dark};
      letter-spacing: 0;
      margin-bottom: 20px;
      // text-align: center;
    }
    .company {
      font-size: 30px;
      font-weight: 700;

      color: ${(props) => props.theme.palette.primary.dark};
      margin-bottom: 10px;
    }
  }

  .MuiLink-root {
    color: #535353;
    text-decoration: underline !important;
  }
  .retry-btn {
    font-weight: bold;
    background-color: ${(props) => props.theme.palette.primary.light};
    border-color: ${(props) => props.theme.palette.primary.light};
    max-width: 365px;
  }
`;

const Offline = ({ redirectTo }) => {
  document.title = "Palmate";

  const handleClickTryAgain = () => {
    HistoryHelper.push(redirectTo || "/");
  };
  return (
    <StyledDiv>
      <div className="input-box">
        <Grid container columnSpacing={"15px"} rowSpacing={"25px"}>
          <Grid item xs={12}>
            <OfflineImage />
          </Grid>
          <Grid item xs={12}>
            <div className="title">Offline</div>
            <div className="desc"> You are currently offline. Please check your internet connection and try again.</div>
          </Grid>

          <Grid item xs={12}>
            <PalButton fullWidth className="retry-btn" variant="contained" onClick={handleClickTryAgain}>
              Try Again
            </PalButton>
          </Grid>
        </Grid>
      </div>
    </StyledDiv>
  );
};

export default Offline;

export function OfflineWrapper({ children }) {
  const [online, setOnline] = useState(navigator.onLine);
  useEffect(() => {
    function updateOnlineStatus() {
      setOnline(navigator.onLine);
    }

    const debounceUpdateOnlineStatus = debounce(updateOnlineStatus, 3000);

    window.addEventListener("online", debounceUpdateOnlineStatus);
    window.addEventListener("offline", debounceUpdateOnlineStatus);

    return () => {
      window.removeEventListener("online", debounceUpdateOnlineStatus);
      window.removeEventListener("offline", debounceUpdateOnlineStatus);
    };
  }, []);

  if (online) {
    return children;
  }
  const url = window.location.pathname;
  return <Offline redirectTo={url} />;
}
