import { createRef, useMemo } from "react";

import { MdAdd, MdDelete, MdEdit } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";

import PRButton from "~components/Generic/PRButton";
import PRContainer from "~components/Generic/PRContainer";
import PRTable from "~components/Generic/PRTable";
import { apiUrlOrganization, organizationAnnouncementFilterType, tableFilterStorageKey } from "~constants";
import DialogHelper from "~helpers/DialogHelper";
import { deleteFilter } from "~store/organization/actions";
import { selectCurrentProject } from "~store/user/selectors";
// import { deleteMemberField, getFilterList } from "~store/organization/actions";

import { filterGetReadableText } from "../FilterUtils";
import "./style.scss";

export default function FilterList() {
  const dispatch = useDispatch();
  const tableRef = createRef();

  const currentProject = useSelector(selectCurrentProject);

  const columns = useMemo(() => {
    const handleDelete = (row) => async () => {
      if (!(await DialogHelper.showQuestionDelete())) return;
      await dispatch(deleteFilter(row.id));
      tableRef.current.refresh();
    };
    return [
      {
        label: "Name",
        key: "name",
        className: "text-nowrap",
      },
      {
        label: "Filter Type",
        key: "filter_type",
        className: "text-nowrap",
        render: (row) => {
          let text = row.filter_type;
          if (row.filter_type === organizationAnnouncementFilterType.ID_LIST) {
            text = "Selection";
          } else if (row.filter_type === organizationAnnouncementFilterType.LOW_CODE) {
            text = "LowCode";
          } else {
            text = "Comparison";
          }
          return <span>{text}</span>;
        },
      },
      {
        label: "Filter Rule",
        key: "filter_rule",
        render: (row) => <span>{filterGetReadableText(row, true)}</span>,
      },
      {
        label: "Actions",
        key: "actions",
        actions: true,
        fixed: "right",
        render: (row) => (
          <div className="d-flex justify-content-center">
            <PRButton
              outline
              color="primary"
              icon={MdEdit}
              link={`/organization/filter-templates/form/${row.id}`}
              size="sm"
              tooltipText="Edit"
            />
            <PRButton
              outline
              className="ms-1"
              color="danger"
              icon={MdDelete}
              size="sm"
              tooltipText="Delete"
              onClick={handleDelete(row)}
            />
          </div>
        ),
      },
    ];
  }, [dispatch, tableRef]);

  const actions = useMemo(() => {
    return [
      {
        label: "Create New",
        icon: MdAdd,
        color: "success",
        link: "/organization/filter-templates/form",
      },
    ];
  }, []);

  return (
    <PRContainer
      actions={actions}
      className="pr-"
      description={"Here you can manage your filter templates to use in future"}
      name="Organization"
      parentName="Filter Templates"
    >
      <PRTable
        ref={tableRef}
        columns={columns}
        storageKey={tableFilterStorageKey.filterList}
        url={apiUrlOrganization.getFilter.format(currentProject.id)}
        urlQuery={{ is_template: true }}
      />
    </PRContainer>
  );
}
