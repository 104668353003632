import { createSelector } from "reselect";

/** @type {() => typeof import("./reducer").initState} */
export const selectRoot = (state) => state.statistics;

export const selectChatbotStatistics = createSelector(selectRoot, (root) => root.statisticChatbot);
export const selectWeeklySessions = createSelector(selectRoot, (root) => root.weeklySessions);
export const selectWeeklyTickets = createSelector(selectRoot, (root) => root.weeklyTickets);
export const selectAgentStatistics = createSelector(selectRoot, (root) => root.agentStatistics);
export const selectAgentTicketStatistics = createSelector(selectRoot, (root) => root.agentTicketStatistics);
export const selectTicketStatistics = createSelector(selectRoot, (root) => root.ticketStatistics);
export const selectGenericStatistics = createSelector(selectRoot, (root) => root.genericStatistics);
export const selectGenericStatisticsByTypeList = createSelector(
  //arg1
  selectGenericStatistics,
  //arg2
  (_, typeList) => typeList,
  //result(arg1, arg2)
  (genericStatistics, typeList) => {
    const filtered = typeList.map((type) => genericStatistics[type]);
    const mapped = filtered.reduce((acc, cur) => {
      if (!cur) return acc;
      return {
        ...acc,
        [cur.key]: cur,
      };
    }, {});
    return mapped;
  }
);

export const selectStatistics = createSelector(selectRoot, (root) => root.statistics);
export const selectStatisticsByKey = (key) => createSelector(selectStatistics, (statistics) => statistics[key] || 0);
export const selectStatisticsFilter = createSelector(selectRoot, (root) => root.statisticsFilter);
export const selectAgents = createSelector(selectRoot, (root) => root.agents);
export const selectAgentSpesificStatistics = createSelector(selectRoot, (root) => root.agentSpesificStatistics);
