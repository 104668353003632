import classNames from "classnames";
import { MdChevronRight } from "react-icons/md";
import { Col, Row } from "reactstrap";

import styled from "@emotion/styled";
import { Grow } from "@mui/material";

import useBootstrapBreakpoint from "~common/hooks/useBootstrapBreakpoint";
import PRLink from "~components/Generic/PRLink";

import PRButton from "./Generic/PRButton";
import { usePRContainerContext } from "./Generic/PRContainer";
import PRTooltip from "./Generic/PRTooltip";
import SmalltalkSelector from "./SmalltalkSelector";

const StyledActionButton = styled(PRButton)`
  /* background-color: #50c8f4; */
`;
export const RenderBreadcrumbActions = ({ actions = [] }) => {
  const isSm = useBootstrapBreakpoint("sm");
  return (
    <Row className="g-0">
      {actions.map((action, index) => {
        const {
          component,
          render,
          label,
          color = "primary-light",
          loading,
          className: actionClassName,
          icon,
          fromContext,
          ...rest
        } = action;
        const ActionComponent = component || StyledActionButton;
        return (
          <Grow key={index} direction="left" in={true} timeout={!fromContext ? 0 : 450}>
            <div
              className={classNames("col col-auto gx-2", {
                "pe-0": index === actions.length - 1,
              })}
            >
              <ActionComponent
                icon={icon}
                loading={loading}
                size={isSm ? "sm" : "md"}
                {...rest}
                className={classNames("fw-medium", actionClassName)}
                color={color}
              >
                {typeof render === "function" ? render() : label}
              </ActionComponent>
            </div>
          </Grow>
        );
      })}
    </Row>
  );
};

const StyledDiv = styled.div`
  padding: 0 5px 0 10px;
  & > .breadcrumbs-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 15px;
    margin-bottom: 22px;
  }
`;
const StyledLabel = styled.span`
  /* margin: 0 10px 31px 10px; */
  font-family: "Museo Sans";
  font-size: 13px;
  font-weight: bold;
  line-height: 1;
  text-align: left;
  color: rgba(0, 0, 0, 0.65);
  font-weight: ${(props) => (props.bold ? "bold" : "normal")};
`;

const Title = styled.span`
  font-size: 18px;
  line-height: 1;
  color: rgba(0, 0, 0, 0.85);
  /* margin-left: 10px; */
`;
const Description = styled.div`
  line-height: 13px;
  font-size: 13px;
  color: var(--bs-secondary);
  margin-top: 3px;
`;

// g-0 pb-2 d-flex align-items-center justify-content-end
const ActionRow = styled(Row)`
  padding-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;
const BreadcrumbVerticalActions = () => {
  const { actions } = usePRContainerContext();
  return <>{actions?.length > 0 && <RenderBreadcrumbActions actions={actions} />}</>;
};
const Breadcrumb = ({ title, breadcrumbItem: breadcrumbItemProp, description, smalltalkSelector }) => {
  const breadcrumbItem =
    breadcrumbItemProp && !Array.isArray(breadcrumbItemProp)
      ? [title, breadcrumbItemProp]
      : [title, ...(breadcrumbItemProp || [])];
  const lastItem = breadcrumbItem[breadcrumbItem.length - 1];

  return (
    <StyledDiv>
      <div className="breadcrumbs-container fs-5 d-flex   justify-content-between">
        <ol className="lh-1 ps-0 pt-0 m-0 d-flex align-items-center">
          <PRLink className="d-flex align-items-center text-secondary fw-bold" scope={"dashboard"} to={"/home"}>
            <StyledLabel bold>Home</StyledLabel>
          </PRLink>
          <MdChevronRight className="fs-4 lh-1" />

          {breadcrumbItem.map((item, index) => {
            const labelItem = (
              <StyledLabel
                className={classNames({
                  "text-secondary": true,
                })}
              >
                {item?.label || item}
              </StyledLabel>
            );
            return (
              <StyledLabel key={index} className="d-flex align-items-center">
                {!item?.url ? (
                  labelItem
                ) : (
                  <PRLink scope={item.scope} to={item?.url || "#"}>
                    {labelItem}
                  </PRLink>
                )}
                {index < breadcrumbItem.length - 1 && <MdChevronRight className="fs-4 " />}
              </StyledLabel>
            );
          })}
        </ol>
        {smalltalkSelector && <div>{smalltalkSelector && <SmalltalkSelector />}</div>}
      </div>
      <ActionRow className="g-0">
        <Col xs>
          <PRTooltip title={description}>
            <Title>{lastItem.label || lastItem}</Title>
            {/* <span className="lh-1 mb-0 mb-2 font-size-18">{lastItem.label || lastItem}</span> */}
          </PRTooltip>

          {description && <Description>{description}</Description>}
        </Col>
        {/* <Col xs="auto">{smalltalkSelector && <SmalltalkSelector />}</Col> */}
        <Col id="breadcrumb-actions" xs="auto">
          <BreadcrumbVerticalActions />
        </Col>
      </ActionRow>
    </StyledDiv>
  );
};

export default Breadcrumb;

// export function BreadcrumbActions({ children, actions, description }) {
//   const breadcrumbActions = document.getElementById("breadcrumb-actions");
//   const breadcrumbDescription = document.getElementById("breadcrumb-description");
//   const portals = [];
//   if (breadcrumbDescription) {
//     portals.push(createPortal(description, breadcrumbDescription));
//   }

//   let childrenItem = children;
//   if (actions) {
//     childrenItem = <RenderActions actions={actions} />;
//   }
//   if (childrenItem && breadcrumbActions) {
//     portals.push(createPortal(childrenItem, breadcrumbActions));
//   }
//   return <>{portals}</>;
// }
