import React, { forwardRef, useMemo, useState, useEffect } from "react";

import * as echarts from "echarts/core";
import { CanvasRenderer, SVGRenderer } from "echarts/renderers";
import ReactEChartsCore from "echarts-for-react/lib/core";

import { Box, CircularProgress } from "@mui/material";

import Utils from "~helpers/Utils";

import theme from "./theme.json";
const chartImports = {
  LineChart: () => import("echarts/charts").then((mod) => mod.LineChart),
  BarChart: () => import("echarts/charts").then((mod) => mod.BarChart),
  PieChart: () => import("echarts/charts").then((mod) => mod.PieChart),
  ScatterChart: () => import("echarts/charts").then((mod) => mod.ScatterChart),
  RadarChart: () => import("echarts/charts").then((mod) => mod.RadarChart),
  MapChart: () => import("echarts/charts").then((mod) => mod.MapChart),
  TreeChart: () => import("echarts/charts").then((mod) => mod.TreeChart),
  TreemapChart: () => import("echarts/charts").then((mod) => mod.TreemapChart),
  GraphChart: () => import("echarts/charts").then((mod) => mod.GraphChart),
  GaugeChart: () => import("echarts/charts").then((mod) => mod.GaugeChart),
  FunnelChart: () => import("echarts/charts").then((mod) => mod.FunnelChart),
  ParallelChart: () => import("echarts/charts").then((mod) => mod.ParallelChart),
  SankeyChart: () => import("echarts/charts").then((mod) => mod.SankeyChart),
  BoxplotChart: () => import("echarts/charts").then((mod) => mod.BoxplotChart),
  CandlestickChart: () => import("echarts/charts").then((mod) => mod.CandlestickChart),
  EffectScatterChart: () => import("echarts/charts").then((mod) => mod.EffectScatterChart),
  LinesChart: () => import("echarts/charts").then((mod) => mod.LinesChart),
  HeatmapChart: () => import("echarts/charts").then((mod) => mod.HeatmapChart),
  PictorialBarChart: () => import("echarts/charts").then((mod) => mod.PictorialBarChart),
  ThemeRiverChart: () => import("echarts/charts").then((mod) => mod.ThemeRiverChart),
  SunburstChart: () => import("echarts/charts").then((mod) => mod.SunburstChart),
  CustomChart: () => import("echarts/charts").then((mod) => mod.CustomChart),
};

const componentImports = {
  TitleComponent: () => import("echarts/components").then((mod) => mod.TitleComponent),
  TooltipComponent: () => import("echarts/components").then((mod) => mod.TooltipComponent),
  GridComponent: () => import("echarts/components").then((mod) => mod.GridComponent),
  ToolboxComponent: () => import("echarts/components").then((mod) => mod.ToolboxComponent),
  BrushComponent: () => import("echarts/components").then((mod) => mod.BrushComponent),
  LegendComponent: () => import("echarts/components").then((mod) => mod.LegendComponent),
  LegendScrollComponent: () => import("echarts/components").then((mod) => mod.LegendScrollComponent),
  LegendPlainComponent: () => import("echarts/components").then((mod) => mod.LegendPlainComponent),
  DataZoomComponent: () => import("echarts/components").then((mod) => mod.DataZoomComponent),
  DataZoomInsideComponent: () => import("echarts/components").then((mod) => mod.DataZoomInsideComponent),
  DataZoomSliderComponent: () => import("echarts/components").then((mod) => mod.DataZoomSliderComponent),
  MarkPointComponent: () => import("echarts/components").then((mod) => mod.MarkPointComponent),
  MarkLineComponent: () => import("echarts/components").then((mod) => mod.MarkLineComponent),
  MarkAreaComponent: () => import("echarts/components").then((mod) => mod.MarkAreaComponent),
  AriaComponent: () => import("echarts/components").then((mod) => mod.AriaComponent),
  TransformComponent: () => import("echarts/components").then((mod) => mod.TransformComponent),
};

function useDynamicECharts(option, renderer) {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    async function loadComponentsAndCharts() {
      const componentKeys = Object.keys(componentImports);
      const chartKeys = Object.keys(chartImports);

      // Bileşenlerin ve grafiklerin yüklenmesi
      const componentsPromises = componentKeys.map((key) => componentImports[key]());
      const chartsPromises = chartKeys.map((key) => chartImports[key]());

      const loadedComponents = await Promise.all(componentsPromises);
      const loadedCharts = await Promise.all(chartsPromises);

      // Bileşenlerin ve grafiklerin ECharts'a kaydedilmesi
      echarts.use([...loadedComponents, ...loadedCharts, renderer === "canvas" ? CanvasRenderer : SVGRenderer]);
      setLoaded(true);
    }

    loadComponentsAndCharts();
  }, []);

  return loaded;
}

const formatterHideBlank = (tooltipProps) => (params) => {
  let tooltipText = "";
  for (let i = 0; i < params.length; i++) {
    const param = params[i];
    let value = Array.isArray(param.value) && param.value?.length > 1 ? param.value[1] : param.value;

    const nonFormattedValue = value;
    if (tooltipProps?.valueFormatter && typeof tooltipProps.valueFormatter === "function") {
      value = tooltipProps.valueFormatter(value);
    } else {
      if (typeof value === "number") {
        value = value.toFixed(2);
      }
    }
    if (nonFormattedValue > 0) {
      tooltipText += `
      <div class="row flex-wrap">
        <div class="col">
          ${param.marker} ${param.seriesName}:
        </div>
        <div class="fw-bold col-auto">
          ${value}
        </div>
      </div>
      `;
    }
  }

  tooltipText += "";
  return tooltipText;
};

/**
 * @param {import("echarts-for-react").EChartsReactProps>} param0
 * @param {React.Ref<any>} ref
 */
function PRChart({ option: optionProp, loading, height = "25vh", renderer = "canvas", ...props }, ref) {
  const option = useMemo(() => {
    const { hideBlank, ...tooltipOptions } = optionProp?.tooltip || {};
    return {
      ...optionProp,

      title: {
        ...optionProp?.title,
        textStyle: {
          fontSize: 14,
          fontWeight: 500,
          fontFamily: "Museo Sans",
          ...optionProp?.title?.textStyle,
        },
      },
      tooltip: {
        trigger: "axis",
        ...tooltipOptions,
        ...(hideBlank && { formatter: formatterHideBlank(tooltipOptions) }),
      },
      // grid: {
      //   // left: 32,
      //   // right: 32,
      //   // top: 32,
      //   // bottom: 32,
      grid: Array.isArray(optionProp?.grid)
        ? optionProp?.grid.map((item) => ({
            left: "3%",
            right: "4%",
            bottom: "3%",
            containLabel: true,
            ...item,
          }))
        : {
            left: "3%",
            right: "4%",
            bottom: "3%",
            containLabel: true,
            ...optionProp?.grid,
          },

      // ...(optionProp?.xAxis && {
      //   xAxis: {
      //     ...optionProp?.xAxis,
      //     boundaryGap: false,
      //   },
      // }),

      toolbox: {
        ...optionProp?.toolbox,
        feature: {
          saveAsImage: {
            ...optionProp?.toolbox?.feature?.saveAsImage,
            name: Utils.slugify(optionProp?.title?.text || "palmate_chart"),
          },
          ...optionProp?.toolbox?.feature,
        },
      },
    };
  }, [optionProp]);
  const style = useMemo(() => {
    return {
      height,
      ...props.style,
    };
  }, [height, props.style]);

  const isEChartsLoaded = useDynamicECharts(option, renderer);
  if (!isEChartsLoaded) {
    return (
      <Box alignItems="center" display="flex" height={height} justifyContent="center">
        <CircularProgress />
      </Box>
    );
  }
  return (
    <ReactEChartsCore
      echarts={echarts}
      {...props}
      ref={ref}
      option={option}
      showLoading={loading}
      style={style}
      theme={theme.theme}
    />
  );
}
/** @typedef {import("echarts-for-react").EChartsReactProps} EChartsReactProps */
export default forwardRef(PRChart);
