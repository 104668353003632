import { useMemo, useRef } from "react";

import { MdAdd, MdDelete, MdEdit } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { Badge } from "reactstrap";

import useProjectChange from "~common/hooks/useProjectChange";
import PRButton from "~components/Generic/PRButton";
import PRContainer from "~components/Generic/PRContainer";
import PRTable from "~components/Generic/PRTable";
import { apiUrlChatbot, tableFilterStorageKey } from "~constants";
import DialogHelper from "~helpers/DialogHelper";
import { deleteDictionary } from "~store/dialogComponents/dictionaries/actions";
import { selectCurrentBot, selectCurrentProject } from "~store/user/selectors";

export default function DictionariesList() {
  const tableRef = useRef(null);
  const currentProject = useSelector(selectCurrentProject);
  const currentBot = useSelector(selectCurrentBot);
  const dispatch = useDispatch();

  useProjectChange(() => {
    tableRef.current.resetPagination();
  }, []);

  const columns = useMemo(() => {
    return [
      {
        label: "Name",
        key: "key",
      },
      {
        label: "Values",
        key: "values",
        render: (row) => {
          return (
            <>
              {row.values?.map((value) => {
                return (
                  <Badge key={value} className="badge-soft-secondary me-1">
                    <span className="font-size-12">{value}</span>
                  </Badge>
                );
              })}
            </>
          );
        },
      },
      {
        label: "Actions",
        key: "actions",
        actions: true,
        render: (row) => {
          const handleClickDelete = async () => {
            if (!(await DialogHelper.showQuestionDelete())) return;
            dispatch(deleteDictionary(currentProject.id, currentBot.id, row.id)).then(() => {
              tableRef.current.refresh();
            });
          };

          return (
            <div className="d-flex justify-content-center">
              <PRButton
                outline
                color="primary"
                icon={MdEdit}
                link={`/chatbot/dictionaries/form/${row.id}`}
                size="sm"
                tooltipText="Edit"
              />
              <PRButton
                outline
                className="ms-1"
                color="danger"
                icon={MdDelete}
                size="sm"
                tooltipText="Delete"
                onClick={handleClickDelete}
              />
            </div>
          );
        },
      },
    ];
  }, [dispatch, currentProject, currentBot]);

  const actions = useMemo(() => {
    return [
      {
        label: "Create New",
        icon: MdAdd,
        color: "success",
        link: `/chatbot/dictionaries/form/`,
      },
    ];
  }, []);

  const filters = useMemo(() => {
    return [
      {
        key: "bot",
        visible: false,
      },
      {
        key: "key",
        label: "Name",
      },
    ];
  }, []);

  const parentName = [
    {
      label: "Dictionaries",
      url: "/chatbot/dictionaries",
    },
  ];
  return (
    <PRContainer
      smalltalkSelector
      actions={actions}
      description={"Here you can manage your dictionaries to use in your chatbot."}
      name="Chatbot"
      parentName={parentName}
    >
      <PRTable
        ref={tableRef}
        columns={columns}
        filters={filters}
        storageKey={tableFilterStorageKey.dictionaryList}
        url={apiUrlChatbot.getDictionary.format(currentProject.id, currentBot.id)}
      />
    </PRContainer>
  );
}
