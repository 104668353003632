import ConstantHelper from "~helpers/ConstantHelper";

export const projectBotStatus = {
  initializing: "initializing",
  ready: "ready",
  train: "train",
  trainRequired: "train_required",
  golive: "golive",
  goliveRequired: "golive_required",
  live: "live",
};

export const projectBotStatusMap = {
  [projectBotStatus.initializing]: "Initializing",
  [projectBotStatus.ready]: "Ready",
  [projectBotStatus.train]: "Training",
  [projectBotStatus.trainRequired]: "Training Required",
  [projectBotStatus.golive]: "Go Live",
  [projectBotStatus.goliveRequired]: "Go Live Required",
  [projectBotStatus.live]: "Live",
};

export const projectLanguage = {
  TR: "Turkish",
  EN: "English",
  DE: "German",
  GR: "Greek",
  AR: "Arabic",
  UK: "Ukrainian",
  RU: "Russian",
  PE: "Persian",
  FR: "French",
  NL: "Dutch",
};

export const projectLanguageOptions = [
  { value: "TR", label: "Turkish" },
  { value: "EN", label: "English" },
  { value: "DE", label: "German" },
  { value: "GR", label: "Greek" },
  { value: "AR", label: "Arabic" },
  { value: "UK", label: "Ukrainian" },
  { value: "RU", label: "Russian" },
  { value: "PE", label: "Persian" },
  { value: "FR", label: "French" },
  { value: "NL", label: "Dutch" },
];

export const [emailTypes, emailTypesOptions, emailTypesMap] = ConstantHelper.typify({
  SMTP: "SMTP",
  SES: "SES",
});

export const [emailStatus, emailStatusOptions, emailStatusMap] = ConstantHelper.typify({
  NEW: "New",
  PEN: "Pending",
  VER: "Verified",
});

export const [projectTimeoutTimes, projectTimeoutTimesOptions, projectTimeoutTimesMap] = ConstantHelper.typify({
  /** @type {"web"} */
  web: "Web",
  /** @type {"mobile"} */
  whatsapp: "Whatsapp",
  /** @type {"telegram"} */
  teams: "Teams",
  /** @type {"telegram"} */
  telegram: "Telegram",
  /** @type {"facebook"} */
  discord: "Discord",
  /** @type {"slack"} */
  slack: "Slack",
  /** @type {"skype"} */
  ios: "IOS",
  /** @type {"android"} */
  android: "Android",
  /** @type {"web"} */
  other_mobile: "Other Mobile",
});

export const [projectTranslateKey, projectTranslateKeyOptions, projectTranslateKeyMap] = ConstantHelper.typify({
  DIRECT_TO_QUEUE_FOR_AGENT: "Direct to Queue for Agent",
  DIRECT_TO_QUEUE_FOR_BOT: "Direct to Queue for Bot",
  TERMINATE_SESSION: "Terminate Session",
  AGENT_CONNECT: "Agent Connect",
  AGENT_DISCONNECT: "Agent Disconnect",
  BOT_CONNECT: "Bot Connect",
  OFFLINE_MESSAGE: "Offline Message",
});
