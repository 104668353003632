import turkeyFlag from "../assets/images/flags/turkey.png";
import usFlag from "../assets/images/flags/uk.png";

const languages = {
  en: {
    label: "English",
    flag: usFlag,
  },
};
if (process.env.NODE_ENV === "development") {
  languages["tr"] = {
    label: "Turkish",
    flag: turkeyFlag,
  };
}

export default languages;
