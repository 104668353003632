import { createRef, useMemo } from "react";

import { MdAdd, MdDelete, MdEdit } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";

import PRButton from "~components/Generic/PRButton";
import PRContainer from "~components/Generic/PRContainer";
import PRTable from "~components/Generic/PRTable";
import {
  apiUrlOrganization,
  organizationActionTypesMap,
  organizationActionTypesOptions,
  tableFilterStorageKey,
} from "~constants";
import DialogHelper from "~helpers/DialogHelper";
import { filterGetReadableText } from "~pages/Organization/Filter/FilterUtils";
import { deleteAction } from "~store/organization/actions";
import { selectCurrentProject } from "~store/user/selectors";

import "./style.scss";

export default function ActionsList() {
  const dispatch = useDispatch();
  const currentProject = useSelector(selectCurrentProject);
  const tableRef = createRef();

  const columns = useMemo(() => {
    const handleDelete = (row) => async () => {
      if (!(await DialogHelper.showQuestionDelete())) return;
      await dispatch(deleteAction(row.id, currentProject.id));
      tableRef.current.refresh();
    };
    return [
      {
        label: "Name",
        key: "name",
      },
      {
        label: "Title",
        key: "title",
      },
      {
        label: "Active",
        key: "active",
        render: (row) => <span>{row.active ? "Yes" : "No"}</span>,
      },
      {
        label: "Periodic",
        key: "is_periodic",
        render: (row) => <span>{row.is_periodic ? "Yes" : "No"}</span>,
      },
      {
        label: "Action Type",
        key: "action_type",
        render: (row) => {
          return organizationActionTypesMap[row.action_type] || row.action_type;
        },
      },
      {
        label: "Filter Name",
        key: "filter_name",
        render: (row) => (
          // <PRTooltip title={filterGetReadableText(row.action_filter, true)}> {/* </PRTooltip> */}
          <span>{row.action_filter?.name}</span>
        ),
      },
      {
        label: "Filter Rule",
        key: "filter_Rule",
        render: (row) => <span>{filterGetReadableText(row.action_filter, true)}</span>,
      },
      {
        label: "Actions",
        key: "actions",
        actions: true,
        render: (row) => (
          <div className="d-flex justify-content-center">
            <PRButton
              outline
              color="primary"
              icon={MdEdit}
              link={`/organization/action-templates/form/${row.id}`}
              size="sm"
              tooltipText="Edit"
            />
            <PRButton
              outline
              className="ms-1"
              color="danger"
              icon={MdDelete}
              size="sm"
              tooltipText="Delete"
              onClick={handleDelete(row)}
            />
          </div>
        ),
      },
    ];
  }, [dispatch, currentProject, tableRef]);

  const actions = useMemo(() => {
    return [
      {
        label: "Create New",
        icon: MdAdd,
        color: "success",
        link: "/organization/action-templates/form",
      },
    ];
  }, []);

  const filters = useMemo(() => {
    return [
      {
        key: "action_type",
        label: "Action Type",
        options: organizationActionTypesOptions,
      },
      {
        key: "action_filter__name",
        label: "Filter Name",
      },
      {
        key: "is_periodic",
        label: "Periodic",
      },
    ];
  }, []);

  return (
    <PRContainer
      actions={actions}
      className="pr-data-format"
      description={"Here you can manage your actions to use in future"}
      name="Organization"
      parentName="Action Templates"
    >
      <PRTable
        ref={tableRef}
        columns={columns}
        filters={filters}
        storageKey={tableFilterStorageKey.actionList}
        url={apiUrlOrganization.getAction.format(currentProject.id)}
        urlQuery={{ is_template: true }}
      />
    </PRContainer>
  );
}
