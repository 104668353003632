const { default: ConstantHelper } = require("~helpers/ConstantHelper");

// export const [settingsMessageTemplateType, settingsMessageTemplateTypeOptions, settingsMessageTemplateTypeMap] =
//   ConstantHelper.typify({
//     SURVEY_ANNOUNCEMENT: "Survey Announcement",
//     SURVEY_REANNOUNCEMENT: "Survey Re-Announcement",
//     TICKET_CREATE_CUSTOMER: "Ticket Create Customer",
//     TICKET_SHARE: "Ticket Share",
//     TICKET_CREATE_AGENT: "Ticket Create Agent",
//     TICKET_RESOLVED: "Ticket Resolved",
//     TICKET_ASSIGNED_USER_CHANGED_CUSTOMER: "Ticket Assigned User Changed Customer",
//     TICKET_ASSIGNED_USER_CHANGED_AGENT: "Ticket Assigned User Changed Agent",
//     TICKET_CATEGORY_CHANGED_CUSTOMER: "Ticket Category Changed Customer",
//     TICKET_UPDATED: "Ticket Updated",
//     TICKET_COMMENT_AGENTS: "Ticket Comment Agents",
//     TICKET_COMMENT_CUSTOMER: "Ticket Comment Customer",
//   });

// export const [
//   settingsReservationMessageTemplateType,
//   settingsReservationMessageTemplateTypeOptions,
//   settingsReservationMessageTemplateTypeMap,
// ] = ConstantHelper.typify({
//   RESERVATION_CREATE: "Reservation Create",
//   RESERVATION_DELETE: "Reservation Delete",
//   RESERVATION_UPDATE: "Reservation Update",
// });

export const [settingsLanguage, settingsLanguageOptions, settingsLanguageMap] = ConstantHelper.typify({
  EN: "English",
  TR: "Turkish",
  DE: "German",
  GR: "Greek",
  AR: "Arabic",
  UA: "Ukrainian",
  RU: "Russian",
  PE: "Persian",
  FR: "French",
});

export const [settingsTemplateFormat, settingsTemplateFormatOptions, settingsTemplateFormatMap] = ConstantHelper.typify(
  {
    HTML: "HTML",
    PLAIN: "Plain",
    MARKDOWN: "Markdown",
  }
);

export const [settingsTemplatePlatformType, settingsTemplatePlatformTypeOptions, settingsTemplatePlatformTypeMap] =
  ConstantHelper.typify({
    EMAIL: "Email",
    SMS: "SMS",
    PUSH_NOTIFICATION: "Push Notification",
  });

export const [guideType, guideTypeOptions, guideTypeMap] = ConstantHelper.typify({
  Guide: "Guide",
  Tooltip: "Tooltip",
});
