import { Fragment, createContext, memo, useCallback, useContext, useEffect, useMemo, useState } from "react";

import classNames from "classnames";
import { cloneDeep } from "lodash";
import { MdCheck } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { Badge, Col, Label, Row } from "reactstrap";

import { RenderBreadcrumbActions } from "~components/Breadcrumb";
import CodeEditor from "~components/CodeEditor";
import { PRAccordionItem } from "~components/Generic/PRAccordion";
import PRAccordion from "~components/Generic/PRAccordion/PRAccordionBody";
import PRButton from "~components/Generic/PRButton";
import PRDivider, { PRDividerLabel } from "~components/Generic/PRDivider";
import PRInput from "~components/Generic/PRInput";
import PRSelect from "~components/Generic/PRSelect";
import PRTab from "~components/Generic/PRTab";
import PRTable from "~components/Generic/PRTable";
import { apiUrlOrganization, organizationAnnouncementFilterType, organizationFilterType } from "~constants";
import Utils from "~helpers/Utils";
import useMonacoScenarioEditorLowCode from "~pages/ChatBot/DialogComponents/ScenarioManager/ScenarioManagerEdit/useMonacoScenarioEditorLowCode";
import useMonacoScenarioEditorVariables from "~pages/ChatBot/DialogComponents/ScenarioManager/ScenarioManagerEdit/useMonacoScenarioEditorVariables";
import { CustomerListCellRenderer } from "~pages/Organization/Customer/CustomerList";
import { getFilterList, getMemberFieldFormatList } from "~store/organization/actions";
import { selectFilters, selectMemberFieldFormat } from "~store/organization/selectors";
import { selectCurrentProject } from "~store/user/selectors";

import FilterItem from "./FilterItem";

import "./style.scss";

const hoverContext = createContext();
export const useHoverContext = () => {
  return useContext(hoverContext);
};

const AccordionWrapper = memo(function ActionWrapper({ show, name, children }) {
  if (!show) return children;
  return (
    <PRAccordion noHeaderBackground noSpacing separated>
      <PRAccordionItem defaultCollapsed={false} title="Filter">
        {children}
      </PRAccordionItem>
    </PRAccordion>
  );
});

export default function FilterRule({
  showAccordion,
  onClickSaveTemplate,
  onChange,
  filter,
  lowCodeData,
  onChangeLowCode,
}) {
  const [isFilterButtonHover, setIsFilterButtonHover] = useState(false);
  const [codeEditor, setCodeEditor] = useState(null);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [filterActions, setFilterActions] = useState(null);

  const filterList = useSelector(selectFilters);
  const dispatch = useDispatch();

  const currentProject = useSelector(selectCurrentProject);
  const memberFieldFormat = useSelector(selectMemberFieldFormat);

  const memberDataFields = memberFieldFormat?.member_data_fields;

  const handleFilterSetActions = useCallback((actions) => {
    setFilterActions(actions);
  }, []);

  useEffect(() => {
    dispatch(getMemberFieldFormatList(currentProject.id));
    dispatch(getFilterList(currentProject.id)).then(({ results = [] }) => {
      if (results.length > 0) {
        setSelectedTemplate(results[0]);
      }
    });
  }, [dispatch, currentProject.id]);

  const columns = useMemo(() => {
    if (!memberDataFields) return [];
    const fields = memberDataFields.map((item) => {
      const label = (
        <span
          className={classNames({
            "fw-semibold": item.identifier,
          })}
        >
          {item.display_name || item.name}
        </span>
      );

      return {
        label: label,
        key: `safe_information.${item.name}`,
        order: item.order,
        render: (obj, index, value) => (
          <CustomerListCellRenderer cellKey={item.name} memberDataFields={memberDataFields} value={value} />
        ),
      };
    });
    fields.unshift({
      label: "#",
      key: "id",
      order: -1,
    });
    return fields;
  }, [memberDataFields]);

  useEffect(() => {
    dispatch(getMemberFieldFormatList(currentProject.id));
  }, [dispatch, currentProject.id]);

  const handleChangeFilter = (value) => {
    onChange?.(value);
  };
  const handleChangeName = (e) => {
    const value = e.target.value;
    onChange?.({ ...filter, name: value });
  };

  const handleChangeLowCode = (value) => {
    onChangeLowCode?.({
      ...lowCodeData,
      name: "Filter LowCode - " + filter?.name,
      implementation: value,
    });
  };

  const contextValue = useMemo(() => {
    const setHover = (isHover) => {
      setIsFilterButtonHover(isHover);
    };
    return [isFilterButtonHover, setHover];
  }, [isFilterButtonHover]);

  const handleRowClick = (id, checked, row) => {
    let newSelected = [...(filter?.member_id_list || [])];
    if (checked) {
      newSelected.push(id);
    } else {
      newSelected = newSelected.filter((item) => item !== id);
    }
    onChange?.({ ...filter, member_id_list: newSelected });
  };

  const handleSelectAll = (idList, rowList) => {
    onChange?.({ ...filter, member_id_list: idList });
  };
  const handleSelectRange = (idList, checked, rowList) => {
    const newSelected = [...(filter?.member_id_list || [])];
    for (const id of idList) {
      if (checked) {
        if (!newSelected.includes(id)) {
          newSelected.push(id);
        }
      } else {
        newSelected.splice(newSelected.indexOf(id), 1);
      }
    }

    onChange?.({ ...filter, member_id_list: newSelected });
  };

  useMonacoScenarioEditorVariables(codeEditor?.editor);
  useMonacoScenarioEditorLowCode(codeEditor?.editor);

  const activeTabId = useMemo(() => {
    if (
      [organizationFilterType.AND, organizationFilterType.OR, organizationFilterType.COMPARISON].includes(
        filter?.filter_type
      )
    ) {
      return "filter";
    } else if (filter?.filter_type === organizationAnnouncementFilterType.ID_LIST) {
      return "id_select";
    } else if (filter?.filter_type === organizationAnnouncementFilterType.LOW_CODE) {
      return "lowcode";
    }
    return "filter";
  }, [filter?.filter_type]);

  const handleChangeTab = (id) => {
    if (id === "filter") {
      onChange?.({ ...filter, filter_type: organizationFilterType.COMPARISON });
    } else if (id === "id_select") {
      onChange?.({ ...filter, filter_type: organizationAnnouncementFilterType.ID_LIST });
    } else if (id === "lowcode") {
      onChange?.({ ...filter, filter_type: organizationAnnouncementFilterType.LOW_CODE });
    }
  };

  const handleChangeTemplate = (value) => {
    let template = Utils.getWithNonAllowedKeys(cloneDeep(value), ["id"], ["member_data_field"]);

    if (!value) {
      template = {
        filter_type: "COMPARISON",
        comparison_type: "EQ",
        member_data_field: {
          id: undefined,
        },
        value: "",
      };
    }

    template.is_template = false;
    setSelectedTemplate(template);
  };
  const handleApplyTemplate = () => {
    onChange?.(selectedTemplate);
  };

  return (
    <>
      <Row className="mb-2 g-2 justify-content-end">
        <Col lg className="d-flex align-items-center " md="auto">
          <Label className=" w-100 text-lg-end" size="md">
            Apply Template:
          </Label>
        </Col>
        <Col md className="d-flex align-items-center" lg="4">
          <PRSelect
            fullWidth
            labelSelector="name"
            options={filterList}
            value={selectedTemplate}
            valueSelector="id"
            onChange={handleChangeTemplate}
          />
          <PRButton
            className="ms-2"
            color="primary"
            disabled={!selectedTemplate}
            icon={MdCheck}
            tooltipText="Apply Template"
            onClick={handleApplyTemplate}
          />
        </Col>
      </Row>
      <AccordionWrapper name={filter?.name} show={showAccordion}>
        {!onClickSaveTemplate && (
          <>
            <Row className="g-2 align-items-center mt-2">
              <Col lg="6" md="12">
                <Label>Template Name:</Label>
                <PRInput value={filter?.name} onChange={handleChangeName} />
              </Col>
            </Row>
            <PRDividerLabel className="my-4">Rule</PRDividerLabel>
          </>
        )}
        <PRTab
          tab={activeTabId}
          tabList={[
            { id: "filter", label: "Comparison" },
            { id: "id_select", label: "Selection" },
            { id: "lowcode", label: "LowCode" },
          ]}
          onChange={handleChangeTab}
        />
        <div className="py-2">
          {activeTabId === "filter" && (
            <>
              <div className="my-2">
                <Label className="font-size-14" size="md">
                  Filter
                </Label>
                <div> Create a filter based on the customer data fields you have defined.</div>
              </div>
              <hoverContext.Provider value={contextValue}>
                <FilterItem depth={1} filter={filter} onChange={handleChangeFilter} />
              </hoverContext.Provider>
            </>
          )}
          {activeTabId === "id_select" && (
            <>
              <div className="my-2 d-flex align-items-center justify-content-between">
                <div>
                  <Label className="font-size-14" size="md">
                    Customers
                  </Label>
                  <div> Select the Customers to Include in the Filter.</div>
                </div>
                {filterActions?.length && <RenderBreadcrumbActions actions={filterActions} />}
              </div>

              <PRTable
                inline
                noWrap
                columns={columns}
                customSetActions={handleFilterSetActions}
                memoizeAsQuery={false}
                selectedRows={filter?.member_id_list || []}
                url={apiUrlOrganization.getMember.format(currentProject.id)}
                onRowChecked={handleRowClick}
                onRowClick={handleRowClick}
                onSelectAll={handleSelectAll}
                onSelectRange={handleSelectRange}
              />
              <Label className="font-size-14 mt-2" size="md">
                Customer Selections
              </Label>
              <div className="my-2 d-flex align-items-center flex-wrap">
                <span className="me-2">Selected Filter Ids:</span>

                {filter?.member_id_list?.map((item) => (
                  <Fragment key={item}>
                    <Badge key={item} className="font-size-12 me-1 badge-soft-primary">
                      {item}
                    </Badge>{" "}
                  </Fragment>
                ))}
                {!filter?.member_id_list?.length && <span className="me-2">None</span>}
              </div>
            </>
          )}
          {activeTabId === "lowcode" && (
            <>
              <div className="my-2">
                <Label className="font-size-14" size="md">
                  LowCode
                </Label>
                <div>Create a filter by using LowCode.</div>
              </div>
              <CodeEditor
                defaultHeight={400}
                value={lowCodeData?.implementation || ""}
                onChange={handleChangeLowCode}
                onMount={setCodeEditor}
              />
            </>
          )}
        </div>

        {onClickSaveTemplate && (
          <>
            <PRDivider className={"mt-1 mb-2"} />
            <Row className="mb-2 g-2 justify-content-start">
              <Col className="gap-2 d-flex align-items-center" xs="auto">
                <PRButton className="" color="secondary" onClick={onClickSaveTemplate}>
                  Create as template
                </PRButton>
              </Col>
            </Row>
          </>
        )}
      </AccordionWrapper>
    </>
  );
}
