import { useCallback, useEffect, useMemo, useState } from "react";

import { withCardon } from "cardon";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Label } from "reactstrap";

import PRInput from "~components/Generic/PRInput";
import PRModal from "~components/Generic/PRModal";
import PRSelect from "~components/Generic/PRSelect";
import AlertHelper from "~helpers/AlertHelper";
import { getAgentInfo, getOnlineAgents, wsTransferAgent } from "~store/socket/livechat/actions";
import { selectAgentInfo, selectOnlineAgents } from "~store/socket/livechat/selectors";

function RedirectAgentModalContent({ get, session }) {
  const dispatch = useDispatch();

  const onlineAgents = useSelector(selectOnlineAgents);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const [selectedAgent, setSelectedAgent] = useState(null);
  const [note, setNote] = useState("");
  const agentInfo = useSelector(selectAgentInfo);

  useEffect(() => {
    dispatch(getAgentInfo());
    dispatch(getOnlineAgents(session.project));
  }, [dispatch, session.project]);

  const handleChangeNote = useCallback((e) => {
    setNote(e.target.value);
  }, []);

  const handleCreate = async () => {
    setLoading(true);
    try {
      await dispatch(wsTransferAgent(session.id, selectedAgent, note));
      AlertHelper.show(t("network.success"), "success");
    } catch (e) {
      AlertHelper.show(t("network.serverError"), "error");
      console.error(e);
    }
    setLoading(false);
    get(true)();
  };

  const onlineAgentsWithoutMe = useMemo(() => {
    if (!Array.isArray(onlineAgents)) return [];
    return onlineAgents.filter((agent) => agent.id !== agentInfo?.id);
  }, [agentInfo?.id, onlineAgents]);
  return (
    <PRModal
      loading={loading}
      submitText="Submit"
      title="Redirect to Customer"
      onClick={handleCreate}
      onClose={get(false)}
    >
      <Label className="mt-2">Target Agent:</Label>
      <PRSelect
        isPrimitiveValue
        labelSelector="name"
        options={onlineAgentsWithoutMe}
        value={selectedAgent}
        valueSelector="id"
        onChange={setSelectedAgent}
      />
      <Label className="mt-2">Note:</Label>
      <PRInput value={note} onChange={handleChangeNote} />
    </PRModal>
  );
}
const RedirectAgentModalModal = withCardon(RedirectAgentModalContent, { destroyOnHide: true });
export default RedirectAgentModalModal;
