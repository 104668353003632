import ConstantHelper from "~helpers/ConstantHelper";

export const [moduleVersionStatusType, moduleVersionStatusTypeOptions, moduleVersionStatusTypeMap] =
  ConstantHelper.typify({
    0: "Draft",
    1: "Checking",
    2: "Ready",
    3: "Failed",
  });

export const [moduleVersionErrorPolicyType, moduleVersionErrorPolicyTypeOptions, moduleVersionErrorPolicyTypeMap] =
  ConstantHelper.typify({
    /** @type {"Report Error"} */
    0: "Report Error",
    /** @type {"Ignore"} */
    1: "Ignore",
    /** @type {"Stop"} */
    2: "Stop",
  });
