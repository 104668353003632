import { useEffect, useMemo } from "react";

import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Col, Label, Row } from "reactstrap";
import * as Yup from "yup";

import PRButton from "~components/Generic/PRButton";
import PRContainer from "~components/Generic/PRContainer";
import PRInput from "~components/Generic/PRInput";
import PRPage from "~components/Generic/PRPage";
import PRSelect from "~components/Generic/PRSelect";
import { livechatAgentStatusOptions, userRole } from "~constants";
import HistoryHelper from "~helpers/HistoryHelper";
import { getCallcenterAgent, setCallcenterAgent, updateCallcenterAgent } from "~store/agents/actions";
import { selectCallcenterAgent } from "~store/agents/selectors";
import { selectCurrentProject } from "~store/user/selectors";

export default function AgentEdit() {
  const { id } = useParams();
  // const [data, setData] = useState();
  const dispatch = useDispatch();
  const currentProject = useSelector(selectCurrentProject);
  const callcenterAgent = useSelector(selectCallcenterAgent);

  const isEditable = useMemo(() => {
    return currentProject.permissions?.some((item2) => [userRole.admin, userRole.AgentManager].includes(item2));
  }, [currentProject?.permissions]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      ...callcenterAgent,
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Required"),
      max_active_chat_count: Yup.number().required("Required").min(1, "Minimum 1").max(100, "Maximum 100"),
    }),
    onSubmit: async (values) => {
      await dispatch(updateCallcenterAgent(currentProject.id, { ...values }));
      HistoryHelper.goBack("/contactcenter/wallboard", { scope: "dashboard" });
    },
  });

  useEffect(() => {
    if (!id) {
      HistoryHelper.goBack("/contactcenter/wallboard", { scope: "dashboard" });
      return;
    }
    dispatch(getCallcenterAgent(currentProject.id, id));
    return () => {
      dispatch(setCallcenterAgent({}));
    };
  }, [dispatch, currentProject?.id, id]);

  const handleClickCancel = () => {
    HistoryHelper.goBack("/contactcenter/wallboard", { scope: "dashboard" });
  };

  const handleChangeSelect = (key) => (value) => {
    formik.setFieldValue(key, value);
  };
  const parentName = [
    {
      label: "Agents",
      url: "/contactcenter/wallboard",
    },
    {
      label: "Edit Agent",
    },
  ];
  return (
    <PRContainer
      className="pr-data-format"
      description={"Here you can create or edit ticket categories to enable users to handle tickets of this category."}
      name="Agent Edit"
      parentName={parentName}
    >
      <PRPage className="">
        <Row className="mt-2 g-2">
          <Col md="4">
            <Label className="mb-2">Name</Label>
          </Col>
          <Col md="8">
            <PRInput
              invalid={formik.touched.name && formik.errors.name}
              name="name"
              type="text"
              value={formik.values.name}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
            />
          </Col>
          <Col md="4">
            <Label className="mb-2">Status</Label>
          </Col>
          <Col className="d-flex align-items-center " md="8">
            <PRSelect
              fullWidth
              isPrimitiveValue
              invalid={formik.touched.status && formik.errors.status}
              isClearable={false}
              name="status"
              options={livechatAgentStatusOptions}
              type="checkbox"
              value={formik.values.status}
              onBlur={formik.handleBlur}
              onChange={handleChangeSelect("status")}
            />
          </Col>
          <Col md="4">
            <Label className="mb-2">Max. Active Chat Count</Label>
          </Col>
          <Col md="8">
            <PRInput
              disabled={!isEditable}
              invalid={formik.touched.max_active_chat_count && formik.errors.max_active_chat_count}
              name="max_active_chat_count"
              type="number"
              value={formik.values.max_active_chat_count}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
            />
          </Col>
        </Row>
        <Row className="justify-content-end mt-2">
          <Col md="auto">
            <PRButton outline className="" onClick={handleClickCancel}>
              Cancel
            </PRButton>
            <PRButton className="ms-2" onClick={formik.handleSubmit}>
              Save
            </PRButton>
          </Col>
        </Row>
      </PRPage>
    </PRContainer>
  );
}
