import { Fragment, useMemo } from "react";

import * as Sentry from "@sentry/react";
import { Col, Row } from "reactstrap";

import styled from "@emotion/styled";

import PRPhoneNumber from "~components/Generic/PRPhoneNumber";
import { chatbotInputRequestType } from "~constants";
import DateHelper from "~helpers/DateHelper";

import MessageFile from "../FileMessage";
import ImageMessage from "../ImageMessage";
import SimpleMessage from "../SimpleMessage";

const StyledDiv = styled.div`
  margin: 0px calc(65px - 20px);
  padding: 20px 25px 20px 20px;
  border-radius: 14px;

  border-radius: 14px;
  box-shadow: 2px 2px 0 0 rgba(0, 0, 0, 0.04);
  /* border: solid 1px #e8e8e8; */
  border: ${(props) => props.theme.palette.chat?.balloon?.customer?.border};
  background-color: ${(props) => props.theme.palette.chat?.body.backgroundColor};

  max-width: 480px;

  font: normal normal normal 16px/18px Museo Sans;
  .form-divider {
    height: 1px;
    margin: 10px 0 20px;
    background-color: #ddd;
  }
  .form-title {
    font-family: Museo Sans;
    font-size: 20px;
    font-weight: 600;
    line-height: 32px;
    text-align: center;
    color: ${(props) => props.theme.palette.chat.balloon.bot.color};
  }
  table {
    color: rgba(0, 0, 0, 0.7);
    td {
      vertical-align: top;
      padding: 10px 0px;
    }
    .text-title {
      margin-right: 5px;
    }
    .text-field {
      font-weight: 700;
      margin-left: 10px;
    }
  }
`;

const StyledContentDiv = styled.div`
  font: normal normal normal 16px/18px Museo Sans;
`;

const primitiveExpectedTypes = [
  chatbotInputRequestType.text,
  chatbotInputRequestType.only_numbers,
  chatbotInputRequestType.email,
  chatbotInputRequestType.date,
  chatbotInputRequestType.select,
  chatbotInputRequestType.custom,
  chatbotInputRequestType.phone_number,
];
function InputRequestContent({ inputFormat, isSingleInput }) {
  const Div = isSingleInput
    ? ({ children }) => <StyledContentDiv>{children}</StyledContentDiv>
    : ({ children, ...props }) => <div {...props}>{children}</div>;

  return (
    <>
      {[...(inputFormat.fields || [])].map((field, i, arr) => {
        const id = field?.format?.id;

        let valueMachineData = field.format?.data?.[id] || inputFormat?.data?.[id] || field.format.data;
        if ([chatbotInputRequestType.custom, chatbotInputRequestType.select].includes(field.format?.type)) {
          let arrayData = valueMachineData?.selected_items || valueMachineData;
          if (!Array.isArray(arrayData)) {
            arrayData = [arrayData];
          }
          valueMachineData = arrayData.map(
            (item) => (item?.human_data || (item?.machine_data != null && item?.machine_data)) ?? item
          );
          valueMachineData = valueMachineData.join(", ");
        } else if (field.format?.type === chatbotInputRequestType.file) {
          // valueMachineData = valueMachineData;
        } else {
          valueMachineData =
            (valueMachineData?.human_data ||
              (valueMachineData?.machine_data != null && valueMachineData?.machine_data)) ??
            valueMachineData;
        }
        const fileName = field.format.name || valueMachineData?.[0]?.file_name;

        const fileExt = field.format?.type === chatbotInputRequestType.file && fileName?.split(".")?.pop();
        const isImage =
          field.format?.type === chatbotInputRequestType.file &&
          ["jpg", "jpeg", "png", "gif", "svg", "webp"].includes(fileExt?.toLowerCase());
        const ImageOrFileComp = isImage && (valueMachineData || []).length <= 1 ? ImageMessage : MessageFile;

        const inputsComponent = (
          <Fragment key={id}>
            {[
              chatbotInputRequestType.text,
              chatbotInputRequestType.only_numbers,
              chatbotInputRequestType.email,
            ].includes(field.format?.type) && <Div className="text-field">{valueMachineData || "-"}</Div>}

            {(field.format?.type === chatbotInputRequestType.custom ||
              field.format?.type === chatbotInputRequestType.select) && (
              <Div className="text-field">{valueMachineData}</Div>
            )}

            {field.format?.type === chatbotInputRequestType.date && (
              <Div className="text-field">
                {(valueMachineData &&
                  DateHelper.getDateTimeLocal(valueMachineData).format(field.format?.format === "time" ? "LT" : "L")) ||
                  "-"}
              </Div>
            )}
            {field.format?.type === chatbotInputRequestType.date_range && (
              <Div className="text-field">
                {valueMachineData &&
                  DateHelper.getDateTimeLocal(valueMachineData?.[0]).format(
                    field.format?.format === "time" ? "LT" : "L"
                  )}
                {" - "}
                {valueMachineData &&
                  DateHelper.getDateTimeLocal(valueMachineData?.[1]).format(
                    field.format?.format === "time" ? "LT" : "L"
                  )}
              </Div>
            )}
            {field.format?.type === chatbotInputRequestType.range && (
              <Div className="text-field">{valueMachineData}</Div>
            )}
            {[chatbotInputRequestType.phone_number].includes(field.format?.type) && (
              <Div className="text-field">
                {valueMachineData ? <PRPhoneNumber render value={valueMachineData} /> : "-"}
              </Div>
            )}

            {field.format?.type === chatbotInputRequestType.file && (
              <div className="d-flex justify-content-center flex-column gap-2">
                {Array.isArray(valueMachineData || []) && (valueMachineData || [])?.length
                  ? (valueMachineData || []).map?.((file, index) => (
                      <ImageOrFileComp
                        key={`file-${index}`}
                        center
                        plainView
                        className="d-flex justify-content-center align-items-center flex-column"
                        fileName={file.file_name}
                        fileUrl={file?.file_url}
                        imageUrl={file?.file_url}
                      />
                    ))
                  : "-"}
              </div>
            )}
          </Fragment>
        );

        if (typeof valueMachineData === "object" && primitiveExpectedTypes.includes(field.format?.type)) {
          Sentry.captureException(
            new Error(
              `valueMachineData is object and must be string: ${JSON.stringify(valueMachineData)}@@@${JSON.stringify(
                field
              )}`
            )
          );
          return "null";
        }

        if (isSingleInput) return inputsComponent;
        return (
          <Row key={id} className="fw-light mb-4 g-1 align-items-center justify-content-center text-center" id={id}>
            <Col xs="12">
              <div className="text-title">{(field.question || "").replace(/:$/, "")}</div>
            </Col>
            <Col className="fw-bold" xs="12">
              {inputsComponent}
            </Col>
          </Row>
        );
      })}
    </>
  );
}

export function InputRequestSingleMessage({
  //
  position,
  messageTime,
  messageId,
  historyId,
  inputFormat,
  type,
  reverseChat,
  sender,
  isSent,
}) {
  return (
    <SimpleMessage
      historyId={historyId}
      isSent={isSent}
      messageId={messageId}
      messageTime={messageTime}
      position={position}
      reverseChat={reverseChat}
      sender={sender}
      type={type}
    >
      <InputRequestContent isSingleInput inputFormat={inputFormat} />
    </SimpleMessage>
  );
}

function InputRequestFormMessage({ inputFormat }) {
  return (
    <StyledDiv>
      {/* TODO: add title dynamic */}
      <div className="form-title">{"Form"}</div>
      <div className="form-divider"> </div>

      <InputRequestContent inputFormat={inputFormat} />

      {/* </SimpleMessage> */}
    </StyledDiv>
  );
}

export default function InputRequestMessage({
  // text,
  position,
  messageTime,
  // fileList,
  type,
  inputFormat,
  // loading,
  reverseChat,
  sender,
  isSent,
  //TODO: Consider to implement like status
  messageId,

  historyId,
  // likeStatus,
}) {
  let component = null;
  const { inputFormatItem, isSingleInput } = useMemo(() => {
    const isSingleInput = inputFormat?.type && inputFormat?.type !== "form";
    let item = inputFormat;

    if (isSingleInput) {
      item = {
        type: "form",
        id: "form_id",
        options: [],
        inline: false,
        description: "",
        data: inputFormat?.data,
        fields: [
          {
            // question: i18next.t("chatbot.getInformations.fillForm"),
            format: {
              ...inputFormat,
            },
          },
        ],
      };
    }

    //Filter captcha field
    if (item?.fields?.length) {
      item = {
        ...item,
        fields: item.fields.filter((field) => field.format?.type !== "captcha"),
      };
    }
    if (!item?.fields?.length) {
      item = null;
    }
    return { inputFormatItem: item, isSingleInput };
  }, [inputFormat]);

  if (!inputFormatItem?.data) return null;

  if (isSingleInput) {
    component = (
      <InputRequestSingleMessage
        historyId={historyId}
        inputFormat={inputFormatItem}
        isSent={isSent}
        messageId={messageId}
        messageTime={messageTime}
        position={position}
        reverseChat={reverseChat}
        sender={sender}
        type={type}
      />
    );
  } else {
    component = (
      <InputRequestFormMessage
        historyId={historyId}
        inputFormat={inputFormatItem}
        isSent={isSent}
        messageId={messageId}
        messageTime={messageTime}
        position={position}
        reverseChat={reverseChat}
        sender={sender}
        type={type}
      />
    );
  }

  return component;
}
