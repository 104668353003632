import { Fragment, useEffect, useState } from "react";

import { MdExpandLess, MdExpandMore, MdInfo, MdOutlineTextsms, MdSchedule } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { Col, Row } from "reactstrap";

import { Box, Collapse } from "@mui/material";

import { useDebouncedEffect } from "~common/hooks/useDebounceEffect";
import PRButton from "~components/Generic/PRButton";
import PRDivider from "~components/Generic/PRDivider";
import PRTooltip from "~components/Generic/PRTooltip";
import { convertSocketMsgToChatMessage, getStatusText } from "~components/Session/SessionUtils";
import DateHelper from "~helpers/DateHelper";
import { getPermissionUserList } from "~store/settings/permission/actions";
import { selectPermissionUserList } from "~store/settings/permission/selectors";
import { selectCurrentProject } from "~store/user/selectors";

export default function SessionStatisticMessage({ distributedRef, session, welcomeSettings }) {
  const dispatch = useDispatch();
  const currentProject = useSelector(selectCurrentProject);
  const permissionUserList = useSelector(selectPermissionUserList);
  const [open, setOpen] = useState(false);

  const [stateChanging, setStateChanging] = useState(false);

  useEffect(() => {
    dispatch(getPermissionUserList(currentProject.id));
  }, [dispatch, currentProject.id]);

  useDebouncedEffect(
    () => {
      if (stateChanging) {
        distributedRef.current.disableScrollOnMessage = false;
        setStateChanging(false);
      }
    },
    [open, stateChanging],
    400
  );
  //   const selectedSessionMessages = [...session.messages];

  const createdDate = DateHelper.getDateTimeLocal(session.queue_join_time);
  const lastMessageDate = session.messages?.[session.messages.length - 1]?.message_time;
  const updatedDate = DateHelper.getDateTimeLocal(lastMessageDate || session.updated);
  // const elapsed = updatedDate.diff(createdDate, "minutes");
  // const formattedTime = DateHelper.formatMoment(elapsed);
  /*
      TODO: Sometimes payload is null due empty history payload
      {
        "history_id": 115183,
        "history_type": "MESSAGE",
        "created": "2023-09-22T06:14:33.950989+0000"
      }

     */
  //   const messageCustomer = session.messages.filter((m) => ["CUSTOMER", "CUS"].includes(m.payload?.sender_type));
  //   const messageBot = session.messages.filter((m) => ["BOT"].includes(m.payload?.sender_type));
  //   const messageAgent = session.messages.filter((m) => ["AGT", "AGENT"].includes(m.payload?.sender_type));
  // const messageCountTotal = messageCustomer.length + messageBot.length + messageAgent.length || 0;

  let maximumDelayAfterCustomerMessage = 0;
  let tempCustomerMessage = null;
  let delayedCustomerMessage = null;

  for (const m of session.messages) {
    if (tempCustomerMessage && ["AGT", "AGENT"].includes(m.payload?.sender_type)) {
      const customerMessageTime = DateHelper.getDateTimeLocal(tempCustomerMessage.message_time);
      const agentMessageTime = DateHelper.getDateTimeLocal(m.payload?.message_time);
      const delay = agentMessageTime.diff(customerMessageTime, "milliseconds");
      if (delay > maximumDelayAfterCustomerMessage) {
        maximumDelayAfterCustomerMessage = delay;
        delayedCustomerMessage = tempCustomerMessage;
      }
    }

    if (["CUSTOMER", "CUS"].includes(m.sender_type)) {
      tempCustomerMessage = m;
    } else {
      tempCustomerMessage = null;
    }
  }

  if (delayedCustomerMessage) {
    delayedCustomerMessage = convertSocketMsgToChatMessage(delayedCustomerMessage);
  }

  if (maximumDelayAfterCustomerMessage > 0) {
    maximumDelayAfterCustomerMessage = DateHelper.formatMoment(maximumDelayAfterCustomerMessage, "ms");
  }
  //   const lastMessageTime = session.messages?.[session.messages.length - 1]?.message_time;

  const agentDataArr = Object.entries(session?.summary_data?.agent_data || {}).map(([key, value]) => ({
    ...value,
    id: Number(key),
  }));

  const handleClickOpenToggle = () => {
    setOpen((prev) => !prev);
    distributedRef.current.disableScrollOnMessage = true;
    setStateChanging(true);
  };

  return (
    <div className="text-center" style={{ width: 400 }}>
      {/* <PRDivider PRDivider color="secondary" /> */}
      {/* <h4 className="text-center mt-3"> Session Statistics </h4> */}
      <div className="d-flex justify-content-center">
        <PRButton noBorder outline onClick={handleClickOpenToggle}>
          <span className="d-flex align-items-center">
            Session Statistics {open ? <MdExpandMore className="fs-5" /> : <MdExpandLess className="fs-5" />}
          </span>
        </PRButton>
      </div>
      <PRDivider />
      <Collapse in={open}>
        <h6 className="text-center text-muted mb-3 mt-0">({welcomeSettings?.popup_title})</h6>
        <Row className="g-2 mb-3">
          <Col lg="12" md="12">
            <div> Connected </div>
            <b>{createdDate.format("LL")}</b>
          </Col>
          <Col className="d-flex flex-column justify-content-between align-items-center mx-auto" lg="12" md="12">
            <div> Status </div>
            <div className=" ">
              <b>{getStatusText(session.session_status, "font-size-12")}</b>
            </div>
          </Col>
          <Col className="d-flex flex-column justify-content-between align-items-center mx-auto" lg="12" md="12">
            <div> Previous Status </div>
            <div className=" ">
              <b>{getStatusText(session.last_status, "font-size-12")}</b>
            </div>
          </Col>
        </Row>
        <Row className="g-2">
          <Col lg="6" md="12">
            <div> Session Start Time </div>
            <b>{createdDate.local().format("LTS")}</b>
          </Col>
          <Col lg="6" md="12">
            <div> Session End Time</div>
            <b>{updatedDate.local().format("LTS")}</b>
          </Col>
          <Col lg="6" md="12">
            <div>Total Messages</div>
            <b>
              {session?.summary_data?.total_messages}
              <MdOutlineTextsms className="ms-1" />
            </b>
          </Col>
          <Col lg="6" md="12">
            <div>Customer Messages</div>
            <b>
              {session?.summary_data?.customer_messages}
              <MdOutlineTextsms className="ms-1" />
            </b>
          </Col>
          <PRDivider color="secondary" />
          <Col
            className="fs-5 fw-bold d-flex flex-column justify-content-between align-items-center mx-auto"
            lg="12"
            md="12"
          >
            <div> Bot Info </div>
          </Col>
          <Col lg="6" md="12">
            <div> Queue Time </div>
            <b>{DateHelper.formatMoment(session?.summary_data?.bot_queue_time, "second")} </b>
          </Col>
          <Col lg="6" md="12">
            <div>Messages</div>
            <b>
              {session?.summary_data?.bot_messages}
              <MdOutlineTextsms className="ms-1" />
            </b>
          </Col>

          <Col lg="6" md="12">
            <div> Chat Time </div>
            <b>{DateHelper.formatMoment(session?.summary_data?.bot_chat_time, "second")} </b>
          </Col>
          <Col lg="6" md="12">
            <div>Avg. Response Time</div>
            <b>{DateHelper.formatMoment(session?.summary_data?.chatbot_response_times?.mean, "second")} </b>
          </Col>
          <Col lg="6" md="12">
            <div className="lh-1">
              Max. Response Time
              <PRTooltip
                title={"The amount of time the agent responded with the most delay after the customer asked a question"}
              >
                <span className="ps-1">
                  <MdInfo className="fs-5" />
                </span>
              </PRTooltip>
            </div>

            <b>
              {DateHelper.formatMoment(session?.summary_data?.chatbot_response_times?.max, "second")}
              <MdSchedule className="ms-1" />
            </b>
          </Col>
          <PRDivider color="secondary" />
          <Col
            className="fs-5 fw-bold d-flex flex-column justify-content-between align-items-center mx-auto"
            lg="12"
            md="12"
          >
            <div> Agent Info </div>
          </Col>
          <Col lg="6" md="12">
            <div>Queue Time </div>
            <b>{DateHelper.formatMoment(session?.summary_data?.callcenter_queue_time, "second")} </b>
          </Col>
          <Col lg="6" md="12">
            <div>Messages</div>
            <b>
              {session?.summary_data?.agent_messages}
              <MdOutlineTextsms className="ms-1" />
            </b>
          </Col>
          {agentDataArr?.map((agentData, index, arr) => {
            const permissionUser = permissionUserList.find((item) => item.id === agentData.id);
            return (
              <Fragment key={agentData.id}>
                <Col className="fs-5 fw-bold pt-2" lg="12" md="12">
                  <PRTooltip title={permissionUser?.email}>
                    <div>{`${permissionUser?.firstname} ${permissionUser?.lastname} (${permissionUser?.username})`}</div>
                  </PRTooltip>
                </Col>
                <Col lg="6" md="12">
                  <div>Chat Time</div>
                  <b>
                    <b>
                      {DateHelper.formatMoment(
                        session?.summary_data?.agent_data?.[agentData.id]?.agent_chat_time,
                        "second"
                      )}
                      <MdOutlineTextsms className="ms-1" />
                    </b>
                  </b>
                </Col>
                <Col lg="6" md="12">
                  <div>Avg. Response Time</div>
                  <b>
                    <b>
                      {DateHelper.formatMoment(
                        session?.summary_data?.agent_data?.[agentData.id]?.agent_response_times?.mean,
                        "second"
                      )}
                      <MdOutlineTextsms className="ms-1" />
                    </b>
                  </b>
                </Col>
                <Col lg="6" md="12">
                  <div className="lh-1">
                    Max. Response Time
                    <PRTooltip
                      title={
                        "The amount of time the agent responded with the most delay after the customer asked a question"
                      }
                    >
                      <span className="ps-1">
                        <MdInfo className="fs-5" />
                      </span>
                    </PRTooltip>
                  </div>

                  <b>
                    {DateHelper.formatMoment(
                      session?.summary_data?.agent_data?.[agentData.id]?.agent_response_times?.max,
                      "second"
                    )}
                    <MdSchedule className="ms-1" />
                  </b>
                </Col>
                {index !== arr.length - 1 && <PRDivider color="secondary" />}
              </Fragment>
            );
          })}
        </Row>
      </Collapse>
      <Box height={10} />
    </div>
  );
}
