import { Fragment, useEffect, useState } from "react";

import { MdChevronRight, MdDelete } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Col, Label, Row } from "reactstrap";

import { getIntentDetails } from "~apiRequests/intent";
import PRButton from "~components/Generic/PRButton";
import PRDivider from "~components/Generic/PRDivider";
import PRLink from "~components/Generic/PRLink";
import PRSelect from "~components/Generic/PRSelect";
import { getIntentList, setScenarioFormState } from "~store/dialogComponents/scenarioManager/actions";
import { selectScenarioManagerResponseSuggestedIntents } from "~store/dialogComponents/scenarioManager/selectors";
import { selectCurrentBot, selectCurrentProject } from "~store/user/selectors";

export default function SuggestedIntents() {
  const { id } = useParams();
  const suggestedIntentIdList = useSelector(selectScenarioManagerResponseSuggestedIntents);
  const dispatch = useDispatch();
  const currentProject = useSelector(selectCurrentProject);
  const currentBot = useSelector(selectCurrentBot);
  const [suggestedIntentList, setSuggestedIntentList] = useState([]);

  useEffect(() => {
    dispatch(setScenarioFormState("response.bot"), currentBot.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    dispatch(setScenarioFormState("response.project"), currentProject.id);
  }, [dispatch, currentProject.id]);

  useEffect(() => {
    if (!suggestedIntentIdList?.length) return;
    const fetchInitialIntentList = async () => {
      const intentPromiseList = [];
      for (const intentId of suggestedIntentIdList) {
        if (!suggestedIntentList.find((item) => item?.id === intentId)) {
          intentPromiseList.push(getIntentDetails(currentProject.id, currentBot.id, intentId));
        }
      }
      if (intentPromiseList.length) {
        const intentList = await Promise.all(intentPromiseList);
        setSuggestedIntentList(intentList);
      }
    };
    fetchInitialIntentList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [suggestedIntentList, suggestedIntentIdList]);

  const handleClickDeleteSuggestedIntent = (intent) => () => {
    dispatch(
      setScenarioFormState("response.suggested_intents", [
        ...suggestedIntentIdList.filter((intentId) => intentId !== intent?.id),
      ])
    );
    setSuggestedIntentList((prevSuggestedIntentList) =>
      prevSuggestedIntentList.filter((intentItem) => intentItem.id !== intent?.id)
    );
  };

  const handleChangeSearchIntent = (intent) => {
    if (!suggestedIntentIdList.includes(intent.id)) {
      dispatch(setScenarioFormState("response.suggested_intents", [...suggestedIntentIdList, intent.id]));
      setSuggestedIntentList([...suggestedIntentList, intent]);
    }
  };

  const handleSearch = async (searchText, callback, signal) => {
    const response = await dispatch(
      getIntentList(currentProject.id, currentBot.id, {
        params: {
          text__icontains: searchText,
          limit: 20,
        },
        signal,
      })
    );

    const responseExceptThis = (response?.results || [])?.filter((item) => !id || item.id !== Number(id));
    return responseExceptThis;
  };
  const handleSelectDisabled = (option) => {
    return suggestedIntentIdList.includes(option?.id);
  };
  return (
    <div className="">
      <Row className="d-flex justify-content-between align-items-center">
        <Col xs>
          <Label className="m-0">Suggested Intents</Label>
        </Col>
        <Col xs="auto">
          <PRSelect
            lazy
            menuPortal
            className="w-xxxl"
            id="entityType"
            isClearable={false}
            isOptionDisabled={handleSelectDisabled}
            labelRenderer={(option) =>
              `${option.text}${option.display_text?.length ? ` (${option.display_text})` : ""}`
            }
            labelSelector="text"
            loadOptions={handleSearch}
            name="entityType"
            placeholder="Search intent..."
            value={null}
            valueSelector="id"
            onChange={handleChangeSearchIntent}
          />
        </Col>
      </Row>
      <Row className="pt-2">
        {!suggestedIntentList?.length && (
          <div>
            <p className="text-muted text-center"> - No suggested intents - </p>
          </div>
        )}
        {suggestedIntentList.map((intent, index) => {
          return (
            <Fragment key={`${intent.id || index}`}>
              <Col xs={12}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div>
                    <MdChevronRight className=" mx-2" />
                    <Label className="mb-0">
                      <PRLink newTab to={`/chatbot/intent/form/${intent?.id}`}>
                        {intent.text}
                      </PRLink>
                    </Label>
                  </div>
                  <PRButton outline color="danger" icon={MdDelete} onClick={handleClickDeleteSuggestedIntent(intent)} />
                </div>
              </Col>
              {suggestedIntentList.length - 1 !== index && (
                <Col xs={12}>
                  <PRDivider color={"secondary-400"} />
                </Col>
              )}
            </Fragment>
          );
        })}
      </Row>
    </div>
  );
}
