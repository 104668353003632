import * as Sentry from "@sentry/react";
import axios from "axios";
import i18next from "i18next";
import _ from "lodash";
import moment from "moment-timezone";
import qs from "qs";

import { apiUrlToken } from "~constants";
import store from "~store";

import AlertHelper from "./AlertHelper";
import DateHelper from "./DateHelper";
import HistoryHelper from "./HistoryHelper";
import IFrameHelper from "./IFrameHelper";
import LoadingHelper from "./LoadingHelper";
import StorageHelper from "./StorageHelper";
import TokenHelper from "./TokenHelper";
import { LS_TOKEN, LS_TOKEN_REFRESH } from "../constants/localStorage";

const axiosApi = axios.create({
  baseURL: window.pr_config.baseUrl + window.pr_config.apiPath,
});
const axiosWebChatApi = axios.create({
  baseURL: window.pr_config.baseUrl + window.pr_config.apiPath,
});
axiosApi.defaults.paramsSerializer = (params) => {
  const cloneParam = _.cloneDeep(params);
  const traverse = (obj) => {
    for (let key in obj) {
      if (obj[key] instanceof moment) {
        obj[key] = DateHelper.format(obj[key]);
      } else if (typeof obj[key] === "object") {
        traverse(obj[key]);
      } else if (Array.isArray(obj[key])) {
        obj[key].forEach((item) => {
          if (typeof item === "object") {
            traverse(item);
          }
        });
      }
    }
  };
  /** Handle moment objects and convert to appropriate format for API */
  traverse(cloneParam);
  const stringified = qs.stringify(cloneParam, { arrayFormat: "repeat" });
  return stringified;
};

class PromisePool {
  constructor(onAppend, onFinish, onFinishAll) {
    this.onAppend = onAppend;
    this.onFinish = onFinish;
    this.onFinishAll = onFinishAll;
    this.promises = [];
    this.finishedPromises = 0;
  }

  add(promise) {
    this.promises.push(promise);
    this.onAppend();

    promise
      .then((result) => {
        this.onFinish?.(result);
        this.finishedPromises++;
        if (this.finishedPromises === this.promises.length) {
          this.onFinishAll();
        }
      })
      .catch((error) => {
        this.onFinish?.(error);
        this.finishedPromises++;
        if (this.finishedPromises === this.promises.length) {
          this.onFinishAll();
        }
      });
  }
}

let loadingCloseTimeout;
const openLoading = () => {
  LoadingHelper.open();
  clearTimeout(loadingCloseTimeout);
};
const closeLoading = () => {
  clearTimeout(loadingCloseTimeout);
  loadingCloseTimeout = setTimeout(() => {
    LoadingHelper.close();
  }, 100);
};

const networkPromisePool = new PromisePool(openLoading, null, closeLoading);

export default class Network {
  constructor() {
    this.axios = IFrameHelper.isWebChatMode() ? axiosWebChatApi : axiosApi;
  }

  static _refreshingPromise = null;
  /**
   * @param {string} url
   * @param urlProp
   * @param {import("axios").AxiosRequestConfig & {
   *   action?: string;
   *   loading?: boolean;
   *   onSuccess?: (response: object) => import("redux").AnyAction;
   *   onFail?: (error: import("axios").AxiosError) => import("redux").AnyAction;
   *   onFinally?: (response: object) => import("redux").AnyAction;
   *   successMsg?: boolean | string;
   *   rawResponse?: boolean;
   * }} options
   * @returns {Promise<object>}
   */
  static async request(urlProp, options = {}) {
    const {
      method,
      action,
      loading,
      headers = {},
      onSuccess,
      onFail,
      onFinally,
      successMsg,
      noBearer,
      noAlert,
      noRedirect,
      rawResponse,
      sameOrigin = true,
      noThrow,
      refreshMode,
      bearerToken,
      ...restOptions
    } = options;
    let error;
    let response;

    let responseItem;
    let url = urlProp;
    const isInAdminPanel = window.location.pathname.startsWith("/dashboard");
    try {
      if (this?._refreshingPromise && !refreshMode) {
        //Block the all requests until the refresh token operation is completed
        await this._refreshingPromise;
      }
      const token = bearerToken || (await TokenHelper.getJwtIfValid());
      const isBearerEnabled = !noBearer && token;
      const headerParams = {
        ...headers,
      };

      if (isBearerEnabled) {
        headerParams.Authorization = `Bearer ${token}`;
      } else {
        delete headerParams.Authorization;
      }

      const urlWithoutProtocol = url?.replace(/(^\w+:|^)\/\//, "");
      if (urlWithoutProtocol?.includes("//")) {
        console.warn(
          "Network: url contains double slashes. Fixed automatically but should be fixed in the source.",
          url
        );
        Sentry.captureMessage(
          "Network: url contains double slashes. Fixed automatically but should be fixed in the source." + url,
          "warning"
        );

        const urlProtocol = url.replace(urlWithoutProtocol, "");
        url = urlProtocol + urlWithoutProtocol.replace(/\/\//g, "/");
      }

      if (process.env.NODE_ENV === "development") {
        if ((url.startsWith("http://") || url.startsWith("https://")) && url.includes("palmate")) {
          const parsedUrl = new URL(url);
          if (parsedUrl.hostname !== window.location.hostname) {
            console.warn("Network: url is not from the same origin. ", parsedUrl);
            if (sameOrigin) {
              url = window.location.origin + parsedUrl.pathname + parsedUrl.search;
            }
          }
        }
      }
      const responsePromise = axiosApi.request(url, {
        ...restOptions,
        method: method || "GET",
        headers: headerParams,
      });
      if (loading) {
        networkPromisePool.add(responsePromise);
      }
      response = await responsePromise;
      responseItem = rawResponse ? response : response.data;
      if (action) {
        store.dispatch({ type: action, payload: responseItem });
      }
      if (onSuccess) {
        let action = onSuccess(responseItem, response);
        if (action?.type) {
          store.dispatch(action);
        }
      }
      if (successMsg && !noAlert) {
        AlertHelper.show(typeof successMsg === "boolean" ? i18next.t("network.success") : successMsg);
      }
    } catch (e) {
      response = null;
      responseItem = rawResponse ? e.response : e.response?.data;
      error = e;

      if (process.env.NODE_ENV === "development") {
        if (e?.message !== "canceled") {
          console.log("%cNetwork: ", "color: gray;", e);
        }
      }
      if (e.response?.status === 401) {
        console.log("Session terminated due to unauthorized access", e);

        const repeatSelfRequest = async () => {
          return await Network.request(urlProp, options);
        };
        if (this._refreshingPromise && !refreshMode) {
          // Block the all requests until the refresh token operation is completed. This occurs when multiple requests are made at the same time.
          // Other requests will wait until the first request's refresh job is completed and rest of the requests will be re-executed if still there is a token.(This mean that the token is refreshed successfully)
          await this._refreshingPromise;
          const token = await StorageHelper.get(LS_TOKEN);
          if (token) {
            return await repeatSelfRequest();
          }
        } else {
          const refreshToken = await StorageHelper.get(LS_TOKEN_REFRESH);
          if (refreshToken && !refreshMode && isInAdminPanel) {
            let resolveRefresh;
            this._refreshingPromise = new Promise((resolve) => {
              resolveRefresh = resolve;
            });
            try {
              const response = await Network.request(apiUrlToken.getRefresh, {
                method: "POST",
                data: { refresh: refreshToken },
                noAlert: true,
                noThrow: true,
                refreshMode: true,
                rawResponse: true,
              });
              if (response.data?.access) {
                await StorageHelper.set(LS_TOKEN, response.data.access);
                resolveRefresh();
                this._refreshingPromise = null;
                return await repeatSelfRequest();
              } else {
                await StorageHelper.remove(LS_TOKEN_REFRESH);
              }
            } catch {
              await StorageHelper.remove(LS_TOKEN_REFRESH);
            } finally {
              resolveRefresh();
              this._refreshingPromise = null;
            }
          }
        }
        // if (await StorageHelper.get(LS_TOKEN)) {
        if (isInAdminPanel && !noRedirect) {
          HistoryHelper.replace("/logout");
        }
        // }
        if (!noAlert) {
          AlertHelper.show(i18next.t("network.unauthorizedAccess"), "error", { key: "network-401" });
        }
      } else if (e.response?.status === 403 && !noAlert) {
        const errorDetail = e.response?.data?.detail;
        console.log("Session terminated due restricted access", e);
        AlertHelper.show(errorDetail || i18next.t("network.forbiddenAccess"), "error", { key: "network-403" });

        // if (StorageHelper.get(LS_TOKEN) && isInAdminPanel && !noRedirect) {
        //   HistoryHelper.replace("/", { scope: "dashboard" });
        // }
      } else if (e.response?.status === 400) {
        if (Object.keys(e.response?.data || {})?.length) {
          // make recursive function to get all string typed values as string array
          const getNestedStringValues = (obj) => {
            let values = [];
            for (const key in obj) {
              const value = obj[key];
              if (typeof value === "string") {
                values.push(value);
              } else if (typeof value === "object") {
                values = [...values, ...getNestedStringValues(value)];
              }
            }
            return values;
          };
          const errors = getNestedStringValues(e.response?.data);
          for (const errorText of errors) {
            !noAlert && AlertHelper.show(errorText, "error");
          }
        } else {
          !noAlert && AlertHelper.show(i18next.t("network.invalidRequest"), "error");
        }
      } else if (e.response?.status === 413) {
        !noAlert && AlertHelper.show(i18next.t("network.invalidFileSize"), "error");
      } else if (e.response?.status >= 500) {
        !noAlert && AlertHelper.show(i18next.t("network.serverError"), "error");
      } else if (e.response?.status === 404) {
        !noAlert && AlertHelper.show(i18next.t("network.serviceNotFound"), "error");
      }

      if (onFail) {
        let action = onFail(e);
        if (action?.type) {
          store.dispatch(action);
        }
      }
    } finally {
      if (onFinally) {
        let action = onFinally(responseItem, response);
        if (action?.type) {
          store.dispatch(action);
        }
      }
    }
    if (error && !noThrow) {
      throw error;
    }
    return responseItem;
  }
}
