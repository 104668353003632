import { createRef, useMemo } from "react";

import { MdAdd, MdDelete, MdEdit } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";

import PRButton from "~components/Generic/PRButton";
import PRContainer from "~components/Generic/PRContainer";
import PRTable from "~components/Generic/PRTable";
import { apiUrlChatLabels } from "~constants";
import DialogHelper from "~helpers/DialogHelper";
import { deleteChatLabel } from "~store/chatLabels/actions";
import { selectCurrentProject } from "~store/user/selectors";

const ChatLabelList = () => {
  const dispatch = useDispatch();
  const tableRef = createRef();
  const currentProject = useSelector(selectCurrentProject);

  const actions = useMemo(() => {
    return [
      {
        label: "Create New",
        icon: MdAdd,
        color: "success",
        link: "/contactcenter/chat-labels/form",
      },
    ];
  }, []);

  const columns = useMemo(() => {
    const handleClickDelete = (row) => async () => {
      if (await DialogHelper.showQuestionDelete()) {
        dispatch(deleteChatLabel(currentProject.id, row.id)).then(() => {
          tableRef.current.refresh();
        });
      }
    };
    return [
      {
        label: "Label",
        key: "key",
      },
      {
        label: "Actions",
        key: "actions",
        actions: true,
        render: (row) => (
          <div className="d-flex justify-content-center">
            <PRButton
              outline
              color="primary"
              icon={MdEdit}
              link={`/contactcenter/chat-labels/form/${row.id}`}
              size="sm"
              tooltipText="Edit"
            />
            <PRButton
              outline
              className="ms-1"
              color="danger"
              icon={MdDelete}
              size="sm"
              tooltipText="Delete"
              onClick={handleClickDelete(row)}
            />
          </div>
        ),
      },
    ];
  }, [currentProject.id, dispatch, tableRef]);
  return (
    <PRContainer
      actions={actions}
      description={"Here you can manage your Chat Labels to label your sessions."}
      name={"Contact Center"}
      parentName="Chat Labels"
    >
      <PRTable
        ref={tableRef}
        className="" //
        columns={columns}
        url={apiUrlChatLabels.get.format(currentProject.id)}
      />
    </PRContainer>
  );
};

export default ChatLabelList;
