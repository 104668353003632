import ConstantHelper from "~helpers/ConstantHelper";

export const chatbotInputTypes = {
  Email: "Email",
  AlphaNumeric: "Alpha Numeric",
  Numbers: "ON",
};

export const chatbotSender = {
  Bot: "BOT",
  Agent: "AGENT",
};

export const chatbotActionMinContrastLimit = 2.25;
export const chatbotTerminateTimeoutSec = 900;
export const chatbotTerminateModalCountdownSec = 60;
export const chatbotPingPongIntervalMs = 10000;
export const chatbotNoMessageWarningTryCount = 10;
export const chatbotNoMessageWarningDelayMs = 1000;
export const chatbotWelcomeMsgDisappearMs = 5000;
export const chatbotPopupCollapseExpandMs = 150;
export const chatbotMaxFileUploadSize = 10 * 1024 * 1024;
export const chatbotTypingThrottleMs = 5000;
export const getChatbotPingPongMaxTimeoutMs = (ms) => ms * 4 * 1000;
export const defaultMobileModeChatbotWidth = 768;

export const chatbotSessionStatus = {
  /** @type {"new"} */
  NEW: "new",
  /** @type {"BW"} */
  BOT_WAIT: "BW",
  /** @type {"BC"} */
  BOT_CHAT: "BC",
  /** @type {"BM"} */
  BOT_MISS: "BM",
  /** @type {"AW"} */
  AGENT_WAIT: "AW",
  /** @type {"BCAW"} */
  BOT_CHAT_AGENT_WAIT: "BCAW",
  /** @type {"BR"} */
  BOT_REPLY: "BR",
  /** @type {"AS"} */
  AGENT_STARTED: "AS",
  /** @type {"AC"} */
  AGENT_CHAT: "AC",
  /** @type {"AM"} */
  AGENT_MISS: "AM",
  /** @type {"FB"} */
  FEEDBACK: "FB",
  /** @type {"TI"} */
  TICKET: "TI",
  /** @type {"TO"} */
  TIMEOUT: "TO",
  /** @type {"FA"} */
  FAILED: "FA",
  /** @type {"CL"} */
  CLOSED: "CL",
  /** @type {"CO"} */
  COMPLETED: "CO",
  /** @type {"DC"} */
  CUSTOMER_DISCONNECT: "DC",
  /** @type {"BJ"} */
  BOT_JOINED: "BJ",
  /** @type {"TK"} */
  TOKEN: "TK",
};

export const chatbotSessionStatusMap = {
  [chatbotSessionStatus.NEW]: "New",
  [chatbotSessionStatus.BOT_WAIT]: "Bot Waiting",
  [chatbotSessionStatus.BOT_CHAT]: "Bot Chatting",
  [chatbotSessionStatus.BOT_MISS]: "Bot Missed",
  [chatbotSessionStatus.AGENT_WAIT]: "Agent Waiting",
  [chatbotSessionStatus.AGENT_STARTED]: "Agent Started",
  [chatbotSessionStatus.AGENT_CHAT]: "Agent Chatting",
  [chatbotSessionStatus.AGENT_MISS]: "Agent Missed",
  [chatbotSessionStatus.FEEDBACK]: "Feedback",
  [chatbotSessionStatus.TICKET]: "Ticket",
  [chatbotSessionStatus.TIMEOUT]: "Timeout",
  [chatbotSessionStatus.FAILED]: "Failed",
  [chatbotSessionStatus.CLOSED]: "Closed",
  [chatbotSessionStatus.COMPLETED]: "Completed",
  [chatbotSessionStatus.CUSTOMER_DISCONNECT]: "Customer Disconnected",
};

export const chatbotSessionStatusOptions = [
  { value: chatbotSessionStatus.NEW, label: chatbotSessionStatusMap[chatbotSessionStatus.NEW] },
  { value: chatbotSessionStatus.BOT_WAIT, label: chatbotSessionStatusMap[chatbotSessionStatus.BOT_WAIT] },
  { value: chatbotSessionStatus.BOT_CHAT, label: chatbotSessionStatusMap[chatbotSessionStatus.BOT_CHAT] },
  { value: chatbotSessionStatus.BOT_MISS, label: chatbotSessionStatusMap[chatbotSessionStatus.BOT_MISS] },
  { value: chatbotSessionStatus.AGENT_STARTED, label: chatbotSessionStatusMap[chatbotSessionStatus.AGENT_STARTED] },
  { value: chatbotSessionStatus.AGENT_WAIT, label: chatbotSessionStatusMap[chatbotSessionStatus.AGENT_WAIT] },
  { value: chatbotSessionStatus.AGENT_CHAT, label: chatbotSessionStatusMap[chatbotSessionStatus.AGENT_CHAT] },
  { value: chatbotSessionStatus.AGENT_MISS, label: chatbotSessionStatusMap[chatbotSessionStatus.AGENT_MISS] },
  { value: chatbotSessionStatus.FEEDBACK, label: chatbotSessionStatusMap[chatbotSessionStatus.FEEDBACK] },
  { value: chatbotSessionStatus.TICKET, label: chatbotSessionStatusMap[chatbotSessionStatus.TICKET] },
  { value: chatbotSessionStatus.TIMEOUT, label: chatbotSessionStatusMap[chatbotSessionStatus.TIMEOUT] },
  { value: chatbotSessionStatus.FAILED, label: chatbotSessionStatusMap[chatbotSessionStatus.FAILED] },
  { value: chatbotSessionStatus.CLOSED, label: chatbotSessionStatusMap[chatbotSessionStatus.CLOSED] },
  { value: chatbotSessionStatus.COMPLETED, label: chatbotSessionStatusMap[chatbotSessionStatus.COMPLETED] },
  {
    value: chatbotSessionStatus.CUSTOMER_DISCONNECT,
    label: chatbotSessionStatusMap[chatbotSessionStatus.CUSTOMER_DISCONNECT],
  },
];
export const chatbotSessionDashboardStatus = {
  QUEUE: "QUE",
  INPROGRESS: "INP",
  CLOSED: "CLO",
  FAIL: "FAI",
};
export const chatbotSenderType = {
  bot: "BOT",
  agent: "AGENT",
  customer: "CUSTOMER",
  system: "SYSTEM",
};

export const chatbotMainSessionStatusList = [
  chatbotSessionStatus.AGENT_WAIT,
  chatbotSessionStatus.AGENT_CHAT,
  chatbotSessionStatus.BOT_CHAT_AGENT_WAIT,
];

export const chatbotHistoryPageSize = 10;
export const chatbotLivechatPageSize = 10;
export const chatbotLivechatPageThrottleMs = 3000;
export const chatbotLivechatPageRefreshIntervalMs = 30000;

export const [chatbotInputRequestType, chatbotInputRequestTypeOptions, chatbotInputRequestTypeMap] =
  ConstantHelper.typify({
    /** @type {"phone_number"} */
    phone_number: "Phone Number",
    /** @type {"email"} */
    email: "Email",
    /** @type {"text"} */
    text: "Text",
    /** @type {"only_numbers"} */
    only_numbers: "Only Numbers",
    /** @type {"range"} */
    range: "Range",
    /** @type {"date"} */
    date: "Date",
    /** @type {"date_range"} */
    date_range: "Date Range",
    /** @type {"file"} */
    file: "File",
    /** @type {"select"} */
    select: "Select",
    /** @type {"custom"} */
    custom: "Custom",
    /** @type {"captcha"} */
    captcha: "Captcha",
    /** @type {"form"} */
    form: "Form",
    /** @type {"location"} */
    location: "Location",
  });

export const chatbotChatMessageToolbarEvent = "chatMessageToolbarEvent";

export const chatbotLikeDislikeType = {
  initial: 0, // Empty state (no like/dislike)
  unknown: 1, // Custom value(not related server)
  like: 2, // Like
  dislike: 3, // Dislike
  invisible: 4, // Custom value(not related server)
};

export const [chatbotGupshupTemplateType, chatbotGupshupTemplateTypeOptions, chatbotGupshupTemplateTypeMap] =
  ConstantHelper.typify({
    TEXT: "Text",
    IMAGE: "Image",
    DOCUMENT: "Document",
    VIDEO: "Video",
    LOCATION: "Location",
  });

export const [chatbotGupshupCategory, chatbotGupshupCategoryOptions, chatbotGupshupCategoryMap] = ConstantHelper.typify(
  {
    MARKETING: "Marketing",
    UTILITY: "Utility",
    AUTHENTICATION: "Authentication",
  }
);

export const [chatbotGupshupButtonType, chatbotGupshupButtonTypeOptions, chatbotGupshupButtonTypeMap] =
  ConstantHelper.typify({
    QUICK_REPLY: "Quick Reply",
    PHONE_NUMBER: "Phone Number",
    URL: "URL",
  });
export const [chatbotGupshupTemplateStatus, chatbotGupshupTemplateStatusOptions, chatbotGupshupTemplateStatusMap] =
  ConstantHelper.typify({
    PENDING: "Pending",
    REJECTED: "Rejected",
    APPROVED: "Approved",
    DELETED: "Deleted",
    DISABLED: "Disabled",
  });
export const [chatbotGupshupLanguage, chatbotGupshupLanguageOptions, chatbotGupshupLanguageMap] = ConstantHelper.typify(
  {
    af: "Afrikaans",
    sq: "Albanian",
    ar: "Arabic",
    az: "Azerbaijani",
    bn: "Bengali",
    bg: "Bulgarian",
    ca: "Catalan",
    zh_CN: "Chinese (CHN)",
    zh_HK: "Chinese (HKG)",
    zh_TW: "Chinese (TAI)",
    hr: "Croatian",
    cs: "Czech",
    da: "Danish",
    nl: "Dutch",
    en: "English",
    en_GB: "English (UK)",
    en_US: "English (US)",
    et: "Estonian",
    fil: "Filipino",
    fi: "Finnish",
    fr: "French",
    ka: "Georgian",
    de: "German",
    el: "Greek",
    gu: "Gujarati",
    ha: "Hausa",
    he: "Hebrew",
    hi: "Hindi",
    hu: "Hungarian",
    id: "Indonesian",
    ga: "Irish",
    it: "Italian",
    ja: "Japanese",
    kn: "Kannada",
    kk: "Kazakh",
    rw_RW: "Kinyarwanda",
    ko: "Korean",
    ky_KG: "Kyrgyz (Kyrgyzstan)",
    lo: "Lao",
    lv: "Latvian",
    lt: "Lithuanian",
    mk: "Macedonian",
    ms: "Malay",
    ml: "Malayalam",
    mr: "Marathi",
    nb: "Norwegian",
    fa: "Persian",
    pl: "Polish",
    pt_BR: "Portuguese (BR)",
    pt_PT: "Portuguese (POR)",
    pa: "Punjabi",
    ro: "Romanian",
    ru: "Russian",
    sr: "Serbian",
    sk: "Slovak",
    sl: "Slovenian",
    es: "Spanish",
    es_AR: "Spanish (ARG)",
    es_ES: "Spanish (SPA)",
    es_MX: "Spanish (MEX)",
    sw: "Swahili",
    sv: "Swedish",
    ta: "Tamil",
    te: "Telugu",
    th: "Thai",
    tr: "Turkish",
    uk: "Ukrainian",
    ur: "Urdu",
    uz: "Uzbek",
    vi: "Vietnamese",
    zu: "Zulu",
  }
);

export const [livechatCallcenterType, livechatCallcenterTypeOptions, livechatCallcenterTypeMap] = ConstantHelper.typify(
  {
    FIFO: "FIFO",
    Pool: "Pool",
  }
);

export const [llmType, llmTypeOptions, llmTypeMap] = ConstantHelper.typify({
  Gemini: "Gemini",
  OpenAI: "OpenAI",
  Groq: "Groq",
});

export const [livechatAgentStatus, livechatAgentStatusOptions, livechatAgentStatusMap] = ConstantHelper.typify({
  AV: "Available",
  UN: "Unavailable",
});

export const [chatbotSessionPlatform, chatbotSessionPlatformOptions, chatbotSessionPlatformMap] = ConstantHelper.typify(
  {
    WHAT: "WhatsApp",
    TELE: "Telegram",
    DISC: "Discord",
    SLAC: "Slack",
    TEA: "Teams",
    WEB: "Web",
    AND: "Android",
    IOS: "iOS",
    INST: "Instagram",
    MOB: "Other Mobile",
  }
);
