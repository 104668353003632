import { useState } from "react";

import { useSelector } from "react-redux";
import { Badge } from "reactstrap";

import PRAccordion, { PRAccordionItem } from "~components/Generic/PRAccordion";
import { dialogComponentsIntentType } from "~constants";
import {
  selectScenarioManagerIntentType,
  selectScenarioManagerResponseMessageItems,
  selectScenarioManagerResponseSuggestedIntents,
} from "~store/dialogComponents/scenarioManager/selectors";

import Messages from "./Messages";
// import Settings from "./Settings";
import SuggestedIntents from "./SuggestedIntents";

function RenderTitleResponseMessages() {
  const responseMessageItems = useSelector(selectScenarioManagerResponseMessageItems);
  return (
    <>
      Messages
      <Badge className="ms-1" color={"primary"}>
        {!!responseMessageItems.length && responseMessageItems.length}
      </Badge>
    </>
  );
}

function RenderTitleResponseSuggestedIntents() {
  const suggestedIntentIdList = useSelector(selectScenarioManagerResponseSuggestedIntents);
  return (
    <>
      Suggested Intents
      <Badge className="ms-1" color={"primary"}>
        {!!suggestedIntentIdList.length && suggestedIntentIdList.length}
      </Badge>
    </>
  );
}

// function RenderTitleResponseSettings() {
//   const responseFeedback = useSelector(selectScenarioManagerResponseFeedback);
//   const hasChanges = responseFeedback !== scenarioManagerInitState.intent.response.ask_feedback;
//   return (
//     <div className="position-relative">
//       Settings
//       {hasChanges && (
//         <span className="ms-2 position-absolute top-0 start-100 translate-middle badge border border-light rounded-circle bg-primary p-1">
//           <span className="visually-hidden"></span>
//         </span>
//       )}
//     </div>
//   );
// }

export default function Response() {
  const responseMessageItems = useSelector(selectScenarioManagerResponseMessageItems);
  const suggestedIntentIdList = useSelector(selectScenarioManagerResponseSuggestedIntents);
  const intentType = useSelector(selectScenarioManagerIntentType);
  const [accordionState, setAccordionState] = useState({
    1: null,
    2: null,
    3: null,
  });

  const handleClickAccordion = (id) => () => {
    setAccordionState({
      ...accordionState,
      [id]: !accordionState[id],
    });
  };

  return (
    <PRAccordion flush>
      {intentType === dialogComponentsIntentType.STANDARD && (
        <PRAccordionItem
          secondary
          collapsed={accordionState[2] ?? !!responseMessageItems.length}
          title={RenderTitleResponseMessages}
          onClick={handleClickAccordion(2)}
        >
          <Messages />
        </PRAccordionItem>
      )}
      <PRAccordionItem
        secondary
        collapsed={accordionState[1] ?? !!suggestedIntentIdList.length}
        title={RenderTitleResponseSuggestedIntents}
        onClick={handleClickAccordion(1)}
      >
        <SuggestedIntents />
      </PRAccordionItem>
      {/* <PRAccordionItem
        secondary
        title={RenderTitleResponseSettings}
        collapsed={accordionState[3]}
        onClick={handleClickAccordion(3)}
      >
        <Settings />
      </PRAccordionItem> */}
    </PRAccordion>
  );
}
