import SanitizeHtml from "sanitize-html";

export default class SanitizeHelper {
  static html(text) {
    const config = {
      allowedTags: false, // This will allow all tags
      disallowedTagsMode: "discard",
      disallowedTags: ["script", "object", "embed", "applet"], // Remove script and other unwanted tags
      allowedAttributes: false, // This will allow all attributes
      disallowedAttributes: {
        "*": ["on*"], // Ignore all "on*" attributes (onmouseover, onclick, etc.)
      },
      allowedSchemes: ["http", "https", "ftp", "mailto", "tel", "sms", "data"],
      allowedIframeHostnames: [
        "www.youtube.com",
        "player.vimeo.com",
        "www.dailymotion.com",
        "www.facebook.com",
        "www.instagram.com",
        "www.tiktok.com",
      ],
    };
    let sanitizedText = SanitizeHtml(text, config);
    //replace &amp; with & to prevent <a/> tag from being sanitized
    sanitizedText = sanitizedText.replace(/&amp;/g, "&");
    return sanitizedText;
  }
  static plainText(text) {
    const config = {
      ...SanitizeHtml.defaults,
      allowedTags: [],
      allowedAttributes: {},
      textFilter: function (text = "") {
        return text.replace(/&nbsp;/g, " ");
      },
    };

    let sanitizedText = SanitizeHtml(text, config);
    //replace &amp; with & to prevent <a/> tag from being sanitized
    sanitizedText = sanitizedText.replace(/&amp;/g, "&");
    return sanitizedText;
  }

  static safeText(text = "") {
    if (typeof text !== "string") {
      return "";
    }
    let safeText = text
      // .replace(/&/g, "&amp;")
      .replace(/</g, "&lt;")
      .replace(/>/g, "&gt;")
      .replace(/"/g, "&quot;")
      .replace(/'/g, "&#x27;")
      .replace(/\//g, "&#x2F;");

    return SanitizeHelper.plainText(safeText);
  }
  static safeUrl(url = "") {
    const safeUrl = SanitizeHelper.safeText(url);
    const allowedSchemes = ["http:", "https:", "wss:", "ws:"];
    let isValid;
    try {
      const parsedUrl = new URL(safeUrl);
      isValid = allowedSchemes.includes(parsedUrl.protocol);
    } catch (e) {
      isValid = false;
    }

    if (isValid) {
      return safeUrl;
    } else {
      const printUrl = typeof url === "string" ? url : JSON.stringify(url);
      console.log("Invalid URL", printUrl, safeUrl);
    }
    return "";
  }
}
