import { useEffect, useRef, useState } from "react";

import { withCardon } from "cardon";
import { useDispatch, useSelector } from "react-redux";

import styled from "@emotion/styled";

import useLoading from "~common/hooks/useLoading";
import PRChart from "~components/Generic/PRChart";
import PRModal from "~components/Generic/PRModal";
import AlertHelper from "~helpers/AlertHelper";
import RenderHelper from "~helpers/RenderHelper";
import SanitizeHelper from "~helpers/SanitizeHelper";
import { downloadStatisticGeneratorReport } from "~store/statisticGenerator/actions";
import { selectCurrentProject } from "~store/user/selectors";

const StyledModal = styled(PRModal)`
  .modal-body {
    max-height: 85vh;
    overflow: auto;
  }
`;
function DynamicReportChartModalContent({ get, reportId }) {
  const currentProject = useSelector(selectCurrentProject);
  const printRef = useRef();
  const dispatch = useDispatch();
  const [loading, q] = useLoading();

  const [reportJson, setReportJson] = useState([]);

  useEffect(() => {
    if (reportId) {
      q(dispatch(downloadStatisticGeneratorReport(currentProject.id, reportId))).then((response) => {
        if (Array.isArray(response?.data)) {
          setReportJson(response?.data || []);
        } else if (typeof response?.data === "object") {
          setReportJson([response?.data]);
        } else if (response?.data) {
          AlertHelper.showWarning("Invalid report data format. Please inspect the report's low-code");
        }
      });
    }
  }, [dispatch, reportId, currentProject?.id, q]);
  const handlePrint = () => {
    const printContent = printRef.current;
    const WinPrint = window.open("", "", "left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0");
    WinPrint.document.write(printContent.innerHTML);
    WinPrint.document.close();
    WinPrint.focus();
    WinPrint.print();
  };
  return (
    <StyledModal
      centered
      isOpen={true}
      size="xl"
      submitText="Print"
      title={"Report Preview"}
      onClick={handlePrint}
      onClose={get(false)}
    >
      <span ref={printRef}>
        {reportJson.map((report, index) => {
          if (report.type === "echarts") {
            const { option, style, height, ...rest } = report;
            return (
              <PRChart
                key={index}
                height={height || "500px"}
                loading={loading}
                option={option}
                {...rest}
                renderer="svg"
                style={style}
              />
            );
          } else if (report.type === "markdown") {
            const renderedMd = RenderHelper.renderMd(report.content, { html: true });
            let sanitizedHtml = SanitizeHelper.html(renderedMd);
            sanitizedHtml = RenderHelper.addBlankTargetToLinks(sanitizedHtml);

            return (
              <div
                key={index}
                dangerouslySetInnerHTML={{ __html: sanitizedHtml }}
                style={{ width: "100%", ...report.style }}
              />
            );
          } else if (report.type === "html") {
            let sanitizedHtml = SanitizeHelper.html(report.content);
            sanitizedHtml = RenderHelper.addBlankTargetToLinks(sanitizedHtml);
            return (
              <div
                key={index}
                dangerouslySetInnerHTML={{ __html: sanitizedHtml }}
                style={{ width: "100%", ...report.style }}
              />
            );
          }
        })}
      </span>
    </StyledModal>
  );
}
const DynamicReportChartModal = withCardon(DynamicReportChartModalContent, { destroyOnHide: true });
export default DynamicReportChartModal;
