import { Fragment, useEffect, useMemo, useRef, useState } from "react";

import { PalButton, PalCard, PalShadowWrapper, PalTypography } from "@palamar/fe-library";
import { withCardon } from "cardon";
import { useFormik } from "formik";
import moment from "moment";
import { MdAdd } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { ButtonGroup, Col, Label, Modal, Row } from "reactstrap";
import * as Yup from "yup";

import { useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import { Add } from "@mui/icons-material";
import { Alert, Box, Fade, Grid, Popover, Switch, Tab, Tabs, Typography } from "@mui/material";

import useLoading from "~common/hooks/useLoading";
import PRButton from "~components/Generic/PRButton";
import PRContainer from "~components/Generic/PRContainer";
import PRDate from "~components/Generic/PRDate";
import PRDivider from "~components/Generic/PRDivider";
import PRInput from "~components/Generic/PRInput";
import PRModal, { modalZIndex } from "~components/Generic/PRModal";
import PRPage from "~components/Generic/PRPage";
import PRSelect from "~components/Generic/PRSelect";
import PalIconButton from "~components/mui/PalIconButton";
import { organizationResourceAttendanceStatus, organizationVisibilityType, userRole } from "~constants";
import AlertHelper from "~helpers/AlertHelper";
import DateHelper from "~helpers/DateHelper";
import debounceAsync from "~helpers/debounceAsync";
import DialogHelper from "~helpers/DialogHelper";
import {
  addBlacklistResourceUser,
  addOrUpdateResourceSlot,
  deleteReservation,
  deleteResourceSlot,
  getReservationMember,
  getReservationMembers,
  getReservations,
  getResource,
  getSlot,
  getSlots,
  postReservationAction,
  removeBlacklistResourceUser,
} from "~store/organization/actions";
import { selectMemberFieldFormat, selectSlot } from "~store/organization/selectors";
import { selectCurrentProject, selectUserInfo } from "~store/user/selectors";

import { CustomerListCellRenderer } from "../Customer/CustomerList";

const StyledReservationCol = styled(Col)`
  display: flex;
  flex-direction: column;
  /* align-items: flex-start; */
`;

const ReservationBox = styled.div`
  background-color: ${(props) =>
    props.isCurrentReserved
      ? props.isActiveTab
        ? "var(--bs-orange-100)"
        : "var(--bs-warning-200)"
      : "var(--bs-secondary-100)"};
  /* border: 1px solid var(--bs-secondary-400); */
  border-top: 1px solid var(--bs-secondary-400);
  border-bottom: 1px solid var(--bs-secondary-400);
  border-right: 1px solid var(--bs-secondary-400);
  border-radius: 0px;
  padding: 4px;

  ${(props) =>
    props.isFirst &&
    `
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
    border-left: 1px solid var(--bs-secondary-400);
  `}
  ${(props) =>
    props.isLast &&
    `
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
  `}
`;

const VerticalReservationBox = styled.div`
  /* background-color: ${(props) => (props.selected ? "var(--bs-warning-200)" : "var(--bs-secondary-100)")}; */
  background-color: ${(props) =>
    props.selected
      ? props.isReservedFromMe
        ? "var(--bs-warning-300)"
        : "var(--bs-warning-200)"
      : props.isReservedFromMe
      ? "var(--bs-success-100)"
      : "var(--bs-secondary-100)"};
  border-left: 1px solid var(--bs-secondary-400);
  border-bottom: 1px solid var(--bs-secondary-400);
  border-right: 1px solid var(--bs-secondary-400);
  border-radius: 0px;
  padding: 4px;
  //disable text selection
  user-select: none;
  ${(props) => props.isFilled && `background-color: var(--bs-danger-100);`}
  ${(props) =>
    props.isFirst &&
    `
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
    border-top: 1px solid var(--bs-secondary-400);
  `}
  ${(props) =>
    props.isLast &&
    `
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
  `} /* ${(props) => props.isReservedFromMe && `background-color: var(--bs-success-100);`} */
`;

const NoBorderDateBox = styled(Box)`
  width: 120px;
  input {
    font-size: 16px;
    border-left: none;
    border-right: none;
    border-top: none;
    border-radius: 0;
    text-align: center;
  }
`;
function EditReservationSlotDateModalContent({
  // hasCreateAction,
  // hasEditAction,

  slotActionsInfo,
  editMode,
  get,
  events,
  reservation,
  resource,
}) {
  const [viewingDate, setViewingDate] = useState(
    events?.[0]?.start_date ? DateHelper.getDateTimeLocal(events[0].start_date) : DateHelper.getDateTimeLocal()
  );
  const [viewingSlots, setViewingSlots] = useState([]);
  const [loading, q] = useLoading();
  const [member, setMember] = useState(reservation?.reserver?.id);
  const [selectedMember, setSelectedMember] = useState(null);
  const [selectedReservationType, setSelectedReservationType] = useState(reservation?.secret_note?.name || "");
  const [memberNote, setMemberNote] = useState(reservation?.note || "");
  const [loadingMember, qMember] = useLoading();

  const { viewingSlotsPrev, viewingSlotsCurrent, viewingSlotsNext } = useMemo(() => {
    //viewingSlots has exist slots in 3 different days. separate by prev, current, next
    const viewingSlotsPrev = viewingSlots.filter((slot) => {
      const slotDate = DateHelper.getDateTimeLocal(slot.start_date);
      return slotDate.isBefore(viewingDate, "day");
    });
    const viewingSlotsCurrent = viewingSlots.filter((slot) => {
      const slotDate = DateHelper.getDateTimeLocal(slot.start_date);
      return slotDate.isSame(viewingDate, "day");
    });

    const viewingSlotsNext = viewingSlots.filter((slot) => {
      const slotDate = DateHelper.getDateTimeLocal(slot.start_date);
      return slotDate.isAfter(viewingDate, "day");
    });

    return { viewingSlotsPrev, viewingSlotsCurrent, viewingSlotsNext };
  }, [viewingSlots, viewingDate]);
  const getIsReservedFromMe = (position, index) => {
    const targetViewingSlots =
      position === "prev" ? viewingSlotsPrev : position === "current" ? viewingSlotsCurrent : viewingSlotsNext;
    const targetSlot = targetViewingSlots[index];
    const slotIdList = reservation?.slot?.length
      ? typeof reservation?.slot?.[0] === "object"
        ? reservation?.slot.map((s) => s.id)
        : reservation?.slot
      : [];
    return slotIdList.includes(targetSlot?.id);
  };

  const dispatch = useDispatch();
  const currentProject = useSelector(selectCurrentProject);
  const [selectedSlotsMap, setSelectedSlotsMap] = useState({
    prev: [],
    current: [],
    next: [],
  });
  const [selectedSlotsPosition, setSelectedSlotsPosition] = useState("current");
  const isDragging = useRef(false);

  const activeSelectedSlots = useMemo(
    () => selectedSlotsMap[selectedSlotsPosition],
    [selectedSlotsPosition, selectedSlotsMap]
  );

  const activeSelectedSlotItems = useMemo(() => {
    const targetViewingSlots =
      selectedSlotsPosition === "prev"
        ? viewingSlotsPrev
        : selectedSlotsPosition === "current"
        ? viewingSlotsCurrent
        : viewingSlotsNext;
    return activeSelectedSlots.map((index) => targetViewingSlots[index]);
  }, [activeSelectedSlots, selectedSlotsPosition, viewingSlotsPrev, viewingSlotsCurrent, viewingSlotsNext]);

  const reservationTypeOptions = useMemo(() => {
    if (!slotActionsInfo.reservation_type_list) return [];
    const options = slotActionsInfo.reservation_type_list.map((item) => {
      return {
        ...item,
        value: item.name,
        label: `${item.islem_adi}, ${item.fiyat} (${item.islem_suresi})`,
      };
    });
    return options;
  }, [slotActionsInfo]);

  const selectedReservationTypeObj = reservationTypeOptions.find((item) => item.name === selectedReservationType);

  const { requiredSlotCount, requiredSlotCountMin, requiredSlotCountMax } = useMemo(() => {
    // if (!activeSelectedSlotItems?.length) return { requiredSlotCount: 0 };

    const reservationMinutes = selectedReservationTypeObj?.islem_suresi_dk;
    // const totalMinutesOfSelectedSlots = activeSelectedSlotItems.reduce((acc, index) => {
    //   const slot = viewingSlotsCurrent[index];
    //   const start = DateHelper.getDateTimeLocal(slot.start_date);
    //   const end = DateHelper.getDateTimeLocal(slot.end_date);
    //   return acc + end.diff(start, "minutes");
    // }, 0);

    const activeSlotItems =
      selectedSlotsPosition === "prev"
        ? viewingSlotsPrev
        : selectedSlotsPosition === "current"
        ? viewingSlotsCurrent
        : viewingSlotsNext;

    const firstSlot = activeSelectedSlotItems[0] || activeSlotItems[0];
    const slotStartDate = DateHelper.getDateTimeLocal(firstSlot?.start_date);
    const slotEndDate = DateHelper.getDateTimeLocal(firstSlot?.end_date);

    const minutes = slotEndDate.diff(slotStartDate, "minutes");
    const requiredSlotCount = reservationMinutes / minutes;

    const requiredSlotCountFloor = Math.max(1, Math.floor(requiredSlotCount));
    const requiredSlotCountCeil = Math.ceil(requiredSlotCount);

    return {
      // allRequiredSlotsSelected: totalMinutesOfSelectedSlots !== reservationMinutes,
      requiredSlotCount,
      requiredSlotCountMin: requiredSlotCountFloor,
      requiredSlotCountMax: requiredSlotCountCeil,
    };
  }, [selectedReservationTypeObj, activeSelectedSlotItems, selectedReservationType]);

  const handleMouseDown = (position, index) => () => {
    const activeSlots =
      selectedSlotsPosition === "prev"
        ? viewingSlotsPrev
        : selectedSlotsPosition === "current"
        ? viewingSlotsCurrent
        : viewingSlotsNext;

    const hoveringItem = activeSlots[index];

    const isReservedFromMe = getIsReservedFromMe(position, index);
    if (!hoveringItem.available_slots && !isReservedFromMe) {
      //  || (!selectedReservationType && !editMode)
      setSelectedSlotsMap(() => {
        return {
          prev: [],
          current: [],
          next: [],
        };
      });
      return;
    }

    // if (selectedReservationType) {
    //   // setSelectedSlots([]);
    //   //take items after specified index and after
    //   const itemsAfterIndex = activeSlots.slice(index);
    //   const followingItemCount =
    //     itemsAfterIndex.length < requiredSlotCountMax ? itemsAfterIndex.length : requiredSlotCountMax;

    //   const followingIndexArray = new Array(followingItemCount).fill(0).map((_, i) => i + index);
    //   const availableIndexes = [];
    //   for (const i of followingIndexArray) {
    //     const item = activeSlots[i];
    //     const isAvailable = item?.available_slots || getIsReservedFromMe(position, index + itemsAfterIndex.length + i);
    //     if (isAvailable) {
    //       availableIndexes.push(i);
    //     } else {
    //       break;
    //     }
    //   }
    //   setSelectedSlotsMap(() => {
    //     return {
    //       prev: [],
    //       current: [],
    //       next: [],
    //       [position]: availableIndexes,
    //     };
    //   });
    //   setSelectedSlotsPosition(position);
    //   return;
    // }

    isDragging.current = true;
    // setSelectedSlots([index]);

    setSelectedSlotsMap(() => {
      return {
        prev: [],
        current: [],
        next: [],
        [position]: [index],
      };
    });
    setSelectedSlotsPosition(position);
  };

  const handleMouseUp = () => {
    isDragging.current = false;
  };

  const handleMouseOver = (position, index) => () => {
    if (isDragging.current) {
      // const hoveringItem = viewingSlots[index];
      const activeSlots =
        selectedSlotsPosition === "prev"
          ? viewingSlotsPrev
          : selectedSlotsPosition === "current"
          ? viewingSlotsCurrent
          : viewingSlotsNext;

      const hoveringItem = activeSlots[index];

      const isReservedFromMe = getIsReservedFromMe(position, index);
      if (!hoveringItem.available_slots && !isReservedFromMe) {
        handleMouseUp();
        return;
      }
      // if (requiredSlotCountMax <= activeSelectedSlots?.length && selectedReservationType) {
      //   handleMouseUp();
      //   return;
      // }
      // setSelectedSlots((prev) => {
      //   if (!prev.includes(index)) {
      //     return [...prev, index];
      //   }
      //   return prev;
      // });

      setSelectedSlotsMap((prev) => {
        const target = prev[position];
        if (!target.includes(index)) {
          const newIndexList = [...target, index];
          const minIndex = Math.min(...newIndexList);
          const maxIndex = Math.max(...newIndexList);
          //regenerate new index list by min and max index
          //this helps to ensure select skipping slots when scrolling
          const newIndexes = Array.from({ length: maxIndex - minIndex + 1 }, (_, i) => i + minIndex);
          return {
            ...prev,
            [position]: newIndexes,
          };
        }
        return prev;
      });
    }
  };

  const nextOneDay = () => {
    const newDate = viewingDate.clone().add(1, "day");
    setViewingDate(newDate);
  };
  const prevOneDay = () => {
    const newDate = viewingDate.clone().subtract(1, "day");
    setViewingDate(newDate);
  };

  const nextOneWeek = () => {
    const newDate = viewingDate.clone().add(1, "week");
    setViewingDate(newDate);
  };
  const prevOneWeek = () => {
    const newDate = viewingDate.clone().subtract(1, "week");
    setViewingDate(newDate);
  };

  const nextOneMonth = () => {
    const newDate = viewingDate.clone().add(1, "month");
    setViewingDate(newDate);
  };

  const prevOneMonth = () => {
    const newDate = viewingDate.clone().subtract(1, "month");
    setViewingDate(newDate);
  };

  useEffect(() => {
    const viewingDateStart = viewingDate.clone().startOf("day");
    const viewingDateEnd = viewingDate.clone().endOf("day").subtract(1, "second");

    const viewingDatePrev = viewingDateStart.clone().subtract(1, "day");
    const viewingDateNext = viewingDateEnd.clone().add(1, "day");
    // console.log("start", start, "end", end);
    q(
      dispatch(
        getSlots(
          resource.id,
          currentProject.id,
          {
            start_date__gte: viewingDatePrev,
            end_date__lte: viewingDateNext,
            limit: 99999,
          },
          {
            loading: false,
            onSuccess: (response) => {
              response.results.sort((a, b) => {
                return DateHelper.getDateTimeLocal(a.start_date).diff(DateHelper.getDateTimeLocal(b.start_date));
              });

              if (response.results[3]) {
                // response.results[3].available_slots = 0;
              }
              setViewingSlots(response.results || []);
            },
          }
        )
      )
    );
  }, [dispatch, resource, currentProject, viewingDate, q]);
  // const formik = useFormik({
  //   enableReinitialize: true,
  //   // initialValues: {
  //   //   id: selectedSlot?.id,
  //   //   start_date: selectedSlot?.start_date,
  //   //   end_date: selectedSlot?.end_date,
  //   // },
  //   // validationSchema: Yup.object().shape({
  //   //   start_date: Yup.date().required("Start date is required"),
  //   //   end_date: Yup.date().required("End date is required"),
  //   // }),

  //   onSubmit: async (values) => {
  //     const payload = {
  //       ...values,
  //     };
  //     try {
  //       await q(dispatch(postReservationAction(currentProject.id, reservation.id, payload)));
  //       get(true)();
  //     } catch {
  //       get(false)();
  //     }
  //   },
  // });

  // const handleDateChange = (key) => (date) => {
  //   if (typeof date === "string") {
  //     formik.setFieldValue(key, date);
  //     return;
  //   }
  //   formik.setFieldValue(key, date);
  // };

  const selectedSlotsDateRangeStr = useMemo(() => {
    if (!activeSelectedSlots?.length || !viewingSlots?.length) return "";
    const firstSelectedSlotIndex = activeSelectedSlots[0];
    const lastSelectedSlotIndex = activeSelectedSlots[activeSelectedSlots.length - 1];
    let targetViewingSlots =
      selectedSlotsPosition === "prev"
        ? viewingSlotsPrev
        : selectedSlotsPosition === "current"
        ? viewingSlotsCurrent
        : viewingSlotsNext;
    const targetViewingFirstSlotDate = targetViewingSlots[firstSelectedSlotIndex]?.start_date;
    const targetViewingLastSlotDate = targetViewingSlots[lastSelectedSlotIndex]?.end_date;
    if (!targetViewingFirstSlotDate || !targetViewingLastSlotDate) return "";
    let firstSelectedSlotDate = DateHelper.getDateTimeLocal(targetViewingFirstSlotDate);
    let lastSelectedSlotDate = DateHelper.getDateTimeLocal(targetViewingLastSlotDate);

    //replace firstSelectedSlotDate and lastSelectedSlotDate which one is earlier
    if (firstSelectedSlotDate.isAfter(lastSelectedSlotDate)) {
      [firstSelectedSlotDate, lastSelectedSlotDate] = [lastSelectedSlotDate, firstSelectedSlotDate];
    }
    const dateStr = firstSelectedSlotDate.format("L");
    const dateRangeSTr = `${dateStr} ${firstSelectedSlotDate.format("LT")} - ${lastSelectedSlotDate.format("LT")}`;

    return dateRangeSTr;
  }, [
    activeSelectedSlots,
    viewingSlots,
    selectedSlotsPosition,
    viewingSlotsPrev,
    viewingSlotsCurrent,
    viewingSlotsNext,
  ]);

  const handleClickSubmit = () => {
    let currentActiveSelectedSlots = activeSelectedSlots;
    if (!currentActiveSelectedSlots?.length && reservation?.slot?.length) {
      currentActiveSelectedSlots = reservation?.slot
        ?.map((s) => s.id)
        .map((id) => viewingSlotsCurrent.findIndex((slot) => slot.id === id));
    }
    if (!currentActiveSelectedSlots?.length) {
      AlertHelper.showError("Please select at least one slot.");
      return;
    }

    // if (!editMode && currentActiveSelectedSlots?.length > 0 && selectedReservationType) {
    //   const isInMinMaxRange =
    //     currentActiveSelectedSlots.length >= requiredSlotCountMin &&
    //     currentActiveSelectedSlots.length <= requiredSlotCountMax;
    //   if (!isInMinMaxRange) {
    //     const isMinMaxEqual = requiredSlotCountMin === requiredSlotCountMax;
    //     if (isMinMaxEqual) {
    //       AlertHelper.showError(
    //         `Please select total ${requiredSlotCount} slots to complete the reservation. Currently, you have selected ${currentActiveSelectedSlots.length} slots.`
    //       );
    //     } else {
    //       AlertHelper.showError(
    //         `Please select between ${requiredSlotCountMin} and ${requiredSlotCountMax} total slots to complete the reservation. Currently, you have selected ${currentActiveSelectedSlots.length} slots.`
    //       );
    //     }
    //     return;
    //   }
    // }
    if (!editMode && !member) {
      AlertHelper.showError("Please select a member.");
      return;
    }
    if (!editMode && !selectedReservationType) {
      AlertHelper.showError("Please select a reservation type.");
      return;
    }

    const targetViewingSlots =
      selectedSlotsPosition === "prev"
        ? viewingSlotsPrev
        : selectedSlotsPosition === "current"
        ? viewingSlotsCurrent
        : viewingSlotsNext;

    const selectedSlotIds = currentActiveSelectedSlots.map((index) => targetViewingSlots[index].id);
    const payload = {
      button_type: editMode ? "edit" : "create",
      payload: {
        // ...reservation,
        ...(reservation?.id && { reservation_id: reservation.id }),
        // member_id: reservation.reserver.id,
        ...(!editMode && { member_id: member }),
        note: memberNote,
        slot_id: selectedSlotIds,
        // reservation_type: selectedReservationType,]
        secret_note: selectedReservationTypeObj,
      },
    };
    dispatch(postReservationAction(currentProject.id, resource.id, payload)).then(() => {
      get(true)();
    });
  };

  const handleChangeMember = (member) => {
    setMember(member);
  };

  const handleChangeReservationType = (reservationType) => {
    setSelectedReservationType(reservationType);
  };

  const handleChangeReservationNote = (e) => {
    setMemberNote(e.target.value);
  };

  const handleLoadOptions = debounceAsync(async (searchText, callback, signal) => {
    const memberList = await dispatch(
      getReservationMembers(
        currentProject.id,
        {
          limit: 20,
          email__icontains: searchText,
        },
        {
          signal,
          loading: false,
        }
      )
    );
    const options = memberList.results.map((item) => {
      return {
        value: item.id,
        label: item.safe_information?.email,
      };
    });
    if (member && !options.find((item) => item.value === (member?.id || member))) {
      const member = await dispatch(
        getReservationMember(currentProject.id, member?.id || member, {
          loading: false,
          onSuccess: () => {},
        })
      );
      if (member) {
        options.push({
          value: member.id,
          label: member.safe_information?.email,
        });
      }
    }
    return options;
  });

  useEffect(() => {
    if (member && member !== selectedMember?.id) {
      qMember(
        dispatch(
          getReservationMember(currentProject.id, member, {
            loading: false,
            onSuccess: () => {},
          })
        )
      ).then((member) => {
        setSelectedMember(member);
      });
    }
  }, [dispatch, currentProject.id, member, selectedMember?.id]);

  const targetUserInfo = Object.entries(selectedMember?.safe_information || {}); // reservation?.customer || selectedMemberObj || {});

  return (
    <PRModal
      size="lg"
      submitText={editMode ? "Update" : "Create"}
      title={`${editMode ? "Update" : "Create"} Reservation Slot`}
      onClick={handleClickSubmit}
      onClose={get(false)}
      // onDelete={reservation?.id ? handleClickDeleteReservation : undefined}
    >
      {editMode && (
        <Grid container alignItems="center" justifyContent="space-between" mb={2} mt={1}>
          <Grid item xs="auto">
            <ButtonGroup className="">
              <PRButton outline color="secondary" size="sm" tooltipText="1 week" onClick={prevOneMonth}>
                -1 Month
              </PRButton>
              <PRButton outline color="secondary" size="sm" tooltipText="1 week" onClick={prevOneWeek}>
                -1 Week
              </PRButton>
              <PRButton outline color="secondary" size="sm" tooltipText="1 month" onClick={prevOneDay}>
                -1 Day
              </PRButton>
            </ButtonGroup>
          </Grid>
          <Grid item xs display="flex" justifyContent="center">
            {/* <Box textAlign="center">{viewingDate.format("LL")}</Box> */}

            <NoBorderDateBox>
              <PRDate
                isClearable={false}
                timeFormat={false}
                value={viewingDate}
                onChange={(date) => setViewingDate(date)}
              />
            </NoBorderDateBox>
          </Grid>
          <Grid item xs="auto">
            <ButtonGroup className="">
              <PRButton outline color="secondary" size="sm" tooltipText="1 month" onClick={nextOneDay}>
                +1 Day
              </PRButton>
              <PRButton outline color="secondary" size="sm" tooltipText="1 week" onClick={nextOneWeek}>
                +1 Week
              </PRButton>
              <PRButton outline color="secondary" size="sm" tooltipText="1 week" onClick={nextOneMonth}>
                +1 Month
              </PRButton>
            </ButtonGroup>
          </Grid>
        </Grid>
      )}
      <Grid container mb={1} spacing={1}>
        <Grid item disabled={editMode} xs={12}>
          <Label>Member</Label>
          <PRSelect
            isPrimitiveValue
            isClearable={false}
            lazy
            // invalid={formik.touched.member && formik.errors.member}
            loadOptions={handleLoadOptions}
            // value={formik.values.member}
            value={member}
            // onChange={handleChange("member")}
            onChange={handleChangeMember}
          />
        </Grid>
        <Grid item xs={12}>
          <Label>Reservation Type</Label>
          <PRSelect
            isPrimitiveValue
            isClearable={false}
            options={reservationTypeOptions}
            value={selectedReservationType}
            onChange={handleChangeReservationType}
          />
        </Grid>
        <Grid item xs={12}>
          <Label>Member Information</Label>

          <PRContainer bare loading={loadingMember}>
            {targetUserInfo?.length ? (
              <ul>
                {targetUserInfo.map(([key, value]) => (
                  <li key={key}>
                    <span className="">{key}</span>: <span className="fw-medium">{value}</span>
                  </li>
                ))}
              </ul>
            ) : (
              <div className="text-muted text-center">No information</div>
            )}
          </PRContainer>
        </Grid>
        <Grid item xs={12}>
          <Label>Note</Label>
          <PRInput rows={3} type="textarea" value={memberNote} onChange={handleChangeReservationNote} />
        </Grid>
        <Grid item className="fs-5" pt={2} xs={12}>
          Please hold down the mouse button and drag over the boxes to select them.
          <Box
            alignItems="center"
            display="flex"
            height={32}
            visibility={activeSelectedSlots?.length ? "visible" : "hidden"}
          >
            New reservation date range is
            <Box color={(theme) => theme.palette.primary.main} fontWeight={"bold"} ml={0.5}>
              {selectedSlotsDateRangeStr}
            </Box>
          </Box>
          <Box>
            <Fade in={selectedReservationType && activeSelectedSlots?.length > 0}>
              <span>
                {requiredSlotCountMin <= activeSelectedSlots?.length &&
                requiredSlotCountMax >= activeSelectedSlots?.length ? (
                  <Alert severity={"success"}>
                    All required slots are selected.
                    {requiredSlotCountMin !== requiredSlotCountMax &&
                      `You can select minimum ${requiredSlotCountMin} and maximum ${requiredSlotCountMax} slots.`}
                  </Alert>
                ) : (
                  <Alert severity={"warning"}>
                    {requiredSlotCountMin !== requiredSlotCountMax
                      ? `Please select between ${requiredSlotCountMin} and ${requiredSlotCountMax} total slots to complete the reservation. Currently, you have selected ${activeSelectedSlots?.length} slots.`
                      : `Please select total ${activeSelectedSlots?.length}/${requiredSlotCount} slots to complete the reservation.`}
                  </Alert>
                )}
              </span>
            </Fade>
          </Box>
        </Grid>
      </Grid>
      <PRContainer bare loading={loading}>
        <Grid container justifyContent="center" spacing={1} sx={{ minHeight: 280 }}>
          {editMode && (
            <Grid item xs={4}>
              <PalTypography variant="subtitle2">{viewingDate.clone().subtract(1, "day").format("LL")}</PalTypography>
              <Grid container alignItems="center" maxHeight={500} overflow="auto" onMouseUp={handleMouseUp}>
                {viewingSlotsPrev.map((slot, index, arr) => {
                  const start = DateHelper.getDateTimeLocal(slot.start_date);
                  const end = DateHelper.getDateTimeLocal(slot.end_date);
                  const isReservedFromMe = getIsReservedFromMe("prev", index);
                  return (
                    <Grid key={slot.id} item xs={12}>
                      <VerticalReservationBox
                        isFilled={!slot.available_slots && !isReservedFromMe}
                        isFirst={index === 0}
                        isLast={index === arr.length - 1}
                        isReservedFromMe={isReservedFromMe}
                        selected={selectedSlotsMap.prev.includes(index)}
                        onMouseDown={handleMouseDown("prev", index)}
                        onMouseOver={handleMouseOver("prev", index)}
                      >
                        <div>{`${start.format("LT")} - ${end.format("LT")}`}</div>
                      </VerticalReservationBox>
                    </Grid>
                  );
                })}
                {!viewingSlotsPrev.length && (
                  <PalTypography color="textSecondary" my={4} textAlign={"center"} variant="body2" width={1}>
                    No slots
                  </PalTypography>
                )}
              </Grid>
            </Grid>
          )}
          <Grid item xs={!editMode ? 12 : 4}>
            <PalTypography variant="subtitle2">{viewingDate.clone().format("LL")}</PalTypography>
            <Grid container alignItems="center" maxHeight={500} overflow="auto" onMouseUp={handleMouseUp}>
              {viewingSlotsCurrent.map((slot, index, arr) => {
                const start = DateHelper.getDateTimeLocal(slot.start_date);
                const end = DateHelper.getDateTimeLocal(slot.end_date);
                const isReservedFromMe = getIsReservedFromMe("current", index);
                return (
                  <Grid key={slot.id} item xs={12}>
                    <VerticalReservationBox
                      isFilled={!slot.available_slots && !isReservedFromMe}
                      isFirst={index === 0}
                      isLast={index === arr.length - 1}
                      isReservedFromMe={isReservedFromMe}
                      selected={selectedSlotsMap.current.includes(index)}
                      onMouseDown={handleMouseDown("current", index)}
                      onMouseOver={handleMouseOver("current", index)}
                    >
                      <div>{`${start.format("LT")} - ${end.format("LT")}`}</div>
                    </VerticalReservationBox>
                  </Grid>
                );
              })}
              {!viewingSlotsCurrent.length && (
                <PalTypography color="textSecondary" my={4} textAlign={"center"} variant="body2" width={1}>
                  No slots
                </PalTypography>
              )}
            </Grid>
          </Grid>
          {editMode && (
            <Grid item xs={4}>
              <PalTypography variant="subtitle2">{viewingDate.clone().add(1, "day").format("LL")}</PalTypography>
              <Grid container alignItems="center" maxHeight={500} overflow="auto" onMouseUp={handleMouseUp}>
                {viewingSlotsNext.map((slot, index, arr) => {
                  const start = DateHelper.getDateTimeLocal(slot.start_date);
                  const end = DateHelper.getDateTimeLocal(slot.end_date);
                  const isReservedFromMe = getIsReservedFromMe("next", index);
                  return (
                    <Grid key={slot.id} item xs={12}>
                      <VerticalReservationBox
                        isFilled={!slot.available_slots && !isReservedFromMe}
                        isFirst={index === 0}
                        isLast={index === arr.length - 1}
                        isReservedFromMe={isReservedFromMe}
                        selected={selectedSlotsMap.next.includes(index)}
                        onMouseDown={handleMouseDown("next", index)}
                        onMouseOver={handleMouseOver("next", index)}
                      >
                        <div>{`${start.format("LT")} - ${end.format("LT")}`}</div>
                      </VerticalReservationBox>
                    </Grid>
                  );
                })}
                {!viewingSlotsNext.length && (
                  <PalTypography color="textSecondary" my={4} textAlign={"center"} variant="body2" width={1}>
                    No slots
                  </PalTypography>
                )}
              </Grid>
            </Grid>
          )}
        </Grid>
      </PRContainer>
    </PRModal>
  );
}

export const EditReservationSlotDateModal = withCardon(EditReservationSlotDateModalContent, { destroyOnHide: true });

const AttendanceStatusDotMiniStyleProps = {
  border: "1px solid",
  borderColor: (theme) => theme.palette.grey[300],
  cursor: "pointer",
  "&:hover": {
    opacity: 0.7,
    transition: "opacity 0.125s",
  },
};
export const AttendanceStatusDot = ({ mini, isAttended, ...rest }) => {
  let color = "secondary";
  const theme = useTheme();
  switch (isAttended) {
    case organizationResourceAttendanceStatus.no_info:
      color = theme.palette.secondary.main;
      break;
    case organizationResourceAttendanceStatus.attended:
      color = theme.palette.success.main;
      break;
    case organizationResourceAttendanceStatus.not_attended:
      color = theme.palette.error.main;
      break;
    default:
      break;
  }

  return (
    <Box
      {...rest}
      bgcolor={color}
      borderRadius="50%"
      height={mini ? 12 : 14}
      minWidth={mini ? 12 : 14}
      sx={{
        ...(mini && AttendanceStatusDotMiniStyleProps),
        ...(rest.sx || {}),
      }}
      width={mini ? 12 : 14}
    />
  );
};

export const AttendanceButton = ({ renderButton, resource, reservation = {}, onUpdated }) => {
  const dispatch = useDispatch();
  const currentProject = useSelector(selectCurrentProject);
  // const [attendanceStatus, setAttendanceStatus] = useState(
  //   reservation?.attendance_status || organizationResourceAttendanceStatus.no_info
  // );
  const [anchorEl, setAnchorEl] = useState(null);
  const handleOpen = (e, ...c) => {
    console.log(c);
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClickUpdate = (attendanceStatus) => async (e) => {
    const payload = {
      button_type: "change_attended_status",
      payload: {
        reservation_id: reservation.id,
        is_attended: attendanceStatus,
      },
    };

    await dispatch(postReservationAction(currentProject.id, resource.id, payload)).then(() => {
      handleClose();
    });
    onUpdated?.(e, attendanceStatus);
  };

  return (
    <>
      {renderButton ? (
        renderButton({ onClick: handleOpen })
      ) : (
        <PalButton
          color="primary"
          endIcon={<AttendanceStatusDot isAttended={reservation?.is_attended} />}
          variant="contained"
          onClick={handleOpen}
        >
          Change Attendance Status
        </PalButton>
      )}
      <Popover
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: "bottom" }}
        open={Boolean(anchorEl)}
        slotProps={{
          paper: {
            sx: {
              marginTop: 0.5,
            },
          },
        }}
        onClose={handleClose}
      >
        <PalCard p={1}>
          <Grid item sm={12} xs={12}>
            <PalButton
              fullWidth
              color="primary"
              endIcon={<AttendanceStatusDot isAttended={organizationResourceAttendanceStatus.no_info} />}
              outline={reservation.is_attended !== organizationResourceAttendanceStatus.no_info}
              onClick={handleClickUpdate(organizationResourceAttendanceStatus.no_info)}
            >
              No Info
            </PalButton>
          </Grid>
          <Grid item sm={12} xs={12}>
            <PalButton
              fullWidth
              color="primary"
              endIcon={<AttendanceStatusDot isAttended={organizationResourceAttendanceStatus.attended} />}
              outline={reservation.is_attended !== organizationResourceAttendanceStatus.attended}
              onClick={handleClickUpdate(organizationResourceAttendanceStatus.attended)}
            >
              Attended
            </PalButton>
          </Grid>
          <Grid item sm={12} xs={12}>
            <PalButton
              fullWidth
              color="primary"
              endIcon={<AttendanceStatusDot isAttended={organizationResourceAttendanceStatus.not_attended} />}
              outline={reservation.is_attended !== organizationResourceAttendanceStatus.not_attended}
              onClick={handleClickUpdate(organizationResourceAttendanceStatus.not_attended)}
            >
              Not Attended
            </PalButton>
          </Grid>
        </PalCard>
      </Popover>
    </>
  );
};

function GenerateReservationBlock({ events, reservation, selectedSlotId }) {
  const relatedEvents = useMemo(() => {
    if (!events?.length) return [];
    const sortedEvents = [...events];
    sortedEvents.sort((a, b) => {
      return DateHelper.getDateTimeLocal(a.start).diff(DateHelper.getDateTimeLocal(b.start));
    });

    const slotIdList = reservation.slot?.length
      ? typeof reservation.slot?.[0] === "object"
        ? reservation.slot.map((s) => s.id)
        : reservation.slot
      : [];
    return sortedEvents.map((e) => ({
      ...e,
      isCurrentReserved: slotIdList?.includes(e.id),
    }));
  }, [events, reservation]);

  return (
    <Grid container overflow="auto" wrap="nowrap">
      {relatedEvents.map((event, index, arr) => {
        const eventStartHHmm = DateHelper.getDateTimeLocal(event.start).format("LT");
        const eventEndHHmm = DateHelper.getDateTimeLocal(event.end).format("LT");

        return (
          <Grid key={event.id} item xs="auto">
            <ReservationBox
              isActiveTab={event.id === selectedSlotId}
              isCurrentReserved={event.isCurrentReserved}
              isFirst={index === 0}
              isLast={index === arr.length - 1}
            >
              <div>{`${eventStartHHmm} - ${eventEndHHmm}`}</div>
            </ReservationBox>
          </Grid>
        );
      })}
    </Grid>
  );
}

const AvailableTabDot = styled.div`
  background-color: ${(props) => (props.available ? "var(--bs-success-300)" : "var(--bs-danger-300)")};
  border-radius: 50%;
  height: 16px;
  width: 16px;
  min-width: 16px;
  margin-left: ${({ theme }) => theme.spacing(0.5)};
  font-size: 10px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  border: ${({ theme }) => `1px solid ${theme.palette.grey[400]}`};
`;

function AddEditReservationSlot({
  get,
  resource: resourceProp,
  events: eventsProps,
  fetchEvents,
  date,
  doubleClick,
  reservedMode,
  slotActionsInfo,
  dynamic,
}) {
  const [loadingDelete, enqueueDelete] = useLoading(false);
  const [loadingUpdate, enqueueUpdate] = useLoading(false);

  const [events, setEvents] = useState(eventsProps);
  const [selectedSlotId, setSelectedSlotId] = useState(events?.[0]?.id);
  const [isModified, setIsModified] = useState(false);
  const slot = useSelector(selectSlot);
  const [resource, setResource] = useState(resourceProp);
  const resourceId = resource?.id;
  const [showAllCurrentReservations, setShowAllCurrentReservations] = useState(reservedMode || false);
  const [allRelatedReservations, setAllRelatedReservations] = useState([]);
  const [triggerFetchEvents, setTriggerFetchEvents] = useState(1);
  const userInfo = useSelector(selectUserInfo);
  const memberFieldFormat = useSelector(selectMemberFieldFormat);
  const memberDataFields = memberFieldFormat?.member_data_fields;
  // const [slotTab, setSlotTab] = useState();
  // const reservations = useSelector(selectReservations);

  const hasEditAction = slotActionsInfo?.action_list?.some((action) => action.button_type === "edit");
  const hasCreateAction = slotActionsInfo?.action_list?.some((action) => action.button_type === "create");

  const reservations = useMemo(
    () => (showAllCurrentReservations ? allRelatedReservations : slot?.reservation || []),
    [slot, allRelatedReservations, showAllCurrentReservations]
  );
  const dispatch = useDispatch();

  const selectedSlotObj = useMemo(() => events?.find((event) => event.id === selectedSlotId), [events, selectedSlotId]);

  const currentProject = useSelector(selectCurrentProject);

  const hasProjectFullRight = [userRole.admin, userRole.organizationManager].some((role) =>
    currentProject?.permissions?.includes(role)
  );
  const hasFullAccess = userInfo.is_superuser || hasProjectFullRight;

  const fetchResource = async () => {
    const newResource = await dispatch(getResource(resourceId, currentProject.id));
    setResource(newResource);
  };
  const handleChangeTab = (event, newValue) => {
    setSelectedSlotId(newValue);
  };

  useEffect(() => {
    (async () => {
      // if (showAllCurrentReservations) return;
      const currentDate = date;
      const beginOfDay = DateHelper.getDateTimeLocal(currentDate).startOf("day");
      const endOfDay = DateHelper.getDateTimeLocal(currentDate).endOf("day").subtract(1, "second");

      // let dateStart = eventsProps?.[0]?.start;
      // let dateEnd = eventsProps?.[0]?.end;

      endOfDay.utcOffset(0, true);
      beginOfDay.utcOffset(0, true);

      // const dateStartStr = dateStart?.toISOString();
      // const dateEndStr = dateEnd?.toISOString();
      const newEvents = await fetchEvents({
        start: beginOfDay,
        end: endOfDay,
      });
      setEvents(newEvents);
      //check is selectedSlot is still in newEvents
      if (!newEvents.some((event) => event.id === selectedSlotId)) {
        setSelectedSlotId(newEvents[0]?.id);
      }
      // setSelectedSlotId(newEvents[0]?.id);
      // if (showAllCurrentReservations) {
      // } else {
      //   dispatch(getSlot(newEvents[0]?.id, resourceId, currentProject.id, true));
      // }
    })();
  }, [
    fetchEvents,
    eventsProps,
    triggerFetchEvents,
    showAllCurrentReservations,
    dispatch,
    resourceId,
    currentProject?.id,
    date,
    // selectedSlotId,
  ]); // ignore showAllCurrentReservations,selectedSlotId

  const handleTriggerFetchEvents = () => {
    setTriggerFetchEvents((prev) => prev + 1);
  };

  const eventOptions = useMemo(() => {
    let options = [];
    if (events?.length) {
      options = events.map((event) => {
        const eventStartHHmm = DateHelper.getDateTimeLocal(event.start).format("LT");
        const eventEndHHmm = DateHelper.getDateTimeLocal(event.end).format("LT");

        const isAvailable = event.availableSlots > 0;

        let label = `${eventStartHHmm} - ${eventEndHHmm}`;
        const eventIdentifier = isAvailable ? event.availableSlots + " Available" : "Unavailable";
        if (eventIdentifier) {
          // label += ` (${eventIdentifier})`;
          label = (
            <Box display="flex" justifyContent="space-between">
              {label}
              <AvailableTabDot available={isAvailable}>{isAvailable ? event.availableSlots : "x"}</AvailableTabDot>
            </Box>
          );
        }

        return {
          id: event.id,
          label,
          value: event.id,
          start: event.start,
          end: event.end,
        };
      });

      //sort by start date

      options.sort((a, b) => {
        return DateHelper.getDateTimeLocal(a.start).diff(DateHelper.getDateTimeLocal(b.start));
      });
    }

    if (!doubleClick) {
      options.push({
        label: "Add new slot...",
        value: "add_new",
      });
    }
    return options;
  }, [events, doubleClick]);

  useEffect(() => {
    if (!resourceId || !currentProject.id || !selectedSlotId || selectedSlotId == "add_new") return;
    dispatch(getSlot(selectedSlotId, resourceId, currentProject.id, true));
  }, [dispatch, resourceId, currentProject.id, selectedSlotId]);

  useEffect(() => {
    if (!showAllCurrentReservations) return;
    const lowestEvent = events?.reduce((acc, event) => {
      if (moment(acc.start).isBefore(moment(event.start))) {
        return acc;
      }
      return event;
    }, events[0]);

    const highestEvent = events?.reduce((acc, event) => {
      // return moment(acc.end).isAfter(moment(event.end));
      if (moment(acc.end).isAfter(moment(event.end))) {
        return acc;
      }
      return event;
    }, events[0]);

    let dateStart = lowestEvent?.start;
    let dateEnd = highestEvent?.end;

    if (dateStart) {
      dateStart = moment(dateStart);
      dateStart.utcOffset(0, true);
    }
    if (dateEnd) {
      dateEnd = moment(dateEnd);
      dateEnd.utcOffset(0, true);
    }

    const dateStartStr = dateStart?.toISOString();
    const dateEndStr = dateEnd?.toISOString();

    dispatch(
      getReservations(
        resourceId,
        currentProject.id,
        {
          ...(dateStart && {
            start_date__gte: dateStartStr,
          }),
          ...(dateEnd && {
            end_date__lte: dateEndStr,
          }),
          limit: 99999,
        },
        {
          onSuccess: (response) => {
            const eventSlotIds = eventOptions.map((event) => event.id);
            let results = response.results || [];
            results = results.filter((reservation) => {
              const slotIdList = reservation.slot?.length
                ? typeof reservation.slot?.[0] === "object"
                  ? reservation.slot.map((s) => s.id)
                  : reservation.slot
                : [];
              return slotIdList.some((slotId) => eventSlotIds.includes(slotId));
            });

            //sort by eventOptions
            results.sort((a, b) => {
              const aIndex = eventSlotIds.indexOf(a.slot[0].id);
              const bIndex = eventSlotIds.indexOf(b.slot[0].id);
              return aIndex - bIndex;
            });

            setAllRelatedReservations(results);
          },
        }
      )
    );
  }, [dispatch, resourceId, currentProject.id, slotActionsInfo, events, eventOptions, showAllCurrentReservations]);

  const isEditMode = events?.length > 0;

  const currentHour = DateHelper.getDateTimeLocal().hour();
  const startDate = selectedSlotObj?.start
    ? DateHelper.getDateTimeLocal(selectedSlotObj.start)
    : DateHelper.getDateTimeLocal(date).add(currentHour, "hour");
  const endDate = selectedSlotObj?.end
    ? DateHelper.getDateTimeLocal(selectedSlotObj.end)
    : DateHelper.getDateTimeLocal(date).add(currentHour + 1, "hour");

  const theDay = useMemo(() => {
    return DateHelper.getDateTimeLocal(date).startOf("day");
  }, [date]);
  const handleCheckShowAllCurrentReservations = (e) => {
    setShowAllCurrentReservations(e.target.checked);
  };
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: selectedSlotObj?.id,
      resource: resourceId,
      start_date: startDate,
      end_date: endDate,
      available_slots: selectedSlotObj?.totalSlots ?? 1,
      // member: selectedEventObj?.reservation?.reserver?.id,
      all_day: startDate.clone().add(1, "day").isSame(endDate, "millisecond"),
    },
    validationSchema: Yup.object().shape({
      start_date: Yup.date().required("Start date is required"),
      end_date: Yup.date()
        .required("End date is required")
        .test("end-date", "End date must be after start date", function (value, context) {
          return (
            DateHelper.getDateTimeLocal(value).isAfter(DateHelper.getDateTimeLocal(context.parent.start_date)) ||
            context.parent.all_day
          );
        }),
      available_slots: Yup.number().required("Available slots is required"),
    }),

    onSubmit: async (values) => {
      let startDate = values.start_date;
      let endDate = values.end_date;
      if (values.all_day) {
        startDate = DateHelper.getDateTimeLocal(values.start_date).startOf("day");
        endDate = startDate.clone().add(1, "day");
      }
      const payload = {
        ...values,
        start_date: DateHelper.format(startDate),
        end_date: DateHelper.format(endDate),
      };
      try {
        await enqueueUpdate(dispatch(addOrUpdateResourceSlot(payload, resourceId, currentProject.id)));

        // const isCreateOrUpdateReservation = selectedEventObj?.reservation?.id !== payload.member;
        // const isDeleteReservation = selectedEventObj?.reservation?.id && !payload.member;
        // if (isDeleteReservation) {
        //   await dispatch(deleteReservation(selectedEventObj?.reservation?.id, resourceId, currentProject.id, true));
        // } else if (isCreateOrUpdateReservation) {
        //   const data = {
        //     id: selectedEventObj?.reservation?.id,
        //     reserver: {
        //       id: payload.member,
        //     },
        //     slot: responseSlot.id,
        //   };
        //   await dispatch(createOrUpdateReservation(data, resourceId, currentProject.id));
        // }
        // get(true)();
        handleTriggerFetchEvents();
        setIsModified(true);
      } catch {
        // get(isModified || false)();
      }
    },
  });

  const handleChange = (key) => (data) => {
    const value = data?.target?.value || data;
    if (key === "available_slots" && !isNaN(value)) {
      if (reservations?.length > value) {
        AlertHelper.showError("Total reservation count should be greater than or equal to current reservation count.");
        return;
      }
      if (value < 0) return;
    }
    formik.setFieldValue(key, value);
  };

  const handleClickSelectEvent = (data) => {
    setSelectedSlotId(data);
  };
  // const handleChangeMember = (data) => {
  //   formik.setFieldValue("member", data);
  // };

  // const selectedMemberObj = useMemo(() => {
  //   const matchedMember = members?.find((member) => member.id === (formik.values.member || memberData?.reserver?.id));
  //   return matchedMember?.safe_information || {};
  // }, [members, formik.values.member, memberData]);

  const handleClickDeleteEvent = async () => {
    if (!(await DialogHelper.showQuestionDelete())) return;
    if (selectedSlotObj?.reservation?.id) {
      await enqueueDelete(
        dispatch(deleteReservation(selectedSlotObj?.reservation?.id, resourceId, currentProject.id)).then(() => {
          get(true)();
        })
      );
    }
    enqueueDelete(
      dispatch(deleteResourceSlot(selectedSlotObj.id, resourceId, currentProject.id)).then(() => {
        get(true)();
      })
    );
  };

  const handleReservationAddUpdate = async () => {
    if (selectedSlotObj?.availableSlots === 0) {
      AlertHelper.showError(
        "Reservation count is full. Please delete some reservations to add new or increase total count."
      );
      return;
    }
    // const result = await AddEditReservationSlotDetailModal.show({
    //   reservation: null,
    //   slotId: selectedSlotId,
    //   resource,
    // });
    const result = await EditReservationSlotDateModal.show({
      events,
      reservation: null,
      resource,
      slotActionsInfo,
      editMode: false,
      hasEditAction,
      hasCreateAction,
    });

    if (result) {
      // dispatch(getReservations(resourceId, currentProject.id));

      dispatch(getSlot(selectedSlotId, resourceId, currentProject.id, true));
      setIsModified(true);
      if (showAllCurrentReservations) {
        handleTriggerFetchEvents();
      }
    }
  };

  const handleDateChange = (key) => (date) => {
    if (typeof date === "string") {
      handleChange(key)(date);
      return;
    }
    const day = theDay.date();
    const month = theDay.month();
    const year = theDay.year();
    const hours = date.hours();
    const minutes = date.minutes();
    const newDate = moment({ year, month, day, hours, minutes });
    handleChange(key)(newDate);
  };

  const handleSlotActionDynamic = (actionItem, reservation) => async () => {
    if (actionItem.button_type === "edit") {
      const result = await EditReservationSlotDateModal.show({
        events,
        reservation: reservation,
        resource,
        slotActionsInfo,
        editMode: true,
      });
      if (result) {
        handleTriggerFetchEvents();
        setIsModified(true);
      }
    } else if (actionItem.button_type === "add_blacklist") {
      dispatch(addBlacklistResourceUser(currentProject.id, resourceId, reservation.reserver.id)).then(async () => {
        await handleTriggerFetchEvents();
        await fetchResource();
        setIsModified(true);
      });
    } else if (actionItem.button_type === "remove_blacklist") {
      dispatch(removeBlacklistResourceUser(currentProject.id, resourceId, reservation.reserver.id)).then(async () => {
        await handleTriggerFetchEvents();
        await fetchResource();
        setIsModified(true);
      });
    } else {
      if (actionItem.ask_yes_no) {
        if (!(await DialogHelper.showQuestionYesNo(actionItem.ask_yes_no))) return;
      }
      await dispatch(
        postReservationAction(currentProject.id, resourceId, {
          button_type: actionItem.button_type,
          payload: {
            ...actionItem?.payload,
            reservation_id: reservation.id,
            member_id: reservation?.reserver?.id,
          },
        })
      );

      handleTriggerFetchEvents();
      setIsModified(true);
    }
  };

  // const targetUserInfo = Object.entries(selectedEventObj?.customer || selectedMemberObj || {});

  const handleChangeAllDay = (e) => {
    formik.setFieldValue("all_day", e.target.checked);
  };

  const reservationRangeStr = useMemo(() => {
    const exitingEvents = eventOptions.filter((event) => event.id);
    const firstEvent = exitingEvents[0];
    const lastEvent = exitingEvents[exitingEvents.length - 1];
    const firstEventDate = DateHelper.getDateTimeLocal(firstEvent?.start);
    const lastEventDate = DateHelper.getDateTimeLocal(lastEvent?.end);
    const dateRangeSTr = `${firstEventDate.format("LT")} - ${lastEventDate.format("LT")}`;
    return dateRangeSTr;
  }, [eventOptions]);

  const visibleSlotActions = useMemo(() => {
    const hideList = ["create", "reservation_type"];

    const filteredActions = slotActionsInfo?.action_list?.filter((action) => !hideList.includes(action.button_type));
    return filteredActions;
  }, [slotActionsInfo?.action_list]);

  const handleClickNewSlot = () => {
    setSelectedSlotId("add_new");
  };

  return (
    <Modal centered isOpen={true} size="xl" zIndex={modalZIndex}>
      <div className="modal-header">
        <h5 className="modal-title mt-0">{isEditMode ? "Edit" : "Add"} Reservation Slot</h5>
        <button aria-label="Close" className="close" data-dismiss="modal" onClick={get(isModified || false)}>
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body mb-3">
        {/* {!reservedMode && ( */}
        <>
          <Grid item sm={6} xs={12}>
            <div className="d-flex align-items-center">
              <Switch
                checked={showAllCurrentReservations}
                disabled={reservedMode}
                onChange={handleCheckShowAllCurrentReservations}
              />
              <Label className="mb-0 me-2">
                {showAllCurrentReservations ? `All reservations (${reservationRangeStr})` : "Slot only reservations"}
              </Label>
            </div>
          </Grid>
          {!showAllCurrentReservations && !dynamic && (
            <PRPage collapse title={`Slot (${eventOptions?.length})`}>
              {isEditMode && (
                <Box alignItems="center" display="flex" justifyContent="space-between">
                  <Tabs value={selectedSlotId} variant="scrollable" onChange={handleChangeTab}>
                    {eventOptions.map((event) => (
                      <Tab key={event.value} label={event.label} value={event.value} />
                    ))}
                  </Tabs>
                  {eventOptions.some((event) => event.value === "add_new") && (
                    <PalIconButton color="primary" onClick={handleClickNewSlot}>
                      <Add />
                    </PalIconButton>
                  )}
                </Box>
                // <PRTab
                //   tab={selectedSlotId}
                //   tabList={eventOptions}
                //   valueSelector="value"
                //   onChange={handleClickSelectEvent}
                // />
              )}

              <Row className="mt-2 g-2">
                <Col disabled={formik.values.all_day} lg={4}>
                  <Label>Start Date</Label>
                  <PRDate
                    dateFormat={false}
                    invalid={formik.touched.start_date && formik.errors.start_date}
                    placeholder="Enter start date"
                    value={formik.values.start_date}
                    onChange={handleDateChange("start_date")}
                  />
                </Col>
                <Col disabled={formik.values.all_day} lg={4}>
                  <Label>End Date</Label>
                  <PRDate
                    dateFormat={false}
                    invalid={formik.touched.end_date && formik.errors.end_date}
                    placeholder="Enter end date"
                    value={formik.values.end_date}
                    onChange={handleDateChange("end_date")}
                  />
                </Col>
                <Col disabled={formik.values.all_day} lg={4}>
                  <Label>Total Reservation Count</Label>
                  <PRInput
                    dateFormat={false}
                    invalid={formik.touched.available_slots && formik.errors.available_slots}
                    placeholder="Enter Total Reservation Count"
                    type="number"
                    value={formik.values.available_slots}
                    onChange={handleChange("available_slots")}
                  />
                </Col>
                {/* {!reservedMode && ( */}
                <Col className="d-flex justify-content-between" xs={12}>
                  {/* <Grid container alignItems={"center"} className="mb-2">
                    <Grid item sm={6} xs={12}>
                      <div className="d-flex align-items-center">
                        <Switch checked={formik.values.all_day} onChange={handleChangeAllDay} />
                        <Label className="mb-0 me-2">All-day</Label>
                      </div>
                    </Grid>
                  </Grid> */}
                  <PRButton
                    color="primary"
                    disabled={loadingDelete || loadingUpdate}
                    loading={loadingUpdate}
                    type="button"
                    onClick={formik.handleSubmit}
                  >
                    {isEditMode && selectedSlotId !== "add_new" ? "Update" : "Create"}
                  </PRButton>
                </Col>
                {/* )} */}
              </Row>
            </PRPage>
          )}
        </>
        {/* // )} */}
        <PRPage
          collapse
          title={
            "Reservations" +
            (!showAllCurrentReservations
              ? ` ${reservations?.length || 0}/${selectedSlotObj?.totalSlots || 0}`
              : ` - ${reservations?.length || 0}`)
          }
        >
          <PalShadowWrapper
            style={{
              maxHeight: showAllCurrentReservations ? 700 : 400,
              overflowY: "auto",
              overflowX: "hidden",
              border: "1px solid var(--bs-secondary-400)",
            }}
          >
            {reservations.map((item, index, arr) => {
              const identifierKey = item.reserver?.identifier?.name;
              const identifierLabel = item.reserver?.identifier?.display_name;
              const identifierValue = item.reserver?.safe_information?.[identifierKey];
              const displayLabel = `${identifierLabel}: ${identifierValue}`;
              const displayEmail = identifierKey ? item.reserver?.safe_information?.email : item.customer?.email;
              const displayName = identifierKey ? item.reserver?.safe_information?.full_name : item.customer?.full_name;
              // if (item?.customer?.email) {
              //   displayLabel = `Email: ${item?.customer?.email}`;
              // } else if (item?.customer?.phone_number) {
              //   displayLabel = `Phone: ${item?.customer?.phone_number}`;
              // } else if (item?.customer?.full_name) {
              //   displayLabel = `Name: ${item?.customer?.full_name}`;
              // } else {
              //   displayLabel = `${identifierLabel}: ${identifierValue}`;
              // }

              // const handleEditReservation = async () => {
              //   const result = await AddEditReservationSlotDetailModal.show({
              //     reservation: item,
              //     slotId: selectedSlotId,
              //     resource,
              //   });
              //   if (result) {
              //     // dispatch(getReservations(resourceId, currentProject.id));

              //     dispatch(getSlot(selectedSlotId, resourceId, currentProject.id, true));
              //     setIsModified(true);
              //   }
              // };
              // const handleDeleteReservation = async () => {
              //   if (!(await DialogHelper.showQuestionDelete())) return;
              //   await enqueueDelete(
              //     dispatch(deleteReservation(item.id, resourceId, currentProject.id)).then(() => {
              //       // dispatch(getReservations(resourceId, currentProject.id));
              //       dispatch(getSlot(selectedSlotId, resourceId, currentProject.id, true));
              //     })
              //   );
              // };

              const publicFields = memberDataFields.filter(
                (item) => item.visibility_type === organizationVisibilityType.PUBLIC
              );

              const slotIdList = item.slot?.length
                ? typeof item.slot?.[0] === "object"
                  ? item.slot.map((s) => s.id)
                  : item.slot
                : [];
              const relatedEvents = events?.filter((event) => slotIdList.includes(event.id));

              let lowestEventStartDate = relatedEvents?.reduce((acc, event) => {
                if (moment(acc.start).isBefore(moment(event.start))) {
                  return acc;
                }
                return event;
              }, relatedEvents[0]);
              lowestEventStartDate = DateHelper.getDateTimeLocal(lowestEventStartDate?.start);

              let highestEventEndDate = relatedEvents?.reduce((acc, event) => {
                if (moment(acc.end).isAfter(moment(event.end))) {
                  return acc;
                }
                return event;
              }, relatedEvents[0]);
              highestEventEndDate = DateHelper.getDateTimeLocal(highestEventEndDate?.end);

              const isInBlacklist = resource.blacklist?.some((blacklist) => blacklist.id === item?.reserver?.id);

              const orderedPublicFields = [...publicFields].sort((a, b) => a.order - b.order);

              return (
                <Fragment key={item.id}>
                  <Row className="m-2 g-1 align-items-center">
                    <StyledReservationCol xs className="text-truncate">
                      {/* <Grid container>
                        <Grid item xs={12} sm={6}>

                        </Grid>
                      </Grid> */}
                      <Grid container spacing={0.5}>
                        {orderedPublicFields.map((field) => {
                          return (
                            <Grid key={field.name} item overflow="hidden" xs={6}>
                              <Box overflow="hidden">
                                <Typography
                                  noWrap
                                  component={"div"}
                                  fontWeight={"bold"}
                                  lineHeight={1}
                                  variant="caption"
                                >
                                  {field.display_name || field.name}
                                </Typography>
                              </Box>
                              <Typography noWrap component={"div"} variant="caption">
                                <CustomerListCellRenderer
                                  cellKey={item.name}
                                  memberDataFields={memberDataFields}
                                  value={item.reserver?.safe_information?.[field.name]}
                                />
                              </Typography>
                            </Grid>
                          );
                        })}
                      </Grid>
                      {/* {displayName && (
                        <Label size="md">
                          {`Name: ${displayName}`}{" "}
                          {isInBlacklist && (
                            <span className="text-danger" size="md">
                              (Blacklisted)
                            </span>
                          )}
                        </Label>
                      )} */}
                      {/* {displayEmail && <Label size="md">{`Email: ${displayEmail}`}</Label>}
                      {identifierKey && identifierKey !== "email" && identifierKey !== "full_name" && (
                        <Label size="md">{displayLabel}</Label>
                      )} */}
                    </StyledReservationCol>
                    <Col className="gap-1 d-flex" xs="auto">
                      {/* <button
                      onClick={() => {
                        EditReservationSlotDateModal.show({
                          events,
                          reservation: item,
                          resource,
                        });
                      }}
                    ></button> */}
                      {visibleSlotActions?.length > 0 &&
                        visibleSlotActions.map((actionItem) => {
                          const { icon: _, label, ...buttonProps } = actionItem?.button_props || {};
                          const ButtonComp =
                            actionItem?.button_type === "change_attended_status" ? AttendanceButton : PalButton;
                          return (
                            <ButtonComp
                              size="small"
                              {...buttonProps}
                              key={actionItem.button_type}
                              variant="contained"
                              // tooltipText={label}
                              onClick={handleSlotActionDynamic(actionItem, item)}
                              {...(actionItem.button_type === "change_attended_status" && {
                                resource,
                                reservation: item,
                                onUpdated: handleTriggerFetchEvents,
                              })}
                              {...(actionItem?.button_type === "add_blacklist" && {
                                disabled: !item?.reserver?.id || isInBlacklist,
                              })}
                              {...(actionItem?.button_type === "remove_blacklist" && {
                                disabled: !item?.reserver?.id || !isInBlacklist,
                              })}
                            >
                              {label}
                            </ButtonComp>
                          );
                        })}
                    </Col>
                    <Col className="gap-1 d-flex" xs="12">
                      <Typography fontWeight={"bold"} variant="caption">
                        {DateHelper.getDateTimeLocal(date).format("L")}{" "}
                        {`${DateHelper.getDateTimeLocal(lowestEventStartDate).format(
                          "LT"
                        )} - ${DateHelper.getDateTimeLocal(highestEventEndDate).format("LT")}`}
                        {item?.secret_note?.islem_adi && ` (${item?.secret_note?.islem_adi})`}
                      </Typography>
                    </Col>
                    <Col className="gap-1 d-flex" xs="12">
                      <GenerateReservationBlock
                        events={events}
                        reservation={item}
                        selectedSlotId={!showAllCurrentReservations && selectedSlotId}
                      />
                    </Col>
                  </Row>
                  {index !== arr.length - 1 && <PRDivider className="my-1" color="secondary-400" />}
                </Fragment>
              );
            })}
          </PalShadowWrapper>
          {hasCreateAction && (
            <Row className="justify-content-end g-1 mt-2">
              <Col xs={"auto"}>
                <PRButton
                  className="me-2"
                  color="success"
                  disabled={reservedMode}
                  icon={MdAdd}
                  size="sm"
                  tooltipText="Add Reservation"
                  onClick={handleReservationAddUpdate}
                />
              </Col>
            </Row>
          )}
        </PRPage>
      </div>
      <div className="modal-footer">
        <PRButton outline color="primary" data-dismiss="modal" onClick={get(isModified || false)}>
          Close
        </PRButton>
        {isEditMode && hasFullAccess && (
          <PRButton
            color="danger"
            disabled={
              !selectedSlotObj?.id ||
              loadingDelete ||
              loadingUpdate ||
              (showAllCurrentReservations && reservations?.length)
            }
            loading={loadingDelete}
            type="button"
            onClick={handleClickDeleteEvent}
          >
            Delete
          </PRButton>
        )}
      </div>
    </Modal>
  );
}
const AddEditReservationSlotModal = withCardon(AddEditReservationSlot, { destroyOnHide: true });
export default AddEditReservationSlotModal;
